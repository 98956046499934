import { IEntityListConfig, EntityListConfig, EntityListColumn, SelectTypes, IEntityListSelectColumn } from '@mt-ng2/entity-list-module';
import { IOrder } from '../../model/interfaces/order';
import { ArchiveOrderListComponent } from './archive-order-list-component';

export class OrdersEntityListConfig extends EntityListConfig {
    constructor(userIsCarrierOrCustomer: boolean) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['OrderNumber'],
                    name: 'Order Number', sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity) => {
                        if (userIsCarrierOrCustomer) {
                            return `<span>${entity["Customer"]["Name"] as string}</span>`;
                        }
                        return `<a>${entity["Customer"]["Name"] as string}</a>`;
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: !userIsCarrierOrCustomer,
                    name: 'Customer',
                    sort: {
                        sortProperty: 'Customer.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity) => {
                        if (!entity["Warehouse"]) {
                            return '<span class="invalid-warehouse-label"><b>INVALID ID</b></span>';
                        }
                        if (userIsCarrierOrCustomer) {
                            return `<span>${entity["Warehouse"]["WarehouseId"] as string}</span>`;
                        }
                        return `<a>${entity["Warehouse"]["WarehouseId"] as string}</a>`;
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: !userIsCarrierOrCustomer,
                    name: 'Warehouse',
                    sort: {
                        sortProperty: 'Warehouse.WarehouseId',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order.Warehouse ? order.Warehouse.Division : '';
                    },
                    name: 'Division',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CreationDate'],
                    name: 'Creation Date',
                    pipes: ['date'],
                    sort: { isDefaultForSort: true },
                }),
                new EntityListColumn({
                    accessors: ['CustomerPickupDate'],
                    name: 'Pickup Date',
                    pipes: ['date'],
                    sort: { isDefaultForSort: true },
                }),
                new EntityListColumn({
                    component: ArchiveOrderListComponent,
                    fireOnColumnSelected: false,
                    name: 'Archive',
                    sort: {
                        disableSort: true,
                    },
                    excludeFromView: userIsCarrierOrCustomer
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder) => {
                        return `<span>${order?.Warehouse?.Office?.Title }</span>`;
                    },
                    bindToInnerHtml: true,
                    excludeFromView: !userIsCarrierOrCustomer,
                    name: 'Location',
                    sort: {
                        sortProperty: 'Warehouse.Office.Title',
                    },
                }),
            ]
        };
        
        if (userIsCarrierOrCustomer)
            listConfig.select = { type: SelectTypes.Multi } as IEntityListSelectColumn;

        super(listConfig);
    }
}
