import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '../model/interfaces/user';
import { IPhone } from '../model/interfaces/base';
import { ICreateUserPayload } from '../model/interfaces/custom/create-user-payload';
import { IAddress } from '@mt-ng2/dynamic-form';
import { IOffice } from '../model/interfaces/office';
import { UpdateUserPicResult } from '../model/interfaces/custom/update-user-pic-result.dto'
import { NotificationTypes } from '../model/NotificationTypes';
import { ICustomer } from '../model/interfaces/customer';

@Injectable()
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        BannedFromPickup: false,
        BannedFromPickupReason: null,
        CarrierId: null,
        Email: '',
        FirstName: '',
        Id: 0,
        IsArchived: false,
        IsMobileUser: false,
        LastName: '',
        NotificationTypeId: NotificationTypes.None,
        Phone: null,
        TractorLicensePlate: '',
        TrailerLicensePlate: '',
        TruckingCompanyName: null,
        UsdotNumber: null,
        Version: null,
    };

    constructor(public http: HttpClient) {
        super('/users', http);
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data);
    }

    saveUserOffices(userId: number, offices: IOffice[]): Observable<number> {
        return this.http.put<number>(`/users/${userId}/offices`, offices);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/address`, { responseType: 'text' as 'json' });
    }

    savePhones(userId: number, phones: IPhone[]): Observable<number> {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    savePhoto(userId: number, photo: File): Observable<UpdateUserPicResult> {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post<UpdateUserPicResult>(`/users/${userId}/pic`, formData);
    }

    deletePhoto(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/pic`);
    }

    ArchiveUser(userId: number): Observable<object> {
        return this.http.post('/users/archive', userId);
    }

    revokeAccess(userId: number): Observable<object> {
        return this.http.post(`/users/${userId}/revoke-access`, null);
    }

    toggleTwicCard(userId: number): Observable<boolean> {
        return this.http.put<boolean>(`/users/${userId}/toggleTwicCard`, null);
    }

    static checkUserIsTwicVerified(user: IUser): boolean {
        const twicCardIsCaptured = user.TwicBackImageId && user.TwicFrontImageId ;
        const twicCardExpired = new Date(user.TwicExpirationDate) < new Date();
        return twicCardIsCaptured && !twicCardExpired;
    }

    updateVersion(user: IUser): Observable<number[]> {
        return this.http.put<number[]>(`/users/${user.Id}/version`, user);
    }

    connectUserAndContact(userId: number, contactId: number ): Observable<number>
    {
        return this.http.post<number>(`/users/${userId}/${contactId}/connect`, null);
    }

    getPrimaryCustomer(userId: number): Observable<ICustomer> {
        return this.http.get<ICustomer>(`/users/${userId}/primary-customer`);
    }
}
