<table class="delivery-table">
    <thead>
        <tr>
            <th>Delivery Number</th>
            <th>Status</th>
            <th>Delivery Equipment Type</th>
            <th>Delivery Type</th>
            <th>Trailer Type</th>
            <th>Carrier</th>
            <th>Customer</th>
            <th>Warehouse</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let delivery of deliveries">
            <td>{{delivery.DeliveryNumber || 'N/A'}}</td>
            <td>{{DeliveryStatuses[delivery.StatusId]}}</td>
            <td>{{DeliveryEquipmentTypes[delivery.DeliveryEquipmentTypeId] || 'N/A'}}</td>
            <td>{{DeliveryTypes[delivery.DeliveryTypeId] || 'N/A'}}</td>
            <td>{{TrailerTypes[delivery.TrailerTypeId] || 'N/A'}}</td>
            <td>{{delivery.Carrier?.Name || 'N/A'}}</td>
            <td>{{delivery.Customer?.Name || 'N/A'}}</td>
            <td>{{delivery.Warehouse?.WarehouseId || 'N/A'}}</td>
        </tr>
    </tbody>
</table>
<br>
<button class="btn btn-primary" (click)="goToDeliveriesPage()">Go to Deliveries Page</button>
<button class="btn btn-danger" (click)="close()">Cancel</button>
