<div class="row">
    <div class="col-md-7">
        <h2>Upcoming Pickup Schedule</h2>
        <h4>
            Click a Pickup Appointment to see details or edit.
        </h4>
    </div>
</div>
<div>
    <mt-dynamic-search-filters [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search($event)"></mt-dynamic-search-filters>
    <mt-search-filter-daterange
        *ngIf="datepickerEntityName"
        [entity]="datepickerEntityName"
        [startDate]="startDate"
        [endDate]="endDate"
        (selectionChanged)="dateSelectionChanged($event)"
    ></mt-search-filter-daterange>
    <button class="btn btn-success" (click)="clear()">Clear</button>
</div>
<br>
<div class="table-responsive">
    <table class="table table-hover">
        <thead>
            <tr>
                <th (click)="orderBy($event, 'PickupNumber')">
                    Pickup Number
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'StatusId')">
                    Status
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'DateRequested')">
                    Desired Pickup Date
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'DateConfirmed')">
                    Confirmed Pickup Date
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th *ngIf="isAdminUser || isCarrierUser" (click)="orderBy($event, 'Driver.FirstName')">
                    Driver
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th *ngIf="isAdminUser || isCustomerUser" (click)="orderBy($event, 'Carrier.Name')">
                    Trucking Company
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'Orders.Sum(o => o.TotalPallet)')">
                    Total Pallet Count
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'Orders.Sum(o => o.TotalWeight)')">
                    Total Order Weight
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'Carrier.Name')">
                    Security Gate Fee
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'Carrier.Name')">
                    Prepaid Amount
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'Carrier.Name')">
                    Pickup Balance
                    <span>
                        <i class="fa fa-sort-asc"></i>
                        <i class="fa fa-sort-desc"></i>
                    </span>
                </th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let pickup of pickups">
                <tr class="no-pointer pickup-row">
                    <td class="ellipsis">
                        {{ pickup.PickupNumber }}
                    </td>
                    <td class="ellipsis">
                        {{ getStatusName(pickup.StatusId) }}
                    </td>
                    <td class="ellipsis">
                        {{ formatDateRequested(pickup) }}
                    </td>
                    <td class="ellipsis">
                        {{ pickup.DateConfirmed | date: 'medium' }}
                    </td>
                    <td *ngIf="isAdminUser || isCarrierUser" class="ellipsis">
                        <span *ngIf="pickup?.Driver?.Id > 0">
                            {{ pickup.Driver.FirstName }} {{ pickup.Driver.LastName }} - {{ pickup.Driver.Phone | phoneFormat }}
                        </span>
                    </td>
                    <td *ngIf="isAdminUser || isCustomerUser" class="ellipsis">
                        <span *ngIf="pickup.CarrierId > 0">{{ pickup.Carrier?.Name }}</span>
                        <span *ngIf="isAdminUser && pickup.CarrierId <= 0">
                            <mt-type-ahead
                                #carrierTypeaheadControl
                                [items]="carriers"
                                [maxToShow]="maxToShow"
                                (selectionChanged)="onCarrierSelected($event, pickup)">
                            </mt-type-ahead>
                        </span>
                    </td>
                    <td>
                        {{ totalPalletCount(pickup) }}
                    </td>
                    <td>
                        {{ totalOrderWeight(pickup) }}
                    </td>
                    <td class="ellipsis">
                        {{ pickup.SecurityGateFee | currency:'USD' }}
                    </td>
                    <td class="ellipsis">
                        {{ totalPickupPrePayment(pickup) | currency:'USD' }}
                    </td>
                    <td class="ellipsis">
                        {{ totalRemainingBalance(pickup) | currency:'USD' }}
                    </td>
                    <td>
                        <button *ngIf="showConfirmButton(pickup)" (click)="confirmPickup(pickup)" class="btn btn-success btn-sm">Confirm</button>
                        <button *ngIf="showEditButton(pickup)" (click)="editPickup(pickup)" class="btn btn-info btn-sm">Edit</button>
                        <button *ngIf="isCarrierUser && showPrePayButtons.get(pickup.Id)" (click)="prePayPickup(pickup)" class="btn btn-default btn-sm">Pre-Pay</button>
                        <button (click)="cancelPickup(pickup)" class="btn btn-danger btn-sm">Cancel</button>
                    </td>
                </tr>
                <tr class="no-pointer no-border order-row">
                    <th class="ellipsis sub-item-header">
                        <b>Order Number</b>
                    </th>
                    <th class="ellipsis sub-item-header">
                        <b>Office</b>
                    </th>
                    <th class="ellipsis sub-item-header">
                        <b>Warehouse</b>
                    </th>
                    <th *ngIf="isAdminUser || isCarrierUser" class="ellipsis sub-item-header">
                        <b>Customer</b>
                    </th>
                    <th *ngIf="isAdminUser || isCustomerUser" class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header">
                        <b>Pallet Count</b>
                    </th>
                    <th class="ellipsis sub-item-header">
                        <b>Order Weight</b>
                    </th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                </tr>
                <tr class="no-pointer no-border order-row" *ngFor="let order of pickup.Orders">
                    <td class="ellipsis sub-item">
                        {{ order.OrderNumber }}
                    </td>
                    <td class="ellipsis sub-item">
                        {{ order?.Warehouse?.Office?.Title ?? '' }}
                    </td>
                    <td *ngIf="order?.Warehouse?.WarehouseId" class="ellipsis sub-item">
                        {{ order?.Warehouse?.WarehouseId }}
                    </td>
                    <td *ngIf="!order?.Warehouse?.WarehouseId" class="ellipsis sub-item">
                        <mt-type-ahead
                            #warehouseTypeaheadControl
                            *ngIf="isAdminUser"
                            [items]="warehouses"
                            nameProperty="WarehouseId"
                            [maxToShow]="maxToShow"
                            (selectionChanged)="onWarehouseSelected($event, order, pickup)">
                        </mt-type-ahead>
                    </td>
                    <td *ngIf="isAdminUser || isCarrierUser" class="ellipsis sub-item">
                        {{ order?.Customer?.Name ?? '' }}
                    </td>
                    <td *ngIf="isAdminUser || isCustomerUser" class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    
                    <td *ngIf="isAdminUser" class="ellipsis sub-item">
                        <a class="update-order-link" (click)="updateOrderTotalPallet(order)">
                            {{ order.TotalPallet ?? 0 }}
                        </a>
                    </td>
                    <td *ngIf="!isAdminUser" class="ellipsis sub-item">
                        {{ order.TotalPallet ?? 0 }}
                    </td>
                    <td *ngIf="isAdminUser" class="ellipsis sub-item">
                        <a class="update-order-link" (click)="updateOrderTotalWeight(order)">
                            {{ order.TotalWeight?.toFixed(2) ?? '0.00'}} lbs
                        </a>
                    </td>
                    <td *ngIf="!isAdminUser" class="ellipsis sub-item">
                        {{ order.TotalWeight?.toFixed(2) ?? '0.00'}} lbs
                    </td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                </tr>
                <tr class="no-pointer no-border order-row" *ngIf="pickup.Orders.length <= 0">
                    <td class="ellipsis sub-item">
                        N/A
                    </td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td *ngIf="isAdminUser || isCarrierUser" class="ellipsis sub-item"></td>
                    <td *ngIf="isAdminUser || isCustomerUser" class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<mt-modal-wrapper
    [showActions]="false"
    [autoShow]="false"
    (ready)="orderTotalPalletDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }">
    
    <order-total-pallet-dialog *ngIf="selectedOrder" [order]="selectedOrder" (onClose)="closeOrderTotalPalletDialog()"></order-total-pallet-dialog>
</mt-modal-wrapper>

<mt-modal-wrapper
    [showActions]="false"
    [autoShow]="false"
    (ready)="orderTotalWeightDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }">

    <order-total-weight-dialog *ngIf="selectedOrder" [order]="selectedOrder" (onClose)="closeOrderTotalWeightDialog()"></order-total-weight-dialog>
</mt-modal-wrapper>
