import { ModuleWithProviders, NgModule } from '@angular/core';
import { NavModule, NavSidebarServiceToken } from '@mt-ng2/nav-module';
import { NavSidebarService } from './sidebar.service';
import { RegistrationRequestsNavComponent } from './registration-requests-nav.component'
import { WalkInOrdersNavComponent } from './walk-in-orders-nav.component';
import { RouterModule } from '@angular/router';

@NgModule({
    exports: [NavModule],
    imports: [NavModule, RouterModule],
    declarations: [RegistrationRequestsNavComponent, WalkInOrdersNavComponent]
})
export class AppNavModule {
    static forRoot(): ModuleWithProviders<AppNavModule> {
        return {
            ngModule: AppNavModule,
            providers: [{ provide: NavSidebarServiceToken, useClass: NavSidebarService }, NavSidebarService],
        };
    }
}
