import { Component, Input, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../user.service';
import { IUser } from '../../model/interfaces/user';
import { UserDynamicConfig } from '../user.dynamic-config';
import { IUserRole } from '../../model/interfaces/user-role'; // added
import { IAuthUser } from '../../model/interfaces/auth-user'; // added
import { OfficeService } from '../../offices/services/office.service';
import { IOffice } from '../../model/interfaces/office';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { tap } from 'rxjs/operators';
import { MetaItem } from '@mt-ng2/base-service';

@Component({
    selector: 'app-user-office-info',
    templateUrl: './user-office-info.component.html',
})
export class UserOfficeInfoComponent implements OnInit {
    @Input() user: IUser;
    @Input() canEdit: boolean;
    authUser: IAuthUser;
    roles: IUserRole[];
    isEditing: boolean;
    isHovered: boolean;
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    offices: IOffice[];
    officeSelectItems: MultiselectItem[];
    selectedOffices: MetaItem[];
    constructor(private userService: UserService, private notificationsService: NotificationsService, private officeService: OfficeService) {}

    ngOnInit(): void {
        this.isEditing = false;
        forkJoin(this.getOffices()).subscribe();
    }

    getOffices(): Observable<IOffice[]> {
        return this.officeService.getAll().pipe(
            tap((answer) => {
                this.offices = answer;
                this.user.Offices.sort((a, b) => a.Title.localeCompare(b.Title));
                this.officeSelectItems = this.offices.map(
                    (item) => new MultiselectItem(new MetaItem(item.Id, item.Title), this.isOfficePreSelected(item.Id)),
                );
            }),
        );
    }

    isOfficePreSelected(officeId: number): boolean {
        return this.user.Offices.some((uo) => uo.Id === officeId);
    }

    officeSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedOffices = event.selectedItems;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(): void {
        if (this.selectedOffices?.length > 0) {
            this.offices = this.selectedOffices.map((selectedOffice) =><IOffice> {
                    Archived: false,
                    Id: selectedOffice.Id,
                    Title: selectedOffice.Name,
                },
            );
        }

        this.userService
            .saveUserOffices(this.user.Id, this.offices).subscribe(() => {
                (this.isEditing = false), this.success(), (this.user.Offices = this.offices);
                this.userService.emitChange(this.user);
            });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
