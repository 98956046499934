import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IPickupDynamicControlsParameters } from '../../model/form-controls/pickup.form-controls';
import { PickupDynamicControlsPartial } from '../../model/partials/pickup-partial.form-controls';
import { IPickup } from '../../model/interfaces/pickup';

export class PickupDynamicConfig<T extends IPickup> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private pickup: T, private configControls?: string[]) {
        super();
        const additionalParams: IPickupDynamicControlsParameters = {};
        const dynamicControls = new PickupDynamicControlsPartial(this.pickup, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['DateRequested'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
