import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { CommonModule } from '@angular/common';
import { PickupPaymentDetailRoutingModule } from './pickup-payment-details-routing.module';
import { PickupPaymentDetailsListComponent } from './pickup-payment-details-list/pickup-payment-details-list.component';
import { PickupPaymentDetailService } from './services/pickup-payment-detail.service';

@NgModule({
    declarations: [PickupPaymentDetailsListComponent],
    imports: [SharedModule, PickupPaymentDetailRoutingModule, CommonModule],
})
export class PickupPaymentDetailsModule {
    static forRoot(): ModuleWithProviders<PickupPaymentDetailsModule> {
        return {
            ngModule: PickupPaymentDetailsModule,
            providers: [PickupPaymentDetailService],
        };
    }
}
