import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ICustomerPickupOverride } from '../../model/interfaces/customer-pickup-override';
import { CustomerPickupOverrideDynamicConfig } from '../customer-pickup-override.dynamic-config';
import { CustomerPickupOverrideService } from '../customer-pickup-override.service';

@Component({
    selector: 'customer-pickup-override',
    templateUrl: 'customer-pickup-override.component.html',
})
export class CustomerPickupOverrideComponent implements OnInit {
    @Input() customerId = 0;
    formFactory: CustomerPickupOverrideDynamicConfig<ICustomerPickupOverride>;
    formObject: DynamicField[] = [];
    doubleClickIsDisabled = false;
    subscription: Subscription = new Subscription();
    customerPickupOverrides: ICustomerPickupOverride[] = [];
    selectedPickupOverride: ICustomerPickupOverride;
    canEdit = false;
    isEditing: boolean;
    emptyCustomerPickupOverride: ICustomerPickupOverride = {
        CustomerId: this.customerId,
        EndTime: null,
        FromDate: null,
        Id: 0,
        NoOfPickupsPerHour: 0,
        StartTime: null,
        ToDate: null,
        NoEndDate: null,
        IsRecurring: true,
        IsRecurringSun: true,
        IsRecurringMon: true,
        IsRecurringTue: true,
        IsRecurringWed: true,
        IsRecurringThu: true,
        IsRecurringFri: true,
        IsRecurringSat: true,
    };

    constructor(
        public customerPickupOverrideService: CustomerPickupOverrideService,
        private notificationService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.getOverrides();
    }

    setConfig(): void {
        let config: IDynamicFormConfig;
        this.formFactory = new CustomerPickupOverrideDynamicConfig<ICustomerPickupOverride>(this.selectedPickupOverride);
        if (this.selectedPickupOverride.Id === 0) {
            // new customer pickup override
            config = this.formFactory.getForCreate();
        } else {
            // existing customer pickup override
            config = this.formFactory.getForUpdate();
        }
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
        this.repositionNoEndDateCheckbox();
    }

    getOverrides(): void {
        this.customerPickupOverrideService.getByCustomer(this.customerId).subscribe((result) => {
            this.customerPickupOverrides = result;
        });
    }

    formSubmitted(form: UntypedFormGroup): void {
        // Kick out if the form is not valid
        if (!form.valid) {
            markAllFormFieldsAsTouched(form);
            return;
        }

        // Kick out if no days are selected for a recurring pickup
        const override = form.value.CustomerPickupOverride as ICustomerPickupOverride;
        if (override.IsRecurring && !this.checkForOneOrMoreRecurringDays(override)){
            this.notificationService.error('At least one day must be selected for recurring pickup');
            return;
        }

        // Kick out if a non-recurring pickup has no end date
        if (!override.IsRecurring && override.NoEndDate) {
            this.notificationService.error('Non-recurring pickups must have an end date');
            return;
        }

        // Assign the form values to the selected pickup override
        this.formFactory.assignFormValues(this.selectedPickupOverride, override);
        this.selectedPickupOverride.CustomerId = this.customerId;
        if (this.selectedPickupOverride.NoEndDate) {
            this.selectedPickupOverride.ToDate = null;
        }

        // Create a new pickup override if it doesn't have an Id
        if (!this.selectedPickupOverride.Id || this.selectedPickupOverride.Id === 0) {
            this.customerPickupOverrideService.create(this.selectedPickupOverride)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe({
                    next: () => {
                        this.selectedPickupOverride = null;
                        this.getOverrides();
                    },
                    error: (response: HttpErrorResponse) => {
                        this.handleErrorResponse(response);
                    }
                });
            return;
        }

        // Update the pickup override if it has an Id
        this.customerPickupOverrideService.update(this.selectedPickupOverride)
            .pipe(finalize(() => this.doubleClickIsDisabled = false))
            .subscribe({
                next: () => {
                    this.selectedPickupOverride = null;
                    this.getOverrides();
                },
                error: (response: HttpErrorResponse) => {
                    this.handleErrorResponse(response);
                }
            });
    }

    isEditMode(override: ICustomerPickupOverride): boolean {
        return this.selectedPickupOverride && this.selectedPickupOverride.Id === override.Id;
    }

    setEdit(override: ICustomerPickupOverride): void {
        this.selectedPickupOverride = { ...override };
        this.setConfig();
    }

    cancel(): void {
        this.selectedPickupOverride = null;
    }

    add(): void {
        this.selectedPickupOverride = { ...this.emptyCustomerPickupOverride };
        this.setConfig();
    }

    formCreated(event: UntypedFormGroup): void {
        this.subscription.add(
            event.get('CustomerPickupOverride.NoEndDate').valueChanges.subscribe((answer) => {
                if (answer) {
                    event.get('CustomerPickupOverride.ToDate').disable();
                    event.get('CustomerPickupOverride.ToDate').setValue(null);
                    event.get('CustomerPickupOverride.ToDate').mtSetRequired(false);
                } else {
                    event.get('CustomerPickupOverride.ToDate').enable();
                    event.get('CustomerPickupOverride.ToDate').mtSetRequired(true);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onDelete(event: ICustomerPickupOverride): void {
        this.customerPickupOverrideService.delete(event.Id)
            .subscribe(
                () => {
                    this.getOverrides();
                    this.notificationService.success('Deleted Successfully.');
                },
                () => {
                    this.notificationService.error('Error could not delete.');
                },
            );
    }

    private checkForOneOrMoreRecurringDays(override: ICustomerPickupOverride): boolean {
        return (
            override.IsRecurringSun ||
            override.IsRecurringMon ||
            override.IsRecurringTue ||
            override.IsRecurringWed ||
            override.IsRecurringThu ||
            override.IsRecurringFri ||
            override.IsRecurringSat
        );
    }

    private repositionNoEndDateCheckbox(): void {
        setTimeout(() => {
            // Get the NoEndDate checkbox
            const noEndDateCheckbox = document.getElementById('NoEndDate');

            // Reposition it next to the EndDate field
            noEndDateCheckbox.parentElement.style.position = 'absolute';
            noEndDateCheckbox.parentElement.style.top = '380px';
            noEndDateCheckbox.parentElement.style.left = '210px';
        });
    }

    private handleErrorResponse(errorResponse: HttpErrorResponse): void {
        if (typeof(errorResponse.error) === 'string') {
            this.notificationService.error(errorResponse.error);
            return;
        }
        this.notificationService.error(errorResponse.error['ModelState'][0] as string);
    }
}
