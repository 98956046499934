import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMtPayPalService } from '@mt-ng2/paypal';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckInPayPalService implements IMtPayPalService {
    constructor(public http: HttpClient) {}

    // get the PayPal order id
    // this is called from the <mt-paypal-buttons> component when the PayPal smart button is clicked
    // and is used to display the PayPal dialog
    getPayPalOrderId(checkInId: number): Observable<string> {
        return this.http.get<string>(`/public-check-in/${checkInId}/paypal-order-id`);
    }

    // capture the funds for the PayPal order
    // this is called from the <mt-paypal-buttons> component when the user chooses and confirms
    // a payment method in the PayPal dialog
    capturePayPalOrder(checkInId: number, payPalOrderId: string): Observable<null> {
        return this.http.post<null>(`/public-check-in/${checkInId}/capture-paypal-order/${payPalOrderId}`, null);
    }

    sendPaymentSms(checkInId: number, amount: number): Observable<null> {
        return this.http.post<null>(`/public-check-in/${checkInId}/amount/${amount}/send-payment-sms`, null);
    }
}
