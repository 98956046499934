import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '../model/interfaces/user';
import { TwicImageTypes } from '../model/TwicImageTypes';
import { UpdateUserPicResult } from '../model/interfaces/custom/update-user-pic-result.dto';

@Injectable()
export class TruckerService extends BaseService<IUser> {
    private emptyTrucker: IUser = {
        AuthUserId: 0,
        BannedFromPickup: false,
        BannedFromPickupReason: null,
        Email: null,
        FirstName: null,
        Id: 0,
        IsArchived: false,
        IsMobileUser: false,
        LastName: null,
        NotificationTypeId: null,
        Phone: null,
        TractorLicensePlate: null,
        TrailerLicensePlate: null,
        TruckingCompanyName: null,
        UsdotNumber: null,
        Version: null,
    };

    constructor(public http: HttpClient) {
        super(`/truckers`, http);
    }

    getEmptyTrucker(): IUser {
        return { ...this.emptyTrucker };
    }

    getTruckersCsv(): Observable<string> {
        const headers = new HttpHeaders({
            Accept: 'text/csv',
        });
        return this.http.get(`/truckers/csv`, { headers: headers, responseType: 'text' });
    }

    saveTwicImage(truckerId: number, photo: File, twicImageType: TwicImageTypes): Observable<UpdateUserPicResult> {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post<UpdateUserPicResult>(`/truckers/${truckerId}/twic/${twicImageType}/image`, formData);
    }

    deleteTwicImage(truckerId: number, twicImageType: TwicImageTypes): Observable<object> {
        return this.http.delete(`/truckers/${truckerId}/twic/${twicImageType}/image`);
    }
}
