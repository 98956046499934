import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RemoteKioskService } from '../../remote-kiosk/remote-kiosk.service';
import { IUser } from '../../model/interfaces/user';

@Component({
    selector: 'app-remote-kiosk-trucker-header',
    templateUrl: './trucker-header.component.html',
})
export class RemoteKioskTruckerHeaderComponent implements OnInit, OnDestroy {
    trucker: IUser;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private remoteKioskService: RemoteKioskService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get('truckerId');
        if (id > 0) {
            this.remoteKioskService.getTrucker(id).subscribe((trucker) => {
                this.setHeader(trucker);
            });
        } else {
            this.header = 'Driver Info';
            this.trucker = this.remoteKioskService.getEmptyTrucker();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(trucker: IUser): void {
        this.trucker = trucker;
    }
}
