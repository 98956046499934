import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { CheckInService } from '../../check-ins/services/check-ins.service';
import { RemoteKioskService } from '../remote-kiosk.service';
import { ICheckInDetail } from '../../model/interfaces/check-in-detail';
import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../../customers/customer.service';
import { NgForm } from '../../../../node_modules/@angular/forms';
import { ICheckInLog } from '../../model/interfaces/check-in-log';

@Component({
    selector: 'app-remote-kiosk-order-info',
    styles: [
        `
            .validator-msg {
                color: red;
            }
        `,
    ],
    templateUrl: './order-info.component.html',
})
export class RemoteKioskOrderInfoComponent implements OnInit {
    inactivityDialogApi: IModalWrapperApi;
    isLoading = false;
    doubleClickIsDisabled = false;
    needsAppointmentToCheckIn = false;
    customers: ICustomer[];
    selectedDetail: ICheckInDetail;
    checkInId: number;
    isDAEOfficeUser = false;
    CheckInDetails: ICheckInDetail[] = [];
    checkInLog: ICheckInLog;
    emptyCheckInDetail: ICheckInDetail = {
        CheckInId: 0,
        Customer: this.customerService.getEmptyCustomer(),
        CustomerId: 0,
        DeliveryCompany: '',
        DeliveryZipCode: '',
        Id: 0,
        OrderNo: '',
    };
    inactivityTimeout: ReturnType<typeof setTimeout>;
    inactivityTime = 5 * 60 * 1000;
    redirectTimeout: ReturnType<typeof setTimeout>;
    redirectTime = 30 * 1000;

    // typeahead
    searching = false;
    searchFailed = false;
    inputformatter = (x: { Name: string }) => x.Name;
    resultformatter = (result: ICustomer) => result.Name;
    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => (this.searching = true)),
            switchMap((term) =>
                term.length < 2
                    ? []
                    : this.customerService.getCustomersByQuery(term).pipe(
                          tap(() => (this.searchFailed = false)),
                          catchError(() => {
                              this.searchFailed = true;
                              return of([]);
                          }),
                      ),
            ),
            tap(() => (this.searching = false)),
        );
    }

    constructor(
        private checkInService: CheckInService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private remoteKioskService: RemoteKioskService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.checkInId = +this.route.snapshot.paramMap.get('checkInId');
        this.setInactivityTimeout();
        this.checkIsDAEOfficeUser();
        this.getCheckInDetails();
    }

    ngOnDestroy(): void {
        this.clearInactivityTime();
    }

    setInactivityTimeout(): void {
        this.inactivityTimeout = setTimeout(() => {
            this.showInactivtyRedirectPopup();
        }, this.inactivityTime);
    }

    showInactivtyRedirectPopup(): void {
        this.redirectTimeout = setTimeout(() => {
            this.routeToKioskHome();
        }, this.redirectTime);
        this.inactivityDialogApi.show();
    }

    cancelTimeoutRedirect(): void {
        clearTimeout(this.redirectTimeout);
        this.setInactivityTimeout();
    }

    clearInactivityTime(): void {
        clearTimeout(this.inactivityTimeout);
    }

    isEditMode(detail: ICheckInDetail): boolean {
        return this.selectedDetail && this.selectedDetail.Id === detail.Id;
    }

    setEdit(detail: ICheckInDetail): void {
        this.selectedDetail = { ...detail };
    }

    preFillCustomer(): void {
        if (this.isDAEOfficeUser || this.needsAppointmentToCheckIn) {
            this.remoteKioskService.getCustomerForOrderNo(this.selectedDetail.OrderNo).subscribe(
                (answer) => {
                    this.selectedDetail.Customer = answer;
                    if (answer == null) {
                        if (this.needsAppointmentToCheckIn && this.selectedDetail.Customer) {
                            this.notificationsService.error('Please go to the window, no appointment on record for the selected customer ' + this.selectedDetail.Customer.Name || '');
                        } else {
                            this.notificationsService.error('No customer found for the order no entered!');
                        }
                    }
                },
                () => {
                    this.error();
                },
            );
        }
    }

    onCustomerSelect(event: object): void {
        this.needsAppointmentToCheckIn = event["item"]["NeedsAppointmentToCheckIn"];
        if (event["item"]["NeedsAppointmentToCheckIn"]) {
        this.needsAppointmentToCheckIn = true;
        if (this.selectedDetail.OrderNo) {
            this.preFillCustomer();
        }
       }
    }

    checkIsDAEOfficeUser(): void {
        this.isLoading = true;
        this.checkInService.checkIsDAEOfficeUser()
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(
                (answer) => {
                    this.isDAEOfficeUser = answer;
                },
            );
    }

    checkIfParsNumberRequired(): boolean {
        return !this.CheckInDetails ? false : this.CheckInDetails.findIndex((d) => d.Customer && d.Customer.ParsNumberRequired) !== -1;
    }

    cancel(detail: ICheckInDetail): void {
        if (detail.Id === 0) {
            this.CheckInDetails.splice(this.CheckInDetails.length - 1, 1);
        }
        this.selectedDetail = null;
    }

    add(): void {
        if (!this.selectedDetail) {
            this.selectedDetail = { ...this.emptyCheckInDetail };
            this.CheckInDetails.push(this.selectedDetail);
        }
    }

    onFormSubmit(form: NgForm): void {
        if (form.invalid) {
            if (!(this.selectedDetail.Customer && this.selectedDetail.Customer.Id)) {
                this.notificationsService.error('Customer  is required');
            }
            if (!this.selectedDetail.OrderNo) {
                this.notificationsService.error('Order#  is required');
            }
            if (this.selectedDetail.Customer && this.selectedDetail.Customer.ParsNumberRequired && !this.selectedDetail.ParsNumber) {
                this.notificationsService.error('PARS number is required');
            }
            return;
        }
        if (!this.selectedDetail.Customer || !this.selectedDetail.Customer.Id) {
            this.notificationsService.error('Customer  is required');
            return;
        }
        this.selectedDetail.CustomerId = this.selectedDetail.Customer.Id;
        if (this.selectedDetail.Id > 0) {
            this.checkInService
                .updateCheckInDetail(this.selectedDetail).subscribe(() => {
                    this.success();
                });
        } else {
            this.selectedDetail.CheckInId = this.checkInId;
            this.checkInService
                .createCheckInDetail(this.selectedDetail).subscribe(() => {
                    this.success();
                });
        }
    }

    onDelete(event: ICheckInDetail): void {
        this.checkInService.deleteCheckInDetail(event.Id).subscribe(
            () => {
                this.success();
            },
            () => {
                this.error();
            },
        );
    }

    getCheckInDetails(): void {
        this.checkInService.getCheckInDetails(this.checkInId).subscribe(
            (answer) => {
                this.CheckInDetails = answer;
                this.add();
            },
            () => {
                this.error();
            },
        );
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.selectedDetail = null;
        this.getCheckInDetails();
        this.notificationsService.success('Saved Successfully');
    }

    atleastOneCheckIndetail(): boolean {
        return this.CheckInDetails && this.CheckInDetails.some((cd) => cd.Id > 0);
    }

    onDone(): void {
        this.checkInService
            .sendNotifications(this.checkInId).subscribe(
                (answer) => {
                    if (answer) {
                        this.checkInService
                            .createCheckInLog(this.createCheckInLog('showing dialog to driver after successfuly sent notifications', this.checkInId))
                            .subscribe();
                        this.modalService
                            .showModal({
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                                text: `You have been checked In. We will contact you when your order is ready.`,
                                timer: 15000,
                                title: 'All Set!',
                            })
                            .subscribe(() => {
                                this.checkInService
                                    .createCheckInLog(
                                        this.createCheckInLog(
                                            'routing to user welcome screen after driver clicking Ok on success prompt',
                                            this.checkInId,
                                        ),
                                    )
                                    .subscribe();
                                this.showFullScreen();
                                void this.router.navigate(['remote-kiosk-user-welcome/']);
                            });
                    } else {
                        this.modalService
                            .showModal({
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                                text: `You have been checked In but customer has not been notified yet. Please see the clerk.`,
                                title: 'Uh oh!',
                            })
                            .subscribe(() => {
                                this.checkInService
                                    .createCheckInLog(
                                        this.createCheckInLog(
                                            'routing to user welcome screen after driver clicking Ok on error prompt',
                                            this.checkInId,
                                        ),
                                    )
                                    .subscribe();
                                this.showFullScreen();
                                void this.router.navigate(['remote-kiosk-user-welcome/']);
                            });
                    }
                },
                () => {
                    this.error();
                },
            );
    }

    showFullScreen(): void {
        const fullscreenElement = document.documentElement;
        if (fullscreenElement.requestFullscreen) {
            /* Chrome, Safari and Opera */
            void fullscreenElement.requestFullscreen();
        }
    }

    createCheckInLog(log: string, checkInId: number): ICheckInLog {
        this.checkInLog = {
            CheckInId: checkInId,
            DateCreatedUtc: new Date(),
            Id: 0,
            LogMessage: log,
        };
        return this.checkInLog;
    }

    routeToKioskHome(): void {
        void this.router.navigate(['remote-kiosk-user-welcome']);
    }
}
