


import { Component } from '@angular/core';
import {IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ModalService } from '@mt-ng2/modal-module';
import { IOrder } from '../../model/interfaces/order';
import { OrderService } from '../order.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    template: `
    <div (click)="showModal($event)">
        <span>{{entity.Archived ? 'Yes' : 'No'}}</span>
    </div>
    `,
})
export class ArchiveOrderModalComponent implements IEntityListDynamicCellComponentGeneric<IOrder> {
    entityListComponentMembers: IEntityListComponentMembers;
    entity: IOrder;
    constructor(
        private claimsService: ClaimsService,
        private modalService: ModalService,
        private orderService: OrderService,
        private notificationsService: NotificationsService,
    ) { }

    showModal(event: PointerEvent): void {
        event.stopImmediatePropagation();

        const hasFullAccessToOrders: boolean = this.claimsService.hasClaim(ClaimTypes.Orders, [ClaimValues.FullAccess]);
        if (!hasFullAccessToOrders) {
            return;
        }

        const archived = this.entity['Archived'] ? 1 : 0;
        this.modalService
            .showModal({
                confirmButtonText: 'Save',
                input: 'checkbox',
                inputValue: archived,
                showCancelButton: true,
                text: 'Update Archived',
                title: '',
            })
            .subscribe((result) => {
                if (result.isConfirmed) {
                    this.orderService.updateArchivedForOrder(this.entity.Id, result.value as number)
                        .subscribe(() => {
                            this.notificationsService.success('updated archived status');
                        });
                }
            });
    }
}
