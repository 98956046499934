<h2>Confirmed Delivery Date:</h2>
<div>
    <input 
        type="date" 
        id="confirmedDeliveryDate" 
        min="{{minDate | date: 'yyyy-MM-dd'}}"
        [(ngModel)]="confirmedDeliveryDate"
        [ngStyle]="{ 'color': !confirmedDeliveryDate ? 'red' : '' }"
        (ngModelChange)="onConfirmedDeliveryDateChange()"
        >
</div>

<div>
    <div *ngIf="loading" class="loading-spinner">
        <div class="spinner"></div>
    </div>
    <table *ngIf="!loading">
        <thead>
            <tr>
                <th>Time From</th>
                <th># of Deliveries</th>
                <th># of Confirmed Deliveries</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let slot of timeSlots; let i = index">
                <td>{{ slot }}</td>
                <td>
                    <input 
                        type="number" 
                        [value]="deliveriesPerSlot[i]"
                        (input)="onDeliveriesChange(i, $event.target.valueAsNumber)" min="0" max="1000"/>
                </td>
                <td>{{getNumberOfConfirmedDeliveriesForSlot(slot)}}</td>
            </tr>
        </tbody>
    </table>
</div>
<b># of Deliveries: 
    <span [ngStyle]="{ 'color': getNumberOfRemainingDeliveries() !== 0 ? 'red' : '' }">
        {{ getNumberOfRemainingDeliveries() }}
    </span>
</b>
<br><br>
<button 
    [disabled]="!confirmedDeliveryDate || getNumberOfRemainingDeliveries() !== 0"
    class="btn btn-primary" 
    (click)="confirmDeliveries()">
    Confirm Deliveries
</button>
<br><br>
<button 
    class="btn btn-danger" 
    (click)="close()">
    Cancel
</button>
