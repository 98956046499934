import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { CommonModule } from '@angular/common';
import { ExceptionQueueRoutingModule } from './exception-queue-routing.module';
import { ExceptionQueueListComponent } from './exception-queue-list/exception-queue-list.component';
import { ExceptionQueueService } from './services/exception-queue.service';

@NgModule({
    declarations: [ExceptionQueueListComponent],
    imports: [SharedModule, ExceptionQueueRoutingModule, CommonModule],
})
export class ExceptionQueueModule {
    static forRoot(): ModuleWithProviders<ExceptionQueueModule> {
        return {
            ngModule: ExceptionQueueModule,
            providers: [ExceptionQueueService],
        };
    }
}
