import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { CustomerService } from '../customer.service';
import { ICustomer } from '../../model/interfaces/customer';
import { CustomerDynamicConfig } from '../customer.dynamic-config';
import { IOffice } from '../../model/interfaces/office';
import { CheckInService } from '../../check-ins/services/check-ins.service';
import { tap } from 'rxjs/operators';
import { ICustomerPickUpHour } from '../../model/interfaces/customer-pick-up-hour';
import { CustomerPickUpHourService } from '../customerpickuphour.service';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-customer-basic-info',
    templateUrl: './customer-basic-info.component.html',
})
export class CustomerBasicInfoComponent implements OnInit {
    @Input() customer: ICustomer;
    @Input() canEdit: boolean;
    @Output() onOfficeIdChanged: EventEmitter<number> = new EventEmitter<number>();
    isEditing: boolean;
    isHovered: boolean;
    isDaeUser = false;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: CustomerDynamicConfig<ICustomer>;
    doubleClickIsDisabled = false;
    offices: IOffice[];
    pickUpHours: ICustomerPickUpHour[];

    constructor(
        private customerService: CustomerService,
        private checkInService: CheckInService,
        private notificationsService: NotificationsService,
        private router: Router,
        private pickUpHoursService: CustomerPickUpHourService,
    ) { }

    ngOnInit(): void {
        this.isEditing = false;
        this.checkIsDAEOfficeUser();
        forkJoin(this.getCustomerPickUpHours()).subscribe(() =>
            this.setConfig(),
        );
    }

    getCustomerPickUpHours(): Observable<ICustomerPickUpHour[]> {
        return this.pickUpHoursService.getAll().pipe(
            tap((answer) => {
                this.pickUpHours = answer;
            }),
        );
    }

    setConfig(): void {
        this.formFactory = new CustomerDynamicConfig<ICustomer>(
            this.customer,
            this.isDaeUser,
            this.pickUpHours,
        );
        let config: IDynamicFormConfig;
        if (this.customer.Id === 0) {
            // new customer
            this.isEditing = true;
            config = this.formFactory.getForCreate();
        } else {
            // existing customer
            config = this.formFactory.getForUpdate();
        }
        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) =>new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.customer.Id === 0) {
            void this.router.navigate(['/customers']);
        } else {
            this.isEditing = false;
        }
    }

    checkIsDAEOfficeUser(): void {
        this.checkInService.checkIsDAEOfficeUser().subscribe((answer) => {
            this.isDaeUser = answer;
        });
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(
                this.customer,
                form.value.Customer as ICustomer,
            );
            if (!this.customer.Id || this.customer.Id === 0) {
                // handle new customer save
                this.customerService
                    .create(this.customer).subscribe((answer) => {
                        void this.router.navigate([`/customers/${answer}`]);
                        this.success();
                        this.customerService.emitChange(this.customer);
                    });
            } else {
                // handle existing customer save
                this.customerService
                    .updateVersion(this.customer).subscribe((success) => {
                        this.customer.Version = success;
                        this.isEditing = false;
                        this.success();
                        this.customerService.emitChange(this.customer);
                        this.setConfig();
                    });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Customer saved successfully.');
    }
}
