import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { forkJoin, Observable } from 'rxjs';
import { IPickupPaymentDetail } from '../../model/interfaces/pickup-payment-detail';
import { IUser } from '../../model/interfaces/user';
import { PickupService } from '../../model/shared-entities/pickups/pickup.service';
import { PickupPaymentDetailService } from '../services/pickup-payment-detail.service';
import { IColumnSortedEvent, IEntity, SortDirection } from '@mt-ng2/entity-list-module';
import { PickupPaymentDetailEntityListConfig } from './pickup-payment-details-list.entity-list-config';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control';

@Component({
    selector: 'app-pickup-payment-details-list',
    templateUrl: './pickup-payment-details-list.component.html',
})
export class PickupPaymentDetailsListComponent implements OnInit {
    query = '';
    order = 'CheckIn.Pickup.PickupNumber';
    orderDirection = 'asc';
    currentPage = 1;
    itemsPerPage = 10;
    total: number;
    startDate: Date = new Date();
    endDate: Date = new Date();
    drivers: IUser[];
    selectedDriverIds: number[] = [];
    statuses: MtSearchFilterItem[] = [
        { Item: { Id: 1, Name: 'Successful' }, Selected: false },
        { Item: { Id: 0, Name: 'Unsuccessful' }, Selected: false }
    ];
    selectedStatusIds: number[] = [];
    pickupPaymentDetails: IPickupPaymentDetail[] = [];
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    datepickerEntityName = 'Date Created';
    entityListConfig: PickupPaymentDetailEntityListConfig;

    constructor(
        private pickupPaymentDetailService: PickupPaymentDetailService,
        private pickupService: PickupService,
    ) {}

    ngOnInit(): void {
        this.pickupService.getPickupDrivers().subscribe(drivers => {
            this.drivers = drivers;
            this.getTableData();
        });
    }

    getTableData(): void {
        forkJoin([
            this.pickupService.getPickupDrivers(),
        ]).subscribe(([drivers]) => {
            this.drivers = drivers;
            this.entityListConfig = new PickupPaymentDetailEntityListConfig();
            this.orderDirection = this.entityListConfig.getDefaultSortDirection();
            this.buildSearchBarAndFilters();
            this.getPaymentDetails();
        });
    }

    getPaymentDetailsCall(): Observable<HttpResponse<IPickupPaymentDetail[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
            query: search && search.length > 0 ? search : '',
        };

        const searchparams = new SearchParams(searchEntity);
        return this.pickupPaymentDetailService.search(searchparams);
    }

    getPaymentDetails(): void {
        this.getPaymentDetailsCall().subscribe((answer) => {
            this.pickupPaymentDetails = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'DriverIds',
                valueArray: this.selectedDriverIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'StatusIds',
                valueArray: this.selectedStatusIds,
            }),
        );

        if (this.startDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateCreatedStartDate',
                value: this.startDate.toDateString(),
            }));
        }

        if (this.endDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateCreatedEndDate',
                value: this.endDate.toDateString(),
            }));
        }

        return _extraSearchParams;
    }

    buildSearchBarAndFilters(): void {    
        this.dynamicSearchFiltersConfig = [{
            Searchbar: {
                label: 'Search',
                type: DynamicSearchFilterTypes.Searchbar,
            },
            Drivers: {
                label: 'Driver',
                type: DynamicSearchFilterTypes.Select,
                options: { selectOptions: this.mapMultiMtSearchFilterItems(this.drivers, 'FirstName', 'LastName', 'Phone') },
            },
            Statuses: {
                label: 'Payment Status',
                type: DynamicSearchFilterTypes.Select,
                options: { selectOptions: this.statuses },
            },
        }];
    }
    
    pageChanged(): void {
        this.getPaymentDetails();
    }

    search(evt: DynamicSearchValues): void {
        this.currentPage = 1;
        this.query = evt.Searchbar as string;
        if (evt.Drivers) {
            this.selectedDriverIds = (evt.Drivers as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Statuses) {
            this.selectedStatusIds = (evt.Statuses as IMetaItem[]).map(x => x.Id);
        }
        this.getPaymentDetails();
    }

    dateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.startDate = range.startDate;
        this.endDate = range.endDate;
        this.getPaymentDetails();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getPaymentDetails();
    }

    private mapMultiMtSearchFilterItems(items: IEntity[], keyOne: string, keyTwo: string, keyThree: string): MtSearchFilterItem[] {
        return items?.map((item) => {
            return new MtSearchFilterItem(
                {
                    Id: item.Id,
                    Name: `${item[keyOne] as string} ${item[keyTwo] as string} (${item[keyThree] as string})`,
                }, false,
            );
        });
    }
}
