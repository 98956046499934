<div class="text-center public-responsive">
    <h1>Thanks!</h1>
    <br>
    <i class="fa fa-lg fa-check-circle check-circle-success"></i>
    <br>
    <h2>You've been checked in.</h2>
    <p class="f-10">Please wait in your truck.</p>
    <p class="f-10">We'll text you at <b><a href="tel:2151233456">(215) 123-3456</a></b> with next steps. Please keep your phone handy.</p>
    <p class="f-10">If you need assistance, please call <b><a href="tel:8567423142">(856) 742-3142</a></b> or enter the trailer.</p>
    <app-driver-check-in-support-button [driver]="driver" [pickup]="pickup"></app-driver-check-in-support-button>
    <br>
</div>
