import { ContactDynamicControls, IContactDynamicControlsParameters } from '../form-controls/contact.form-controls';
import { IContact } from '../interfaces/contact';
import { ICarrier } from '../interfaces/carrier';
import { ICustomer } from '../interfaces/customer';
import { INotificationType } from '../interfaces/notification-type';
import { IContactType } from '../interfaces/contact-type';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';

export class ContactDynamicControlsPartial extends ContactDynamicControls {

    constructor(contactPartial?: IContact, additionalParameters?: IContactDynamicControlsParameters) {
        super(contactPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.Archived).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CarrierId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CustomerId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateCreated).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateModified).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Email).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.FirstName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.LastName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ModifiedById).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotificationTypeId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotificationWhenDriverChecksIn).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotificationWhenDriverIsFulfilled).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotificationWhenDriverIsIssuedGatePass).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotificationWhenDriverIsNotified).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.PhoneNo).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Title).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.TypeId).type.fieldType = DynamicFieldTypes.Input;
        (<DynamicField>this.Form.CarrierId).value = contactPartial && contactPartial.CarrierId ? contactPartial.CarrierId : 0;
        (<DynamicField>this.Form.CustomerId).value = contactPartial && contactPartial.CustomerId ? contactPartial.CustomerId : 0;
        (<DynamicField>this.Form.NotificationTypeId).value = contactPartial && contactPartial.NotificationTypeId ? contactPartial.NotificationTypeId : 1;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.Archived).label = 'Some other Label';
        // (<DynamicLabel>this.View.CarrierId).label = 'Some other Label';
        // (<DynamicLabel>this.View.CustomerId).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateCreated).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateModified).label = 'Some other Label';
        // (<DynamicLabel>this.View.Email).label = 'Some other Label';
        // (<DynamicLabel>this.View.FirstName).label = 'Some other Label';
        // (<DynamicLabel>this.View.LastName).label = 'Some other Label';
        // (<DynamicLabel>this.View.ModifiedById).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotificationTypeId).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotificationWhenDriverChecksIn).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotificationWhenDriverIsFulfilled).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotificationWhenDriverIsIssuedGatePass).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotificationWhenDriverIsNotified).label = 'Some other Label';
        // (<DynamicLabel>this.View.PhoneNo).label = 'Some other Label';
        // (<DynamicLabel>this.View.Title).label = 'Some other Label';
        // (<DynamicLabel>this.View.TypeId).label = 'Some other Label';
        this.View.CarrierId = new DynamicLabel({
			    label: 'Carrier',
			    value: contactPartial && contactPartial.hasOwnProperty('CarrierId') && contactPartial.CarrierId !== null ? contactPartial.Carrier?.Name : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
        );

        this.View.CustomerId = new DynamicLabel({
			    label: 'Customer',
			    value: contactPartial && contactPartial.hasOwnProperty('CustomerId') && contactPartial.CustomerId !== null ? contactPartial.Customer?.Name : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
        );

        this.View.NotificationTypeId = new DynamicLabel({
			    label: 'Notification Type',
			    value: contactPartial && contactPartial.hasOwnProperty('NotificationTypeId') && contactPartial.NotificationTypeId !== null ? contactPartial.NotificationType?.Name : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
        )
    }
}
