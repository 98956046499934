<form #readyForm="ngForm" (ngSubmit)="formSubmitted(readyForm)">
    <label for="totalWeight">Order Weight</label>
    <input type="number" name="totalWeight" [ngModel]="order.TotalWeight" class="form-control" />
    <br>
    <div class="text-center">
        <button type="submit" class="btn btn-success" [disabled]="doubleClickIsDisabled">
            Save
        </button>
        <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
    </div>
</form>
