<div class="text-center public-responsive">
    <h1>Your Orders Are Ready!</h1>
    <br>
    <div><i class="fa fa-lg fa-check-circle check-circle-success"></i></div>
    <br><br>
    <div class="list-container">
        <ul>
            <li *ngFor="let order of ordersWithDoors">
                <i class="fa fa-lg fa-check-circle"></i> {{ order.OrderNumber }}
            </li>
        </ul>
    </div>
    <br>
    <div>
        <button type="button" class="btn btn-primary" (click)="goToDriverOrdersReadyPage()">Start Collecting</button>
    </div>
    <br>
    <app-driver-check-in-support-button [driver]="driver" [pickup]="pickup"></app-driver-check-in-support-button>
    <br>
</div>