import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { IUser } from '../../model/interfaces/user';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IOrder } from '../../model/interfaces/order';
import { CarrierService } from '../../carriers/services/carrier.service';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { ICarrierForRegistration } from '../../model/interfaces/custom/carrier-for-registration';
import { OrderStatuses } from '../../model/OrderStatuses';

@Component({
    selector: 'app-driver-check-in-create-pickup',
    templateUrl: './driver-check-in-create-pickup.component.html',
    styleUrls: ['./driver-check-in-create-pickup.component.css'],
})
export class DriverCheckInCreatePickupComponent implements OnInit {
    loading = true;
    enteringOrders = true;
    driver: IUser;
    orders: IOrder[] = [];
    carriers: ICarrierForRegistration[];
    availableDateTimes: Date[];
    selectedDateTime: string;
    findOrderForm: FormGroup;
    selectTimeAndDateForm: FormGroup;
    
    constructor(
        private formBuilder: FormBuilder, 
        private checkInService: PublicCheckInService,
        private notificationService: NotificationsService,
        private router: Router,
        private carrierService: CarrierService,
    ) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
        });

        this.checkInService.driverSelectedOrders$.subscribe((orders) => {
            this.orders = orders ?? [];
        });

        setTimeout(() => {
            if (!this.driver) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }

            this.carrierService.getActiveCarriersForRegistration().subscribe((carriers) => {
                this.carriers = carriers;
                if (this.orders?.length > 0) {
                    this.confirmOrders();
                } else {
                    this.initFindOrderForm();
                    this.loading = false;
                }
            });
        }, 500);
    }

    findOrderFormSubmit() {
        const orderNumber = this.findOrderForm.get('orderNumber').value as string;
        this.checkInService.getOrdersForPickup(orderNumber)
            .subscribe({
                next: (orders) => {
                    if (!orders || orders.length <= 0) {
                        void this.router.navigate([`public/driver-check-in-create-walk-in`], { queryParams: { orderNumber } });
                        return;
                    }

                    const order = orders.find(o => o.StatusId === OrderStatuses.Created && o.PickUpId > 0);
                    if (!order) {
                        this.notificationService.error(`No orders found for that number. Please try again or reach out to your trucking company for assistance.`);
                        return;
                    }

                    if (order.CarrierId !== this.driver.CarrierId) {
                        this.notificationService.error(`No orders found that match your selected carrier. Please try again or reach out to your trucking company for assistance.`); 
                        return;
                    }

                    if (this.orders.length > 0 && this.orders.some(o => o.Warehouse?.OfficeId !== order.Warehouse?.OfficeId)) {
                        this.notificationService.error(`No orders found that match the office of the selected orders. Please try again or reach out to your trucking company for assistance.`); 
                        return;
                    }

                    if (this.orders.some(p => p.Id === order.Id)) {
                        this.notificationService.error('This order has already been entered.');
                        return;
                    }

                    this.orders.push(order);
                },
            });
    }

    initFindOrderForm(): void {
        this.findOrderForm = this.formBuilder.group({
            orderNumber: [null, Validators.required],
        });
    }

    getDriverSearchTimes(dateRequested: Date, carrierId: number): void {
        this.loading = true;
        const officeId = this.orders[0].Warehouse.OfficeId;
        this.checkInService.getDriverSearchTimes(
            this.orders.map(o => o.Id),
            dateRequested,
            dateRequested,
            officeId,
            this.driver.Id
        )
        .subscribe((availableDateTimes) => {
            this.availableDateTimes = availableDateTimes;
            this.initSelectTimeAndDateForm(dateRequested, carrierId);
            this.enteringOrders = false;
            this.loading = false;
        });
    }

    confirmOrders(): void {
        const carrierId = this.orders[0].CarrierId;
        const dateRequested = CommonFunctions.setToMidnight(new Date());
        this.getDriverSearchTimes(dateRequested, carrierId);
    }

    initSelectTimeAndDateForm(dateRequested: Date, carrierId: number): void {
        const formattedDate = CommonFunctions.formatDateForInput(dateRequested);
        this.selectTimeAndDateForm = this.formBuilder.group({
            carrier: [{ value: carrierId, disabled: true }],
            dateRequested: [{ value: formattedDate, disabled: true }]
        });
    }

    onDateTimeSelected(dateTime: string) {
        this.selectedDateTime = dateTime;
    }

    addMoreOrders() {
        this.initFindOrderForm();
        this.enteringOrders = true;
    }

    selectTimeAndDateFormSubmit(): void {
        this.loading = true;
        const carrierId = this.orders[0].CarrierId;
        const UTCEquivalent = CommonFunctions.getUTCEquivalent(this.selectedDateTime);
        this.checkInService.createDriverPickup(
            this.orders, 
            UTCEquivalent, 
            carrierId,
            this.driver.Id
        ).subscribe((pickup) => {
            this.notificationService.success(`Successfully confirmed the Time & Date. The Holt Admin will review to confirm the appointment.`);
            this.checkInService.checkedInPickup$.next(pickup);
            void this.router.navigate(['public/driver-check-in-info']);
        });
    }
}
