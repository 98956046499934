<div class="row">
    <div class="col-md-7">
        <h2>Walk-In Deliveries</h2>
    </div>
</div>
<div>
    <mt-search-filter-select
        [items]="customerItems"
        entity="Customer"
        (selectionChanged)="getWalkInOrders()"
    >
    </mt-search-filter-select>
    <button
        class="btn btn-default btn-assign-warehouses"
        (click)="getWalkInOrders()"
    >
        Refresh List &#160;&#160;<i class="fa fa-refresh"></i>
    </button>
    <button
        [disabled]="selectedCheckInDetailIds.length === 0"
        class="btn btn-default btn-assign-warehouses"
        (click)="bulkAssignWarehouse()"
    >
        Bulk Assign Warehouse
    </button>
</div>

<mt-entity-list
    *ngIf="entityListConfig"
    [entities]="checkInDetails"
    [total]="total"
    [(itemsPerPage)]="itemsPerPage"
    [(currentPage)]="currentPage"
    (pageChanged)="getWalkInOrders()"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (columnSelectedEvent)="columnSelected($event)"
>
</mt-entity-list>
