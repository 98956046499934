import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { TwicImageTypes } from '../../model/TwicImageTypes';
import { RegistrationRequestService } from '../registration-request.service';
import { IRegistrationRequest } from '../../model/interfaces/registration-request';

@Component({
    selector: 'app-registration-request-detail',
    templateUrl: './registration-request-detail.component.html',
})
export class RegistrationRequestDetailComponent implements OnInit {
    registrationRequest: IRegistrationRequest;
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    TwicImageTypes = TwicImageTypes;
    previousUrl: string;

    constructor(
        private registrationRequestService: RegistrationRequestService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        // https://stackoverflow.com/a/71296528
        this.previousUrl = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl.toString();
    }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.RegistrationRequests, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('registrationRequestId');
        // try load if id > 0
        if (this.id > 0) {
            this.getRegistrationRequestById(this.id);
        } else {
            // set customer to emptyCustomer
            this.registrationRequest = this.registrationRequestService.getEmptyRegistrationRequest();
        }
        this.editingComponent.next('');
    }

    getRegistrationRequestById(id: number): void {
        this.registrationRequestService.getById(id).subscribe((registrationRequest) => {
            this.registrationRequest = registrationRequest;
        });
    }

    close(): void {
        if (this.previousUrl === '/registration-requests') {
            void this.router.navigate([this.previousUrl]);
        } else {
            void this.router.navigate([`/registration-requests`]);
        }
    }
}
