import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { OrderService } from './order.service';
import { ClaimTypes } from '../model/ClaimTypes';
import { SchedulingQueueComponent } from './scheduling-queue/scheduling-queue.component';
import { ManageSchedulingComponent } from './manage-scheduling/manage-scheduling.component';
import { ManageOrdersComponent } from './scheduled-orders/manage-orders.component';
import { WalkInOrdersComponent } from './walk-in-orders/walk-in-orders.component';
import { CarrierRequestFormComponent } from './carrier-request/carrier-request-form.component';
import { OrderBasicInfoComponent } from './order-basic-info/order-basic-info.component';
import { PickupSchedulingOrderSelection } from './pickup-scheduling/order-selection/order-selection.component';
import { PickupSchedulingCarrierSelection } from './pickup-scheduling/carrier-selection/carrier-selection.component';
import { PickupSchedulingDateSelection } from './pickup-scheduling/date-selection/date-selection.component';
import { PickupSchedulingManagePickupsComponent } from './pickup-scheduling/manage-pickups/manage-pickups.component';
import { PickupSchedulingDriverSelectionComponent } from './pickup-scheduling/driver-selection/driver-selection.component';
import { PickupSchedulingTimeSelectionComponent } from './pickup-scheduling/time-selection/time-selection.component';

const orderEntityConfig = {
    claimType: ClaimTypes.Orders,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'customerId',
    query: 'order',
    service: OrderService,
    title: 'Order Detail',
};

const orderListRoleGuard = {
    claimType: ClaimTypes.Orders,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Orders',
};

const orderRoutes: Routes = [
    { path: 'orders', component: SchedulingQueueComponent, canActivate: [AuthGuard], data: orderListRoleGuard },
    {
        canActivate: [AuthGuard],
        component: ManageSchedulingComponent,
        data: orderListRoleGuard,
        path: `orders/manage-scheduling/:${orderEntityConfig.entityIdParam}/:${orderEntityConfig.query}`,
    },
    {
        canActivate: [AuthGuard],
        component: ManageOrdersComponent,
        data: orderListRoleGuard,
        path: `orders/manage-orders`,
    },
    {
        canActivate: [AuthGuard],
        component: WalkInOrdersComponent,
        data: orderListRoleGuard,
        path: `orders/walk-in-orders`,
    },
    {
        canActivate: [AuthGuard],
        component: CarrierRequestFormComponent,
        data: orderListRoleGuard,
        path: `orders/carrier-request-form`,
    },
    {
        canActivate: [AuthGuard],
        component: OrderBasicInfoComponent,
        data: orderListRoleGuard,
        path: `orders/:id/basic-info`,
    },
    {
        canActivate: [AuthGuard],
        component: PickupSchedulingOrderSelection,
        data: orderListRoleGuard,
        path: `create-requests/order-selection`,
    },
    {
        canActivate: [AuthGuard],
        component: PickupSchedulingOrderSelection,
        data: orderListRoleGuard,
        path: `create-requests/order-selection/:id`,
    },
    {
        canActivate: [AuthGuard],
        component: PickupSchedulingCarrierSelection,
        data: orderListRoleGuard,
        path: `create-requests/carrier-selection`,
    },
    {
        canActivate: [AuthGuard],
        component: PickupSchedulingDateSelection,
        data: orderListRoleGuard,
        path: `create-requests/date-selection`,
    },
    {
        canActivate: [AuthGuard],
        component: PickupSchedulingDriverSelectionComponent,
        data: orderListRoleGuard,
        path: `create-requests/driver-selection`,
    },
    {
        canActivate: [AuthGuard],
        component: PickupSchedulingTimeSelectionComponent,
        data: orderListRoleGuard,
        path: `create-requests/time-selection`,
    },
    {
        canActivate: [AuthGuard],
        component: PickupSchedulingManagePickupsComponent,
        data: orderListRoleGuard,
        path: `create-requests/manage-pickups`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(
            orderRoutes,
        ),
    ],
})
export class OrderRoutingModule {}
