import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { Component } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUser } from '../../../model/interfaces/user';
import { finalize } from 'rxjs/operators';
import { TruckerService } from '../../trucker.service';

@Component({
    styles: [`

    `],
    templateUrl: './archive-trucker-list.component.html',
})
export class ArchiveTruckerListComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    item: IUser;
    isLoading = false;

    constructor(private truckerService: TruckerService,
        private notificationsService: NotificationsService
    ) { }

    set entity(value: IUser) {
        this.item = value;
    }

    archive(event: MouseEvent): void {
        this.item.IsArchived = !this.item.IsArchived;
        event.stopPropagation();
        if (!this.isLoading) {
            this.isLoading = true;
            this.truckerService
                .update(this.item)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe({
                    next: () => {
                        const archiveText = this.item.IsArchived ? 'archived' : 'unarchived';
                        this.notificationsService.success(`Successfully ${archiveText} driver ${this.item.FirstName} ${this.item.LastName}`);
                        this.entityListComponentMembers.getEntities();
                    },
                    error: () => this.notificationsService.error('Archive failed')
                });
        }
    }
}
