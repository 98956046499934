import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { ICarrierForRegistration } from '../../model/interfaces/custom/carrier-for-registration';
import { ICarrier } from '../../model/interfaces/carrier';

export const emptyCarrier: ICarrier = {
    AccountNo: null,
    Archived: false,
    Id: 0,
    InvoiceForPickupFees: false,
    Name: null,
};

@Injectable({
    providedIn: 'root',
})
export class CarrierService extends BaseService<ICarrier> {
    constructor(public http: HttpClient) {
        super('/carriers', http);
    }

    getActive(): Observable<ICarrier[]> {
        return this.http.get<ICarrier[]>('/carriers/active');
    }

    getActiveCarriersForRegistration(): Observable<ICarrierForRegistration[]> {
        return this.http.get<ICarrier[]>('/carriers/active-for-registration');
    }

    getEmptyCarrier(): ICarrier {
        return { ...emptyCarrier };
    }

    getSimpleCarriers(): Observable<ICarrier[]> {
        return this.http.get<ICarrier[]>(`/carriers/simple/`);
    }
}
