import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { UserService } from '../user.service';
import { IUser } from '../../model/interfaces/user';
import { UserDynamicConfig } from '../user.dynamic-config';
import { AuthEntityService } from '../../auth-entity/auth-entity.service'; // added
import { IUserRole } from '../../model/interfaces/user-role'; // added
import { IAuthUser } from '../../model/interfaces/auth-user'; // added
import { AuthUserDynamicConfig } from '../../auth-entity/auth-user/auth-user.dynamic-config';
import { ICreateUserPayload } from '../../model/interfaces/custom/create-user-payload';
import { AuthService } from '@mt-ng2/auth-module';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { NotificationTypeService } from '../../contacts/notification-type.service';
import { forkJoin, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactService } from '../../contacts/contact.service';
import { ContactDynamicConfig } from '../../model/shared-entities/contacts/contact.dynamic-config';
import { IContact } from '../../model/interfaces/contact';
import { CarrierService } from '../../carriers/services/carrier.service';
import { CustomerService } from '../../customers/customer.service';
import { ICustomer } from '../../model/interfaces/customer';
import { ICarrier } from '../../model/interfaces/carrier';
import { UserRoles } from '../../model/UserRoles';
import { OrderService } from '../../orders/order.service';

@Component({
    selector: 'app-user-basic-info',
    templateUrl: './user-basic-info.component.html',
})
export class UserBasicInfoComponent implements OnInit {
    @Input() user: IUser;
    @Input() canEdit: boolean;
    authUser: IAuthUser;
    roles: IUserRole[];
    isEditing: boolean;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: UserDynamicConfig<IUser>;
    userForm: FormGroup;
    roleIdChanges$: Subscription;
    doubleClickIsDisabled = false;
    routeSnapshot: string;
    carriers: ICarrier[];
    customers: ICustomer[];
    loggedInUser: IUser;
    authUserRole: number;

    constructor(
        private userService: UserService,
        private notificationsService: NotificationsService,
        private authEntitiyService: AuthEntityService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationTypeService: NotificationTypeService,
        private contactService: ContactService,
        private carrierService: CarrierService,
        private customerService: CustomerService,
        private cdr: ChangeDetectorRef,
        private orderService: OrderService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.routeSnapshot = this.router.url;
        if (this.isNewUser()) {
            this.authEntitiyService
                .getAllRoles() // added
                .subscribe((answer) => {
                    this.roles = answer.body
                        .filter((role) => ![UserRoles.DriverUser, UserRoles.DAEUser, UserRoles.HoltClerk]
                        .includes(role.Id));

                    this.setConfig();
                });
        } else {
            this.orderService.getAuthUserRole(this.user.AuthUserId).subscribe((roleId) => {
                this.authUserRole = roleId;
                this.setConfig();
            });
        }
    }

    private isNewUser(): boolean {
        return this.user && this.user.Id && this.user.Id > 0 ? false : true;
    }

    getAdditionalConfigs(): [AuthUserDynamicConfig<IAuthUser>, AuthUserDynamicConfig<IAuthUser>, ContactDynamicConfig<IContact>] {
        const pwConfigControls: string[] = ['SendResetEmail', 'Password', 'ConfirmPassword'];
        const authUser = this.isNewUser() ? null : this.user.AuthUser;
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, null, pwConfigControls);
        const roleConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, this.roles);
        const contactConfigControls: string[] = ['CarrierId', 'CustomerId'];
        const contactConfig = new ContactDynamicConfig<IContact>(this.user.Contact, [], this.carriers, this.customers, contactConfigControls);
        return [pwConfig, roleConfig, contactConfig];
    }

    setConfig(): void {
        const loggedIn  = this.authService.currentUser.getValue();
        forkJoin([
            this.notificationTypeService.getAll(), 
            this.carrierService.getActive(), 
            this.customerService.getActiveCustomers(), 
            this.userService.getById(loggedIn.Id ?? 0),
        ]).subscribe((answer) => {
            const notificationTypes = answer[0].sort((a,b) => a.Id - b.Id);
            this.carriers = answer[1];
            this.customers = answer[2];
            this.loggedInUser = answer[3];
            let configControls = [] as string[];
            if(this.routeSnapshot === '/users/my-profile')
            {
                configControls = [
                    'FirstName',
                    'LastName',
                    'Phone',
                    'UsdotNumber',
                    'DriverLicenseNumber',
                    'TractorLicensePlate',
                    'TrailerLicensePlate',
                    'NotificationTypeId',
                ];
            }
            else {
                configControls = [
                    'FirstName',
                    'LastName',
                    'Phone',
                    'UsdotNumber',
                    'DriverLicenseNumber',
                    'TractorLicensePlate',
                    'TrailerLicensePlate',
                ];
            }

            if (this.user.AuthUser?.RoleId !== UserRoles.DriverUser){
                configControls.push('Email');
            }

            let config: IDynamicFormConfig;
            this.formFactory = new UserDynamicConfig<IUser>(this.user, null, notificationTypes, configControls);

            if (this.isNewUser()) {
                this.isEditing = true;
                config = this.formFactory.getForCreate(this.getAdditionalConfigs());
            } else if (this.user.ContactId > 0) {
                const contactConfigControls: string[] = ['CarrierId', 'CustomerId'];
                const contactConfig = new ContactDynamicConfig<IContact>(this.user.Contact, [], this.carriers, this.customers, contactConfigControls);
                config = this.formFactory.getForUpdate([contactConfig]);
            } else {
                config = this.formFactory.getForUpdate();
            }

            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));

            // If logged-in user is not an admin, then disable carrier & customer fields
            if (this.loggedInUser.AuthUser?.RoleId !== UserRoles.Admin) { 
                this.formObject.find(o => o.name === 'CarrierId').disabled = true;
                this.formObject.find(o => o.name === 'CustomerId').disabled = true;
                return;
            }
        
            // If contact has a selected carrier, then disable the customer field
            if (this.user?.Contact?.CarrierId > 0)
                this.formObject.find(o => o.name === 'CustomerId').disabled = true;
        
            // If contact has a selected customer, then disable the carrier field
            if (this.user?.Contact?.CustomerId > 0)
                this.formObject.find(o => o.name === 'CarrierId').disabled = true;
        });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewUser()) {
            void this.router.navigate(['/users']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        let passwordmatch = true;
        if (this.isNewUser()) {
            if (!this.authService.matchPassword(form)) {
                passwordmatch = false;
            }
        }
        if (form.valid && passwordmatch) {
            const roleId = form.value.AuthUser?.RoleId
            if (roleId === UserRoles.Carrier && form.value.Contact?.CarrierId <= 0) {
                markAllFormFieldsAsTouched(form);
                this.error('Please select a carrier.');
                return;
            }

            if (roleId === UserRoles.Customer && form.value.Contact?.CustomerId <= 0) {
                markAllFormFieldsAsTouched(form);
                this.error('Please select a customer.');
                return;
            }

            this.formFactory.assignFormValues(this.user, form.value.User as IUser);
            if (this.isNewUser()) {
                this.user.IsMobileUser = false;
                if (!this.user.Phone) {
                    this.user.Phone = null;
                }

                const data: ICreateUserPayload = {
                    Password: form.value.AuthUser.Password,
                    SendEmail: form.value.AuthUser.resetEmail || false,
                    User: this.user,
                    Username: form.value.AuthUser.Username,
                    UserRoleId: form.value.AuthUser.RoleId,
                    CarrierId: form.value.Contact?.CarrierId ?? 0,
                    CustomerId: form.value.Contact?.CustomerId ?? 0,
                };
                // handle new user save
                this.userService
                    .createUser(data).subscribe((answer) => {
                        void this.router.navigate([`/users/${answer}`]);
                        this.userService.emitChange(this.user);
                        this.success();
                    });
            } else {
                // handle existing user save
                this.userService
                    .updateVersion(this.user).subscribe((answer) => {
                        answer
                            ? ((this.user.Version = answer),
                              (this.isEditing = false),
                              this.success(),
                              this.userService.emitChange(this.user),
                              this.setConfig())
                            : this.error();
                            this.updateContactNotificationStatus();
                    },
                    (errorResponse: HttpErrorResponse) => {
                        let message = '';
                        message = errorResponse.message;
                        this.notificationsService.error(message);
                    });

                    if (this.user.Contact) {
                        this.user.Contact.FirstName = this.user.FirstName;
                        this.user.Contact.LastName = this.user.LastName;
                        this.user.Contact.Email = this.user.Email;
                        this.user.Contact.PhoneNo = this.user.Phone;
                        if (this.user.NotificationTypeId > 0) {
                            this.user.Contact.NotificationTypeId = this.user.NotificationTypeId;
                        }
                        this.updateContactCarrierCustomer(form);
                        this.contactService
                            .update(this.user.Contact)                            .subscribe(() => {
                                this.notificationsService.success('Contact details have been updated.');
                            },
                            (errorResponse: HttpErrorResponse) => {
                                this.notificationsService.error(errorResponse.error['ModelState'] as string);
                            });
                    }
            }
        } else {
            if (!passwordmatch) {
                this.error('Passwords do not match');
            } else {
                markAllFormFieldsAsTouched(form);
                this.error();
            }
        }
    }

    formCreated(form: FormGroup): void {
        this.userForm = form;
        if (this.user && this.user.Id === 0) {
            this.roleIdChanges$ = form.controls.AuthUser.get('RoleId').valueChanges.subscribe((state) => {
                form.controls.Contact.get('CarrierId').disable();
                form.controls.Contact.get('CustomerId').disable();
                
                form.controls.Contact.get('CarrierId').setValue(null);
                form.controls.Contact.get('CustomerId').setValue(null);

                if (state === UserRoles.Carrier) {
                    form.controls.Contact.get('CarrierId').enable();
                }

                if (state === UserRoles.Customer) {
                    form.controls.Contact.get('CustomerId').enable();
                }
                
                this.cdr.detectChanges();
            });
        }
    }

    private updateContactNotificationStatus() {
        if (!this.user.ContactId && (this.authUserRole === UserRoles.Carrier || this.authUserRole === UserRoles.Customer)) {
            this.userService.connectUserAndContact(this.user.Id, 0).subscribe((answer) => {
                this.contactService.updatePartial({ NotificationTypeId: this.user.NotificationTypeId }, answer).subscribe(() => {
                        this.notificationsService.success('Contact status has been updated');
                    }, (errorResponse: HttpErrorResponse) => {
                        this.notificationsService.error(errorResponse.message);
                    }
                );
            },
            (errorResponse: HttpErrorResponse) => {
                const message = errorResponse.error as string;
                this.notificationsService.error(message.split('\r\n')[0]);
            });
        }
        else if (this.user.NotificationTypeId > 0) {
            this.contactService.updatePartial({ NotificationTypeId: this.user.NotificationTypeId }, this.user.ContactId)
                .subscribe(() => {
                    this.notificationsService.success('Contact status has been updated');
                }, (errorResponse: HttpErrorResponse) => {
                    this.notificationsService.error(errorResponse.message);
                });
        }
    }

    private updateContactCarrierCustomer(form: UntypedFormGroup): void {
        if (this.loggedInUser.AuthUser?.RoleId !== UserRoles.Admin) { 
            return;
        }

        const customerId = form.value.Contact?.CustomerId;
        const carrierId = form.value.Contact?.CarrierId;
        if (customerId > 0 && carrierId > 0) {
            this.notificationsService.error('Validation failed: \r\n -- : A Contact cannot have both a Carrier and a Customer.');
            return;
        }

        if (customerId === 0) {
            this.user.Contact.CustomerId = null;
            form.value.Contact.CustomerId = null;
        } else {
            this.user.Contact.CustomerId = customerId;
        }
        
        if (carrierId === 0) {
            this.user.Contact.CarrierId = null;
            form.value.Contact.CarrierId = null;
        } else {
            this.user.Contact.CarrierId = carrierId;
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    updateVersion(version): void {
        this.user.Version = version;
    }
}
