<div [ngStyle]="{ height: '600px' }">
    <br />
    <div class="row">
        <div class="text-center">
            <h4>Enter your contact information</h4>
        </div>
    </div>
    <br />
    <form *ngIf="abstractSearchUserControls && orderContactInformationForm" [formGroup]="orderContactInformationForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <mt-dynamic-field
                    [field]="abstractSearchUserControls.FirstName"
                    [form]="orderContactInformationForm"
                    (valueChanges)="saveFirstName($event)"
                ></mt-dynamic-field>
                <mt-dynamic-field
                    [field]="abstractSearchUserControls.LastName"
                    [form]="orderContactInformationForm"
                    (valueChanges)="saveLastName($event)"
                ></mt-dynamic-field>
                <mt-dynamic-field
                    [field]="abstractSearchUserControls.Email"
                    [form]="orderContactInformationForm"
                    (valueChanges)="saveEmail($event)"
                ></mt-dynamic-field>
                <mt-dynamic-field
                    [field]="abstractSearchUserControls.Phone"
                    [form]="orderContactInformationForm"
                    (valueChanges)="savePhone($event)"
                ></mt-dynamic-field>
                <mt-dynamic-field
                    [field]="abstractSearchUserControls.IsCustomerPickup"
                    [form]="orderContactInformationForm"
                    (valueChanges)="saveIsCustomerPickup($event)"
                ></mt-dynamic-field>
                <mt-dynamic-field
                    [field]="abstractSearchUserControls.CarrierId"
                    [form]="orderContactInformationForm"
                    (valueChanges)="saveCarrierId($event)"
                ></mt-dynamic-field>
            </div>
            <div class="col-md-3"></div>
        </div>
        <br />
        <div class="row copy-text">
            <span><i>For Questions regarding your order, please contact us at 215-551-2737 or email us Customerservice@holtlogistics.com.</i></span>
        </div>
        <div class="row" style="margin-top: -15px">
            <div [ngStyle]="{ 'margin-top': '35px' }">
                <button type="submit" class="btn btn-primary pull-right" [ngStyle]="{ 'margin-right': '30px' }" [disabled]="isLoading">
                    Submit Order
                </button>
                <button class="btn btn-submit pull-left" (click)="Prev()" [ngStyle]="{ 'margin-left': '30px' }">Previous</button>
            </div>
        </div>
    </form>
    <ngx-loading [show]="isLoading"></ngx-loading>
</div>
