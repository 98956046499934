import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { IUser } from '../../model/interfaces/user';
import { Router } from '@angular/router';
import { IPickup } from '../../model/interfaces/pickup';
import { IOrder } from '../../model/interfaces/order';
import { ICheckIn } from '../../model/interfaces/check-in';
import { OrderLimits } from '../../model/classes/order-limits';
import { forkJoin } from 'rxjs';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { IModalResult, ModalService } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-driver-check-in-edit-pickup-orders',
    templateUrl: './driver-check-in-edit-pickup-orders.component.html',
    styleUrls: ['./driver-check-in-edit-pickup-orders.component.css'],
})
export class DriverCheckInEditPickupOrdersComponent implements OnInit {
    loading = true;
    driver: IUser;
    pickup: IPickup;
    checkIn: ICheckIn;
    selectedOrders: IOrder[];
    selectableOrders: IOrder[] = [];
    orderLimits: OrderLimits;
    officeId: number;
    disableSaveButton = true;
    orderInput = '';

    constructor(
        private checkInService: PublicCheckInService,
        private notificationService: NotificationsService,
        private router: Router,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
        });

        this.checkInService.checkedInPickup$.subscribe((pickup) => {
            this.pickup = pickup;
            this.selectedOrders = pickup?.Orders;
            if (this.selectedOrders?.length > 0) {
                this.officeId = this.selectedOrders[0].Warehouse.OfficeId;
                this.selectedOrders.sort((a, b) => a.OrderNumber.localeCompare(b.OrderNumber));
            }
        });

        this.checkInService.driverCreatedCheckIn$.subscribe((checkIn) => {
            this.checkIn = checkIn;
        });

        setTimeout(() => {
            if (!this.driver || !this.pickup) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }

            forkJoin([
                this.checkInService.getDriverOrders(this.officeId),
                this.checkInService.getOrderLimits(),
            ])
            .subscribe(([driverOrders, orderLimits]) => {
                this.selectableOrders = driverOrders;
                this.orderLimits = orderLimits;
                this.loading = false;
                this.showOrderLimitWarningIfNeeded();
            });
        }, 500);
    }

    searchForOrder() {
        const selectedOrder = this.selectableOrders.find(order => order.OrderNumber.toLowerCase() === this.orderInput.trim().toLowerCase());
        if (selectedOrder) {
            this.selectedOrders.push(selectedOrder);
            this.selectedOrders.sort((a, b) => a.OrderNumber.localeCompare(b.OrderNumber));
            this.selectableOrders = this.selectableOrders.filter(o => o.Id !== selectedOrder.Id);
            this.disableSaveButton = false;
            this.orderInput = '';
            this.showOrderLimitWarningIfNeeded();
        } else {
            this.notificationService.error('Order not found.');
        }
    }

    removeOrder(selectedOrder: IOrder): void {
        if (this.selectedOrders.length <= 1) {
            this.notificationService.error('Pickup must have one or more orders.');
            return;
        }

        this.modalService.showModal({
                title: `Remove Order from Pickup?`,
                showCancelButton: true,
                confirmButtonText: `Remove`,
            })
            .subscribe((result: IModalResult) => {
                if (result.isConfirmed) {
                    this.selectedOrders = this.selectedOrders.filter(o => o.Id !== selectedOrder.Id);
                    this.selectedOrders.sort((a, b) => a.OrderNumber.localeCompare(b.OrderNumber));
                    this.selectableOrders.push(selectedOrder);
                    this.disableSaveButton = false;
                    this.showOrderLimitWarningIfNeeded();
                }
            });
    }

    goToPaymentPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        this.checkInService.checkedInPickup$.next(this.pickup);
        this.checkInService.driverCreatedCheckIn$.next(this.checkIn);
        void this.router.navigate([`public/driver-check-in-payment`]);
    }

    savePickup(): void {
        this.loading = true;
        this.checkInService.assignOrdersToPickup(this.pickup.Id, this.driver.Id, this.selectedOrders)
            .subscribe({
                next: (pickup) => {
                    this.pickup = pickup;
                    this.notificationService.success('Orders successfully saved.');
                    this.disableSaveButton = true;
                },
                complete: () => {
                    this.loading = false;
                }
            });
    }

    goToDriverCheckInInfoPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate(['public/driver-check-in-info']);
    }

    private showOrderLimitWarningIfNeeded(): void { 
        const orderCustomers = this.selectedOrders.map(o => o.Customer);
        const largestCustomerPalletCount = CommonFunctions.getLargestCustomerPalletCount(orderCustomers);
        const ordersTotalPallet = CommonFunctions.getOrdersTotalPallet(this.selectedOrders);
        const palletCountLimit = largestCustomerPalletCount ?? this.orderLimits.PalletCount;
        
        if (ordersTotalPallet > palletCountLimit) 
            this.notificationService.warning(`The total Pallet Count ${ordersTotalPallet} exceeds the limit set at ${palletCountLimit}. Proceed with caution.`);

        const largestCustomerOrderWeight = CommonFunctions.getLargestCustomerOrderWeight(orderCustomers);
        const ordersTotalWeight = CommonFunctions.getOrdersTotalWeight(this.selectedOrders);
        const orderWeightLimit = largestCustomerOrderWeight ?? this.orderLimits.OrderWeight;
        if (ordersTotalWeight > orderWeightLimit) 
            this.notificationService.warning(`The total Order Weight ${ordersTotalWeight} lbs exceeds the limit set at ${orderWeightLimit} lbs. Proceed with caution.`);
    }
}
