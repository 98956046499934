import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { CarrierFeesComponent } from './carrier-fees/carrier-fees.component';
import { CarrierRoutingModule } from './carrier-routing.module';
import { CarrierComponent } from './carrier/carrier.component';
import { CarrierHeaderComponent } from './carrier-header/carrier-header.component';
import { CarrierDetailComponent } from './carrier-detail/carrier-detail.component';
import { CarrierBasicInfoComponent } from './carrier-basic-info/carrier-basic-info.component';

@NgModule({
    declarations: [
        CarrierComponent,
        CarrierFeesComponent,
        CarrierHeaderComponent,
        CarrierDetailComponent,
        CarrierBasicInfoComponent
    ],
    imports: [SharedModule, CarrierRoutingModule],
})
export class CarrierModule { }
