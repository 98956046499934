import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { IOfficeNonTwicTiming } from '../../model/interfaces/office-non-twic-timing';
import { OfficeNonTwicTimingService } from '../services/office-non-twic-timing.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { OfficeNonTwicTimingDynamicConfig } from '../office-non-twic-timing.dynamic-config';
import { DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'office-non-twic-timing',
    templateUrl: 'office-non-twic-timing.component.html',
})
export class OfficeNonTwicTimingComponent implements OnInit {
    @Input() officeId = 0;
    canEdit = false;
    isEditing = false;
    doubleClickIsDisabled = false;
    officeNonTwicTimings: IOfficeNonTwicTiming[] = [];
    selectedNonTwicTiming: IOfficeNonTwicTiming;
    subscription: Subscription = new Subscription();
    formObject: DynamicField[] = [];
    formFactory: OfficeNonTwicTimingDynamicConfig<IOfficeNonTwicTiming>;
    emptyNonTwicTiming: IOfficeNonTwicTiming = {
        EndTime: null,
        FromDate: null,
        Id: 0,
        OfficeId: this.officeId,
        StartTime: null,
        ToDate: null,
    };

    constructor(
        private claimsService: ClaimsService,
        private officeNonTwicTimingService: OfficeNonTwicTimingService,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Offices, [ClaimValues.FullAccess]);
        this.getNonTwicTimings();
    }

    setConfig(): void {
        let config:IDynamicFormConfig;
        this.formFactory = new OfficeNonTwicTimingDynamicConfig<IOfficeNonTwicTiming>(this.selectedNonTwicTiming);
        if (this.selectedNonTwicTiming.Id === 0) {
            // new office
            config = this.formFactory.getForCreate();
        } else {
            // existing office
            config = this.formFactory.getForUpdate();
        }
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
    }

    getNonTwicTimings(): void {
        this.officeNonTwicTimingService.getByOffice(this.officeId).subscribe((result) => {
            this.officeNonTwicTimings = result;
        });
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            
            this.formFactory.assignFormValues(this.selectedNonTwicTiming, form.value.OfficeNonTwicTiming as IOfficeNonTwicTiming);
            this.selectedNonTwicTiming.OfficeId = this.officeId;
            if (!this.selectedNonTwicTiming.Id || this.selectedNonTwicTiming.Id === 0) {
                this.officeNonTwicTimingService
                    .create(this.selectedNonTwicTiming).subscribe(() => {
                        this.selectedNonTwicTiming = null;
                        this.getNonTwicTimings();
                    });
            } else {
                this.officeNonTwicTimingService
                    .update(this.selectedNonTwicTiming).subscribe(() => {
                        this.selectedNonTwicTiming = null;
                        this.getNonTwicTimings();
                    });
            }
        } else {
            markAllFormFieldsAsTouched(form);
        }
    }

    isEditMode(nonTwicTiming: IOfficeNonTwicTiming): boolean {
        return this.selectedNonTwicTiming && this.selectedNonTwicTiming.Id === nonTwicTiming.Id;
    }

    setEdit(nonTwicTiming: IOfficeNonTwicTiming): void {
        this.selectedNonTwicTiming = { ...nonTwicTiming };
        this.setConfig();
    }

    add(): void {
        this.selectedNonTwicTiming = { ...this.emptyNonTwicTiming };
        this.setConfig();
    }

    onDelete(event: IOfficeNonTwicTiming): void {
        this.officeNonTwicTimingService.delete(event.Id).subscribe(
            () => {
                this.getNonTwicTimings();
                this.notificationService.success('Deleted Successfully.');
            },
            () => {
                this.notificationService.error('Error could not delete.');
            },
        );
    }

    cancel(): void {
        this.selectedNonTwicTiming = null;
    }
}
