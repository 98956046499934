import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { Component } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { ExceptionQueueService } from '../services/exception-queue.service';
import { IExceptionQueue } from '../../model/interfaces/exception-queue';

@Component({
    templateUrl: './archive-exception-list-component.html',
})
export class ArchiveExceptionListComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    item: IExceptionQueue;
    isLoading = false;
    constructor(private exceptionQueueService: ExceptionQueueService, private notificationsService: NotificationsService) { }

    set entity(value: IExceptionQueue) {
        this.item = value;
    }

    delete(event: MouseEvent): void {
        event.stopPropagation();
        if (!this.isLoading) {
            this.isLoading = true;
            this.exceptionQueueService
                .deleteException(this.item.Id)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe({
                    next: () => {
                        this.notificationsService.success(`Successfully deleted exception.`);
                        this.entityListComponentMembers.getEntities();
                    },
                    error: () => {
                        this.notificationsService.error('Archive failed');
                    }
                });
        }
    }
}
