import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrder } from '../../model/interfaces/order';
import { combineLatest, forkJoin } from 'rxjs';
import { IPickup } from '../../model/interfaces/pickup';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';

@Component({
    selector: 'app-driver-notified',
    templateUrl: './driver-notified.component.html',
    styleUrls: ['./driver-notified.component.css'],
})
export class DriverNotifiedComponent implements OnInit {
    driver: IUser;
    pickup: IPickup;
    ordersWithDoors: IOrder[] = [];
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private checkInService: PublicCheckInService,
    ) { }

    ngOnInit(): void {
        const driverId = +this.route.snapshot.paramMap.get('driverId');
        const pickupId = +this.route.snapshot.paramMap.get('pickupId');

        if (driverId > 0 && pickupId > 0) {
            forkJoin([
                this.checkInService.findValidDriverById(driverId),
                this.checkInService.getPickupById(pickupId),
            ]).subscribe(([driver, pickup]) => {
                this.driver = driver;
                this.pickup = pickup;
                this.getOrdersWithDoors();
            });

            return;
        }
        
        combineLatest([this.checkInService.checkedInDriver$, this.checkInService.checkedInPickup$]).subscribe(
            ([driver, pickup]) => {
                this.driver = driver;
                this.pickup = pickup;
                if (!driver || !pickup) {
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }
                this.getOrdersWithDoors();
            }
        );
    }

    getOrdersWithDoors(): void {
        const checkInId = this.pickup.CheckIns[0].Id;
        this.checkInService.getCheckInDetailDoors(checkInId).subscribe({
            next: (checkInDetails) => {
                checkInDetails.forEach(detail => {
                    if (detail.OfficeDoor?.Door) {
                        this.ordersWithDoors.push(detail.Order);
                    } 
                });
            }
        });
    }

    goToDriverOrdersReadyPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        this.checkInService.checkedInPickup$.next(this.pickup);
        void this.router.navigate([`public/driver-orders-ready`]);
    }
}
