// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    apiVersion: '1',
    appName: 'Remote Kiosk',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 1,
    authSecretVariable: 'F9xIUsDid3CdYypXEeZGbjbi',
    baseApiUrl: '',
    docPath: 'docs/',
    googleApiKey: '', // Empty this string to disable google login
    imgPath: 'docs/images/',
    logger: false,
    production: false,
    payPalClientId: 'ATFfO1XGomU7Z9lnM3E4WJ8qq6wN_xAWfmBvaHCrdnpqnT5NorFsdJLFTMWC5wTmdERjxiOzmr4bYnjs',
    squareApplicationId: 'sandbox-sq0idb-UZP9vxOBzji6_xMmbDMK8A',
    squareLocationId: 'LR2X6BY2GJX3P',
    squareWebPaymentsScriptUrl: 'https://sandbox.web.squarecdn.com/v1/square.js',
};
