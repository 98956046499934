import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { ContactsComponent } from './contacts-list/contacts-list.component';
import { ContactService } from './contact.service';
import { ContactDetailComponent } from './contacts-details/contacts-details.component';



const contactEntityConfig = {
    claimType: ClaimTypes.Contacts,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'contactId',
    service: ContactService,
    title: 'Contact Info',
};

const contactsListRoleGuard = {
    claimType: ClaimTypes.Contacts,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Contacts',
};

const contactAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Contacts,
    claimValues: [ClaimValues.FullAccess],
};

const contactsRoutes: Routes = [
    { path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard], data: contactsListRoleGuard },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: ContactDetailComponent, pathMatch: 'full' }],
        component: ContactDetailComponent,
        data: contactAddRoleGuard,
        path: 'contacts/add',
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: ContactDetailComponent, pathMatch: 'full' }],
        component: ContactDetailComponent,
        data: contactEntityConfig,
        path: `contacts/:${contactEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(contactsRoutes)],
})
export class ContactsRoutingModule {}
