import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TruckerService } from '../trucker.service';
import { IUser } from '../../model/interfaces/user';
import { TruckerBanHistoryEntityListConfig } from './trucker-ban-history.entity-list-config';
import { IDriverBanHistory } from '../../model/interfaces/driver-ban-history';
import { TruckerBanHistoryService } from './trucker-ban-history.service';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

@Component({
    selector: 'app-trucker-ban-history',
    templateUrl: './trucker-ban-history.component.html',
})
export class TruckerBanHistoryComponent implements OnInit {
    trucker: IUser;
    previousUrl: string;
    entityListConfig: TruckerBanHistoryEntityListConfig;
    order = 'ActionDate';
    orderDirection: string;
    truckerId: number;
    query: string;
    total: number;
    truckerBanHistory: IDriverBanHistory[];
    currentPage = 1;
    itemsPerPage = 10;

    constructor(
        private truckerService: TruckerService,
        private truckerBanHistoryService: TruckerBanHistoryService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.truckerId = +this.route.snapshot.paramMap.get('truckerId');
        this.getTruckerById(this.truckerId);
        this.initEntityListConfig();
        this.getTruckerBanHistory();
    }

    getTruckerById(id: number): void {
        this.truckerService.getById(id).subscribe((trucker) => {
            this.trucker = trucker;
        });
    }

    initEntityListConfig(): void {
        this.entityListConfig = new TruckerBanHistoryEntityListConfig();
        this.order = 'ActionDate';
        this.orderDirection = 'desc';
    }

    getTruckerBanHistory(): void {
        const searchParams = this.getSearchParams();
        this.truckerBanHistoryService.get(searchParams).subscribe((answer) => {
            this.truckerBanHistory = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getSearchParams(): SearchParams {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [
            new ExtraSearchParams({
                name: 'TruckerIds',
                valueArray: [this.truckerId],
            })
        ];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        return new SearchParams(searchEntity);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getTruckerBanHistory();
    }

    search(query: string): void {
        this.query = query;
        this.getTruckerBanHistory();
    }

    close(): void {
        void this.router.navigate([`/truckers/${this.truckerId}`]);
    }
}
