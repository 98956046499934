import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { CarrierRequestStatusService } from '../../orders/carrier-request-status.service';
import { CarrierRequestService } from '../carrier-request.service';
import { ICarrierRequest } from '../../model/interfaces/carrier-request';
import { CarrierRequestDynamicConfig } from './carrier-request.dynamic-config';
import { ICarrierRequestStatus } from '../../model/interfaces/carrier-request-status';
import { UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

@Component({
    selector: 'app-carrier-request-form',
    templateUrl: './carrier-request-form.component.html',
})
export class CarrierRequestFormComponent implements OnInit {
    carrierRequest: ICarrierRequest
    formObject: DynamicField[] = [];
    formFactory: CarrierRequestDynamicConfig<ICarrierRequest>;
    viewOnly: DynamicLabel[] = [];
    isEditing = false;
    doubleClickIsDisabled = false;

    constructor(

        private carrierRequestService: CarrierRequestService,
        private carrierRequestStatusService: CarrierRequestStatusService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.carrierRequest = this.carrierRequestService.getEmptyCarrierRequest();
        this.setConfig();
    }

    setConfig(): void {
        this.carrierRequestStatusService.getAll().subscribe((statuses: ICarrierRequestStatus[]) => {
            this.formFactory = new CarrierRequestDynamicConfig<ICarrierRequest>(
                this.carrierRequest,
                statuses,
                null
            );

            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
            this.isEditing = true;
        });
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.carrierRequest, form.value.CarrierRequest as ICarrierRequest);
            this.carrierRequest.DateCreated = new Date();

            this.carrierRequestService.create(this.carrierRequest)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe(
                    (answer) => {
                        this.notificationsService.success('Saved Successfully');
                        this.carrierRequestService.emitChange(this.carrierRequest);
                        this.carrierRequest.Id =  answer;
                        this.setConfig();
                    },
                    (error) => {
                        let errorMessage = 'An error occurred';
                        if(typeof error.message === 'string') errorMessage = error.message;
                        this.notificationsService.error(errorMessage);
                    }
                );
        }
        else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save Failed');
        }
    }

    /*****
     *  For this task here: https://milesit.striven.com/tasks/TaskInfo.aspx?TaskID=3634767
     *
     *  we needed to prove that we can generate a carrier when approved, but that page isn't made yet, so this is commented out
     *  it has been tested and it does work
     *
     */

    // approveCarrierRequest(): void {
    //     this.carrierRequestService.updatePartial({StatusId: CarrierRequestStatuses.Approved }, this.carrierRequest.Id).subscribe(() => {
    //         this.notificationsService.success('Status Changed to Approved');
    //         this.generateCarrierFromCarrierRequest('123456456');
    //     },
    //     (error) => {
    //         let errorMessage = 'An error occurred';
    //         if(typeof error.message === 'string') errorMessage = error.message;
    //         this.notificationsService.error(errorMessage);
    //     });
    // }

    // private generateCarrierFromCarrierRequest(accountNo : string): void {
    //     const carrier: ICarrier = {
    //         AccountNo: accountNo,
    //         Archived: false,
    //         Id: 0,
    //         Name: this.carrierRequest.CompanyName,
    //         InvoiceForPickupFees: false,
    //     };

    //     this.carrierRequestService.createNewCarrier(this.carrierRequest.Id, carrier).subscribe(()=> {
    //         this.notificationsService.success('Carrier Request Generated');
    //     },
    //     (error) => {
    //         let errorMessage = 'An error occurred';
    //         if(typeof error.message === 'string') errorMessage = error.message;
    //         this.notificationsService.error(errorMessage);
    //     });
    // }

    backButtonPressed(): void {
        void this.router.navigate(['/orders']);
    }
}
