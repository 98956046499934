import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IOrder } from '../interfaces/order';
import { ICarrier } from '../interfaces/carrier';
import { ICustomerDockLocation } from '../interfaces/customer-dock-location';
import { ICustomer } from '../interfaces/customer';
import { INotFulFilledReason } from '../interfaces/not-ful-filled-reason';
import { IPickup } from '../interfaces/pickup';
import { IOrderStatus } from '../interfaces/order-status';
import { IWarehouse } from '../interfaces/warehouse';

export interface IOrderDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
    statuses?: IOrderStatus[];
    customerDockLocations?: ICustomerDockLocation[];
    warehouses?: IWarehouse[];
    carriers?: ICarrier[];
    notFulFilledReasons?: INotFulFilledReason[];
    pickUps?: IPickup[];
}

export class OrderDynamicControls {

    formGroup: string;
    customers: ICustomer[];
    statuses: IOrderStatus[];
    customerDockLocations: ICustomerDockLocation[];
    warehouses: IWarehouse[];
    carriers: ICarrier[];
    notFulFilledReasons: INotFulFilledReason[];
    pickUps: IPickup[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private order?: IOrder, additionalParameters?: IOrderDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Order';
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;
        this.customerDockLocations = additionalParameters && additionalParameters.customerDockLocations || undefined;
        this.warehouses = additionalParameters && additionalParameters.warehouses || undefined;
        this.carriers = additionalParameters && additionalParameters.carriers || undefined;
        this.notFulFilledReasons = additionalParameters && additionalParameters.notFulFilledReasons || undefined;
        this.pickUps = additionalParameters && additionalParameters.pickUps || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.hasOwnProperty('Archived') && this.order.Archived !== null ? this.order.Archived : false,
            }),
            CarrierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                name: 'CarrierId',
                options: this.carriers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.CarrierId || null,
            }),
            CreationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Creation Date',
                name: 'CreationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.CreationDate || null,
            }),
            CustomerDockLocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Dock Location',
                name: 'CustomerDockLocationId',
                options: this.customerDockLocations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.CustomerDockLocationId || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.order && this.order.CustomerId || null,
            }),
            CustomerPickupDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Pickup Date',
                name: 'CustomerPickupDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.CustomerPickupDate || null,
            }),
            DateCancelled: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Cancelled',
                name: 'DateCancelled',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.DateCancelled || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.DateCreated || null,
            }),
            DateExported: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Exported',
                name: 'DateExported',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.DateExported || null,
            }),
            DateFulfilled: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Fulfilled',
                name: 'DateFulfilled',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.DateFulfilled || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.DateModified || null,
            }),
            DateRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Requested',
                name: 'DateRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.DateRequested || null,
            }),
            DateScheduled: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Scheduled',
                name: 'DateScheduled',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.DateScheduled || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.ModifiedById || null,
            }),
            NotFulFilledReasonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Not Ful Filled Reason',
                name: 'NotFulFilledReasonId',
                options: this.notFulFilledReasons,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.NotFulFilledReasonId || null,
            }),
            OrderNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Number',
                name: 'OrderNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.order && this.order.hasOwnProperty('OrderNumber') && this.order.OrderNumber !== null ? this.order.OrderNumber.toString() : '',
            }),
            PickUpDateTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pick Up Date Time',
                name: 'PickUpDateTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.PickUpDateTime || null,
            }),
            PickUpId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pick Up',
                name: 'PickUpId',
                options: this.pickUps,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.PickUpId || null,
            }),
            PrevDateRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Prev Date Requested',
                name: 'PrevDateRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.PrevDateRequested || null,
            }),
            ScheduleConfirmationNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Schedule Confirmation Number',
                name: 'ScheduleConfirmationNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.ScheduleConfirmationNumber || null,
            }),
            SchedulerAssumesTruckerTwicCard: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scheduler Assumes Trucker Twic Card',
                name: 'SchedulerAssumesTruckerTwicCard',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.hasOwnProperty('SchedulerAssumesTruckerTwicCard') && this.order.SchedulerAssumesTruckerTwicCard !== null ? this.order.SchedulerAssumesTruckerTwicCard : false,
            }),
            SchedulerEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scheduler Email',
                name: 'SchedulerEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.order && this.order.hasOwnProperty('SchedulerEmail') && this.order.SchedulerEmail !== null ? this.order.SchedulerEmail.toString() : '',
            }),
            SchedulerFirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scheduler First Name',
                name: 'SchedulerFirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.order && this.order.hasOwnProperty('SchedulerFirstName') && this.order.SchedulerFirstName !== null ? this.order.SchedulerFirstName.toString() : '',
            }),
            SchedulerLastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scheduler Last Name',
                name: 'SchedulerLastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.order && this.order.hasOwnProperty('SchedulerLastName') && this.order.SchedulerLastName !== null ? this.order.SchedulerLastName.toString() : '',
            }),
            SchedulerPhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scheduler Phone Number',
                name: 'SchedulerPhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.order && this.order.hasOwnProperty('SchedulerPhoneNumber') && this.order.SchedulerPhoneNumber !== null ? this.order.SchedulerPhoneNumber.toString() : '',
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.order && this.order.StatusId || null,
            }),
            TotalPallet: new DynamicField({
                formGroup: this.formGroup,
                label: 'Total Pallet',
                name: 'TotalPallet',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.TotalPallet || null,
            }),
            TotalWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Total Weight',
                name: 'TotalWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.TotalWeight || null,
            }),
            WarehouseId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Warehouse',
                name: 'WarehouseId',
                options: this.warehouses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.order && this.order.WarehouseId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.order && this.order.hasOwnProperty('Archived') && this.order.Archived !== null ? this.order.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CarrierId: new DynamicLabel({
			    label: 'Carrier',
			    value: getMetaItemValue(this.carriers as unknown as IMetaItem[], this.order && this.order.hasOwnProperty('CarrierId') && this.order.CarrierId !== null ? this.order.CarrierId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreationDate: new DynamicLabel({
			    label: 'Creation Date',
			    value: this.order && this.order.CreationDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            CustomerDockLocationId: new DynamicLabel({
			    label: 'Customer Dock Location',
			    value: getMetaItemValue(this.customerDockLocations as unknown as IMetaItem[], this.order && this.order.hasOwnProperty('CustomerDockLocationId') && this.order.CustomerDockLocationId !== null ? this.order.CustomerDockLocationId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CustomerId: new DynamicLabel({
			    label: 'Customer',
			    value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.order && this.order.hasOwnProperty('CustomerId') && this.order.CustomerId !== null ? this.order.CustomerId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CustomerPickupDate: new DynamicLabel({
			    label: 'Customer Pickup Date',
			    value: this.order && this.order.CustomerPickupDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateCancelled: new DynamicLabel({
			    label: 'Date Cancelled',
			    value: this.order && this.order.DateCancelled || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.order && this.order.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateExported: new DynamicLabel({
			    label: 'Date Exported',
			    value: this.order && this.order.DateExported || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateFulfilled: new DynamicLabel({
			    label: 'Date Fulfilled',
			    value: this.order && this.order.DateFulfilled || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.order && this.order.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateRequested: new DynamicLabel({
			    label: 'Date Requested',
			    value: this.order && this.order.DateRequested || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateScheduled: new DynamicLabel({
			    label: 'Date Scheduled',
			    value: this.order && this.order.DateScheduled || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: this.order && this.order.ModifiedById || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            NotFulFilledReasonId: new DynamicLabel({
			    label: 'Not Ful Filled Reason',
			    value: getMetaItemValue(this.notFulFilledReasons as unknown as IMetaItem[], this.order && this.order.hasOwnProperty('NotFulFilledReasonId') && this.order.NotFulFilledReasonId !== null ? this.order.NotFulFilledReasonId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            OrderNumber: new DynamicLabel({
			    label: 'Order Number',
			    value: this.order && this.order.hasOwnProperty('OrderNumber') && this.order.OrderNumber !== null ? this.order.OrderNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PickUpDateTime: new DynamicLabel({
			    label: 'Pick Up Date Time',
			    value: this.order && this.order.PickUpDateTime || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            PickUpId: new DynamicLabel({
			    label: 'Pick Up',
			    value: getMetaItemValue(this.pickUps as unknown as IMetaItem[], this.order && this.order.hasOwnProperty('PickUpId') && this.order.PickUpId !== null ? this.order.PickUpId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PrevDateRequested: new DynamicLabel({
			    label: 'Prev Date Requested',
			    value: this.order && this.order.PrevDateRequested || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ScheduleConfirmationNumber: new DynamicLabel({
			    label: 'Schedule Confirmation Number',
			    value: this.order && this.order.ScheduleConfirmationNumber || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SchedulerAssumesTruckerTwicCard: new DynamicLabel({
			    label: 'Scheduler Assumes Trucker Twic Card',
			    value: this.order && this.order.hasOwnProperty('SchedulerAssumesTruckerTwicCard') && this.order.SchedulerAssumesTruckerTwicCard !== null ? this.order.SchedulerAssumesTruckerTwicCard : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SchedulerEmail: new DynamicLabel({
			    label: 'Scheduler Email',
			    value: this.order && this.order.hasOwnProperty('SchedulerEmail') && this.order.SchedulerEmail !== null ? this.order.SchedulerEmail.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SchedulerFirstName: new DynamicLabel({
			    label: 'Scheduler First Name',
			    value: this.order && this.order.hasOwnProperty('SchedulerFirstName') && this.order.SchedulerFirstName !== null ? this.order.SchedulerFirstName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SchedulerLastName: new DynamicLabel({
			    label: 'Scheduler Last Name',
			    value: this.order && this.order.hasOwnProperty('SchedulerLastName') && this.order.SchedulerLastName !== null ? this.order.SchedulerLastName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SchedulerPhoneNumber: new DynamicLabel({
			    label: 'Scheduler Phone Number',
			    value: this.order && this.order.hasOwnProperty('SchedulerPhoneNumber') && this.order.SchedulerPhoneNumber !== null ? this.order.SchedulerPhoneNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            StatusId: new DynamicLabel({
			    label: 'Status',
			    value: getMetaItemValue(this.statuses as unknown as IMetaItem[], this.order && this.order.hasOwnProperty('StatusId') && this.order.StatusId !== null ? this.order.StatusId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TotalPallet: new DynamicLabel({
			    label: 'Total Pallet',
			    value: this.order && this.order.TotalPallet || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            TotalWeight: new DynamicLabel({
			    label: 'Total Weight',
			    value: this.order && this.order.TotalWeight || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            WarehouseId: new DynamicLabel({
			    label: 'Warehouse',
			    value: getMetaItemValue(this.warehouses as unknown as IMetaItem[], this.order && this.order.hasOwnProperty('WarehouseId') && this.order.WarehouseId !== null ? this.order.WarehouseId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
