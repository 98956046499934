import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../order.service';
import { IOrder } from '../../../model/interfaces/order';
import { UntypedFormGroup } from '@angular/forms';
import { PickupDynamicConfig } from '../pickup.dynamic-config'
import { IPickup } from '../../../model/interfaces/pickup';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { ICarrier } from '../../../model/interfaces/carrier';
import { PickupService } from '../../../model/shared-entities/pickups/pickup.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ModalService } from '@mt-ng2/modal-module';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@mt-ng2/auth-module';
import { UserService } from '../../../users/user.service';
import { IUser } from '../../../model/interfaces/user';
import { PickupStatus } from '../../order-basic-info/pickup-status-enum';
import { CommonService } from '../../../common/services/common.service';

@Component({
    selector: 'app-pickup-scheduling-date-selection',
    templateUrl: './date-selection.component.html',
    styles: [`
        .pickup-scheduling-table {
            color: #000;
        }
        .centered-card {
            width: 50%;
            padding: 20px;
        }
        .miles-card b {
            color: black;
        }
    `]
})
export class PickupSchedulingDateSelection implements OnInit {
    currentUser: IUser;
    pickup: IPickup;
    selectedOrders: IOrder[];
    selectedCarrier: ICarrier;
    selectedZip: string;
    formFactory: PickupDynamicConfig<IPickup>;
    formObject: DynamicField[] = [];
    doubleClickIsDisabled = false;

    constructor(
        private orderService: OrderService,
        private pickupService: PickupService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.orderService.selectedPickupOrders$.subscribe((selectedOrders) => {
            if (!selectedOrders || selectedOrders.length <= 0) {
                void this.router.navigate([`/create-requests/order-selection`]);
                return;
            }
            this.selectedOrders = selectedOrders;
        });

        const userId = this.authService.currentUser.getValue().Id;
        this.userService.getById(userId).subscribe(user => {
            this.currentUser = user;
        });

        this.orderService.selectedPickupCarrier$.subscribe((selectedCarrier) => {
            this.selectedCarrier = selectedCarrier;
        });
        this.orderService.selectedPickupZip$.subscribe((selectedZip) => {
            this.selectedZip = selectedZip;
        });
        this.pickupService.selectedPickup$.subscribe((selectedPickup) => {
            if (selectedPickup) {
                this.pickup = selectedPickup;
            } else {
                this.pickup = this.pickupService.getEmptyPickup();
                this.pickup.DateRequested = new Date();
                this.pickup.DeliveryZipCode = this.selectedZip;
            }
        });
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new PickupDynamicConfig<IPickup>(this.pickup);        
        const config = this.formFactory.getForCreate();
        this.formObject = config.formObject?.map((x) =>new DynamicField(x));
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (this.pickup.Id > 0) {
            this.pickup.Orders = this.selectedOrders;
            this.pickup.CarrierId = this.selectedCarrier.Id;
            this.pickup.DeliveryZipCode = this.selectedZip;
            this.pickup.DateRequested = form.value.DateRequested as Date;
            this.pickup.DriverId = null;

            this.pickupService.updateWithFks(this.pickup).subscribe({
                next: () => {
                    this.pickupService.sendUpdatedEmails(this.pickup.Id).subscribe({
                        next: () => {
                            this.showSuccessModal();
                        },
                        error: (errorResponse: HttpErrorResponse) => {
                            this.handleErrorResponse(errorResponse);
                        }
                    });
                },
                error: (errorResponse: HttpErrorResponse) => {
                    this.handleErrorResponse(errorResponse);
                }
            });
            return;
        };

        const params = {
            carrierId: this.selectedCarrier.Id,
            driverId: null,
            dateRequested: form.value.DateRequested as Date,
            dateConfirmed: null,
            deliveryZipCode: this.selectedZip,
            orders: this.selectedOrders,
            statusId: PickupStatus.Requested,
        }

        this.pickupService.createScheduledPickup(params).subscribe({
            next: (pickup: IPickup) => {
                this.pickupService.sendUpdatedEmails(pickup.Id).subscribe({
                    next: () => {
                        this.showSuccessModal();
                    },
                    error: (errorResponse: HttpErrorResponse) => {
                        this.handleErrorResponse(errorResponse);
                    }
                });
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.handleErrorResponse(errorResponse);
            }
        });
    }

    cancelPickup(pickup: IPickup): void {
        if (pickup.Id > 0) {
            this.commonService.cancelPickup(pickup);
            return;
        }

        void this.router.navigate([`/create-requests/order-selection`]);
    }
    
    private showSuccessModal(): void {
        this.modalService
            .showModal({
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                text: `"${this.selectedCarrier.Name}" is reviewing your request and will confirm your pickup date ASAP.`,
                title: 'Got It!',
                icon: 'success',
            })
            .subscribe(() => {
                void this.router.navigate(['create-requests/manage-pickups']);
            });
    }

    private handleErrorResponse(errorResponse: HttpErrorResponse): void {
        if (typeof(errorResponse.error) === 'string') {
            this.notificationsService.error(errorResponse.error);
            return;
        }
        this.notificationsService.error(errorResponse.error['ModelState'][0] as string);
    }
}
