import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { TruckerService } from '../trucker.service';
import { UserDynamicConfig } from '../../users/user.dynamic-config';
import { IUser } from '../../model/interfaces/user';
import { CarrierService } from '../../carriers/services/carrier.service';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-trucker-info',
    templateUrl: './trucker-info.component.html',
})
export class TruckerInfoComponent implements OnInit {
    @Input() trucker: IUser;
    @Input() canEdit: boolean;
    isEditing: boolean;
    isHovered: boolean;
    truckerId = 0;
    checkInId = 0;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    subscription: Subscription = new Subscription();

    constructor(
        private truckerService: TruckerService,
        private notificationsService: NotificationsService,
        private router: Router,
        private carrierService: CarrierService,
    ) { }

    ngOnInit(): void {
        this.isEditing = false;
        this.setConfig();
    }

    setConfig(): void {
        this.carrierService.getActive().subscribe((carriers) => {

            const configControls = [
                'FirstName',
                'LastName',
                'Email',
                'Phone',
                'UsdotNumber',
                'DriverLicenseNumber',
                'TractorLicensePlate',
                'TrailerLicensePlate',
                'CarrierId',
                'TwicExpirationDate',
                'TwicCardNumber',
                'BannedFromPickup',
                'BannedFromPickupReason',
            ];

            this.formFactory = new UserDynamicConfig<IUser>(
                this.trucker,
                carriers,
                null,
                configControls,
            );

            let config: IDynamicFormConfig;
            if (this.trucker.Id === 0) {
                // new customer
                this.isEditing = true;
                config = this.formFactory.getForCreate();
            } else {
                // existing customer
                config = this.formFactory.getForUpdate();
            }

            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.trucker.Id === 0) {
            void this.router.navigate(['/truckers']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (!form.valid) {
            markAllFormFieldsAsTouched(form);
            this.error();
            return;
        }
        
        // assign form values to trucker
        this.formFactory.assignFormValues(this.trucker, form.value.User as IUser);

        // handle new trucker save
        if (!this.trucker.Id || this.trucker.Id === 0) {
            this.truckerService.create(this.trucker)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe((answer) => {
                    void this.router.navigate([`/truckers/${answer}`]);
                    this.success();
                    this.truckerService.emitChange(this.trucker);
                });

            return;
        }

        // handle existing trucker save
        this.truckerService.updateVersion(this.trucker)
            .pipe(finalize(() => this.doubleClickIsDisabled = false))
            .subscribe(
                (success) => {
                    this.trucker.Version = success;
                    if (!this.trucker.BannedFromPickup) {
                        this.trucker.BannedFromPickupReason = null;
                    }
                    this.isEditing = false;
                    this.success();
                    this.truckerService.emitChange(this.trucker);
                    this.setConfig();
                },
            );
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    formCreated(event: UntypedFormGroup): void {
        this.subscription.add(
            event.get('User.BannedFromPickup').valueChanges.subscribe((banned: boolean) => {
                if (banned !== this.trucker.BannedFromPickup) {
                    event.get('User.BannedFromPickupReason').setValidators([Validators.required]);
                    event.get('User.BannedFromPickupReason').markAsTouched();
                    event.get('User.BannedFromPickupReason').updateValueAndValidity();
                    event.get('User.BannedFromPickupReason').setValue(null);
                } else {
                    event.get('User.BannedFromPickupReason').clearValidators();
                    event.get('User.BannedFromPickupReason').markAsUntouched();
                    event.get('User.BannedFromPickupReason').updateValueAndValidity();
                    event.get('User.BannedFromPickupReason').setValue(this.trucker.BannedFromPickupReason);
                }
            }),
        );
    }
}
