import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SharedEntitiesService } from '@mt-ng2/shared-entities-module';
import { IAddressContainer } from '@mt-ng2/dynamic-form';

import { IContact } from '../../interfaces/contact';
import { catchError } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';

export const emptyContact: IContact = {
    Archived: false,
    DateCreated: new Date(),
    Email: '',
    FirstName: '',
    GrantAccessToPortal: false,
    Id: 0,
    LastName: '',
    NotificationTypeId: 1,
    NotificationWhenDriverChecksIn: false,
    NotificationWhenDriverIsFulfilled: false,
    NotificationWhenDriverIsIssuedGatePass: false,
    NotificationWhenDriverIsNotified: false,
    PhoneNo: '',
    PrimaryContact: false,
    Title: '',
    TypeId: 1,
};

@Injectable()
export class ContactService extends SharedEntitiesService<IContact> {
    constructor(public baseEntityUrl: string, public http: HttpClient, private notificationService : NotificationsService) {
        super(baseEntityUrl, '/contacts/1', http);
    }

    savePhones(baseEntityId: number, contactId: number, phoneCollection: any): any {
        return this.http
            .put(`${this.baseEntityUrl}/${baseEntityId}/contacts/${contactId}/phones`, phoneCollection, { responseType: 'text' as 'json' })
            .pipe(catchError(this.handleError));
    }

    saveAddress(baseEntityId: number, contactId: number, address: IAddressContainer): any {
        if (address.AddressId > 0) {
            return this.http.put(`${this.baseEntityUrl}/${baseEntityId}/contacts/${contactId}/addresses`, address);
        } else {
            return this.http.post(`${this.baseEntityUrl}/${baseEntityId}/contacts/${contactId}/address`, address);
        }
    }

    deleteAddress(baseEntityId: number, contactId: number, address: IAddressContainer): any {
        return this.http.delete(`${this.baseEntityUrl}/${baseEntityId}/contacts/${contactId}/addresses/${address.AddressId}`);
    }

    // saveEntity(parentId: number, entity: IContact): Observable<number> {
    //     return super.saveEntity(parentId,entity);
    // }
}
