import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IContact } from '../../interfaces/contact';
import { ContactDynamicControls, IContactDynamicControlsParameters } from '../../form-controls/contact.form-controls';
import { INotificationType } from '../../interfaces/notification-type';
import { ICarrier } from '../../interfaces/carrier';
import { ICustomer } from '../../interfaces/customer';

export class ContactDynamicConfig<T extends IContact> extends DynamicConfig<T> implements IDynamicConfig<T> {
    FirstName: string;
    LastName: string;
    PhoneNo: string;
    Archived: boolean;
    TypeId: Number;
    Email: string;
    GrantAccessToPortal: boolean;

    constructor(
        private contact: T,
        private notificationTypes?: INotificationType[],
        private carriers?: ICarrier[],
        private customers?: ICustomer[],
        private configControls?: string[]
    ) {
        super();
        const additionalParams: IContactDynamicControlsParameters = {
            notificationTypes: notificationTypes,
            carriers: carriers,
            customers: customers,
        };
        const dynamicControls = new ContactDynamicControls(this.contact, additionalParams);
        if (!configControls) {
            this.configControls = ['FirstName', 'LastName', 'Title', 'PhoneNo', 'Email', 'NotificationTypeId', 'PrimaryContact', 'GrantAccessToPortal'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
