import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { ExceptionQueueListComponent } from './exception-queue-list/exception-queue-list.component';

const roleGuard: IRoleGuarded = {
    claimType: ClaimTypes.PickupPaymentDetails,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
};

const routes: Routes = [
    { path: 'exception-queue-list', component: ExceptionQueueListComponent, canActivate: [AuthGuard], data: roleGuard },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class ExceptionQueueRoutingModule {}
