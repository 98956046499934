import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ContactService } from '../../model/shared-entities/contacts/contact.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Injectable({providedIn: 'root'})
export class CarrierContactService extends ContactService {
    constructor(public http: HttpClient, notificationService: NotificationsService) {
        super('/carriers', http, notificationService);
    }
}
