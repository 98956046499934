import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { CheckInService, IToBeFulfilledParam } from '../services/check-ins.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICheckIn } from '../../model/interfaces/check-in';
import { Subscription, timer } from 'rxjs';
import { ICheckInDetail } from '../../model/interfaces/check-in-detail';
import { ClaimTypes } from '../../model/ClaimTypes';
import { CheckInStatuses } from '../../model/CheckInStatuses';
import { CheckInQueueItem } from '../../model/classes/check-in-queue-item';
import { CheckInDetailQueueItem } from '../../model/classes/check-in-detail-queue-item';
import { CheckInInfo } from '../../model/classes/check-in-info';
import { DatePipe } from '@angular/common';
import { OrderService } from '../../orders/order.service';
import { IOrder } from '../../model/interfaces/order';
import { OrderStatuses } from '../../model/OrderStatuses';
import { CheckInTypes } from '../../model/CheckInTypes';
import { WarehouseService } from '../../warehouses/services/warehouse.service';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { CheckInDetailService } from '../services/check-in-detail.service';
import { SortDirection } from '../../common/components/sort-arrows.component';
import { CheckInStatusService } from '../services/check-in-status.service';
import { UserService } from '../../users/user.service';
import { SyncOrAsync } from '../../model/interfaces/custom/sync-or-async';
import { AuthService } from '@mt-ng2/auth-module';
import { UserRoles } from '../../model/UserRoles';
import { IPickup } from '../../model/interfaces/pickup';
import { CustomerService } from '../../customers/customer.service';
import { OfficeService } from '../../offices/services/office.service';
import { PickupService } from '../../model/shared-entities/pickups/pickup.service';
import { SweetAlertResult } from 'sweetalert2';
import { IDelivery } from '../../model/interfaces/delivery';
import { DeliveryStatuses } from '../../model/DeliveryStatuses';

interface ISortColumn {
    Name: string;
    SortDirection: SortDirection;
}

@Component({
    selector: 'app-checkins',
    templateUrl: './checkins.component.html',
    styleUrls: ['./checkins.component.css'],
})
export class CheckInsComponent implements OnInit, OnDestroy {
    searchControl = new UntypedFormControl();
    checkInQueueItems: CheckInQueueItem[];
    selectedCheckIn: CheckInQueueItem;
    lastCheckInId: number;
    query = '';
    canEditCheckIn = false;
    isDAEOfficeUser = false;
    isAdminUser: boolean;
    subscription = new Subscription();
    checkInDoorSelectionDialogApi: IModalWrapperApi;
    gatePassDialogApi: IModalWrapperApi;
    checkInTypes = CheckInTypes;
    warehouseMetaItems: MtSearchFilterItem[] = [];
    customerMetaItems: MtSearchFilterItem[] = [];
    officeMetaItems: MtSearchFilterItem[] = [];
    driverMetaItems: MtSearchFilterItem[] = [];
    checkInStatusItems: MtSearchFilterItem[] = [];
    notFulFilledReasonItems: MtSearchFilterItem[] = [];
    selectedPickup: IPickup;
    selectedDelivery: IDelivery;
    pickupGatePassNumberDialogApi: IModalWrapperApi;
    deliveryGatePassNumberDialogApi: IModalWrapperApi;
    sortColumns: ISortColumn[] = [
        { Name: 'TruckerName', SortDirection: SortDirection.None },
        { Name: 'CheckInTime', SortDirection: SortDirection.None },
        { Name: 'Status', SortDirection: SortDirection.None },
    ];

    constructor(
        private checkInService: CheckInService,
        private checkInStatusService: CheckInStatusService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private router: Router,
        private datePipe: DatePipe,
        private orderService: OrderService,
        private warehouseService: WarehouseService,
        private modalService: ModalService,
        private checkInDetailService: CheckInDetailService,
        private userService: UserService,
        private authService: AuthService,
        private customerService: CustomerService,
        private officeService: OfficeService,
        private pickupService: PickupService,
    ) { }

    ngOnInit(): void {
        if (!this.authService.isAuthenticated()) {
            this.initSubscriptions();
            return;
        }

        const userId = this.authService.currentUser.getValue().Id;
        this.userService.getById(userId).subscribe(user => {
            this.isAdminUser = user.AuthUser.RoleId === UserRoles.Admin;
            const isCustomerOrCarrierUser = (user.AuthUser.RoleId === UserRoles.Customer || user.AuthUser.RoleId === UserRoles.Carrier);
            const urlIsHomePage = (this.router.url === '/' || this.router.url === '/home');
            
            if (isCustomerOrCarrierUser && urlIsHomePage) {
                void this.router.navigate(['/create-requests/manage-pickups']);
                return;
            } 
            
            if (isCustomerOrCarrierUser) {
                void this.router.navigate(['/pickups']);
                return;
            }

            this.initSubscriptions();
        })
    }

    toggleOrders(checkinQueueItem: CheckInQueueItem): void {
        checkinQueueItem.ShowOrders = !checkinQueueItem.ShowOrders;
    }

    initSubscriptions(): void {
        this.canEditCheckIn = this.claimsService.hasClaim(ClaimTypes.CheckIns, [ClaimValues.FullAccess]);
        const refreshTimer = timer(0, 15000);
        this.subscription.add(
            refreshTimer.subscribe(() => {
                this.getCheckInsToBeFulFilled();
                this.checkIsDAEOfficeUser();
            }),
        );
        
        this.getCheckInsToBeFulFilled();
        
        this.warehouseService.getUserWarehouses().subscribe((answer) => {
            this.warehouseMetaItems = CommonFunctions.mapMtSearchFilterItems(answer, 'WarehouseId');
        });

        this.customerService.getActiveCustomers().subscribe((answer) => {
            this.customerMetaItems = CommonFunctions.mapMtSearchFilterItems(answer, 'Name');
        });

        this.officeService.getActive().subscribe((answer) => {
            this.officeMetaItems = CommonFunctions.mapMtSearchFilterItems(answer, 'Title');
        });

        this.pickupService.getPickupDrivers().subscribe((answer) => {
            this.driverMetaItems = CommonFunctions.mapMultiMtSearchFilterItems(answer, 'FirstName', 'LastName', 'Phone');
        });

        this.checkInStatusService.getItems().subscribe((result) => {
            const filterItems = result
                .filter((item) => item.Id !== CheckInStatuses.Fulfilled && item.Id !== CheckInStatuses.NotFulFilled)
                .map((item) => {
                    return new MtSearchFilterItem(
                        {
                            Id: item.Id,
                            Name: item.Name,
                        },
                        false,
                    );
                });
            this.checkInStatusItems = filterItems;
        });

        this.orderService.getOrderNotFulFilledReasons().subscribe((answer) => {
            const statusMetaItems = answer.map((item) => {
                return new MtSearchFilterItem(
                    {
                        Id: item.Id,
                        Name: item.Name,
                    },
                    false,
                );
            });
            this.notFulFilledReasonItems = statusMetaItems;
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onEdit(event: CheckInQueueItem): void {
        void this.router.navigate(['/checkins/monitoring/info/', event.Id]);
    }

    getCheckInsToBeFulFilled(): void {
        const search: IToBeFulfilledParam = {
            Order: null,
            OrderDirection: null,
            Query: this.query && this.query.length > 0 ? this.query : '',
            StatusIds: this.getSelectedFilters(this.checkInStatusItems),
            WarehouseIds: this.getSelectedFilters(this.warehouseMetaItems),
            CustomerIds: this.getSelectedFilters(this.customerMetaItems),
            OfficeIds: this.getSelectedFilters(this.officeMetaItems),
            DriverIds: this.getSelectedFilters(this.driverMetaItems),
        };

        const sortColumn = this.getCurrentSortColumn();
        if (sortColumn) {
            search.Order = sortColumn.Name;
            search.OrderDirection = SortDirection[sortColumn.SortDirection];
        }

        this.checkInService.getToBeFulFilled(search).subscribe((checkInQueueItems) => {
            if (this.checkInQueueItems?.length > 0) { 
                this.reSyncCollapsibleOrders(checkInQueueItems)
            }
            this.checkInQueueItems = checkInQueueItems;
        });
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    checkIsDAEOfficeUser(): void {
        this.checkInService.checkIsDAEOfficeUser().subscribe((answer) => {
            this.isDAEOfficeUser = answer;
        });
    }

    search(query: string): void {
        this.query = query;
        this.getCheckInsToBeFulFilled();
    }

    ondeleteCheckIn(event: ICheckIn): void {
        this.checkInService.deleteCheckIn(event.Id).subscribe(
            () => {
                this.getCheckInsToBeFulFilled();
                this.notificationsService.success(' Deleted');
            },
            () => {
                this.getCheckInsToBeFulFilled();
                this.notificationsService.error('Delete Failed');
            },
        );
    }

    onCheckInFulFilled(event: ICheckIn): void {
        this.checkInService.markFulFilled(event.Id).subscribe(
            () => {
                this.getCheckInsToBeFulFilled();
                this.notificationsService.success('Order FulFilled');
                this.lastCheckInId = event.Id;
                const undoTimer = timer(15000);
                undoTimer.subscribe(() => {
                    this.lastCheckInId = 0;
                });
            },
            () => {
                this.getCheckInsToBeFulFilled();
                this.notificationsService.error('Marking FulFilled Failed');
            },
        );
    }

    onCheckInNotFulFilled(event: ICheckIn): void {
        const options = {};
        this.notFulFilledReasonItems.map((item) => {
            return (options[item.Item.Id] = item.Item.Name);
        });
        this.modalService
            .showModal({
                input: 'select',
                inputOptions: options,
                inputValidator: function (value: string): SyncOrAsync<string> {
                    return new Promise<string>(function (resolve: (value?: string) => void): void {
                        if (value !== '') {
                            resolve();
                        } else {
                            resolve('You need to select a reason');
                        }
                    });
                },
                inputValue: '',
                showCancelButton: true,
                title: 'Select Reason',
            })
            .subscribe((result) => {
                if (result.value) {
                    this.checkInService.markNotFulFilled(event.Id, result.value as number).subscribe(
                        () => {
                            this.notificationsService.success('Marked check-in as not-fulfilled');
                            this.getCheckInsToBeFulFilled();
                        },
                        () => {
                            this.notificationsService.error('Marking Not FulFilled Failed');
                        },
                    );
                }
            });
    }

    onOrderFulFilled(checkinQueueItem: CheckInQueueItem, checkInDetail: CheckInDetailQueueItem): void {
        this.checkInService.markOrderFulFilled(checkinQueueItem.CheckInInfo.Id, checkInDetail.Order.Id).subscribe({
            next: () => {
                this.getCheckInsToBeFulFilled();
                this.notificationsService.success('Order FulFilled');
            }
        });
    }

    onOrderNotFulFilled(checkinQueueItem: CheckInQueueItem, checkInDetail: CheckInDetailQueueItem): void {
        const options = {};
        this.notFulFilledReasonItems.map((item) => {
            return (options[item.Item.Id] = item.Item.Name);
        });
        this.modalService
            .showModal({
                input: 'select',
                inputOptions: options,
                inputValidator: function (value: string): SyncOrAsync<string> {
                    return new Promise<string>(function (resolve: (value?: string) => void): void {
                        if (value !== '') {
                            resolve();
                        } else {
                            resolve('You need to select a reason');
                        }
                    });
                },
                inputValue: '',
                showCancelButton: true,
                title: 'Select Reason',
            })
            .subscribe((result) => {
                if (result.value) {
                    this.checkInService.markOrderNotFulFilled(checkinQueueItem.CheckInInfo.Id, checkInDetail.Order.Id, result.value as number).subscribe({
                        next: () => {
                            this.notificationsService.success('Marked check-in as not-fulfilled');
                            this.getCheckInsToBeFulFilled();
                        }
                    });
                }
            });
    }

    onNotifyDriver(queueItem: CheckInQueueItem): void {
        const pickupGatePassNumber = queueItem?.CheckInInfo?.Pickup?.GatePassNumber;
        const deliveryGatePassNumber = queueItem?.CheckInInfo?.Delivery?.GatePassNumber;

        const noPickupGatePassNumber = !pickupGatePassNumber || pickupGatePassNumber.trim() === '';
        const noDeliveryGatePassNumber = !deliveryGatePassNumber || deliveryGatePassNumber.trim() === '';
        
        if (noPickupGatePassNumber && noDeliveryGatePassNumber) { 
            this.notificationsService.error('Please add a valid Gate Pass Number before notifying the driver.');
            return;
        }

        this.selectedCheckIn = queueItem;
        if (this.selectedCheckIn.SelectDoorsForOrder) {
            this.checkInDoorSelectionDialogApi.show();
        } else {
            if ((queueItem.CheckInInfo.CheckInTypeId = CheckInTypes.Mobile)) {
                this.gatePassDialogApi.show();
            } else {
                this.checkInService.notifyDriver(this.selectedCheckIn.CheckInInfo.Id, null).subscribe(
                    (answer) => {
                        this.getCheckInsToBeFulFilled();
                        if (answer) {
                            this.notificationsService.success('Driver Notified');
                        } else {
                            this.notificationsService.error('Failed to notify driver');
                        }
                    },
                    () => {
                        this.getCheckInsToBeFulFilled();
                        this.notificationsService.error('Notifying Driver Failed');
                    },
                );
            }
        }
    }

    closeOpenDoorSelectionDialog(): void {
        this.selectedCheckIn = null;
        this.checkInDoorSelectionDialogApi.close();
        this.getCheckInsToBeFulFilled();
    }

    closeGatePassDialog(): void {
        this.selectedCheckIn = null;
        this.gatePassDialogApi.close();
        this.getCheckInsToBeFulFilled();
    }

    undoMarkingFulFilled(): void {
        this.checkInService.undoMarkingFulFilled(this.lastCheckInId).subscribe(
            () => {
                this.lastCheckInId = 0;
                this.notificationsService.success('Successfully Revoked Changes');
                this.getCheckInsToBeFulFilled();
            },
            () => {
                this.getCheckInsToBeFulFilled();
                this.notificationsService.error('Failed');
            },
        );
    }

    onResendNotification(event: ICheckInDetail): void {
        this.checkInService.resendNotification(event.Id).subscribe(
            (answer) => {
                if (answer) {
                    this.notificationsService.success('Successfully Resent Notifications');
                } else {
                    this.notificationsService.error('Failed to resend notifications');
                }
            },
            () => {
                this.notificationsService.error('Failed to resend notifications');
            },
        );
    }

    onMarkAsPickedUp(event: CheckInDetailQueueItem): void {
        this.orderService.markOrdersAsPickedUp([event.Order]).subscribe(
            () => {
                this.notificationsService.success('Successfully marked order as picked up');
                this.getCheckInsToBeFulFilled();
            },
            () => this.notificationsService.error('Failed to mark order as picked up'),
        );
    }

    orderIsPickedUp(order: IOrder): boolean {
        if (order) {
            return order.StatusId === OrderStatuses.PickedUp;
        }
        return false;
    }

    noCheckInQueueItems(): boolean {
        return !this.checkInQueueItems || this.checkInQueueItems.length === 0;
    }

    getBackgroundColor(checkIn: CheckInInfo): string | null {
        return checkIn.StatusId === CheckInStatuses.DriverNotified ? 'green' : null;
    }

    getNotificationInfo(checkInInfo: CheckInInfo): string {
        if (!checkInInfo.DriverFirstNotifiedTime) {
            return '';
        }
        return `${this.datePipe.transform(checkInInfo.DriverFirstNotifiedTime, 'shortTime')} (${checkInInfo.NotificationCount})`;
    }

    updateWarehouse(checkInDetail: CheckInDetailQueueItem): void {
        const options = {};
        this.warehouseMetaItems.forEach((item) => {
            options[`${item.Item.Name }`] = item.Item.Name ;
        });
        this.modalService
            .showModal({
                input: 'select',
                inputOptions: options,
                inputValidator: function (value: string): SyncOrAsync<string> {
                    return new Promise<string>(function (resolve: (value?: string) => void): void {
                        if (value !== '') {
                            resolve();
                        } else {
                            resolve('You need to select a Warehouse');
                        }
                    });
                },
                inputValue: checkInDetail.Warehouse?.WarehouseId ? checkInDetail.Warehouse.WarehouseId : '',
                showCancelButton: true,
                title: 'Update Warehouse',
            })
            .subscribe((result) => {
                if (result.value) {
                    const filterItem = this.warehouseMetaItems.find((i) => i.Item.Name === result.value);
                    const warehouseId = parseInt(`${filterItem.Item.Id}`, 10);
                    this.checkInDetailService.updateWarehouse(checkInDetail.Id, warehouseId).subscribe(
                        () => {
                            this.modalService.showModal({
                                html: 'Successfully Updated Warehouse',
                            	icon: 'success',
                            });
                            this.getCheckInsToBeFulFilled();
                        },
                        () => {
                            this.notificationsService.error('Update failed');
                        },
                    );
                }
            });
    }

    sortColumnClicked(sortColumn: ISortColumn): void {
        const newSortDirection = sortColumn.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

        // reset all the columns sorting
        this.sortColumns.forEach((col) => col.SortDirection = SortDirection.None);

        // apply the new sort direction to this column
        sortColumn.SortDirection = newSortDirection;

        // pull the sorted data
        this.getCheckInsToBeFulFilled();
    }

    getCurrentSortColumn(): ISortColumn {
        return this.sortColumns.find((col) => col.SortDirection !== SortDirection.None);
    }

    toggleTwicCard(checkinQueueItem: CheckInQueueItem): void {
        this.userService.toggleTwicCard(checkinQueueItem.CheckInInfo.CheckInUserId).subscribe(
            () => {
                this.getCheckInsToBeFulFilled();
            },
            () => {
                this.notificationsService.error('Toggle TWIC card failed.');
            },
        );
    }

    getOrderStatusName(statusId: number): string {
        return Object.keys(OrderStatuses).find(key => OrderStatuses[key] === statusId);
    }

    getDeliveryStatusName(statusId: number): string {
        return Object.keys(DeliveryStatuses).find(key => DeliveryStatuses[key] === statusId);
    }

    updatePickupGatePassNumber(pickup: IPickup): void {
        this.selectedPickup = pickup;
        this.pickupGatePassNumberDialogApi.show();
    }

    updateDeliveryGatePassNumber(delivery: IDelivery): void {
        this.selectedDelivery = delivery;
        this.deliveryGatePassNumberDialogApi.show();
    }

    closePickupGatePassNumber(): void {
        this.selectedPickup = null;
        this.pickupGatePassNumberDialogApi.close();
        this.getCheckInsToBeFulFilled();
    }

    closeDeliveryGatePassNumber(): void {
        this.selectedDelivery = null;
        this.deliveryGatePassNumberDialogApi.close();
        this.getCheckInsToBeFulFilled();
    }

    displayGatePassNumber(gatePassNumber: string): string {
        return (gatePassNumber && gatePassNumber.trim() !== '') ? gatePassNumber : 'N/A';
    }

    expandAll(): void {
        this.checkInQueueItems.forEach((item) => {
            item.ShowOrders = true;
        });
    }
    
    collapseAll(): void {
        this.checkInQueueItems.forEach((item) => {
            item.ShowOrders = false;
        });
    }

    showExpandAllButton(): boolean {
        return this.checkInQueueItems?.some((item) => !item.ShowOrders);
    }

    showCollapseAllButton(): boolean {
        return this.checkInQueueItems?.every((item) => item.ShowOrders);
    }

    reSyncCollapsibleOrders(checkInQueueItems: CheckInQueueItem[]): void { 
        checkInQueueItems.forEach(item => {
            item.ShowOrders = this.checkInQueueItems.find(x => x.CheckInInfo.Id === item.CheckInInfo.Id)?.ShowOrders ?? true;
        });
    }

    onUpdateComment(queueItem: CheckInQueueItem): void {
        this.modalService.showModal({
            input: "textarea",
            inputLabel: "Comment",
            inputPlaceholder: "Type your comment here...",
            inputAttributes: {
                "aria-label": "Type your comment here"
            },
            showCancelButton: true,
            inputValue: queueItem.CheckInInfo.Comment,
        }).subscribe((result: SweetAlertResult) => {
            if (!result.isConfirmed)
                return;

            const comment = result.value as string;
            this.checkInService.updateCheckInComment(queueItem.CheckInInfo.Id, comment).subscribe({
                next: () => {
                    queueItem.CheckInInfo.Comment = comment;
                    this.notificationsService.success("Comment updated successfully");
                }
            });
        });
    }
}
