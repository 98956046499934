import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { IUser } from '../../model/interfaces/user';
import { CarrierService } from '../../carriers/services/carrier.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CommonService } from '../../common/services/common.service';
import { ICarrierForRegistration } from '../../model/interfaces/custom/carrier-for-registration';

@Component({
    selector: 'app-driver-check-in-edit',
    templateUrl: './driver-check-in-edit.component.html',
    styleUrls: ['./driver-check-in-edit.component.css'],
})
export class DriverCheckInEditComponent implements OnInit {
    loading = true;
    editDriverForm: FormGroup;
    driver: IUser;
    carriers: ICarrierForRegistration[];
    doubleClickIsDisabled = false;

    constructor(
        private formBuilder: FormBuilder, 
        private checkInService: PublicCheckInService,
        private notificationService: NotificationsService,
        private carrierService: CarrierService,
        private router: Router,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            if (!driver) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            } 
        
            this.carrierService.getActiveCarriersForRegistration().subscribe((carriers) => {
                this.carriers = carriers;
                this.editDriverForm = this.formBuilder.group({
                    firstName: [this.driver.FirstName, [Validators.required, this.commonService.firstAndLastNameValidator()]],
                    lastName: [this.driver.LastName, [Validators.required, this.commonService.firstAndLastNameValidator()]],
                    phone: [this.driver.Phone, [Validators.required, this.commonService.phoneNumberValidator()]],
                    carrier: [this.driver.CarrierId, Validators.required],
                    UsdotNumber: [this.driver.UsdotNumber, [this.commonService.checkForAlphaNumericCharacters()]],
                    licensePlate: [this.driver.TrailerLicensePlate, [Validators.required, this.commonService.checkForAlphaNumericCharacters()]],
                    driverNotificationOptOut: [this.driver.DriverNotificationOptOut, []],
                });
                this.loading = false;
            });
        });
    }

    onSubmit() {
        this.driver.FirstName = this.editDriverForm.get('firstName').value;
        this.driver.LastName = this.editDriverForm.get('lastName').value;
        this.driver.Phone = this.editDriverForm.get('phone').value;
        this.driver.CarrierId = this.editDriverForm.get('carrier').value;
        this.driver.UsdotNumber = this.editDriverForm.get('UsdotNumber').value;
        this.driver.TrailerLicensePlate = this.editDriverForm.get('licensePlate').value;
        this.driver.DriverNotificationOptOut = this.editDriverForm.get('driverNotificationOptOut').value;
        this.checkInService
            .editDriver(this.driver).subscribe({
                next: (driver) => {
                    this.notificationService.success('Driver updated.');
                    this.checkInService.checkedInDriver$.next(driver);
                    void this.router.navigate(['public/driver-check-in-info']);
                },
                error: (errorResponse: HttpErrorResponse) => {
                    this.notificationService.error(errorResponse.error['ModelState'] as string);
                },
            });
    }

    goToDriverCheckInPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate(['public/driver-check-in']);
    }

    goToDriverEditTwicCardPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate(['public/driver-check-in-edit-twic-card']);
    }
}
