import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { Observable } from 'rxjs';
import { ExceptionQueueService } from '../services/exception-queue.service';
import { IColumnSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ExceptionQueueEntityListConfig } from './exception-queue-list.entity-list-config';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ExceptionQueueStatuses } from '../exception-queue-status-enum'
import { IExceptionQueue } from '../../model/interfaces/exception-queue';
import { ModalService } from '@mt-ng2/modal-module';
import { SyncOrAsync } from '../../model/interfaces/custom/sync-or-async';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Router } from '@angular/router';

@Component({
    selector: 'app-exception-queue-list',
    templateUrl: './exception-queue-list.component.html',
})
export class ExceptionQueueListComponent implements OnInit {
    query = '';
    order = 'DateCreated';
    orderDirection = 'desc';
    currentPage = 1;
    itemsPerPage = 10;
    total: number;
    statuses = [
        ExceptionQueueStatuses.Pending,
        ExceptionQueueStatuses.Resolved,
        ExceptionQueueStatuses.Canceled,
    ];
    selectedStatusIds: number[] = [ExceptionQueueStatuses.Pending];
    statusMetaItems: MtSearchFilterItem[] = [];
    exceptions: IExceptionQueue[] = [];
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    entityListConfig: ExceptionQueueEntityListConfig;

    constructor(
        private exceptionQueueService: ExceptionQueueService,
        private modalService: ModalService,
        private notificationService: NotificationsService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        void this.router.navigate(['/home']);
    }

    getTableData(): void {
        this.entityListConfig = new ExceptionQueueEntityListConfig();
        this.buildSearchBarAndFilters();
        this.getExceptions();
    }

    getExceptionsCall(): Observable<HttpResponse<IExceptionQueue[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
            query: search && search.length > 0 ? search : '',
        };

        const searchparams = new SearchParams(searchEntity);
        return this.exceptionQueueService.search(searchparams);
    }

    getExceptions(): void {
        this.getExceptionsCall().subscribe((answer) => {
            this.exceptions = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'StatusIds',
                valueArray: this.selectedStatusIds,
            }),
        );

        return _extraSearchParams;
    }

    buildSearchBarAndFilters(): void {
        
        this.dynamicSearchFiltersConfig = [{
            Searchbar: {
                label: 'Search',
                type: DynamicSearchFilterTypes.Searchbar,
            },
            Statuses: {
                label: 'Status',
                type: DynamicSearchFilterTypes.Select,
                options: { selectOptions: this.statusMetaItems },
            },
        }];
    }
    
    pageChanged(): void {
        this.getExceptions();
    }

    search(evt: DynamicSearchValues): void {
        this.currentPage = 1;
        this.query = evt.Searchbar as string;
        if (evt.Statuses) {
            this.selectedStatusIds = (evt.Statuses as IMetaItem[]).map(x => x.Id);
        }
        this.getExceptions();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getExceptions();
    }

    columnSelected(event: IColumnSelectedEvent): void {
        switch (event.column.name) {
            case 'Status':
                this.updateStatus(event);
                break;
            default:
                break;
        }
    }

    private updateStatus(event: IColumnSelectedEvent): void {
        const options = {};
        this.statusMetaItems.map((item) => {
            return (options[item.Item.Id] = item.Item.Name);
        });
        this.modalService
            .showModal({
                input: 'select',
                inputOptions: options,
                inputValidator: function (value: string): SyncOrAsync<string> {
                    return new Promise<string>(function (resolve: (value?: string) => void): void {
                        if (value !== '') {
                            resolve();
                        } else {
                            resolve('You need to select a status');
                        }
                    });
                },
                inputValue: event.entity['StatusId'],
                showCancelButton: true,
                title: 'Update Status',
            })
            .subscribe((result) => {
                if (result.value) {
                    event.entity['StatusId'] = result.value;
                    this.exceptionQueueService.update(event['entity'] as IExceptionQueue).subscribe({
                        next: () => {
                            this.modalService
                                .showModal({ html: 'Successfully Updated the status', icon: 'success' })
                                .subscribe();
                            this.getExceptions();
                        },
                        error: () => {
                            this.notificationService.error('Update failed');
                        }
                    });
                }
            });
    }
}
