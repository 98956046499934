import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { AuthService, ClaimsService, ClaimValues, ILoggedIn } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IColumnSortedEvent, SortDirection, IColumnSelectedEvent, IEntity, ISelectionChangedEvent } from '@mt-ng2/entity-list-module';
import { ModalService } from '@mt-ng2/modal-module';
import { OrderService } from '../order.service';
import { CustomerService } from '../../customers/customer.service';
import { IOrder } from '../../model/interfaces/order';
import { ClaimTypes } from '../../model/ClaimTypes';
import { OrdersEntityListConfig } from './orders.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Observable, Subscription } from 'rxjs';
import { WarehouseService } from '../../warehouses/services/warehouse.service';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { SyncOrAsync } from '../../model/interfaces/custom/sync-or-async';
import { UserRoles } from '../../model/UserRoles';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control';

@Component({
    selector: 'app-orders',
    styleUrls: ['./scheduling-queue.component.css'],
    templateUrl: './scheduling-queue.component.html',
})
export class SchedulingQueueComponent implements OnInit, OnDestroy {
    orders: IOrder[];
    currentPage = 1;
    query = '';
    itemsPerPage = 999;
    total: number;
    customerMetaItems: MtSearchFilterItem[] = [];
    locationMetaItems: MtSearchFilterItem[] = [];
    warehouseMetaItems: MtSearchFilterItem[] = [];
    userIsCustomer = false;
    userIsCarrier = false;
    entityListConfig: OrdersEntityListConfig;
    order: string;
    orderDirection: string;
    lastSyncedDate: Date;
    lastSyncedFileName: string;
    canAddOrder = false;
    subscriptions: Subscription = new Subscription();
    showArchived = false;
    showMissingWarehouseOrders = false;
    CommonFunctions = CommonFunctions;
    currentUser: ILoggedIn;
    customerPickupStartDate: Date = null;
    customerPickupEndDate: Date = null;
    selectedEntities: IEntity[];

    constructor(
        private orderService: OrderService,
        private orderCustomerService: CustomerService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
        private warehouseService: WarehouseService,
        private modalService: ModalService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.currentUser = this.authService.currentUser.getValue();
        const authId = this.currentUser.AuthId;
        this.orderService.getAuthUserRole(authId).subscribe(authUserRole => {
            if (authUserRole === UserRoles.Carrier) {
                this.userIsCarrier = true;
                this.initEntityListConfig();
                return;
            }

            if (authUserRole === UserRoles.Customer) {
                this.userIsCustomer = true;
                this.initEntityListConfig();
                this.getOrdersForCustomer(this.currentUser.Id, true);
                return;
            }

            this.initEntityListConfig();
                this.subscriptions.add(
                    this.orderService.getLastSyncedDate().subscribe((date) => {
                        this.lastSyncedDate = date;
                    }),
                );
                this.subscriptions.add(
                    this.orderService.getLastSyncedFileName().subscribe((fileName) => {
                        this.lastSyncedFileName = fileName;
                    }),
                );
                this.canAddOrder = this.claimsService.hasClaim(ClaimTypes.Orders, [ClaimValues.FullAccess]);
                this.orderCustomerService.getSimpleCustomers().subscribe((answer) => {
                    this.customerMetaItems = CommonFunctions.mapMtSearchFilterItems(answer);
                });
                this.warehouseService.getActive().subscribe((answer) => {
                    this.warehouseMetaItems = CommonFunctions.mapMtSearchFilterItems(answer, 'WarehouseId');
                });
                this.getOrders();
        });
    }

    userIsCarrierOrCustomer(): boolean {
        return (this.userIsCarrier || this.userIsCustomer);
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedCustomersIds: number[] = this.getSelectedFilters(this.customerMetaItems);
        const selectedOfficeIds: number[] = this.getSelectedFilters(this.locationMetaItems);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: selectedCustomersIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'OfficeIds',
                valueArray: selectedOfficeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShowArchived',
                value: String(this.showArchived),
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShowMissingWarehouseOrders',
                value: String(this.showMissingWarehouseOrders),
            }),
        );

        if (this.customerPickupStartDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                  name: 'CustomerPickupStartDate',
                  value: this.customerPickupStartDate.toDateString(),
                }),
            );
        }

        if (this.customerPickupEndDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                  name: 'CustomerPickupEndDate',
                  value: this.customerPickupEndDate.toDateString(),
                }),
            );
        }

        return _extraSearchParams;
    }

    getSearchParams(): SearchParams {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        return new SearchParams(searchEntity);
    }

    getOrders(): void {
        const searchParams = this.getSearchParams();
        this.orderService.getUnscheduledOrders(searchParams).subscribe((answer) => {
            this.orders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getOrdersForCustomer(userId: number, setLocationMetaItems: boolean): void {
        const searchParams = this.getSearchParams();
        this.orderService.getUnscheduledOrdersForCustomer(searchParams, userId).subscribe((answer) => {
            this.orders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            if (setLocationMetaItems) {
                this.setLocationMetaItems(this.orders);
            }
        });
    }

    getOrdersForCarrier(userId: number, setLocationMetaItems: boolean): void {
        const searchParams = this.getSearchParams();
        this.orderService.getUnscheduledOrdersForCarrier(searchParams, userId).subscribe((answer) => {
            this.orders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            if (setLocationMetaItems) {
                this.setLocationMetaItems(this.orders);
            }
        });
    }

    setLocationMetaItems(orders: IOrder[]): void {
        const offices: IEntity[] = orders.map(o => ({
            Id: o.Warehouse?.OfficeId,
            Name: o.Warehouse?.Office?.Title,
          }));
        const uniqueOffices = offices.filter((v, i, a) => {
            return a.findIndex(t => (t.Id === v.Id)) === i;
        });
        this.locationMetaItems = CommonFunctions.mapMtSearchFilterItems(uniqueOffices);
    }

    search(query: string): void {
        this.query = query;
        if (this.userIsCustomer) {
            this.getOrdersForCustomer(this.currentUser.Id, false);
            return;
        }
        if (this.userIsCarrier) {
            this.getOrdersForCarrier(this.currentUser.Id, false);
            return;
        }
        this.getOrders();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getOrders();
    }

    columnSelected(event: IColumnSelectedEvent): void {
        switch (event.column.name) {
            case 'Customer':
                this.updateCustomer(event);
                break;
            case 'Warehouse':
                this.updateWarehouse(event);
                break;
            default:
                break;
        }
    }

    private updateCustomer(event: IColumnSelectedEvent): void {
        const options = {};
        this.customerMetaItems.map((item) => {
            return (options[item.Item.Id] = item.Item.Name);
        });
        this.modalService
            .showModal({
                input: 'select',
                inputOptions: options,
                inputValidator: function (value: string): SyncOrAsync<string> {
                    return new Promise<string>(function (resolve: (value?: string) => void): void {
                        if (value !== '') {
                            resolve();
                        } else {
                            resolve('You need to select a customer');
                        }
                    });
                },
                inputValue: event.entity["CustomerId"],
                showCancelButton: true,
                title: 'Update Customer',
            })
            .subscribe((result) => {
                if (result.value) {
                    event.entity["CustomerId"] = result.value;
                    this.orderService.update(event["entity"] as IOrder).subscribe(
                        () => {
                            this.modalService
                                .showModal({
                                    html: 'Successfully Updated the customer',
                                	icon: 'success',
})
                                .subscribe();
                            this.getOrders();
                        },
                        () => {
                            this.notificationsService.error('Update failed');
                        },
                    );
                }
            });
    }

    private updateWarehouse(event: IColumnSelectedEvent): void {
        const options = {};
        this.warehouseMetaItems.map((item) => {
            return (options[item.Item.Id] = item.Item.Name);
        });
        this.modalService
            .showModal({
                input: 'select',
                inputOptions: options,
                inputValidator: function (value: string): SyncOrAsync<string> {
                    return new Promise<string>(function (resolve: (value?: string) => void): void {
                        if (value !== '') {
                            resolve();
                        } else {
                            resolve('You need to select a Warehouse');
                        }
                    });
                },
                inputValue: event.entity["WarehouseId"] ? event.entity["WarehouseId"] : '',
                showCancelButton: true,
                title: 'Update Warehouse',
            })
            .subscribe((result) => {
                if (result.value) {
                    event.entity["WarehouseId"] = result.value;
                    this.orderService.update(event["entity"] as IOrder).subscribe(
                        () => {
                            this.modalService.showModal({
                                html: 'Successfully Updated Order Warehouse',
                            	icon: 'success',
});
                            this.getOrders();
                        },
                        () => {
                            this.notificationsService.error('Update failed');
                        },
                    );
                }
            });
    }

    private initEntityListConfig(): void {
        this.entityListConfig = new OrdersEntityListConfig(this.userIsCarrierOrCustomer());
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    selectionChanged(event: ISelectionChangedEvent): void {
        this.selectedEntities = event.selectedEntities;
    }

    getLastSyncedDate(): Observable<Date> {
        return this.orderService.getLastSyncedDate();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    redirectToCarrierRequestForm(): void {
        void this.router.navigate(['/orders/carrier-request-form']);
    }

    redirectToManageSchedulingPage(): void {
        this.orderService.selectedOrders$.next(this.selectedEntities);
        if (this.userIsCustomer) {
            void this.router.navigate([`/orders/manage-scheduling/${this.selectedEntities[0][`Customer`][`Id`] as string}/*`]);
        } else {
            void this.router.navigate(['/orders/manage-scheduling/0/*']);
        }
    }

    dateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.customerPickupStartDate = range.startDate;
        this.customerPickupEndDate = range.endDate;
        this.getOrdersForCustomer(this.currentUser.Id, false);
    }
}
