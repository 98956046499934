import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IColumnSortedEvent, SortDirection, IItemDeletedEvent, IItemSelectedEvent } from '@mt-ng2/entity-list-module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { IDocument } from '../../model/interfaces/base';
import { DeliveryDocumentsService } from '../services/delivery-documents.service';
import { DeliveryDocumentsEntityListConfig } from './delivery-documents.entity-list-config';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-delivery-documents',
    templateUrl: './delivery-documents.component.html',
})
export class DeliveryDocumentsComponent implements OnInit {
    documents: IDocument[] = [];
    deliveryId: number;
    total: number;
    currentPage = 1;
    itemsPerPage = 10;
    query = '';
    entityListConfig: DeliveryDocumentsEntityListConfig;
    order: string;
    orderDirection: string;

    constructor(
        private deliveryDocumentsService: DeliveryDocumentsService,
        private notificationsService: NotificationsService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.deliveryId = +this.route.snapshot.paramMap.get('deliveryId');
        this.initEntityListConfig();
        this.getDeliveryDocuments();
    }

    private initEntityListConfig(): void {
        this.entityListConfig = new DeliveryDocumentsEntityListConfig();
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    getDeliveryDocuments(): void {
        const searchParams = this.getSearchParams();
        this.deliveryDocumentsService.getDocuments(this.deliveryId, searchParams).subscribe((answer) => {
            this.documents = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getSearchParams(): SearchParams {
        const search = this.query;
        const searchEntity: IEntitySearchParams = {
            extraParams: null,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        return new SearchParams(searchEntity);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getDeliveryDocuments();
    }

    search(query: string): void {
        this.query = query;
        this.getDeliveryDocuments();
    }

    onItemSelected(event: IItemSelectedEvent): void {
        this.deliveryDocumentsService.getDocument(this.deliveryId, event.entity.Id as number).subscribe({
            next: (document) => {
                saveAs(document, event.entity.Name);
                this.notificationsService.success('Document retrieved successfully.');
            },
            error: () => {
                this.notificationsService.error('Error retrieving document.');
            }
        });
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        this.deliveryDocumentsService.deleteDocument(this.deliveryId, event.entity.Id as number).subscribe({
            next: () => {
                this.notificationsService.success('Document deleted successfully.');
                this.getDeliveryDocuments();
            },
            error: () => {
                this.notificationsService.error('Error deleting document.');
            }
        });
    }
}
