import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { ICarrier } from '../model/interfaces/carrier';
// import
import { ICustomer } from '../model/interfaces/customer';
import { IContact } from '../model/interfaces/contact';
import { ContactDynamicControlsPartial } from '../model/partials/contact-partial.form-controls';
import { INotificationType } from '../model/interfaces/notification-type';

export class ContactsDynamicConfig<T extends IContact> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private contact: T, private carriers: ICarrier[], private customers: ICustomer[], private notificationTypes: INotificationType[], private configControls?: string[]) {
        super();
        const dynamicControls = new ContactDynamicControlsPartial(this.contact, {carriers: carriers, customers: customers, notificationTypes: notificationTypes});
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'FirstName',
                'LastName',
                'Email',
                'PhoneNo',
                'NotificationTypeId'
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),

        };
    }
}
