import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CommonService } from '../../common/services/common.service';
import { ConfirmDeliveriesParams } from '../../model/interfaces/custom/confirm-deliveries-params';
import { IDelivery } from '../../model/interfaces/delivery';
import { DeliveryService } from '../services/delivery.service';

@Component({
    selector: 'confirm-deliveries-dialog',
    templateUrl: './confirm-deliveries-dialog.component.html',
    styleUrls: ['./confirm-deliveries-dialog.component.css'],
})
export class ConfirmDeliveriesDialogComponent implements OnInit {
    @Input('deliveries') deliveries: IDelivery[];
    @Output('onClose') onClose: EventEmitter<void> = new EventEmitter<void>();

    deliveriesPerSlot: number[] = [];
    timeSlots: string[] = [];
    confirmedDeliveryDate: string;
    deliveriesToConfirm: ConfirmDeliveriesParams[] = [];
    confirmedDeliveries: IDelivery[] = [];
    loading = false;
    minDate = new Date();

    constructor(
        private notificationsService: NotificationsService,
        private deliveryService: DeliveryService,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.generateTimeSlots();
    }

    close(): void {
        this.confirmedDeliveryDate = null;
        this.deliveriesPerSlot = [];
        this.onClose.emit();
    }

    private generateTimeSlots(): void {
        const startHour = 6; // 6:00 AM
        const endHour = 20; // 7:00 PM

        for (let hour = startHour; hour < endHour; hour++) {
            this.timeSlots.push(this.formatHour(hour));
        }
    }

    onConfirmedDeliveryDateChange(): void {
        this.loading = true;
        this.deliveryService.getConfirmedDeliveries(this.confirmedDeliveryDate).subscribe({
            next: (deliveries: IDelivery[]) => {
                this.confirmedDeliveries = deliveries;
                this.loading = false;
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.commonService.handleErrorResponse(errorResponse);
                this.loading = false;
            }
        });
    }

    formatHour(hour: number): string {
        const period = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12; // Convert to 12-hour format
        return `${hour12}:00 ${period}`;
    }

    onDeliveriesChange(index: number, value: number) {
        if (Number.isNaN(value)) {
            value = 0;
        }
        this.deliveriesPerSlot[index] = value;
    }

    getNumberOfRemainingDeliveries(): number {
        return this.deliveries.length - this.deliveriesPerSlot.reduce((a, b) => a + b, 0);
    }

    confirmDeliveries(): void {
        const copyOfDeliveries = [...this.deliveries];
        this.deliveriesPerSlot.forEach((count, i) => {
            for (let j = 0; j < count; j++) {
                this.deliveriesToConfirm.push({
                    DeliveryId: copyOfDeliveries.pop().Id,
                    ConfirmedDeliveryDate: `${this.confirmedDeliveryDate} ${this.timeSlots[i]}`
                });
            }
        });

        this.loading = true;
        this.deliveryService.confirmDeliveries(this.deliveriesToConfirm).subscribe({
            next: () => {
                this.notificationsService.success('Deliveries Confirmed');
                this.close();
                this.loading = false;
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.commonService.handleErrorResponse(errorResponse);
                this.loading = false;
            }
        });
    }

    getNumberOfConfirmedDeliveriesForSlot(slot: string): number {
        if (!this.confirmedDeliveries || !this.confirmedDeliveries.length || !this.confirmedDeliveryDate) {
            return 0;
        }

        const matchingDeliveries = this.confirmedDeliveries.filter(d =>
            this.isSameDeliverySlot(d.ConfirmedDeliveryDate.toString(), slot)
        );

        return matchingDeliveries.length;
    }

    private isSameDeliverySlot(deliveryDate: string, slot: string): boolean {
        const deliveryDateTime = new Date(deliveryDate);
        const deliveryDateOnly = deliveryDateTime.toISOString().split('T')[0];
        const deliveryTime = deliveryDateTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

        const targetDate = new Date(`${this.confirmedDeliveryDate}, ${slot}`);
        const targetDateOnly = targetDate.toISOString().split('T')[0];
        const targetTime = targetDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

        return deliveryDateOnly === targetDateOnly && this.normalizeTime(deliveryTime) === this.normalizeTime(targetTime);
    };

    private normalizeTime(time: string): string {
        const [hours, minutesPeriod] = time.split(':');
        const [minutes, period] = minutesPeriod.split(' ');

        let normalizedHours = parseInt(hours, 10);
        if (normalizedHours === 0) {
            normalizedHours = 12;
        } else if (normalizedHours > 12) {
            normalizedHours -= 12;
        }

        return `${normalizedHours}:${minutes} ${period}`;
    };
}
