import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';

@Component({
    selector: 'app-driver-check-in',
    templateUrl: './driver-check-in.component.html',
    styleUrls: ['./driver-check-in.component.css'],
})
export class DriverCheckInComponent implements OnInit {
    logoImageSrc = '../../../../assets/logo-full.png';
    driver: IUser;
    constructor(private router: Router, private checkInService: PublicCheckInService,) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            if (!driver) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            } 
        });
    }
    
    imPickingUp(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate([`public/driver-check-in-add-pickups`]);
    }

    goToDriverEditPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate([`public/driver-check-in-edit`]);
    }

    isDelivering(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate([`public/driver-delivering`]);
    }
}
