import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { IUser } from '../../model/interfaces/user';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../customers/customer.service';
import { ICustomerForRegistration } from '../../model/interfaces/custom/customer-for-registration';
import { IPickup } from '../../model/interfaces/pickup';
import { IWalkInPickupParams } from '../../model/interfaces/custom/walk-in-pickup-params';
import { ISelectionChangedEvent } from '@mt-ng2/type-ahead-control';

@Component({
    selector: 'app-driver-check-in-create-walk-in',
    templateUrl: './driver-check-in-create-walk-in.component.html',
    styleUrls: ['./driver-check-in-create-walk-in.component.css'],
})
export class DriverCheckInCreateWalkInComponent implements OnInit {
    loading = true;
    driver: IUser;
    customers: ICustomerForRegistration[] = [];
    pickupParams: IWalkInPickupParams[] = [];
    selectedCustomerId: number;
    maxToShow = 1000;

    constructor(
        private checkInService: PublicCheckInService,
        private notificationService: NotificationsService,
        private router: Router,
        private customerService: CustomerService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
        });

        setTimeout(() => {
            if (!this.driver) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }

            this.route.queryParams.subscribe(params => {
                const orderNumber = params['orderNumber'] as string;
                if (!orderNumber) {
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }

                this.pickupParams.push({ orderNumber, validOrderNumber: false, customerId: 0 });
            });

            this.customerService.getActiveCustomersForRegistration().subscribe({
                next: (customers) => {
                    this.customers = customers;
                    this.loading = false;
                }
            });
        }, 500);
    }

    onCustomerSelected(event: ISelectionChangedEvent, pickupParam: IWalkInPickupParams): void {
        const selectedCustomer = event.selection ? this.customers.find((o) => o.Id === event.selection.Id) : null;
        if (!selectedCustomer)
            return;

        pickupParam.customerId = selectedCustomer.Id;
    }

    validateOrderNumber(pickupParam: IWalkInPickupParams) {
        if (pickupParam.customerId <= 0) {
            this.notificationService.error('Please select a customer for this order number.');
            return;
        }

        const newOrderNumber = pickupParam.orderNumber?.trim();
        if (!newOrderNumber || newOrderNumber.length <= 0) {
            this.notificationService.error('Please enter a valid order number.');
            return;
        }

        const orderNumbers = this.pickupParams
            .filter((param) => param !== pickupParam)
            .map((param) => {
                return param.orderNumber
            });

        if (orderNumbers.includes(newOrderNumber)) {
            this.notificationService.error('This order number is already in the list. Please enter a different order number.');
            return;
        }

        this.checkInService.getOrdersForPickup(newOrderNumber).subscribe({
            next: (orders) => {
                if (orders?.length > 0) {
                    this.notificationService.error('This order number is already in use. Please enter a different order number.');
                    return;
                }

                pickupParam.validOrderNumber = true;
                this.notificationService.success('Order number saved to the list.');
            }
        });
    }

    addPickupParam() {
        this.pickupParams.push({ orderNumber: '', validOrderNumber: false, customerId: 0 });
    }

    removePickupParam(pickupParam: IWalkInPickupParams) {
        const index = this.pickupParams.indexOf(pickupParam);
        if (index >= 0) {
            this.pickupParams.splice(index, 1);
        }
    }

    disableSubmitButton(): boolean {
        return this.pickupParams.some((param) => !param.validOrderNumber || param.customerId <= 0);
    }

    submitList() {
        this.checkInService.createWalkInPickup(this.pickupParams, this.driver.Id).subscribe({
            next: (pickup: IPickup) => {
                this.notificationService.success(`Walk-in pickup ${pickup.PickupNumber} created successfully. The Holt Admin will review to confirm the appointment.`);
                this.checkInService.checkedInDriver$.next(this.driver);
                void this.router.navigate(['public/driver-check-in']);
            }
        });
    }
}
