import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IRegistrationRequest } from '../../model/interfaces/registration-request';
import { formatDate } from '@angular/common';
import { RegistrationRequestActionsDynamicCellComponent } from './registration-request-actions-dynamic-cell.component';

export class RegistrationRequestEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (requester: IRegistrationRequest): string {
                        return `${requester.FirstName} ${requester.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'FirstName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (requester: IRegistrationRequest): string {
                        return typeof requester.Carrier?.Name === 'string' ? requester.Carrier.Name : '';
                    },
                    name: 'Carrier',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Carrier.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (requester: IRegistrationRequest): string {
                        return typeof requester.Customer?.Name === 'string' ? requester.Customer.Name : '';
                    },
                    name: 'Customer',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Customer.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (requester: IRegistrationRequest): string {
                        return formatDate(requester.DateCreated, 'fullDate', 'en-US');
                    },
                    name: 'Date Created',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'DateCreated',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (requester: IRegistrationRequest): string {
                        return requester.EmailAddress;
                    },
                    name: 'Email Address',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'EmailAddress',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (requester: IRegistrationRequest): string {
                        return requester.PhoneNumber;
                    },
                    name: 'Phone Number',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'PhoneNumber',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (requester: IRegistrationRequest): string {
                        return typeof requester.RegistrationRequestStatus.Name === 'string' ? requester.RegistrationRequestStatus.Name : '';
                    },
                    name: 'Status',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'RegistrationRequestStatus.Name',
                    },
                }),
                new EntityListColumn({
                    component: RegistrationRequestActionsDynamicCellComponent,
                    name: '',
                    sort: {
                        disableSort: true,
                    },
                })
            ],
        };
        super(listConfig);
    }
}
