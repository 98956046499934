import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from "@mt-ng2/dynamic-form";
import { ICarrierRequest } from "../../model/interfaces/carrier-request";
import { ICarrierRequestStatus } from "../../model/interfaces/carrier-request-status";
import { CarrierRequestDynamicControlsPartial } from "../../model/partials/carrier-request-partial.form-controls";

export class CarrierRequestDynamicConfig<T extends ICarrierRequest> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private carrierRequest: T, private carrierRequestStatuses: ICarrierRequestStatus[], private configControls?: string[]) {
        super();
        const dynamicControls =  new CarrierRequestDynamicControlsPartial(this.carrierRequest, { statuses: carrierRequestStatuses});
        if(!configControls) {
            this.configControls = [
                'CompanyName',
                'ContactName',
                'ContactPhoneNumber',
                'ContactEmailAddress',
            ]
        }

        this.setControls(this.configControls, dynamicControls);
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
