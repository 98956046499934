import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRegistrationRequest } from '../../../model/interfaces/registration-request';

@Injectable()
export class RegistrationService {
    constructor(public http: HttpClient) {}

    create(request: IRegistrationRequest): Observable<void> {
        return this.http.post<void>(`/registration-requests/create`, request);
    }
}
