import { formatDate } from '@angular/common';
import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IDriverBanHistory } from '../../model/interfaces/driver-ban-history';

export class TruckerBanHistoryEntityListConfig extends EntityListConfig {
    constructor() {

        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Action'],
                    name: 'Action',
                }),
                new EntityListColumn({
                    accessorFunction: function (history: IDriverBanHistory): string {
                        return formatDate(history.ActionDate, 'medium', 'en-US');
                    },
                    name: 'Date',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'ActionDate',
                    },
                }),
                new EntityListColumn({
                    accessors: ['Reason'],
                    name: 'Reason',
                }),
                new EntityListColumn({
                    accessorFunction: function (history: IDriverBanHistory): string {
                        const fullName = history.BannedBy ? history.BannedBy.FirstName + ' ' + history.BannedBy.LastName : 'N/A';
                        if (fullName === 'N/A') {
                            return fullName;
                        } else {
                            return fullName + ' (' + history.BannedBy.Email + ')';
                        }
                    },
                    name: 'Banned By',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'ActionDate',
                    },
                }),
            ],
        }

        super(listConfig);
    }
}
