import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { OfficeService } from '../services/office.service';
import { IOffice } from '../../model/interfaces/office';
import { OfficeDynamicConfig } from '../office.dynamic-config';
import { finalize } from 'rxjs/operators';
import { LocationService } from '../../locations/services/location.service';
import { ILocation } from '../../model/interfaces/location';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-office-basic-info',
    templateUrl: './office-basic-info.component.html',
})
export class OfficeBasicInfoComponent implements OnInit {
    @Input() office: IOffice;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: OfficeDynamicConfig<IOffice>;
    doubleClickIsDisabled = false;
    locations: ILocation[];

    constructor(
        private officeService: OfficeService,
        private notificationsService: NotificationsService,
        private router: Router,
        private locationService: LocationService,
    ) { }

    ngOnInit(): void {
        this.isEditing = false;
        this.locationService.getAll().subscribe((locations) => {
            this.locations = locations;
            this.setConfig();
        });
    }

    setConfig(): void {
        let config: IDynamicFormConfig;
        this.formFactory = new OfficeDynamicConfig<IOffice>(this.office, this.locations);

        if (this.office.Id === 0) {
            // new office
            this.isEditing = true;
            config = this.formFactory.getForCreate();
        } else {
            // existing office
            config = this.formFactory.getForUpdate();
        }

        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.office.Id === 0) {
            void this.router.navigate(['/offices']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            const formValues = form.value.Office as IOffice;
            formValues.NonTwicFee = formValues.NonTwicFee ?? 0;
            formValues.TwicFee = formValues.TwicFee ?? 0;
            
            this.formFactory.assignFormValues(this.office, formValues);
            (this.office).DaeOffice = formValues.DaeOffice;

            if (!this.office.Id || this.office.Id === 0) {
                // handle new office save
                this.officeService.create(this.office)
                    .pipe(finalize(() => this.doubleClickIsDisabled = false))
                    .subscribe((answer) => {
                        void this.router.navigate([`/offices/${answer}`]);
                        this.success();
                        this.officeService.emitChange(this.office);
                    });
            } else {
                // handle existing office save
                this.officeService.updateVersion(this.office)
                    .pipe(finalize(() => this.doubleClickIsDisabled = false))
                    .subscribe(
                        (success) => {
                            this.office.Version = success;
                            this.isEditing = false;
                            this.success();
                            this.officeService.emitChange(this.office);
                            this.setConfig();
                        });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Office saved successfully.');
    }
}
