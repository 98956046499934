import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { Location } from '@angular/common';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IPickup } from '../../model/interfaces/pickup';
import { IOrder } from '../../model/interfaces/order';

@Component({
    selector: 'app-driver-check-in-support',
    templateUrl: './driver-check-in-support.component.html',
    styleUrls: ['./driver-check-in-support.component.css'],
})
export class DriverCheckInSupportComponent implements OnInit {
    logoImageSrc = '../../../../assets/logo-full.png';
    driver: IUser;
    pickup: IPickup;
    orders: IOrder[];
    constructor(
        private router: Router, 
        private checkInService: PublicCheckInService, 
        private location: Location,
        private notificationService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            if (!driver) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            } 
        });

        this.checkInService.checkedInPickup$.subscribe((pickup) => {
            this.pickup = pickup;
        });

        this.checkInService.driverSelectedOrders$.subscribe((orders) => {
            this.orders = orders;
        });
    }

    goBack(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        this.checkInService.driverSelectedOrders$.next(this.orders);
        this.location.back();
    }

    getHelp(): void {
        const pickupId = this.pickup?.Id ?? 0;
        this.checkInService.getHelp(this.driver.Id, pickupId)
            .subscribe({
                next: (alreadyInQueue) => {
                    if (alreadyInQueue) {
                        this.notificationService.success('Your issue has already been logged. An administrator will reach out to assist you soon.');
                    } else {
                        this.notificationService.success('Thank you for registering your issue. An administrator will reach out to assist you soon.');
                    }
                },
                error: () => {
                    this.notificationService.error('Error trying to add to exception queue.');
                },
            });
    }

    callPhoneNumber() {
        const phoneNumberLink = document.getElementById('phone-number-link');
        phoneNumberLink.click();
    }
}
