import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { DeliveryService } from '../../services/delivery.service';
import { IDelivery } from '../../../model/interfaces/delivery';
import { AuthService } from '@mt-ng2/auth-module';
import { OrderService } from '../../../orders/order.service';
import { UserRoles } from '../../../model/UserRoles';
import { DeliveryStatuses } from '../../../model/DeliveryStatuses';

@Component({
    templateUrl: './archive-delivery-list.component.html',
})
export class ArchiveDeliveryListComponent implements IEntityListDynamicCellComponent, OnInit {
    entityListComponentMembers: IEntityListComponentMembers;
    item: IDelivery;
    isLoading = false;
    authUserRole: number;
    userRoles = UserRoles;
    deliveryStatuses = DeliveryStatuses;
    canDelete = false;

    constructor(
        private deliveryService: DeliveryService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private orderService: OrderService,
        private changeDetector: ChangeDetectorRef
    ) { }
    
    ngOnInit(): void {
        const loggedIn = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(loggedIn.AuthId).subscribe({
            next: (authUserRole) => {
                this.authUserRole = authUserRole;
                this.canDelete = this.checkCanDelete(authUserRole, this.item);
                this.changeDetector.markForCheck();
            }
        });
    }

    set entity(value: IDelivery) {
        this.item = value;
    }

    archive(event: MouseEvent): void {
        this.item.Archived = !this.item.Archived;
        event.stopPropagation();
        if (!this.isLoading) {
            this.isLoading = true;
            this.deliveryService
                .update(this.item)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe({
                    next: () => {
                        const archiveText = this.item.Archived ? 'archived' : 'unarchived';
                        this.notificationsService.success(`Successfully ${archiveText} delivery.`);
                        this.entityListComponentMembers.getEntities();
                    },
                    error: () => this.notificationsService.error('Archive failed')
                });
        }
    }

    checkCanDelete(authUserRole: number, delivery: IDelivery): boolean {
        if (authUserRole === this.userRoles.Admin)
            return true;
        
        const userIsCarrierOrCustomer = authUserRole === this.userRoles.Carrier || authUserRole === this.userRoles.Customer;
        if (userIsCarrierOrCustomer && delivery.StatusId === this.deliveryStatuses.Requested)
            return true;

        return false;
    }
}
