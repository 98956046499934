import { formatDate } from '@angular/common';
import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig, SelectTypes, IEntityListSelectColumn } from '@mt-ng2/entity-list-module';
import { UserRoles } from '../../model/UserRoles';
import { IDelivery } from '../../model/interfaces/delivery';
import { ArchiveDeliveryListComponent } from './archive-delivery-list/archive-delivery-list.component';

export class DeliveriesEntityListConfig extends EntityListConfig {
    constructor(authUserRole: number) {

        const listConfig: IEntityListConfig = {
            columns: [],
        }

        if (authUserRole === UserRoles.Admin) {
            listConfig.columns.push(
                new EntityListColumn({
                    accessors: ['DeliveryNumber'],
                    name: 'Delivery Number',
                }),
            );
        }

        listConfig.columns.push(
            new EntityListColumn({
                accessorFunction: function (delivery: IDelivery): string {
                    return formatDate(delivery.DeliveryDate, 'mediumDate', 'en-US');
                },
                name: 'Requested Delivery Date',
                sort: {
                    defaultDirection: SortDirection.Asc,
                    sortProperty: 'DeliveryDate',
                },
            }),
        );

        if (authUserRole === UserRoles.Admin) {
            listConfig.columns.push(
                new EntityListColumn({
                    accessorFunction: function (delivery: IDelivery): string {
                        if (!delivery.ConfirmedDeliveryDate)
                            return '';

                        return formatDate(delivery.ConfirmedDeliveryDate, 'medium', 'en-US');
                    },
                    name: 'Confirmed Delivery Date',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'ConfirmedDeliveryDate',
                    },
                }),
            );
        }

        listConfig.columns.push(
            new EntityListColumn({
                accessors: ['DeliveryStatus', 'Name'],
                name: 'Status',
            }),
            new EntityListColumn({
                accessors: ['DeliveryEquipmentType', 'Name'],
                name: 'Equipment Type',
            }),
            new EntityListColumn({
                accessors: ['DeliveryType', 'Name'],
                name: 'Delivery Type',
            }),
        );

        if (authUserRole === UserRoles.Admin) {
            listConfig.columns.push(
                new EntityListColumn({
                    accessors: ['Warehouse', 'WarehouseId'],
                    name: 'Warehouse',
                }),
                new EntityListColumn({
                    accessors: ['Carrier', 'Name'],
                    name: 'Carrier',
                }),
                new EntityListColumn({
                    accessors: ['Customer', 'Name'],
                    name: 'Customer',
                }),
            );  
        }

        if (authUserRole === UserRoles.Carrier) {
            listConfig.columns.push(
                new EntityListColumn({
                    accessors: ['Customer', 'Name'],
                    name: 'Customer',
                }),
            );  
        }

        if (authUserRole === UserRoles.Customer) {
            listConfig.columns.push(
                new EntityListColumn({
                    accessors: ['Carrier', 'Name'],
                    name: 'Carrier',
                }),
            );
        }

        listConfig.columns.push(
            new EntityListColumn({
                accessors: ['Country', 'Name'],
                name: 'Country Of Origin',
            }),
            new EntityListColumn({
                accessors: ['ContainerNumber'],
                name: 'Container #',
            }),
            new EntityListColumn({
                accessors: ['TrailerNumber'],
                name: 'Trailer #',
            }),
            new EntityListColumn({
                accessorFunction: function (delivery: IDelivery): string {
                    return `<a href="#/deliveries/${delivery.Id}">
                                <i href="#/deliveries/${delivery.Id}" class="fa fa-2x fa-fw fa-edit icon-main" aria-hidden="true"></i>
                            </a>`;
                },
                bindToInnerHtml: true,
                name: 'Edit',
                sort: {
                    disableSort: true,
                },
            }),
        );

        listConfig.columns.push(
            new EntityListColumn({
                component: ArchiveDeliveryListComponent,
                fireOnColumnSelected: false,
                name: 'Archive',
                sort: {
                    disableSort: true,
                },
            }),
        );

        super(listConfig);
        if (authUserRole === UserRoles.Admin) {
            this.select = { type: SelectTypes.Multi } as IEntityListSelectColumn;
        }
    }
}
