import { Component, Injector } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IEntity, ISharedEntitiesComponentConfig, SharedEntitiesComponent, } from '@mt-ng2/shared-entities-module';
import { IContact } from '../../model/interfaces/contact';
import { emptyContact } from '../../model/shared-entities/contacts/contact.service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ContactNotificationTypeService } from '../../model/shared-entities/contacts/contact-notification-type.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../users/user.service';

@Component({
    selector: 'customer-detail-shared-entities',
    templateUrl: './customer-detail-shared-entities.component.html',
})
export class CustomerDetailSharedEntitiesComponent extends SharedEntitiesComponent {
    contact: IContact = emptyContact;
    errorMessage: string;
    _contactSharedEntitiesConfig: ISharedEntitiesComponentConfig<IContact>;
    form: FormGroup;
    constructor (
        route: ActivatedRoute,
        router: Router,
        injector: Injector,
        notificationsService: NotificationsService,
        claimsService: ClaimsService,
        private notificationsServiceCustomerDetail: NotificationsService, // can't use the other, a bit dumb but whatever
        private notificationTypeService: ContactNotificationTypeService,
        private userService: UserService,

    ) {
        super();
    }

    /*********************************************************************************
     * For save() and saveEntityBypass()
     *
     * This override is here for displaying more specific error handling only
     * since we error if there under specific conditions, such as duplicate
     * names in contacts and users, we need to be more specific than 'Save Failed'
     * otherwise the form feels broken
     * please see lines 190 through 212 in the link below:
     * https://gitlab.4miles.com/MilesNPM/mt-ng2-shared-entities-module/-/tree/develop/src/shared-entities-info
     *******************************************************************************/
    save(entityForm: UntypedFormGroup): void {
        if (entityForm.valid) {
            const entity = this._sharedEntitiesConfig.getFormValues(this.selectedEntity, entityForm.value as IEntity);
            if (this.entitysPassedIn) {
                this.selectedEntity = null;
            } else {
                this.saveEntityBypass(entity);
            }
        } else {
            this.notificationsServiceCustomerDetail.error('Save Failed');
            markAllFormFieldsAsTouched(entityForm);
        }
    }

    delete(): void {
        this.service.deleteEntity(this.parentId, this.selectedEntity.Id).subscribe(
            () => {
                this.notificationsServiceCustomerDetail.success(`Contact archived.`);
                this.selectedEntity = null;
                this.getEntities();
            },
            (errorResponse: HttpErrorResponse) => {
                let errorObject = errorResponse.error;
                if (typeof(errorObject) === 'string') {
                    errorObject = JSON.parse(errorResponse.error as string);
                }
                this.notificationsServiceCustomerDetail.error(errorObject['ModelState'] as string)
            },
        );
    }

    private saveEntityBypass(entity: IEntity): void {
        this.service.saveEntity(this.parentId, entity).subscribe(
            () => {
                this.notificationsServiceCustomerDetail.success(`${this._sharedEntitiesConfig.EntityName} Saved Successfully`);
                this.selectedEntity = null;
                this.getEntities();
            },
            (errorResponse: HttpErrorResponse) => {
                let errorObject = errorResponse.error;
                if (typeof(errorObject) === 'string') {
                    errorObject = JSON.parse(errorResponse.error as string);
                }
                this.notificationsServiceCustomerDetail.error(errorObject['ModelState'] as string)
            },
        );
    }

    formCreated(form: FormGroup): void {
        this.form = form;
        const grantAccessToPortal = this.form.controls.Contact.get('GrantAccessToPortal').value;
        if (grantAccessToPortal === true) {
            this.form.controls.Contact.get('GrantAccessToPortal').disable();
        }
    }

    revokeAccess(userId: number) {
        this.userService.revokeAccess(userId)
            .subscribe({next: () => {
                this.form.controls.Contact.get('GrantAccessToPortal').setValue(false);
                this.selectedEntity['GrantAccessToPortal'] = false;
                this.selectedEntity = null;
                this.notificationsServiceCustomerDetail.success(`Access revoked for ${this._sharedEntitiesConfig.EntityName}`);
            }});
    }
}
