<div class="row">
    <div class="col-md-7">
        <h2>Drivers</h2>
    </div>
</div>

<div class="row">
    <div class="col-md-10">
        <mt-dynamic-search-filters [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search($event)"></mt-dynamic-search-filters>
    </div>
    <button class="btn btn-success pull-right" (click)="getTruckersCsv()">Export To Excel&nbsp;&nbsp;<i class="fa fa-file-excel-o"></i></button>
    <a href="javascript:void(0)" #tref></a>
</div>
<br />
<mt-entity-list
    [entities]="truckers"
    [total]="total"
    [(currentPage)]="currentPage"
    (pageChanged)="getTruckers()"
    (itemSelectedEvent)="truckerSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (itemDeletedEvent)="onItemDeleted($event)"
>
</mt-entity-list>
