import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';
import { SharedEntitiesInfoComponent, SharedEntitiesListComponent } from '@mt-ng2/shared-entities-module';
import { CarrierComponent } from '../carriers/carrier/carrier.component';
import { ClaimTypes } from '../model/ClaimTypes';
import { CarrierDetailComponent } from './carrier-detail/carrier-detail.component';
import { CarrierFeesComponent } from './carrier-fees/carrier-fees.component';
import { CarrierHeaderComponent } from './carrier-header/carrier-header.component';
import { CarrierService } from './services/carrier.service';
import { contactsSharedEntity } from './shared-entities/carrier.shared-entities';

const carrierListRoleGuard = {
    claimType: ClaimTypes.Carriers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Carriers',
};

const carrierAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Carriers,
    claimValues: [ClaimValues.FullAccess],
};

const carrierFeesRoleGuard = {
    claimType: ClaimTypes.CarrierFees,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Carrier Fees',
};

export const carrierPaths = {
    carriers: 'carriers',
    carriersAdd: 'carriers/add',
    carriersFees: 'carriers/fees',
};

const carrierEntityConfig = {
    claimType: ClaimTypes.Carriers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'carrierId',
    service: CarrierService,
    title: 'Carrier Detail',
    sharedEntities: [contactsSharedEntity]
};

const carrierRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CarrierComponent,
        data: carrierListRoleGuard,
        path: carrierPaths.carriers,
    },
    {
        canActivate: [AuthGuard],
        component: CarrierFeesComponent,
        data: carrierFeesRoleGuard,
        path: carrierPaths.carriersFees,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: CarrierDetailComponent, pathMatch: 'full' }],
        component: CarrierHeaderComponent,
        data: carrierAddRoleGuard,
        path: carrierPaths.carriersAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [
            { path: '', component: CarrierDetailComponent, pathMatch: 'full' },
        ],
        component: CarrierHeaderComponent,
        data: carrierEntityConfig,
        path: `carriers/:${carrierEntityConfig.entityIdParam}`,
    },
    {
        component: SharedEntitiesListComponent,
        data: { title: 'Carrier Contacts' },
        path: contactsSharedEntity.path,
        pathMatch: 'full',
    },
    {
        component: SharedEntitiesInfoComponent,
        path: `${contactsSharedEntity.path}/:${contactsSharedEntity.entityIdParam}`,
        pathMatch: 'full',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(carrierRoutes)],
})
export class CarrierRoutingModule {}
