import { ContactSharedEntitiesConfig } from '../../model/shared-entities/contacts/contact.shared-entities-config';
import { CarrierContactService } from './carrier-contact.service';

export enum CarrierSharedEntities {
    Contacts,
}

export const contactsSharedEntity = {
    config: ContactSharedEntitiesConfig,
    entityIdParam: 'contactId',
    path: 'contacts',
    service: CarrierContactService,
    sharedEntity: CarrierSharedEntities.Contacts,
};
