import { IEntityListConfig, EntityListConfig, EntityListColumn, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IOffice } from '../../model/interfaces/office';

export class OfficesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Title',
                }),
                new EntityListColumn({
                    accessorFunction: (office: IOffice): string => {
                        return office.Warehouses.length > 0 ? office.Warehouses.filter(w => !w.Archived).map((w) => w.WarehouseId).join(', ') : '';
                    },
                    name: 'Warehouses',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
        this.delete = new EntityListDeleteColumn();
    }
}
