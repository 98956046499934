<div class="row">
    <div class="col-md-7">
        <h2>Registration Requests</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <mt-dynamic-search-filters
        *ngIf="searchConfig"
        [config]="searchConfig"
        (searchValuesUpdated)="search($event)">
    </mt-dynamic-search-filters>
    </div>
</div>
<br />
<mt-entity-list
    [entities]="registrationRequests"
    [total]="total"
    [(currentPage)]="currentPage"
    (pageChanged)="getRegistrationRequests()"
    (itemSelectedEvent)="registrationRequestSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>
