import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PublicCheckInDynamicControlsPartial } from '../../../model/partials/public-check-in-partial.form-controls';
import { PublicCheckInDTO } from '../../../model/interfaces/custom/public-check-in-dto';
import { PublicCheckInService } from '../services/public-check-in.service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { CheckInType } from '../../../model/CheckInType';
import { IExpandableObject } from '../../../model/expandable-object';

@Component({
    selector: 'app-phone-number-input',
    styles: [
        `
            .col-sm-12 {
                padding: 10px;
            }
        `,
    ],
    templateUrl: './phone-number-input.component.html',
})
export class PhoneNumberInputComponent implements OnInit {
    checkInData: PublicCheckInDTO;
    phoneNumberInputForm: UntypedFormGroup;
    formControls: IExpandableObject;
    logoImageSrc = '../../../../assets/logo-full.png';
    constructor(private checkInService: PublicCheckInService, private router: Router, private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}
    ngOnInit(): void {
        this.checkInService.checkInData.subscribe((response) => {
            this.checkInData = response;
            this.createForm();
        });
    }
    createForm(): void {
        this.getControls();
    }

    getControls(): void {
        this.formControls = new PublicCheckInDynamicControlsPartial(this.checkInData, [], {
            formGroup: 'phoneNumberInputForm',
        }).Form;
        this.cdr.detectChanges();
        this.phoneNumberInputForm = this.assignFormGroups();
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            phoneNumberInputForm: this.fb.group({}),
        });
    }

    savePhoneAndProceedAsPickup(): void {
        this.updateCheckInDataAndNavigateToNextStep(CheckInType.PICKUP, 'public/pick-up');
    }

    savePhoneAndProceedAsDelivery(): void {
        this.updateCheckInDataAndNavigateToNextStep(CheckInType.DELIVERY, 'public/delivery');
    }

    updateCheckInDataAndNavigateToNextStep(checkInType: CheckInType, path: string): void {
        if (this.phoneNumberInputForm.valid) {
            this.checkInData.phone = this.phoneNumberInputForm.get('phoneNumberInputForm.Phone').value;
            this.checkInData.checkInTypeId = checkInType;
            this.checkInService.checkInData.next(this.checkInData);
            void this.router.navigate([path]);
        } else {
            markAllFormFieldsAsTouched(this.phoneNumberInputForm);
        }
    }
}
