import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IUser } from '../../model/interfaces/user';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../user.service';
import { ClaimsService, ClaimValues, ILoggedIn } from '@mt-ng2/auth-module';
import { IAddress } from '../../model/interfaces/base';
import { ClaimTypes } from '../../model/ClaimTypes';
import { AuthService } from '@mt-ng2/auth-module';
import { IUserPhone } from '../../model/interfaces/user-phone';
import { OrderService } from '../../orders/order.service';
import { UserRoles } from '../../model/UserRoles';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
})
export class UserDetailComponent implements OnInit {
    user: IUser;
    currentUser: ILoggedIn;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canEditOffice: boolean;
    userAddress: IAddress;
    canAdd: boolean;
    myProfile: boolean;
    userRoles: UserRoles;

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
        private notificationsService: NotificationsService,
        private orderService: OrderService,
    ) { }

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        this.currentUser = this.authService.currentUser.getValue();

        this.orderService.getAuthUserRole(this.currentUser.AuthId).subscribe(authUserRole => {
            if (this.router.url.endsWith('my-profile')) {
                this.myProfile = true;
                const userId = this.currentUser.Id;
                this.proccessUserId(userId);
                if (authUserRole === UserRoles.Carrier || authUserRole === UserRoles.Customer) {
                    this.canEditOffice = false;
                } else {
                    this.canEditOffice = this.canEdit;
                }
                return;
            }

            if (authUserRole === UserRoles.Carrier || authUserRole === UserRoles.Customer) {
                void this.router.navigate(['/orders']);
                return;
            }
            this.route.params.subscribe(params => {
                this.proccessUserId(Number(params['userId']));
            });
        });
    }

    proccessUserId(userId: number): void {
        if (userId > 0) {
            this.getUserById(userId);
        } else {
            this.user = this.userService.getEmptyUser();
        }
        this.editingComponent.next('');
    }

    getUserById(id: number): void {
        this.userService.getById(id).subscribe((user) => {
            this.user = user;
            this.canEdit = this.user.AuthUser?.IsEditable;
            this.canEditOffice = this.user.AuthUser?.RoleId === UserRoles.Admin;
            this.currentUser = this.authService.currentUser.getValue();
            if (user.Address) {
                this.userAddress = user.Address;
            } else {
                this.userAddress = null;
            }
        });
    }

    saveAddress(address: IAddress): void {
        this.userService.saveAddress(this.user.Id, address).subscribe((answer) => {
            address.Id = answer;
            this.notificationsService.success('Address Saved Successfully');
            this.user.Address = address;
            this.userAddress = address;
        });
    }

    deleteAddress(): void {
        this.userService.deleteAddress(this.user.Id).subscribe(() => {
            this.notificationsService.success('Address Deleted Successfully');
            this.userAddress = null;
            this.user.Address = null;
        });
    }

    savePhones(phoneCollection: IUserPhone[]): void {
        this.userService.savePhones(this.user.Id, phoneCollection).subscribe(() => {
            this.notificationsService.success('Phones Saved Successfully');
            this.user.UserPhones = phoneCollection;
            this.editingComponent.next('');
        });
    }

    updateVersion(version): void {
        this.user.Version = version;
    }

    isDriverUser(user: IUser): boolean {
        return user.AuthUser?.RoleId === UserRoles.DriverUser;
    }
}
