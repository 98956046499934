import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ICarrier } from '../../model/interfaces/carrier';
import { CarrierService } from '../services/carrier.service';

@Component({
    selector: 'app-carrier-header',
    templateUrl: './carrier-header.component.html',
})
export class CarrierHeaderComponent implements OnInit, OnDestroy {
    carrier: ICarrier;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private carrierService: CarrierService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.carrierService.changeEmitted$.subscribe((carrier) => {
                this.setHeader(carrier);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('carrierId');
        if (id > 0) {
            this.carrierService.getById(id).subscribe((carrier) => {
                this.setHeader(carrier);
            });
        } else {
            this.header = 'Add Carrier';
            this.carrier = this.carrierService.getEmptyCarrier();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(carrier: ICarrier): void {
        this.carrier = carrier;
        this.header = `Carrier: ${carrier.Name}`;
    }
}
