import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IRegistrationRequest } from '../model/interfaces/registration-request';
import { Observable, Subject, tap } from 'rxjs';

export const emptyRegistrationRequest: IRegistrationRequest = {
    DateCreated: new Date(),
    EmailAddress: '',
    FirstName: '',
    Id: 0,
    LastName: '',
    NotificationWhenDriverChecksIn: false,
    NotificationWhenDriverIsFulfilled: false,
    NotificationWhenDriverIsIssuedGatePass: false,
    NotificationWhenDriverIsNotified: false,
    PhoneNumber: '',
    StatusId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class RegistrationRequestService extends BaseService<IRegistrationRequest> {
    registrationStatusUpdated: Observable<void>;
    private registrationAddedSubject: Subject<void>;

    constructor(public http: HttpClient) {
        super('/registration-requests', http);
        this.registrationAddedSubject = new Subject<void>();
        this.registrationStatusUpdated = this.registrationAddedSubject.asObservable();
    }

    getEmptyRegistrationRequest(): IRegistrationRequest {
        return { ...emptyRegistrationRequest };
    }

    updateStatus(statusId: number, id: number) {
        return this.updatePartial({StatusId: statusId}, id).pipe(
            tap(()=> {
                this.registrationAddedSubject.next();
            })
        )
    }

    approve(registrationRequestId: number, contactId: number) {
        return this.http.put<void>(`/registration-requests/${registrationRequestId}/approve/contact/${contactId}`, null).pipe(
            tap(()=> {
                this.registrationAddedSubject.next();
            })
        )
    }

    deny(id: number) {
        return this.http.put<void>(`/registration-requests/${id}/deny`, null).pipe(
            tap(()=> {
                this.registrationAddedSubject.next();
            })
        )
    }

    generateContact(registrationRequest: IRegistrationRequest) {
        return this.http.post<number>(`/registration-requests/generate-contact`, registrationRequest)
    }

}
