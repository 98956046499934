import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ImageResponse } from '../../model/interfaces/image-response';
import { IPickup } from '../../model/interfaces/pickup';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';

@Component({
    selector: 'app-driver-gate-pass-code',
    templateUrl: './driver-gate-pass-code.component.html',
    styleUrls: ['./driver-gate-pass-code.component.css'],
})
export class DriverGatePassCodeComponent implements OnInit {
    driver: IUser;
    pickup: IPickup;
    imageSrc: string;
    isTwicVerified: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private checkInService: PublicCheckInService,
    ) { }

    ngOnInit(): void {
        const driverId = +this.route.snapshot.paramMap.get('driverId');
        const pickupId = +this.route.snapshot.paramMap.get('pickupId');

        if (driverId > 0 && pickupId > 0) {
            forkJoin([
                this.checkInService.findValidDriverById(driverId),
                this.checkInService.getPickupById(pickupId),
            ]).subscribe(([driver, pickup]) => {
                this.driver = driver;
                this.pickup = pickup;
                
                if (!driver || !pickup) {
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }

                this.getGatePassBarcode();
            });

            return;
        }

        void this.router.navigate(['public/driver-check-in-info']);
    }

    getGatePassBarcode(): void {
        this.checkInService.getGatePassBarcode(this.driver.Id, this.pickup.Id).subscribe({
            next: (imageResponse: ImageResponse) => {
                this.imageSrc = 'data:image/png;base64,' + imageResponse.Image;
            }
        });
    }
}
