import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IColumnSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { Subscription } from 'rxjs';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { CustomerService } from '../../customers/customer.service';
import { ClaimTypes } from '../../model/ClaimTypes';
import { IWarehouse } from '../../model/interfaces/warehouse';
import { WarehouseService } from '../../warehouses/services/warehouse.service';
import swal from 'sweetalert2';
import { WalkInOrdersEntityListConfig } from './walk-in-orders.entity-list-config';
import { WalkInOrdersStateService } from './walk-in-orders-state.service';
import { CheckInDetailService, IWalkInOrderPartial } from '../../check-ins/services/check-in-detail.service';
import { HourFilterPipe } from '../../common/filters/common.hour-filter';
import { SyncOrAsync } from '../../model/interfaces/custom/sync-or-async';
import { NavSidebarService } from '../../nav/sidebar.service';

@Component({
    providers: [WalkInOrdersStateService],
    selector: 'walk-in-orders',
    styles: [
        `
            .btn-assign-warehouses {
                margin: 10px;
            }
        `,
    ],
    templateUrl: 'walk-in-orders.component.html',
})
export class WalkInOrdersComponent implements OnInit, OnDestroy {
    checkInDetails: IWalkInOrderPartial[];
    currentPage = 1;
    query = '';
    itemsPerPage = 999;
    total: number;
    customerItems: MtSearchFilterItem[] = [];
    order = '';
    orderDirection = '';
    canAddOrder = false;
    subscriptions: Subscription = new Subscription();
    showArchived = false;
    showMissingWarehouseOrders = false;
    CommonFunctions = CommonFunctions;
    warehouses: IWarehouse[];
    entityListConfig: WalkInOrdersEntityListConfig;
    selectedCheckInDetailIds: number[] = [];

    constructor(
        private checkInDetailService: CheckInDetailService,
        private orderCustomerService: CustomerService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private warehouseService: WarehouseService,
        private stateService: WalkInOrdersStateService,
        private hourFilter: HourFilterPipe,
        private navSidebarService: NavSidebarService
    ) {}

    ngOnInit(): void {
        this.entityListConfig = new WalkInOrdersEntityListConfig(this.stateService, this.hourFilter);
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
        this.canAddOrder = this.claimsService.hasClaim(ClaimTypes.Orders, [ClaimValues.FullAccess]);
        this.orderCustomerService.getSimpleCustomers().subscribe((answer) => {
            this.customerItems = CommonFunctions.mapMtSearchFilterItems(answer);
        });
        this.warehouseService.getActive().subscribe((answer) => {
            this.warehouses = answer;
        });
        this.getWalkInOrders();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedCustomersIds: number[] = this.getSelectedFilters(this.customerItems);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: selectedCustomersIds,
            }),
        );

        return _extraSearchParams;
    }

    getWalkInOrders(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.checkInDetailService.getWalkInOrders(searchparams).subscribe((answer) => {
            this.checkInDetails = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            this.navSidebarService.refreshNavBarCount();
        });
    }

    search(query: string): void {
        this.query = query;
        this.getWalkInOrders();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getWalkInOrders();
    }

    columnSelected(event: IColumnSelectedEvent): void {
        if (this.selectedCheckInDetailIds.indexOf(event.entity.Id as number) === -1) {
            this.selectedCheckInDetailIds.push(event.entity.Id as number);
        } else {
            this.selectedCheckInDetailIds.splice(this.selectedCheckInDetailIds.indexOf(event.entity.Id as number), 1);
        }
        this.stateService.selectedCheckInDetailIds$.next(this.selectedCheckInDetailIds);
    }

    bulkAssignWarehouse(): void {
        this.updateWarehouse();
    }

    private updateWarehouse(): void {
        const options = {};
        this.warehouses.forEach((item) => {
            options[`${item.WarehouseId}`] = item.WarehouseId;
        });
        void swal.fire({
            input: 'select',
            inputOptions: options,
            inputValidator: function (value: string): SyncOrAsync<string> {
                return new Promise<string>(function (resolve: (value?: string) => void): void {
                    if (value !== '') {
                        resolve();
                    } else {
                        resolve('You need to select a Warehouse');
                    }
                });
            },
            showCancelButton: true,
            title: 'Update Warehouse',
        }).then((result) => {
            if (result.value) {
                const filterItem = this.warehouses.find((i) => i.WarehouseId === result.value);
                this.checkInDetailService.bulkAssignWalkInOrdersToWarehouse(this.selectedCheckInDetailIds, filterItem.Id).subscribe(() => {
                    this.notificationsService.success('Successfully updated Warehouse');
                    this.getWalkInOrders();
                    this.selectedCheckInDetailIds = [];
                    this.stateService.selectedCheckInDetailIds$.next(this.selectedCheckInDetailIds);
                });
            }
        });
    }
}
