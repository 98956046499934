import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IExceptionQueue } from '../../model/interfaces/exception-queue';
import { ExceptionQueueStatuses } from '../exception-queue-status-enum';
import { ArchiveExceptionListComponent } from './archive-exception-list-component';

export class ExceptionQueueEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (exception: IExceptionQueue): string {
                        return `${exception.User?.FirstName} ${exception.User?.LastName}`;
                    },
                    name: 'Driver Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'User.FirstName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (exception: IExceptionQueue): string {
                        return exception.Pickup?.PickupNumber;
                    },
                    name: 'Pickup Number',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Pickup.PickupNumber',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (exception: IExceptionQueue): string {
                        return exception.User?.Phone;
                    },
                    name: 'Phone Number',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'User.Phone',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (exception: IExceptionQueue): string => {
                        return ExceptionQueueStatuses[exception.StatusId];
                    },
                    name: 'Status',
                    fireOnColumnSelected: true,
                    sort: { sortProperty: 'StatusId' }
                }),
                new EntityListColumn({
                    component: ArchiveExceptionListComponent,
                    fireOnColumnSelected: false,
                    name: 'Delete',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        
        super(listConfig);
    }
}
