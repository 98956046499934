import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { appNavMenu } from './app-nav-menu-config';
import { HttpClient } from '@angular/common/http';
import { NavSidebarContentContainer, INavSidebarService, NavSidebarHeaderItem } from '@mt-ng2/nav-module';

@Injectable()
export class NavSidebarService implements INavSidebarService {
    private http = inject(HttpClient);
    content: BehaviorSubject<NavSidebarContentContainer[]>;
    navigationMenu: NavSidebarContentContainer;
    private messageSource = new Subject<void>();
    message$ = this.messageSource.asObservable();

    constructor() {
        this.navigationMenu = new NavSidebarContentContainer({
            header: new NavSidebarHeaderItem({ content: `NAVIGATION` }),
            rows: appNavMenu,
        });

        this.content = new BehaviorSubject([this.navigationMenu]);
    }

    getPendingRegistrationRequestsCount() {
        return this.http.get<number>(`/registration-requests/pending-count`);
    }

    getWalkInOrdersCount() {
        return this.http.get<number>(`/check-in-details/walk-ins-count`);
    }

    refreshNavBarCount() {
        this.messageSource.next();
    }
}
