<div *ngIf="driver" class="text-center public-responsive">
    <h1>Check-In</h1>
    <img class="page-center-message" src="{{ logoImageSrc }}" />
    <p class="f-20" *ngIf="!driver.BannedFromPickup">
        Are you picking up or delivering cargo?
    </p>
    <p class="f-20" *ngIf="driver.BannedFromPickup" class="error-message">
        Access not permitted. Please contact dispatcher.
    </p>
    <br />
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <button [disabled]="driver.BannedFromPickup" type="button" (click)="imPickingUp()" class="btn btn-primary">I'm Picking Up</button>
        </div>
        <div class="col-md-6 col-sm-12">
            <button [disabled]="driver.BannedFromPickup" type="button" (click)="isDelivering()" class="btn btn-primary">I'm Delivering</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <span class="new-driver-link--wrapper">
                <div class="new-driver-link">Not Ready to Pickup? Please <a (click)="goToDriverEditPage()">click here</a> to go back to your profile.
                </div>
            </span>
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver"></app-driver-check-in-support-button>
    <br />
</div>
