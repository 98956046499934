import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IRegistrationRequestStatus } from '../model/interfaces/registration-request-status';

export const emptyRegistrationRequestStatus: IRegistrationRequestStatus = {
    Id: 0,
    Name: null,
};

@Injectable({
    providedIn: 'root',
})
export class RegistrationRequestStatusService extends BaseService<IRegistrationRequestStatus> {
    constructor(public http: HttpClient) {
        super('/registration-request-statuses', http);
    }

    getEmptyRegistrationRequestStatus(): IRegistrationRequestStatus {
        return { ...emptyRegistrationRequestStatus };
    }
}
