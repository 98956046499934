import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { UserService } from '../user.service';
import { UserRoleService } from '../../user-roles/user-role.service';
import { IUser } from '../../model/interfaces/user';
import { ClaimTypes } from '../../model/ClaimTypes';
import { UsersEntityListConfig } from './users.entity-list-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { OrderService } from '../../orders/order.service';
import { UserRoles } from '../../model/UserRoles';
import { forkJoin } from 'rxjs';
import { CarrierService } from '../../carriers/services/carrier.service';
import { CustomerService } from '../../customers/customer.service';
import { ICarrier } from '../../model/interfaces/carrier';
import { ICustomer } from '../../model/interfaces/customer';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { IUserRole } from '../../model/interfaces/user-role';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
    searchControl = new UntypedFormControl();
    showArchived = false;
    users: IUser[];
    userRoleMetaItems: MtSearchFilterItem[] = [];
    currentPage = 1;
    query = '';
    total: number;
    canAddUser = false;
    roles: IUserRole[];
    carriers: ICarrier[];
    customers: ICustomer[];
    selectedCarrierIds: number[] = [];
    selectedCustomerIds: number[] = [];
    selectedRoleIds: number[] = [];
    entityListConfig = new UsersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];

    constructor(
        private userService: UserService,
        private userRoleService: UserRoleService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private orderService: OrderService,
        private carrierService: CarrierService,
        private customerService: CustomerService,
    ) {}

    ngOnInit(): void {
        const currentUser = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(currentUser.AuthId).subscribe(authUserRole => {
            if (authUserRole === UserRoles.Carrier || authUserRole === UserRoles.Customer) {
                void this.router.navigate(['/orders']);
                return;
            }
            this.canAddUser = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
            this.getUsers();
            this.initFilters();
        });
    }

    initFilters(): void {
        forkJoin([
            this.userRoleService.getAll(), 
            this.carrierService.getActive(),
            this.customerService.getActiveCustomers(), 
        ]).subscribe(([roles, carriers, customers]) => {
            this.roles = roles
                .filter((role) => ![UserRoles.DAEUser, UserRoles.HoltClerk]
                .includes(role.Id));

            this.carriers = carriers;
            this.customers = customers;
            this.dynamicSearchFiltersConfig = [{
                Searchbar: {
                    label: 'Search',
                    type: DynamicSearchFilterTypes.Searchbar,
                },
                UserRoles: {
                    label: 'User Type',
                    type: DynamicSearchFilterTypes.Select,
                    options: {
                        selectOptions: CommonFunctions.mapMtSearchFilterItems(this.roles, 'Name')
                    },
                },
                Carriers: {
                    label: 'Carrier',
                    type: DynamicSearchFilterTypes.Select,
                    options: {
                        selectOptions: CommonFunctions.mapMtSearchFilterItems(this.carriers, 'Name')
                    },
                },
                Customers: {
                    label: 'Customer',
                    type: DynamicSearchFilterTypes.Select,
                    options: {
                        selectOptions: CommonFunctions.mapMtSearchFilterItems(this.customers, 'Name')
                    },
                },
            }];
        });
    }

    getUsers(): void {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShowArchived',
                value: this.showArchived.toString(),
            }),
            new ExtraSearchParams({
                name: 'UserRoleIds',
                valueArray: this.selectedRoleIds,
            }),
            new ExtraSearchParams({
                name: 'CarrierIds',
                valueArray: this.selectedCarrierIds,
            }),
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: this.selectedCustomerIds,
            }),
        );

        const search = this.query;
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);

        this.userService.get(searchparams).subscribe((answer) => {
            this.users = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    search(evt: DynamicSearchValues): void {
        this.query = evt.Searchbar as string;
        this.currentPage = 1;
        
        if (evt.UserRoles) {
            this.selectedRoleIds = (evt.UserRoles as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Carriers) {
            this.selectedCarrierIds = (evt.Carriers as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Customers) {
            this.selectedCustomerIds = (evt.Customers as IMetaItem[]).map(x => x.Id);
        }

        this.getUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUsers();
    }

    userSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/users', event.entity.Id]);
    }

    archiveSelected(event: IItemSelectedEvent): void {
        if (event.entity["IsArchived"]) {
            this.notificationsService.info('This user is already archived.');
        } else {
            this.userService.ArchiveUser(event.entity.Id as number).subscribe(
                () => {
                    this.notificationsService.success('User successfully archived!');
                    this.getUsers();
                },
                () => {
                    this.notificationsService.error('An error occurred while archiving the user.');
                },
            );
        }
    }
}
