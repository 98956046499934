<div class="row">
    <div class="col-md-7">
        <h2>Gate Locations</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <br />
</div>

<mt-entity-list
    [entities]="locations"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getLocations()"
    (itemSelectedEvent)="locationSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>

<div *ngIf="canAddLocation" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/locations', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
