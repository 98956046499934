import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '@mt-ng2/auth-module';
import { IUser } from '../../model/interfaces/user';
import { UserService } from '../user.service';
import { OrderService } from '../../orders/order.service';
import { UserRoles } from '../../model/UserRoles';

@Component({
    selector: 'app-user-header',
    templateUrl: './user-header.component.html',
})
export class UserHeaderComponent implements OnInit, OnDestroy {
    user: IUser;
    userId: number;
    header: string;
    showBackButton = true;
    subscriptions: Subscription = new Subscription();

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private orderService: OrderService,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.userService.changeEmitted$.subscribe((user) => {
                this.setHeader(user);
            }),
        );

        const userIdParam = this.route.snapshot.paramMap.get('userId');
        if (userIdParam === 'my-profile') {
            const currentUser = this.authService.currentUser.getValue();
            this.userId = currentUser.Id;
            this.orderService.getAuthUserRole(currentUser.AuthId).subscribe(roleId => {
                this.showBackButton = (roleId !== UserRoles.Carrier && roleId !== UserRoles.Customer);
            });
        } else {
            this.userId = +userIdParam;
        }

        if (this.userId > 0) {
            this.userService.getById(this.userId).subscribe((user) => {
                this.setHeader(user);
            });
            return;
        }
        this.header = 'Add User';
        this.user = this.userService.getEmptyUser();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(user: IUser): void {
        this.user = user;
        this.header = `User: ${this.user.FirstName} ${this.user.LastName}`;
    }
}
