import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '@mt-ng2/auth-module';

@Injectable({
    providedIn: 'root'
})
export class GuestGuard  {
    constructor(private authService: AuthService, private router: Router) { }

    async canActivate(): Promise<boolean> {
        try {
            const result = await firstValueFrom(this.authService.isAuthenticated());
            if (result) {
                void this.router.navigate(['/']);
                return false;
            }
            return true;
        } catch (error) {
            void this.router.navigate(['/error']);
            return false;
        }
    }
}