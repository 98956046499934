<div class="row">
    <div class="col-md-6">
        <div *ngIf="registrationRequest">
            <app-registration-request-info
            [registrationRequest]="registrationRequest"
            [canEdit]="canEdit">
            </app-registration-request-info>
            <br/>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <a (click)="close()" class="btn btn-default">Close</a>
    </div>
</div>
