import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { catchError, Observable } from 'rxjs';
import { IPickupPaymentDetail } from '../../model/interfaces/pickup-payment-detail';

export const emptyPickupPaymentDetail: IPickupPaymentDetail = {
    Amount: 0.00,
    CheckInId: 0,
    IsSuccessful: false,
    DateCreated: new Date(),
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class PickupPaymentDetailService extends BaseService<IPickupPaymentDetail> {
    constructor(public http: HttpClient) {
        super('/pickup-payment-details', http);
    }

    search(searchparameters: SearchParams): Observable<HttpResponse<IPickupPaymentDetail[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IPickupPaymentDetail[]>(`/pickup-payment-details/search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }
}
