<div *ngIf="selectedOrders?.length > 0">
    <div class="row">
        <div class="col-md-7">
            <h2>Pickup Scheduling - Step 2 of 3</h2>
            <h4>
                Which Driving Company would you like to have pick up these orders?
            </h4>
        </div>
    </div>
    <br>
    <div class="miles-editable miles-card centered-card">
        <div *ngIf="selectedPickup?.Id > 0">
            <h3 class="pickup-number-label">Pickup Number: {{ selectedPickup?.PickupNumber }}</h3>
            <br>
        </div>
        <table class="table table-striped pickup-scheduling-table">
            <thead>
                <tr>
                    <th><b>Order Number</b></th>
                    <th><b>Office</b></th>
                    <th><b>Warehouse</b></th>
                    <th><b>Date Created</b></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of selectedOrders">
                    <td><span>{{ order.OrderNumber }}</span></td>
                    <td><span>{{ order.Warehouse?.Office?.Title }}</span></td>
                    <td><span>{{ order.Warehouse?.WarehouseId }}</span></td>
                    <td><span>{{ order.DateCreated | date: 'medium' }}</span></td>
            </tbody>
        </table>
        <br>
        <form [formGroup]="carrierSelectionForm" (ngSubmit)="onCarrierSelectionFormSubmit()" *ngIf="carrierSelectionForm">
            <div class="row input-row-padding">
                <div class="input-group input-group-text">
                    <label [ngClass]="{ 'error-text': selectedCarrierTouched && !selectedCarrier}">
                        <i class="fa fa-lg fa-fw fa-truck reverse-icon"></i> Select Trucking Company:
                    </label>
                    <span class="error-text ng-star-inserted"> *</span>
                    <mt-type-ahead 
                        [ngClass]="{ 'has-error': selectedCarrierTouched && !selectedCarrier}" 
                        (selectionChanged)="onCarrierSelected($event)"
                        [selectedItem]="selectedCarrier"
                        [items]="carriers" >
                    </mt-type-ahead>
                    <div class="error-text" *ngIf="!selectedCarrier && selectedCarrierTouched">
                        <small>Driving company is required.</small>
                    </div>
                </div>
            </div>
            <div class="row input-row-padding">
                <div class="input-group input-group-text">
                    <label [ngClass]="{ 'error-text': carrierSelectionForm.controls.deliveryZipCode.invalid && carrierSelectionForm.controls.deliveryZipCode.touched }">
                        <i class="fa fa-lg fa-fw fa-map-o"></i> Delivery Zip Code:
                    </label>
                    <input class="form-control" type="text" formControlName="deliveryZipCode" 
                        [ngClass]="{ 'has-error': carrierSelectionForm.controls.deliveryZipCode.invalid && carrierSelectionForm.controls.deliveryZipCode.touched }">
                    <div class="error-text" *ngIf="carrierSelectionForm.controls.deliveryZipCode.invalid && carrierSelectionForm.controls.deliveryZipCode.touched">
                        <small *ngIf="carrierSelectionForm.controls.deliveryZipCode.errors?.pattern">Invalid Zip-code format.</small>
                    </div>
                </div>
            </div>
            <br>
            <div class="text-center">
                <button class="btn btn-success" type="submit" [disabled]="!selectedCarrier || carrierSelectionForm.controls.deliveryZipCode.invalid">Next Step</button>
            </div>
        </form>
    </div>
</div>
