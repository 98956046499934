import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ICustomer } from '../interfaces/customer';
import { ICustomerPickUpHour } from '../interfaces/customer-pick-up-hour';

export interface ICustomerDynamicControlsParameters {
    formGroup?: string;
    customerPickUpHours?: ICustomerPickUpHour[];
}

export class CustomerDynamicControls {

    formGroup: string;
    customerPickUpHours?: ICustomerPickUpHour[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customer?: ICustomer, additionalParameters?: ICustomerDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Customer';
        this.customerPickUpHours = additionalParameters && additionalParameters.customerPickUpHours || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('Archived') && this.customer.Archived != null ? this.customer.Archived : false,
            }),
            AvailableForCheckIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Available For Check In',
                name: 'AvailableForCheckIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('AvailableForCheckIn') && this.customer.AvailableForCheckIn != null ? this.customer.AvailableForCheckIn : false,
            }),
            CanSchedulePerDockLocation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can Schedule Per Dock Location',
                name: 'CanSchedulePerDockLocation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('CanSchedulePerDockLocation') && this.customer.CanSchedulePerDockLocation != null ? this.customer.CanSchedulePerDockLocation : false,
            }),
            CustomerNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Number',
                name: 'CustomerNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('CustomerNumber') && this.customer.CustomerNumber != null ? this.customer.CustomerNumber.toString() : '',
            }),
            CustomerPickUpHoursId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Pick Up Hours',
                name: 'CustomerPickUpHoursId',
                options: this.customerPickUpHours,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.CustomerPickUpHoursId || null,
            }),
            DefaultEndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default End Time',
                name: 'DefaultEndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customer && this.customer.hasOwnProperty('DefaultEndTime') && this.customer.DefaultEndTime != null ? this.customer.DefaultEndTime.toString() : '',
            }),
            DefaultStartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Start Time',
                name: 'DefaultStartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customer && this.customer.hasOwnProperty('DefaultStartTime') && this.customer.DefaultStartTime != null ? this.customer.DefaultStartTime.toString() : '',
            }),
            DefaultWeekdayFri: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Fri',
                name: 'DefaultWeekdayFri',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayFri') && this.customer.DefaultWeekdayFri != null ? this.customer.DefaultWeekdayFri : true,
            }),
            DefaultWeekdayMon: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Mon',
                name: 'DefaultWeekdayMon',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayMon') && this.customer.DefaultWeekdayMon != null ? this.customer.DefaultWeekdayMon : true,
            }),
            DefaultWeekdaySat: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Sat',
                name: 'DefaultWeekdaySat',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdaySat') && this.customer.DefaultWeekdaySat != null ? this.customer.DefaultWeekdaySat : true,
            }),
            DefaultWeekdaySun: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Sun',
                name: 'DefaultWeekdaySun',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdaySun') && this.customer.DefaultWeekdaySun != null ? this.customer.DefaultWeekdaySun : true,
            }),
            DefaultWeekdayThu: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Thu',
                name: 'DefaultWeekdayThu',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayThu') && this.customer.DefaultWeekdayThu != null ? this.customer.DefaultWeekdayThu : true,
            }),
            DefaultWeekdayTue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Tue',
                name: 'DefaultWeekdayTue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayTue') && this.customer.DefaultWeekdayTue != null ? this.customer.DefaultWeekdayTue : true,
            }),
            DefaultWeekdayWed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Wed',
                name: 'DefaultWeekdayWed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayWed') && this.customer.DefaultWeekdayWed != null ? this.customer.DefaultWeekdayWed : true,
            }),
            DispatcherEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dispatcher Email',
                name: 'DispatcherEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(255) ],
                validators: { 'maxlength': 255 },
                value: this.customer && this.customer.hasOwnProperty('DispatcherEmail') && this.customer.DispatcherEmail != null ? this.customer.DispatcherEmail.toString() : '',
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('Name') && this.customer.Name != null ? this.customer.Name.toString() : '',
            }),
            NeedsAppointmentToCheckIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Needs Appointment To Check In',
                name: 'NeedsAppointmentToCheckIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('NeedsAppointmentToCheckIn') && this.customer.NeedsAppointmentToCheckIn != null ? this.customer.NeedsAppointmentToCheckIn : false,
            }),
            NonTwicFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Non Twic Fee',
                name: 'NonTwicFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.NonTwicFee || null,
            }),
            NoOfPickupsPerHour: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Of Pickups Per Hour',
                name: 'NoOfPickupsPerHour',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.NoOfPickupsPerHour || null,
            }),
            OrderWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Weight',
                name: 'OrderWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.OrderWeight || null,
            }),
            PalletCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pallet Count',
                name: 'PalletCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.PalletCount || null,
            }),
            ParsNumberRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pars Number Required',
                name: 'ParsNumberRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('ParsNumberRequired') && this.customer.ParsNumberRequired != null ? this.customer.ParsNumberRequired : false,
            }),
            SchedulingAndDoorAssignment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scheduling And Door Assignment',
                name: 'SchedulingAndDoorAssignment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('SchedulingAndDoorAssignment') && this.customer.SchedulingAndDoorAssignment != null ? this.customer.SchedulingAndDoorAssignment : false,
            }),
            SecurityFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Security Fee',
                name: 'SecurityFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.SecurityFee || 0,
            }),
            SkipPayment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skip Payment',
                name: 'SkipPayment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('SkipPayment') && this.customer.SkipPayment != null ? this.customer.SkipPayment : false,
            }),
            TwicFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Fee',
                name: 'TwicFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.TwicFee || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.customer && this.customer.hasOwnProperty('Archived') && this.customer.Archived != null ? this.customer.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AvailableForCheckIn: new DynamicLabel({
                label: 'Available For Check In',
                value: this.customer && this.customer.hasOwnProperty('AvailableForCheckIn') && this.customer.AvailableForCheckIn != null ? this.customer.AvailableForCheckIn : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CanSchedulePerDockLocation: new DynamicLabel({
                label: 'Can Schedule Per Dock Location',
                value: this.customer && this.customer.hasOwnProperty('CanSchedulePerDockLocation') && this.customer.CanSchedulePerDockLocation != null ? this.customer.CanSchedulePerDockLocation : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerNumber: new DynamicLabel({
                label: 'Customer Number',
                value: this.customer && this.customer.hasOwnProperty('CustomerNumber') && this.customer.CustomerNumber != null ? this.customer.CustomerNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerPickUpHoursId: new DynamicLabel({
                label: 'Customer Pick Up Hours',
                value: getMetaItemValue(this.customerPickUpHours as unknown as IMetaItem[], this.customer && this.customer.hasOwnProperty('CustomerPickUpHoursId') ? this.customer.CustomerPickUpHoursId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultEndTime: new DynamicLabel({
                label: 'Default End Time',
                value: this.customer && this.customer.hasOwnProperty('DefaultEndTime') && this.customer.DefaultEndTime != null ? this.customer.DefaultEndTime.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultStartTime: new DynamicLabel({
                label: 'Default Start Time',
                value: this.customer && this.customer.hasOwnProperty('DefaultStartTime') && this.customer.DefaultStartTime != null ? this.customer.DefaultStartTime.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayFri: new DynamicLabel({
                label: 'Default Weekday Fri',
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayFri') && this.customer.DefaultWeekdayFri != null ? this.customer.DefaultWeekdayFri : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayMon: new DynamicLabel({
                label: 'Default Weekday Mon',
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayMon') && this.customer.DefaultWeekdayMon != null ? this.customer.DefaultWeekdayMon : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdaySat: new DynamicLabel({
                label: 'Default Weekday Sat',
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdaySat') && this.customer.DefaultWeekdaySat != null ? this.customer.DefaultWeekdaySat : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdaySun: new DynamicLabel({
                label: 'Default Weekday Sun',
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdaySun') && this.customer.DefaultWeekdaySun != null ? this.customer.DefaultWeekdaySun : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayThu: new DynamicLabel({
                label: 'Default Weekday Thu',
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayThu') && this.customer.DefaultWeekdayThu != null ? this.customer.DefaultWeekdayThu : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayTue: new DynamicLabel({
                label: 'Default Weekday Tue',
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayTue') && this.customer.DefaultWeekdayTue != null ? this.customer.DefaultWeekdayTue : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayWed: new DynamicLabel({
                label: 'Default Weekday Wed',
                value: this.customer && this.customer.hasOwnProperty('DefaultWeekdayWed') && this.customer.DefaultWeekdayWed != null ? this.customer.DefaultWeekdayWed : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DispatcherEmail: new DynamicLabel({
                label: 'Dispatcher Email',
                value: this.customer && this.customer.hasOwnProperty('DispatcherEmail') && this.customer.DispatcherEmail != null ? this.customer.DispatcherEmail.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                value: this.customer && this.customer.hasOwnProperty('Name') && this.customer.Name != null ? this.customer.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedsAppointmentToCheckIn: new DynamicLabel({
                label: 'Needs Appointment To Check In',
                value: this.customer && this.customer.hasOwnProperty('NeedsAppointmentToCheckIn') && this.customer.NeedsAppointmentToCheckIn != null ? this.customer.NeedsAppointmentToCheckIn : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NonTwicFee: new DynamicLabel({
                label: 'Non Twic Fee',
                value: this.customer && this.customer.NonTwicFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            NoOfPickupsPerHour: new DynamicLabel({
                label: 'No Of Pickups Per Hour',
                value: this.customer && this.customer.NoOfPickupsPerHour || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            OrderWeight: new DynamicLabel({
                label: 'Order Weight',
                value: this.customer && this.customer.OrderWeight || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            PalletCount: new DynamicLabel({
                label: 'Pallet Count',
                value: this.customer && this.customer.PalletCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ParsNumberRequired: new DynamicLabel({
                label: 'Pars Number Required',
                value: this.customer && this.customer.hasOwnProperty('ParsNumberRequired') && this.customer.ParsNumberRequired != null ? this.customer.ParsNumberRequired : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            SchedulingAndDoorAssignment: new DynamicLabel({
                label: 'Scheduling And Door Assignment',
                value: this.customer && this.customer.hasOwnProperty('SchedulingAndDoorAssignment') && this.customer.SchedulingAndDoorAssignment != null ? this.customer.SchedulingAndDoorAssignment : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            SecurityFee: new DynamicLabel({
                label: 'Security Fee',
                value: this.customer && this.customer.SecurityFee || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            SkipPayment: new DynamicLabel({
                label: 'Skip Payment',
                value: this.customer && this.customer.hasOwnProperty('SkipPayment') && this.customer.SkipPayment != null ? this.customer.SkipPayment : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TwicFee: new DynamicLabel({
                label: 'Twic Fee',
                value: this.customer && this.customer.TwicFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
        };

    }
}
