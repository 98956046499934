import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { ClosedDateService } from '../services/closed-date.service';

@Component({
    selector: 'app-closed-date-header',
    templateUrl: './closed-date-header.component.html',
})
export class ClosedDateHeaderComponent implements OnInit {
    id: number;
    header$: Observable<string>;
    readonly closedDateService = inject(ClosedDateService);
    readonly route = inject(ActivatedRoute);

    constructor() {
        this.id = +(this.route.snapshot.paramMap.get('closedDateId') ?? 0);
    }

    ngOnInit(): void {
        this.closedDateService.setInitialTitle(this.id);
        this.header$ = this.closedDateService.title$;
    }
}
