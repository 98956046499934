import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { TruckersEntityListConfig } from './truckers.entity-list-config';
import { TruckerService } from '../trucker.service';
import { CommonFunctions } from '../../check-ins/check-in-search-classes/check-in-search.class';
import { IUser } from '../../model/interfaces/user';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';

@Component({
    selector: 'app-truckers',
    templateUrl: './truckers.component.html',
})
export class TruckersComponent implements OnInit {
    @ViewChild('tref', { static: true }) tref: ElementRef;
    searchControl = new UntypedFormControl();
    includedArchived = 0;
    fortnightlyUpdatedTwic = 0;
    truckers: IUser[];
    total: number;
    currentPage = 1;
    query = '';
    entityListConfig = new TruckersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    canAddTrucker = false;
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    selectedTwicVerified: number[] = [];
    constructor(
        private truckerService: TruckerService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canAddTrucker = this.claimsService.hasClaim(ClaimTypes.Truckers, [ClaimValues.FullAccess]);
        if (!this.canAddTrucker) {
            this.entityListConfig.delete = null;
        }
        this.getTruckers();
        this.buildSearchBarAndFilters();
    }


    getTruckers(): void {
        const _extraSearchParams: ExtraSearchParams[] = [];
        const search = this.query;
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includedArchived ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'TwicVerified',
                valueArray: this.selectedTwicVerified,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'FortnightlyUpdatedTwic',
                value: this.fortnightlyUpdatedTwic ? '1' : '0',
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.truckerService.get(searchparams).subscribe((answer) => {
            this.truckers = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(evt: DynamicSearchValues): void {
        this.query = evt.Searchbar as string;
        this.includedArchived = evt.IncludedArchived ? 1 : 0;
        this.fortnightlyUpdatedTwic = evt.FortnightlyUpdatedTwic ? 1 : 0;
        if (evt.TwicVerified) {
            this.selectedTwicVerified = (evt.TwicVerified as IMetaItem[]).map(x => x.Id);
        }
        this.getTruckers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        if (this.order === 'TWIC Verified') {
            this.order = 'TwicExpirationDate';
        }
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getTruckers();
    }

    truckerSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/truckers', event.entity.Id]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        event.entity["Archived"] = !event.entity["Archived"];
        this.truckerService.updateVersion(event.entity as IUser).subscribe(
            () => {
                this.getTruckers();
                this.notificationsService.success('Deleted');
            },
            () => {
                this.getTruckers();
                this.notificationsService.error('Delete Failed');
            },
        );
    }

    getTruckersCsv(): void {
        this.truckerService.getTruckersCsv().subscribe((answer) => {
            this.flushFile(answer, 'truckers.csv');
        });
    }

    flushFile(data: string, outputFileName: string): void {
        const blobCreator = new CommonFunctions();
        const downloadLink = this.tref.nativeElement;
        const blob = blobCreator.createBlob(data, 'text/csv', true);
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = outputFileName;
        downloadLink.click();
    }

    buildSearchBarAndFilters(): void {
        const yesAndNo = [{Id: 1, Name: 'Yes'}, {Id: 2, Name: 'No'}];
        const commonFunctions = new CommonFunctions();
        this.dynamicSearchFiltersConfig = [{
            Searchbar: {
                label: 'Search',
                type: DynamicSearchFilterTypes.Searchbar,
            },
            IncludedArchived: {
                label: ' Include Archived',
                type: DynamicSearchFilterTypes.Checkbox,
                options: {
                    selectOptions: commonFunctions.mapMtSearchFilterItems(yesAndNo, 'Name')
                },
            },
            TwicVerified: {
                label: 'TWIC Verified',
                type: DynamicSearchFilterTypes.Select,
                options: {
                    selectOptions: commonFunctions.mapMtSearchFilterItems(yesAndNo, 'Name')
                },
            },
            FortnightlyUpdatedTwic: {
                label: ' Newly created or updated TWIC card uploads',
                type: DynamicSearchFilterTypes.Checkbox,
                options: {
                    selectOptions: commonFunctions.mapMtSearchFilterItems(yesAndNo, 'Name')
                },
            },
        }];
    }
}
