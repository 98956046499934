import { IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from "@mt-ng2/entity-list-module";
import { IRegistrationRequest } from "../../model/interfaces/registration-request";
import { Component, OnInit } from "@angular/core";
import { RegistrationRequestStatuses } from "../../model/RegistrationRequestStatuses";
import { RegistrationRequestService } from "../registration-request.service";
import { NotificationsService } from "@mt-ng2/notifications-module";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    styles: [`.hidden { visibility: hidden; }`],
    template: `
    <span (click)="$event.stopImmediatePropagation()">
        <div style="min-height: 30px">
            <button (click)="generateContact()" [ngClass]="isPending ? 'btn btn-primary': 'btn btn-primary disabled hidden'" title="Accept Request">
                <i class="fa fa-thumbs-up"></i> Accept
            </button>
            <button (click)="rejectRequest()" [ngClass]="isPending ? 'btn btn-danger': 'btn btn-danger disabled hidden'" title="Reject Request">
                <i class="fa fa-thumbs-down"></i> Reject
            </button>
        </div>
    </span>
    `,
})
export class RegistrationRequestActionsDynamicCellComponent implements IEntityListDynamicCellComponentGeneric<IRegistrationRequest>, OnInit{
    entity: IRegistrationRequest;
    entityListComponentMembers: IEntityListComponentMembers;
    isPending: boolean;
    errorMessage: string;

    constructor(
        private registrationRequestService: RegistrationRequestService,
        private notificationService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.isPending = this.entity.StatusId === RegistrationRequestStatuses.Pending;
    }

    acceptRequest(contactId: number): void {
        if (this.entity.StatusId !== RegistrationRequestStatuses.Approved && this.isPending) {
            this.registrationRequestService.approve(this.entity.Id, contactId).subscribe(() => {
                this.notificationService.success(`Application for ${this.entity.FirstName} ${this.entity.LastName} has been approved`);
                location.reload();
            });
        }
    }

    rejectRequest(): void {
        if (this.entity.StatusId !== RegistrationRequestStatuses.Rejected && this.isPending) {
            this.registrationRequestService.deny(this.entity.Id).subscribe(() => {
                this.notificationService.warning(`Application for ${this.entity.FirstName} ${this.entity.LastName} has been rejected`);
                location.reload();
            });
        }
    }

    generateContact(): void {
        if(this.isPending) {
            this.registrationRequestService.generateContact(this.entity).subscribe((answer) => {
                this.notificationService.success('Account has been created!');
                this.acceptRequest(answer);
            },
            (error: HttpErrorResponse) => {
                this.errorMessage = error.error.toString().split('\r\n')[0] ?? 'An unknown error occurred when trying to create the contact';
                this.notificationService.error(this.errorMessage);
            });
        }
    }
}
