import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPickup } from '../../model/interfaces/pickup';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';

@Component({
    selector: 'app-driver-checked-in',
    templateUrl: './driver-checked-in.component.html',
    styleUrls: ['./driver-checked-in.component.css'],
})
export class DriverCheckedInComponent implements OnInit {
    driver: IUser;
    pickup: IPickup;
    constructor(private router: Router, private checkInService: PublicCheckInService,) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            if (!driver) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            } 
        });

        this.checkInService.checkedInPickup$.subscribe((pickup) => {
            this.pickup = pickup;
        });
    }
}
