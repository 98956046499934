import { IEntityListConfig, EntityListConfig, EntityListColumn, SelectTypes, IEntityListSelectColumn } from '@mt-ng2/entity-list-module';
import { UserRoles } from '../../../model/UserRoles';
import { IOrder } from '../../../model/interfaces/order';
import { OrderService } from '../../order.service';
import { IModalWrapperApi } from '@mt-ng2/modal-module';

export class OrdersEntityListConfig extends EntityListConfig {
    private zero = 0;
    constructor(authUserRole: number, orderService: OrderService, orderTotalPalletDialogApi: IModalWrapperApi, orderTotalWeightDialogApi: IModalWrapperApi) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['OrderNumber'],
                    name: 'Order Number',
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order?.Warehouse?.Office?.Title ?? '';
                    },
                    name: 'Office',
                    sort: { sortProperty: 'Warehouse.Office.Title' }
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order?.Warehouse?.WarehouseId ?? '';
                    },
                    name: 'Warehouse',
                    sort: { sortProperty: 'Warehouse.WarehouseId' }
                }),
            ]
        };

        if (authUserRole === UserRoles.Carrier || authUserRole === UserRoles.Admin) {
            listConfig.columns.push(new EntityListColumn({
                accessorFunction: (order: IOrder): string => {
                    return order?.Customer?.Name ?? '';
                },
                name: 'Customer',
                sort: { sortProperty: 'Customer.Name' }
            }));
        }

        listConfig.columns.push(new EntityListColumn({
            accessors: ['DateCreated'],
            name: 'Date Created',
            pipes: ['date:medium'],
        }));

        if (authUserRole === UserRoles.Admin) {
            listConfig.columns.push(new EntityListColumn({
                accessorFunction: (order: IOrder): string => {
                    return `${order?.TotalPallet ?? this.zero}`;
                },
                name: 'Pallet Count',
                sort: { sortProperty: 'TotalPallet' },
                linkFunction: (order: IOrder) => {
                    orderService.selectedOrder$.next(order);
                    orderTotalPalletDialogApi.show();
                }
            }));
    
            listConfig.columns.push(new EntityListColumn({
                accessorFunction: (order: IOrder): string => {
                    return `${order?.TotalWeight?.toFixed(2) ?? this.zero.toFixed(2)} lbs`;
                },
                name: 'Order Weight',
                sort: { sortProperty: 'TotalWeight' },
                linkFunction: (order: IOrder) => {
                    orderService.selectedOrder$.next(order);
                    orderTotalWeightDialogApi.show();
                }
            }));
        } else {
            listConfig.columns.push(new EntityListColumn({
                accessorFunction: (order: IOrder): string => {
                    return `${order?.TotalPallet ?? this.zero}`;
                },
                name: 'Pallet Count',
                sort: { sortProperty: 'TotalPallet' }
            }));
    
            listConfig.columns.push(new EntityListColumn({
                accessorFunction: (order: IOrder): string => {
                    return `${order?.TotalWeight?.toFixed(2) ?? this.zero.toFixed(2)} lbs`;
                },
                name: 'Order Weight',
                sort: { sortProperty: 'TotalWeight' }
            }));
        }

        listConfig.select = { type: SelectTypes.Multi } as IEntityListSelectColumn;
        super(listConfig);
    }
}
