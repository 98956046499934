import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ICarrier } from '../../model/interfaces/carrier';
import { CarrierDynamicConfig } from '../carrier.dynamic-config';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { CarrierService } from '../services/carrier.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { Router } from '@angular/router';

@Component({
    selector: 'app-carrier-basic-info',
    templateUrl: './carrier-basic-info.component.html',
})
export class CarrierBasicInfoComponent implements OnInit {
    @Input() carrier: ICarrier;
    @Input() canEdit: boolean;
    canAdd: boolean;
    isEditing: boolean;
    isHovered: boolean;
    doubleClickIsDisabled = false;
    formFactory: CarrierDynamicConfig<ICarrier>;
    formObject: DynamicField[] = [];
    viewOnly: DynamicLabel[] = [];

    get isNewCarrier(): boolean {
        return this.carrier?.Id ? false : true;
    }

    constructor(
        private carrierService: CarrierService, 
        private notificationsService: NotificationsService, 
        private claimsService: ClaimsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Carriers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.isEditing = false;
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new CarrierDynamicConfig<ICarrier>(this.carrier);
        if (this.isNewCarrier) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.carrier.Id === 0) {
            void this.router.navigate(['/carriers']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.carrier, form.value.Carrier as ICarrier);
            if (!this.carrier.Id || this.carrier.Id === 0) {
                this.carrierService
                    .create(this.carrier).subscribe((res) => {
                        this.isEditing = false;
                        this.success();
                        void this.router.navigate(['/carriers', res]);
                    });
            } else {
                this.carrierService
                    .update(this.carrier).subscribe(() => {
                        this.isEditing = false;
                        this.setConfig();
                        this.success();
                    });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Carrier saved successfully.');
    }
}
