import { ModuleWithProviders, NgModule } from '@angular/core';
import { CheckInsComponent } from './check-in-list/checkins.component';
import { PickupGatePassDialogComponent } from './check-in-list/pickup-gatepass-number-dialog/pickup-gatepass-number-dialog.component';
import { DeliveryGatePassDialogComponent } from './check-in-list/delivery-gatepass-number-dialog/delivery-gatepass-number-dialog.component';
import { SharedModule } from '../common/shared.module';
import { CheckInsRoutingModule } from './check-ins-routing.module';
import { CheckInService } from './services/check-ins.service';
import { CheckInMonitoringDetailComponent } from './check-in-monitoring-detail/check-in-monitoring-detail.component';
import { CheckInMonitoringHeaderComponent } from './check-in-monitoring-header/check-in-monitoring-header.component';
import { CheckInMonitoringOrderInfoComponent } from './check-in-monitoring-order-info/check-in-monitoring-order-info.component';
import { CheckInMonitoringTruckerInfoComponent } from './check-in-monitoring-trucker-info/check-in-monitoring-trucker-info.component';
import { CheckInMonitoringTruckerGuestInfoComponent } from './check-in-monitoring-trucker-guest-info/check-in-monitoring-trucker-guest-info.component';
import { NgbTypeaheadModule } from '../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { CheckInReportComponent } from './check-in-report/check-in-report.component';
import { CheckInDoorSelectionDialogComponent } from './check-in-door-selection-dialog/check-in-door-selection-dialog.component';
import { CheckInDetailService } from './services/check-in-detail.service';
import { GatePassDialogComponent } from './gate-pass-dialog/gate-pass-dialog.component';

@NgModule({
    declarations: [
        CheckInsComponent,
        CheckInMonitoringDetailComponent,
        CheckInMonitoringHeaderComponent,
        CheckInMonitoringOrderInfoComponent,
        CheckInMonitoringTruckerInfoComponent,
        CheckInMonitoringTruckerGuestInfoComponent,
        CheckInReportComponent,
        CheckInDoorSelectionDialogComponent,
        GatePassDialogComponent,
        PickupGatePassDialogComponent,
        DeliveryGatePassDialogComponent
    ],
    imports: [SharedModule, CheckInsRoutingModule, NgbTypeaheadModule],
})
export class CheckInsModule {
    static forRoot(): ModuleWithProviders<CheckInsModule> {
        return {
            ngModule: CheckInsModule,
            providers: [CheckInService, CheckInDetailService],
        };
    }
}
