import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { ICustomer } from '../model/interfaces/customer';
import { INote } from '@mt-ng2/note-control';
import { IHasNotes } from '@mt-ng2/entity-components-notes';
import { IHasAddresses } from '@mt-ng2/entity-components-addresses';
import { IDocument, IHasDocuments } from '@mt-ng2/entity-components-documents';
import { SearchParams } from '@mt-ng2/common-classes';
import { IAddressContainer } from '@mt-ng2/dynamic-form';
import { ICustomerDoor } from '../model/interfaces/customer-door';
import { catchError } from 'rxjs/operators';
import { IDockLocation } from '../model/interfaces/dock-location';
import { PhoneCollectionDTO } from '../model/interfaces/custom/phone-collection.dto';
import { ICustomerDockLocation } from '../model/interfaces/customer-dock-location';
import { ICustomerForRegistration } from '../model/interfaces/custom/customer-for-registration';

export const emptyCustomer: ICustomer = {
    Archived: false,
    AvailableForCheckIn: false,
    CustomerNumber: '',
    DefaultEndTime: null,
    DefaultStartTime: null,
    DispatcherEmail: '',
    Id: 0,
    Name: null,
    NeedsAppointmentToCheckIn: false,
    NoOfPickupsPerHour: 0,
    NonTwicFee: null,
    OrderWeight: null,
    PalletCount: null,
    ParsNumberRequired: false,
    SchedulingAndDoorAssignment: false,
    SecurityFee: null,
    SkipPayment: false,
    TwicFee: null,
    Version: null,
    DefaultWeekdaySun: true,
    DefaultWeekdayMon: true,
    DefaultWeekdayTue: true,
    DefaultWeekdayWed: true,
    DefaultWeekdayThu: true,
    DefaultWeekdayFri: true,
    DefaultWeekdaySat: true,
};

@Injectable()
export class CustomerService extends BaseService<ICustomer> implements IHasNotes, IHasAddresses, IHasDocuments {
    constructor(public http: HttpClient) {
        super('/customers', http);
    }

    getEmptyCustomer(): ICustomer {
        return { ...emptyCustomer };
    }

    getSimpleCustomers(): Observable<ICustomer[]> {
        return this.http.get<ICustomer[]>(`/customers/simple/`);
    }

    getCustomersByQuery(query: string): Observable<ICustomer[]> {
        return this.http.get<ICustomer[]>(`/customers/query/${query}`);
    }

    getCustomerForManageScheduling(): Observable<ICustomer[]> {
        return this.http.get<ICustomer[]>(`/customers/manage-scheduling`);
    }

    deleteCustomer(customerId: number): Observable<object> {
        return this.http.delete(`/customers/delete/${customerId}`, {
            responseType: 'text' as 'json',
        });
    }

    getActiveCustomers(): Observable<ICustomer[]> {
        return this.http.get<ICustomer[]>(`/customers/active`);
    }

    getActiveCustomersForRegistration(): Observable<ICustomerForRegistration[]> {
        return this.http.get<ICustomer[]>(`/customers/active-for-registration`);
    }

    // not sure where this belongs
    saveCustomerPhones(customerId: number, phoneCollection: PhoneCollectionDTO): Observable<void> {
        return this.http.put<void>(`/customers/${customerId}/phones`, phoneCollection, { responseType: 'text' as 'json' });
    }

    // BEGIN Notes section
    getNotes(customerId: number, searchparameters: SearchParams): Observable<HttpResponse<INote[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<INote[]>(`/customers/${customerId}/notes/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    saveNote(customerId: number, note: INote): Observable<number> {
        if (!note.Id) {
            note.Id = 0;
            return this.http.post<number>(`/customers/${customerId}/notes`, note);
        } else {
            return this.http.put<number>(`/customers/${customerId}/notes`, note, { responseType: 'text' as 'json' });
        }
    }

    deleteNote(customerId: number, noteId: number): Observable<object> {
        return this.http.delete(`/customers/${customerId}/notes/${noteId}`, {
            responseType: 'text' as 'json',
        });
    }
    // END Notes section

    // BEGIN Addresses section
    getAddresses(customerId: number, searchparameters: SearchParams): Observable<HttpResponse<IAddressContainer[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IAddressContainer[]>(`/customers/${customerId}/addresses/_search`, { observe: 'response', params: params })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    saveAddress(customerId: number, address: IAddressContainer): Observable<number> {
        if (!address.AddressId) {
            address.AddressId = 0;
            address.Address.Id = 0;
            return this.http.post<number>(`/customers/${customerId}/addresses`, address);
        } else {
            return this.http.put<number>(`/customers/${customerId}/addresses/${address.AddressId}`, address, { responseType: 'text' as 'json' });
        }
    }

    deleteAddress(customerId: number, addressId: number): Observable<object> {
        return this.http.delete(`/customers/${customerId}/addresses/${addressId}`, { responseType: 'text' as 'json' });
    }
    // END Addresses section

    // BEGIN Documents section
    getDocument(customerId: number, docId: number): Observable<Blob> {
        return this.http.get(`/customers/${customerId}/documents/${docId}`, {
            responseType: 'blob' as const,
        });
    }

    getDocuments(customerId: number, searchparameters: SearchParams): Observable<HttpResponse<IDocument[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IDocument[]>(`/customers/${customerId}/documents/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    saveDocument(customerId: number, file: File): Observable<IDocument> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post<IDocument>(`/customers/${customerId}/documents`, formData);
    }

    deleteDocument(customerId: number, docId: number): Observable<object> {
        return this.http.delete(`/customers/${customerId}/documents/${docId}`, {
            responseType: 'text' as 'json',
        });
    }
    // END Documents section

    // BEGIN Doors section

    getCustomerDoors(customerId: number): Observable<ICustomerDoor[]> {
        return this.http.get<ICustomerDoor[]>(`/customers/${customerId}/doors`);
    }

    saveCustomerDoors(customerId: number, customerDoors: ICustomerDoor[]): Observable<object> {
        return this.http.put(`/customers/${customerId}/doors`, customerDoors);
    }

    removeCustomerDoors(customerId: number): Observable<void> {
        return this.http.get<void>(`/customers/${customerId}/removedoors`);
    }

    // END Doors section

    // BEGIN Dock Locations section

    getDockLocations(): Observable<IDockLocation[]> {
        return this.http.get<IDockLocation[]>(`/customers/all-dock-locations`);
    }

    saveCustomerDockLocations(customerId: number, customerDockLocations: ICustomerDockLocation[]): Observable<object> {
        return this.http.put(`/customers/${customerId}/dock-locations`, customerDockLocations);
    }

    // End Dock Locations section
}
