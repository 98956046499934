import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { sortByProperty } from '@mt-ng2/common-functions';
import { IStatesService, IState, ICountriesService, ICountry } from '@mt-ng2/dynamic-form';
import { tap } from 'rxjs/operators';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { IPickup } from '../../model/interfaces/pickup';
import { PickupService } from '../../model/shared-entities/pickups/pickup.service';
import { ModalService } from '@mt-ng2/modal-module';
import { SweetAlertResult } from 'sweetalert2';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Injectable()
export class CommonService implements IStatesService, ICountriesService {
    private _states: IState[];
    private _countries: ICountry[];

    constructor(
        private http: HttpClient,
        private pickupService: PickupService,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
    ) {}

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    const indexOfUS = answer.findIndex((country) => country.CountryCode === 'US');
                    answer.splice(0, 0, answer.splice(indexOfUS, 1)[0]);
                    this._countries = answer;
                }),
            );
        } else {
            return of(this._countries);
        }
    }

    phoneNumberValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
          const phoneNumber = control.value as string;
          const valid = /^[0-9]{10}$/.test(phoneNumber);
          return valid ? null : { 'forbiddenNumber': true };
        };
    }

    firstAndLastNameValidator(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: object} | null => {
            const forbidden = /^[a-zA-Z']+$/.test(control.value as string);
            return !forbidden ? {'forbiddenName': {value: control.value}} : null;
        };
    }

    checkForAlphaNumericCharacters(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: object} | null => {
            const forbidden = /^[a-zA-Z0-9]*$/.test(control.value as string);
            return !forbidden ? {'forbiddenNumber': {value: control.value}} : null;
        };
    }

    cancelPickup(pickup: IPickup): void {
        const totalPrePayment = this.totalPickupPrePayment(pickup);
        let text = `Are you sure you want to cancel this pickup?`;
        if (totalPrePayment > 0)
            text = `This pickup has been prepaid. Are you sure you want to cancel this pickup?.`;

        this.pickupService.isWithinAppointmentTimeframe(pickup.Id).subscribe({
            next: () => {
                this.modalService.showModal({
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, Cancel Pickup',
                    showCancelButton: true,
                    cancelButtonText: `No, Go Back`,
                    text: text,
                    title: 'Cancel Pickup',
                    icon: 'warning',
                }).subscribe((result: SweetAlertResult) => {
                    if (!result.isConfirmed)
                        return;

                    this.pickupService.cancel(pickup.Id).subscribe({
                        next: () => {
                            this.modalService.showModal({
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK',
                                title: 'Pickup has been canceled.',
                                icon: 'success',
                            }).subscribe(() => {
                                location.reload();
                            });
                        }
                    });
                });
            }
        });
    }

    private totalPickupPrePayment(pickup: IPickup): number {
        if (!pickup.PickupPaymentDetails)
            return 0;

        return pickup.PickupPaymentDetails
            .filter(d => d.IsSuccessful)
            .reduce((sum, cur) => sum + cur.Amount, 0);
    }

    handleErrorResponse(errorResponse: HttpErrorResponse): void {
        if (typeof(errorResponse.error) === 'string') {
            this.notificationsService.error(errorResponse.error);
            return;
        }

        const modelState = errorResponse.error['ModelState'];
        if (modelState) {
            this.notificationsService.error(modelState[0] as string);
            return
        }

        this.notificationsService.error('An error occurred. Please ask your administrator for help.');
    }
}
