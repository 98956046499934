import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { CheckInService } from '../services/check-ins.service';
import { TruckerService } from '../../truckers/trucker.service';
import { finalize } from 'rxjs/operators';
import { IUser } from '../../model/interfaces/user';
import { UserDynamicConfig } from '../../users/user.dynamic-config';
import { CarrierService } from '../../carriers/services/carrier.service';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-check-in-monitoring-trucker-info',
    templateUrl: './check-in-monitoring-trucker-info.component.html',
})
export class CheckInMonitoringTruckerInfoComponent implements OnInit {
    @Input() trucker: IUser;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;

    constructor(
        private checkInService: CheckInService,
        private truckerService: TruckerService,
        private notificationsService: NotificationsService,
        private router: Router,
        private carrierService: CarrierService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.setConfig();
    }

    setConfig(): void {
        this.carrierService.getActive().subscribe((carriers) => {
            this.formFactory = new UserDynamicConfig<IUser>(this.trucker, carriers);
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.trucker.Id === 0) {
            void this.router.navigate(['/checkins']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.trucker, form.value.User as IUser);
            // handle existing trucker account save
            this.truckerService.update(this.trucker)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe(
                    () => {
                        this.isEditing = false;
                        this.success();
                        this.setConfig();
                    },
                    () => {
                        markAllFormFieldsAsTouched(form);
                        this.error();
                    },
                );
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
