import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CustomerService } from '../../customers/customer.service';
import { CheckInService } from '../services/check-ins.service';
import { CheckInQueueItem } from '../../model/classes/check-in-queue-item';
import { CheckInDetailDoorSelection } from '../../model/classes/check-in-detail-door-selection';
import { PickupDoorPayload } from '../../model/classes/pickup-door-payload';
import { NgForm } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { CheckInTypes } from '../../model/CheckInTypes';
import { WarehouseService } from '../../warehouses/services/warehouse.service';

@Component({
    selector: 'check-in-door-selection-dialog',
    styles: [
        `
            .validator-msg {
                color: red;
            }
        `,
    ],
    templateUrl: './check-in-door-selection-dialog.component.html',
})
export class CheckInDoorSelectionDialogComponent implements OnInit {
    customerId: number;
    doubleClickIsDisabled = false;
    checkInDetailDoorItems: CheckInDetailDoorSelection[] = [];
    gatePassId: string;
    checkInTypes = CheckInTypes;

    @Input('checkin') checkinQueueItem: CheckInQueueItem;
    @Output('onClose') onClose: EventEmitter<void> = new EventEmitter<void>();
    constructor(
        private customerService: CustomerService,
        private notificationsService: NotificationsService,
        private checkInService: CheckInService,
        private warehouseService: WarehouseService,
    ) {}

    ngOnInit(): void {
        this.checkInDetailDoorItems = [];
        if (this.checkinQueueItem && this.checkinQueueItem !== null) {
            this.checkinQueueItem.CheckInQueueItemDetails.forEach((cd) => {
                const newDoorSelection: CheckInDetailDoorSelection = {
                    CheckInDetailId: cd.Id,
                    OfficeDoor: null,
                    OfficeDoors: [],
                    CustomerId: cd.CustomerId,
                    CustomerSchedulingAndDoorAssignment: cd.CustomerSchedulingAndDoorAssignment,
                    OrderNo: cd.OrderNo,
                };
                this.checkInDetailDoorItems.push(newDoorSelection);
                if (cd.CustomerId && cd.CustomerSchedulingAndDoorAssignment) {
                    this.warehouseService.getOfficeDoors(cd.Warehouse.Id).subscribe((answer) => {
                        newDoorSelection.OfficeDoors = answer;
                    });
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.checkinQueueItem) {
            this.ngOnInit();
        }
    }

    formSubmitted(form: NgForm): void {
        if (form.invalid) {
            markAllFormFieldsAsTouched(form.form);
            return;
        }
        
        const pickupDoorPayload: PickupDoorPayload[] = this.checkInDetailDoorItems.map((c) => ({
            CheckinDetailId: c.CheckInDetailId,
            OfficeDoor: c.OfficeDoor,
            CustomerDoor: null,
        }));
        
        this.checkInService
            .notifyDriverWithPickUpDetails(this.checkinQueueItem.CheckInInfo.Id, pickupDoorPayload, this.gatePassId)            .subscribe(
                (answer) => {
                    if (String(answer) === 'true') {
                        this.notificationsService.success('Driver Notified');
                    } else {
                        this.notificationsService.error('Failed to notify driver');
                    }
                    this.close();
                },
                () => {
                    this.notificationsService.error('Notifying Driver Failed');
                    this.close();
                },
            );
    }

    close(): void {
        this.onClose.emit();
    }
}
