import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';
import { IPickup } from '../../../model/interfaces/pickup';
import { Router } from '@angular/router';
import { PickupService } from '../../../model/shared-entities/pickups/pickup.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '@mt-ng2/modal-module';
import { SweetAlertResult } from 'sweetalert2';
import { UserRoles } from '../../../model/UserRoles';
import { AuthService } from '@mt-ng2/auth-module';
import { OrderService } from '../../order.service';

@Component({
    template: `
        <div *ngIf="loaded">
            <ng-container>
                <button *ngIf="showEditButton" (click)="pickupSelected()" class="btn btn-success btn-sm">Edit</button>
            </ng-container>
            <ng-container>
                <button (click)="pickupCanceled()" class="btn btn-success btn-sm">Cancel </button>
            </ng-container>
        </div>
    `,
})
export class EditPickupButtonComponent implements OnInit, IEntityListDynamicCellComponentGeneric<IPickup> {
    entityListComponentMembers: IEntityListComponentMembers;
    entity: IPickup;
    loaded: boolean;
    showEditButton: boolean;

    constructor(
        private router: Router,
        private pickupService: PickupService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private authService: AuthService,
        private orderService: OrderService,
        private cdRef: ChangeDetectorRef
    ) { }
    
    ngOnInit(): void {
        const currentUser = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(currentUser.AuthId).subscribe(authUserRole => {
            this.hideEditButtonIfNeeded(authUserRole);
            this.loaded = true;
            this.cdRef.detectChanges();
        });
    }

    pickupSelected(): void {
        this.pickupService.isWithinAppointmentTimeframe(this.entity['Id'])
            .subscribe(() => {
                void this.router.navigate(['/create-requests/order-selection', this.entity['Id']]);
            },
            (errorResponse: HttpErrorResponse) => {
                if (typeof (errorResponse.error) === 'string') {
                    this.notificationsService.error(errorResponse.error);
                } else {
                    this.notificationsService.error(errorResponse.error['ModelState'][0] as string);
                }
            });
    }

    pickupCanceled(): void {
        this.pickupService.isWithinAppointmentTimeframe(this.entity['Id'])
            .subscribe(() => {
                this.modalService.showModal({
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    text: `Are you sure you want to cancel this pickup?`,
                    title: 'Cancel Pickup',
                    icon: 'warning',
                })
                .subscribe((result: SweetAlertResult) => {
                    if (!result.isConfirmed)
                        return;

                    this.pickupService.cancel(this.entity['Id'])
                        .subscribe(() => {
                            this.modalService.showModal({
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK',
                                title: 'Pickup has been canceled.',
                                icon: 'success',
                            }).subscribe(() => {
                                location.reload();
                            });
                        },
                        (errorResponse: HttpErrorResponse) => {
                            if (typeof (errorResponse.error) === 'string') {
                                this.notificationsService.error(errorResponse.error);
                            } else {
                                this.notificationsService.error(errorResponse.error['ModelState'][0] as string);
                            }
                        });
                });
            },
            (errorResponse: HttpErrorResponse) => {
                if (typeof (errorResponse.error) === 'string') {
                    this.notificationsService.error(errorResponse.error);
                } else {
                    this.notificationsService.error(errorResponse.error['ModelState'][0] as string);
                }
            });
    }

    hideEditButtonIfNeeded(authUserRoleId: number): void {
        // As a Holt Admin, hide the edit button if there is no driver AND the customer created the pickup. 
        const isHoltAdmin = authUserRoleId === UserRoles.Admin;
        const createdByCustomer = this.entity.CreatedBy?.AuthUser?.RoleId === UserRoles.Customer

        if (isHoltAdmin && !this.entity.Driver && createdByCustomer) {
            this.showEditButton = false;
        } else {
            this.showEditButton = true;
        }
    }
}
