import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IOfficePickupOverride } from '../interfaces/office-pickup-override';
import { IOffice } from '../interfaces/office';

export interface IOfficePickupOverrideDynamicControlsParameters {
    formGroup?: string;
    offices?: IOffice[];
}

export class OfficePickupOverrideDynamicControls {

    formGroup: string;
    offices?: IOffice[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private officepickupoverride?: IOfficePickupOverride, additionalParameters?: IOfficePickupOverrideDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'OfficePickupOverride';
        this.offices = additionalParameters && additionalParameters.offices || undefined;

        this.Form = {
            EndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'End Time',
                name: 'EndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('EndTime') && this.officepickupoverride.EndTime != null ? this.officepickupoverride.EndTime.toString() : '',
            }),
            FromDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'From Date',
                name: 'FromDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.FromDate || null,
            }),
            IsRecurring: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring',
                name: 'IsRecurring',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurring') && this.officepickupoverride.IsRecurring != null ? this.officepickupoverride.IsRecurring : true,
            }),
            IsRecurringFri: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Fri',
                name: 'IsRecurringFri',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringFri') && this.officepickupoverride.IsRecurringFri != null ? this.officepickupoverride.IsRecurringFri : true,
            }),
            IsRecurringMon: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Mon',
                name: 'IsRecurringMon',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringMon') && this.officepickupoverride.IsRecurringMon != null ? this.officepickupoverride.IsRecurringMon : true,
            }),
            IsRecurringSat: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Sat',
                name: 'IsRecurringSat',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringSat') && this.officepickupoverride.IsRecurringSat != null ? this.officepickupoverride.IsRecurringSat : true,
            }),
            IsRecurringSun: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Sun',
                name: 'IsRecurringSun',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringSun') && this.officepickupoverride.IsRecurringSun != null ? this.officepickupoverride.IsRecurringSun : true,
            }),
            IsRecurringThu: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Thu',
                name: 'IsRecurringThu',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringThu') && this.officepickupoverride.IsRecurringThu != null ? this.officepickupoverride.IsRecurringThu : true,
            }),
            IsRecurringTue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Tue',
                name: 'IsRecurringTue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringTue') && this.officepickupoverride.IsRecurringTue != null ? this.officepickupoverride.IsRecurringTue : true,
            }),
            IsRecurringWed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Wed',
                name: 'IsRecurringWed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringWed') && this.officepickupoverride.IsRecurringWed != null ? this.officepickupoverride.IsRecurringWed : true,
            }),
            NoEndDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'No End Date',
                name: 'NoEndDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('NoEndDate') && this.officepickupoverride.NoEndDate != null ? this.officepickupoverride.NoEndDate : true,
            }),
            NoOfPickupsPerHour: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Of Pickups Per Hour',
                name: 'NoOfPickupsPerHour',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.NoOfPickupsPerHour || 0,
            }),
            OfficeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Office',
                name: 'OfficeId',
                options: this.offices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.officepickupoverride && this.officepickupoverride.OfficeId || null,
            }),
            StartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Start Time',
                name: 'StartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('StartTime') && this.officepickupoverride.StartTime != null ? this.officepickupoverride.StartTime.toString() : '',
            }),
            ToDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'To Date',
                name: 'ToDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.ToDate || null,
            }),
        };

        this.View = {
            EndTime: new DynamicLabel({
                label: 'End Time',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('EndTime') && this.officepickupoverride.EndTime != null ? this.officepickupoverride.EndTime.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FromDate: new DynamicLabel({
                label: 'From Date',
                value: this.officepickupoverride && this.officepickupoverride.FromDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            IsRecurring: new DynamicLabel({
                label: 'Is Recurring',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurring') && this.officepickupoverride.IsRecurring != null ? this.officepickupoverride.IsRecurring : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringFri: new DynamicLabel({
                label: 'Is Recurring Fri',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringFri') && this.officepickupoverride.IsRecurringFri != null ? this.officepickupoverride.IsRecurringFri : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringMon: new DynamicLabel({
                label: 'Is Recurring Mon',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringMon') && this.officepickupoverride.IsRecurringMon != null ? this.officepickupoverride.IsRecurringMon : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringSat: new DynamicLabel({
                label: 'Is Recurring Sat',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringSat') && this.officepickupoverride.IsRecurringSat != null ? this.officepickupoverride.IsRecurringSat : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringSun: new DynamicLabel({
                label: 'Is Recurring Sun',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringSun') && this.officepickupoverride.IsRecurringSun != null ? this.officepickupoverride.IsRecurringSun : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringThu: new DynamicLabel({
                label: 'Is Recurring Thu',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringThu') && this.officepickupoverride.IsRecurringThu != null ? this.officepickupoverride.IsRecurringThu : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringTue: new DynamicLabel({
                label: 'Is Recurring Tue',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringTue') && this.officepickupoverride.IsRecurringTue != null ? this.officepickupoverride.IsRecurringTue : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringWed: new DynamicLabel({
                label: 'Is Recurring Wed',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurringWed') && this.officepickupoverride.IsRecurringWed != null ? this.officepickupoverride.IsRecurringWed : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NoEndDate: new DynamicLabel({
                label: 'No End Date',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('NoEndDate') && this.officepickupoverride.NoEndDate != null ? this.officepickupoverride.NoEndDate : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NoOfPickupsPerHour: new DynamicLabel({
                label: 'No Of Pickups Per Hour',
                value: this.officepickupoverride && this.officepickupoverride.NoOfPickupsPerHour || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            OfficeId: new DynamicLabel({
                label: 'Office',
                value: getMetaItemValue(this.offices as unknown as IMetaItem[], this.officepickupoverride && this.officepickupoverride.hasOwnProperty('OfficeId') ? this.officepickupoverride.OfficeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            StartTime: new DynamicLabel({
                label: 'Start Time',
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('StartTime') && this.officepickupoverride.StartTime != null ? this.officepickupoverride.StartTime.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ToDate: new DynamicLabel({
                label: 'To Date',
                value: this.officepickupoverride && this.officepickupoverride.ToDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
        };

    }
}
