import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IDelivery } from '../../model/interfaces/delivery';
import { DeliveryService } from '../services/delivery.service';

@Component({
    selector: 'app-delivery-header',
    templateUrl: './delivery-header.component.html',
})
export class DeliveryHeaderComponent implements OnInit, OnDestroy {
    delivery: IDelivery;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private deliveryService: DeliveryService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.deliveryService.changeEmitted$.subscribe((delivery) => {
                this.setHeader(delivery);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('deliveryId');
        if (id > 0) {
            this.deliveryService.getById(id).subscribe((delivery) => {
                this.setHeader(delivery);
            });
        } else {
            this.header = 'Add Delivery';
            this.delivery = this.deliveryService.getEmptyDelivery();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(delivery: IDelivery): void {
        this.delivery = delivery;
        this.header = `Delivery: ${delivery.DeliveryNumber}`;
    }
}
