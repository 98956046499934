import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { TwicImageTypes } from '../../model/TwicImageTypes';
import { ContactService } from '../contact.service';
import { IContact } from '../../model/interfaces/contact';

@Component({
    selector: 'app-component-detail',
    templateUrl: './contacts-details.component.html',
})
export class ContactDetailComponent implements OnInit {
    contact: IContact;
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    TwicImageTypes = TwicImageTypes;
    previousUrl: string;

    constructor(
        private contactService: ContactService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        // https://stackoverflow.com/a/71296528
        this.previousUrl = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl.toString();
    }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Contacts, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('contactId');
        // try load if id > 0
        if (this.id > 0) {
            this.getContactById(this.id);
        } else {
            // set customer to emptyCustomer
            this.contact = this.contactService.getEmptyContact();
        }
        this.editingComponent.next('');
    }

    getContactById(id: number): void {
        this.contactService.getById(id).subscribe((contact) => {
            this.contact = contact;
        });
    }

    close(): void {
        if (this.previousUrl === '/contacts') {
            void this.router.navigate([this.previousUrl]);
        } else {
            void this.router.navigate([`/contacts`]);
        }
    }
}
