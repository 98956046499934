import { PickupDynamicControls, IPickupDynamicControlsParameters } from '../form-controls/pickup.form-controls';
import { IPickup } from '../interfaces/pickup';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDatepickerOptions, InputTypes } from '@mt-ng2/dynamic-form';

export class PickupDynamicControlsPartial extends PickupDynamicControls {

    constructor(pickupPartial?: IPickup, additionalParameters?: IPickupDynamicControlsParameters) {
        super(pickupPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.CarrierId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CreatedById).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CustomerId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateCreated).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateModified).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateRequested).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DeliveryZipCode).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DriverId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ModifiedById).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.PickupNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.StatusId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ValidTwicCard).type.fieldType = DynamicFieldTypes.Input;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.CarrierId).label = 'Some other Label';
        // (<DynamicLabel>this.View.CreatedById).label = 'Some other Label';
        // (<DynamicLabel>this.View.CustomerId).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateCreated).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateModified).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateRequested).label = 'Some other Label';
        // (<DynamicLabel>this.View.DeliveryZipCode).label = 'Some other Label';
        // (<DynamicLabel>this.View.DriverId).label = 'Some other Label';
        // (<DynamicLabel>this.View.ModifiedById).label = 'Some other Label';
        // (<DynamicLabel>this.View.PickupNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.StatusId).label = 'Some other Label';
        // (<DynamicLabel>this.View.ValidTwicCard).label = 'Some other Label';

        const today = new Date();
        const datepickerOptions: IDatepickerOptions = { minDate: { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() } };

        this.Form = {
            DateRequested: new DynamicField({
                formGroup: '',
                label: '',
                labelHtml: `<i class="fa fa-lg fa-fw fa-calendar"></i> <b>Select Date</b>:`,
                name: 'DateRequested',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Datepicker, datepickerOptions: datepickerOptions }),
                validators: { required: false },
                value: pickupPartial?.DateRequested ?? today,
            }),
        };
    }
}
