<div *ngIf="driver && driver.Id > 0" class="text-center">
    <h1>TWIC Upload</h1>
    <br />
    <div class="row centered-row">
        <div class="col-md-6 centered-column">
            <div class="miles-card padded">
                <h4>TWIC Image Front</h4>
                <div class="upload-container">
                    <label for="imageInput">
                      <i *ngIf="!driver.TwicFrontImage" class="fa fa-address-card-o"></i>
                      <img *ngIf="driver.TwicFrontImage" src="{{ imagePath }}{{ driver.TwicFrontImage.ImagePath }}" class="thumb" alt="Twic Image" />
                    </label>
                    <input type="file" id="imageInput" (change)="onFileSelected($event, TwicImageTypes.Front)" accept="image/*" hidden>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row centered-row">
        <div class="col-md-6 centered-column">
            <div class="miles-card padded">
                <h4>TWIC Image Back</h4>
                <div class="upload-container">
                    <label for="imageInput">
                      <i *ngIf="!driver.TwicBackImage" class="fa fa-address-card"></i>
                      <img *ngIf="driver.TwicBackImage" src="{{ imagePath }}{{ driver.TwicBackImage.ImagePath }}" class="thumb" alt="Twic Image" />
                    </label>
                    <input type="file" id="imageInput" (change)="onFileSelected($event, TwicImageTypes.Back)" accept="image/*" hidden>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row text-center centered-row">
        <div class="col-md-6 centered-column">
            <form [formGroup]="editDriverForm" class="form-contents" (ngSubmit)="onSubmit()">
                <div>
                    <div>
                        <label for="twicCardNumber">TWIC Card Number:</label>
                        <input type="text" id="twicCardNumber" formControlName="twicCardNumber" required>
                        <div *ngIf="editDriverForm.get('twicCardNumber').errors?.required && editDriverForm.get('twicCardNumber').touched"
                            class="error-message">
                            TWIC Card Number is required.
                        </div>
                        <div *ngIf="editDriverForm.get('twicCardNumber').errors?.maxlength  && editDriverForm.get('twicCardNumber').touched"
                            class="error-message">
                            Twic Card Number should not exceed {{ editDriverForm.get('twicCardNumber').errors?.maxlength.requiredLength }} digits.
                        </div>
                    </div>
                    <br />
                    <div>
                        <label for="twicExpirationDate">TWIC Expiration Date:</label>
                        <input type="date" id="twicExpirationDate" formControlName="twicExpirationDate">
                    </div>
                    <br />
                    <div>
                        <label>TWIC Status: 
                            <span id="twic-verified-status" [ngClass]="{ 'twic-verified': isTwicVerified}">
                                {{ isTwicVerified ? 'Verified' : 'Not Verified' }}
                            </span>
                        </label>
                    </div>
                </div>
                <br />
            </form>
            <br />
            <button 
                type="submit" 
                class="btn btn-primary" 
                (click)="onSubmit()" 
                [disabled]="editDriverForm.invalid || !driver.TwicFrontImage || !driver.TwicBackImage || isUnchanged()">
                Save
            </button>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <span class="new-driver-link--wrapper">
                <div class="new-driver-link">Finished Uploading? Please <a (click)="goToDriverEditPage()">click here</a> to go back to your profile.
                </div>
            </span>
        </div>
    </div>
    <br />
    <app-driver-check-in-support-button [driver]="driver"></app-driver-check-in-support-button>
    <br />
</div>
