<div *ngIf="loading" class="loading-spinner">
    <div class="spinner"></div>
</div>
<div *ngIf="!loading && driver && pickup" class="text-center public-responsive">
    <h1>Edit Pickup Orders</h1>
    <br><br>
    <div class="row text-center centered-row">
        <div class="col-md-6 col-sm-12 centered-column">
            <form (ngSubmit)="searchForOrder()">
                <input 
                    type="text" 
                    class="form-control" 
                    [(ngModel)]="orderInput" 
                    name="orderInput" 
                    required 
                    placeholder="Enter Order Number to add to pickup">
                <br>
                <button type="submit" class="btn btn-primary" [disabled]="!orderInput">Search</button>
            </form>
        </div>
    </div>
    <br>
    <div class="row text-center centered-row">
        <div class="col-md-6 col-sm-12 centered-column">
            <div class="list-container">
                <ul class="list-group" style="width: 100%;">
                    <li *ngFor="let selectedOrder of selectedOrders" class="list-group-item align-items-center">
                        {{ selectedOrder.OrderNumber }} 
                        <span class="custom-tooltip">
                            <i (click)="removeOrder(selectedOrder)" class="pull-right fa fa-lg fa-remove" style="color: #dd4b39;" title="Remove"></i>
                            <span class="custom-tooltip-text">Remove order from pickup</span>
                        </span>
                    </li>
                </ul>
            </div>
            <br>
            <button (click)="savePickup()" type="button" class="btn btn-primary" [disabled]="disableSaveButton">Save</button>
            <br><br>
            <button (click)="goToPaymentPage()" type="button" class="btn btn-primary">Proceed to Payment without editing</button>    
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver" [pickup]="pickup"></app-driver-check-in-support-button>
    <br />
</div>
