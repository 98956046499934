import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { ICustomerNonTwicTiming } from '../../model/interfaces/customer-non-twic-timing';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ICustomerPickupOverride } from '../../model/interfaces/customer-pickup-override';
import { CustomerNonTwicTimingDynamicConfig } from '../customer-non-twic-timing.dynamic-config';
import { CustomerNonTwicTimingService } from '../customer-non-twic-timing.service';
import { DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'customer-non-twic-timing',
    templateUrl: 'customer-non-twic-timing.component.html',
})
export class CustomerNonTwicTimingComponent implements OnInit {
    @Input() customerId = 0;

    canEdit = false;
    doubleClickIsDisabled = false;
    isEditing: boolean;

    selectedNonTwicTiming: ICustomerNonTwicTiming;
    customerNonTwicTimings: ICustomerNonTwicTiming[] = [];
    emptyCustomerNonTwicTiming: ICustomerNonTwicTiming = {
        CustomerId: this.customerId,
        EndTime: null,
        FromDate: null,
        Id: 0,
        StartTime: null,
        ToDate: null,
    };

    formObject: DynamicField[] = [];
    formFactory: CustomerNonTwicTimingDynamicConfig<ICustomerNonTwicTiming>;

    constructor(
        public customerNonTwicTimingService: CustomerNonTwicTimingService,
        private notificationService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.getNonTwicTimings();
    }

    setConfig(): void {
        let config: IDynamicFormConfig;
        this.formFactory = new CustomerNonTwicTimingDynamicConfig<ICustomerNonTwicTiming>(this.selectedNonTwicTiming);
        if (this.selectedNonTwicTiming.Id === 0) {
            // new customer non twic timing
            config = this.formFactory.getForCreate();
        } else {
            // existing customer non twic timing
            config = this.formFactory.getForUpdate();
        }
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
    }

    isEditMode(timing: ICustomerNonTwicTiming): boolean {
        return this.selectedNonTwicTiming && this.selectedNonTwicTiming.Id === timing.Id;
    }

    getNonTwicTimings(): void {
        this.customerNonTwicTimingService.getByCustomer(this.customerId).subscribe((result) => {
            this.customerNonTwicTimings = result;
        });
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.selectedNonTwicTiming, form.value.CustomerNonTwicTiming as ICustomerNonTwicTiming);
            this.selectedNonTwicTiming.CustomerId = this.customerId;
            if (!this.selectedNonTwicTiming.Id || this.selectedNonTwicTiming.Id === 0) {
              this.customerNonTwicTimingService.create(this.selectedNonTwicTiming)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe(() => {
                    this.selectedNonTwicTiming = null;
                    this.getNonTwicTimings();
              });
            } else {
              this.customerNonTwicTimingService.update(this.selectedNonTwicTiming)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe(
                  () => {
                    this.selectedNonTwicTiming = null;
                    this.getNonTwicTimings();
                  });
            }
          } else {
            markAllFormFieldsAsTouched(form);
          }
    }

    cancel(): void {
        this.selectedNonTwicTiming = null;
    }

    setEdit(override: ICustomerPickupOverride): void {
        this.selectedNonTwicTiming = { ...override };
        this.setConfig();
    }

    onDelete(event: ICustomerNonTwicTiming): void {
        this.customerNonTwicTimingService.delete(event.Id)
            .subscribe(
                () => {
                    this.getNonTwicTimings();
                    this.notificationService.success('Deleted Successfully.');
                },
                () => {
                    this.notificationService.error('Error could not delete.');
                },
            );
    }

    add(): void {
        this.selectedNonTwicTiming = { ...this.emptyCustomerNonTwicTiming };
        this.setConfig();
    }
}
