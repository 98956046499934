export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    UserRoles = 4,
    RemoteKiosk = 5,
    CheckIns = 6,
    Truckers = 7,
    Offices = 8,
    Orders = 9,
    Locations = 10,
    Warehouses = 11,
    Carriers = 12,
    CarrierFees = 13,
    RegistrationRequests = 14,
    Contacts = 15,
    PickupPaymentDetails = 16,
    ExceptionQueue = 17,
    Deliveries = 18,
}
