import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { NavService } from '@mt-ng2/nav-module';
import { NavigationEnd, Router } from '../../node_modules/@angular/router';
import { PrintModeService } from '../../node_modules/@mt-ng2/entity-list-module';
import { AuthService } from '@mt-ng2/auth-module';
import { environment } from './environments/environment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    template: `
        <div [ngClass]="{ 'public-outer': isPublic === true }" *ngIf="isPublic === true" [hidden]="appReady === null">
            <ng-progress></ng-progress>
            <div [ngClass]="{ 'public-middle': isPublic === true }">
                <router-outlet></router-outlet>
            </div>
        </div>

        <div *ngIf="isPublic === false" [hidden]="appReady === null">
            <div
                class="skin-blue sidebar-mini"
                [class.sidebar-open]="!sidebarCollapsed && showNav"
                [class.sidebar-collapse]="sidebarCollapsed || !showNav"
                [class.sidebar-mini]="showNav"
            >
                <div class="wrapper">
                    <mt-nav></mt-nav>
                    <div class="container-fluid content-wrapper" [style.min-height]="showFooter ? null : '100vh'">
                        <ng-progress></ng-progress>
                        <router-outlet></router-outlet>
                        <br />
                    </div>
                    <mt-footer></mt-footer>
                </div>
            </div>
        </div>

        <div [hidden]="appReady !== null" [style.position]="'relative'">
            <div class="pulse">
                <span></span>
                <img class="logo-img " src="{{ logoFull }}" alt="Logo" />
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';

    sidebarCollapsed: boolean;
    showNav: boolean;
    showFooter: boolean;
    appReady: boolean = null;
    logoFull = `${environment.imgPath}logo-full.png`;
    subscriptions: Subscription = new Subscription();

    // this value controls whether we see the normal site or the public site
    // starts off undefined, so neither shows til we know where we are via the router
    isPublic: boolean;

    constructor(
        private navService: NavService,
        private authService: AuthService,
        private router: Router,
        private baseprintModeService: PrintModeService,
        private cdr: ChangeDetectorRef,
    ) {
        // sets the root view to be used with notifications
    }

    ngOnInit(): void {
        // router subscribtion to determine if route isPublic
        this.subscriptions.add(
            // pulled this example on accessing current route data from
            // https://stackoverflow.com/questions/38644314/changing-the-page-title-using-the-angular-2-new-router/38652281#38652281
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.isPublic = this.getIsPublic(this.router.routerState, this.router.routerState.root);
                    this.setPublicBackground(this.isPublic);
                }
            }),
        );

        this.subscriptions.add(
            this.baseprintModeService.printMode.subscribe((inPrintMode) => {
                this.navService.showNav.next(!inPrintMode);
                this.navService.showFooter.next(!inPrintMode);
            }),
        );

        this.subscriptions.add(
            this.authService.appReady.subscribe((answer) => {
                this.appReady = answer;
                this.cdr.detectChanges();
            }),
        );

        this.sidebarCollapsed = this.navService.sidebarCollapsed.getValue();
        this.subscriptions.add(
            this.navService.sidebarCollapsed.subscribe((sidebarCollapsed: boolean) => {
                this.sidebarCollapsed = sidebarCollapsed;
                this.cdr.detectChanges();
            }),
        ); 

        this.showNav = this.navService.showNav.getValue();
        this.subscriptions.add(
            this.navService.showNav.subscribe((showNav: boolean) => {
                this.showNav = showNav;
                this.cdr.detectChanges();
            }),
        );

        this.showFooter = this.navService.showFooter.getValue();
        this.subscriptions.add(
            this.navService.showFooter.subscribe((showFooter: boolean) => {
                this.showFooter = showFooter;
                this.cdr.detectChanges();
            }),
        );

        if (this.router.url.includes('remote-kiosk')) {
            this.handleKioskMode();
        }

        this.subscriptions.add(
            this.router.events.subscribe((answer) => {
                if (answer instanceof NavigationEnd) {
                    if (answer.url.includes('remote-kiosk')) {
                        this.handleKioskMode();
                    } else {
                        if (this.baseprintModeService.printMode.getValue() === true) {
                            this.baseprintModeService.togglePrintMode();
                        }
                    }
                }
            }),
        );
    }

    handleKioskMode(): void {
        if (this.baseprintModeService.printMode.getValue() === false) {
            this.baseprintModeService.togglePrintMode();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getIsPublic(state, parent): boolean {
        if (parent && parent.snapshot.data && parent.snapshot.data.isPublic) {
            return true;
        }

        if (state && parent) {
            return this.getIsPublic(state, state.firstChild(parent));
        }

        return false;
    }

    setPublicBackground(isPublic: boolean): void {
        if (isPublic) {
            document.body.style.backgroundColor = '#ecf0f5';
        } else {
            document.body.style.backgroundColor = '#fff';
        }
    }
}
