// add the format const as an import
import { Pipe, PipeTransform } from '@angular/core';
import { format } from '@mt-ng2/format-functions';

@Pipe({
    name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            return format.phone(value);
        }
        return '';
    }
}