import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IUser } from '../../model/interfaces/user';
import { UserService } from '../../users/user.service';
import { ArchiveTruckerListComponent } from './archive-trucker-list/archive-trucker-list.component';

export class TruckersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (trucker: IUser): string {
                        return `${trucker.FirstName + ' ' + trucker.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'FirstName',
                    },
                }),
                new EntityListColumn({
                    name: 'Phone',
                }),
                new EntityListColumn({
                    accessorFunction: (user: IUser) => {
                        return user.Carrier?.Name;
                    },
                    name: 'Driving Company',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Carrier.Name',
                    },
                }),
                new EntityListColumn({
                    accessors: ['UsdotNumber'],
                    name: 'US DOT Number',
                }),
                new EntityListColumn({
                    accessors: ['TrailerLicensePlate'],
                    name: 'Trailer License Plate',
                }),
                new EntityListColumn({
                    accessors: ['TractorLicensePlate'],
                    name: 'Tractor License Plate',
                }),
                new EntityListColumn({
                    accessorFunction: (user: IUser) => {
                        return UserService.checkUserIsTwicVerified(user)
                            ? 'Yes'
                            : 'No';
                    },
                    name: 'TWIC Verified',
                }),
                new EntityListColumn({
                    component: ArchiveTruckerListComponent,
                    fireOnColumnSelected: false,
                    name: 'Archive',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            rowClass: (entity: IUser) => entity.BannedFromPickup ? 'archived' : '',
        };
        super(listConfig);
    }
}
