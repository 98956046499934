<div class="row">
    <div class="col-md-7">
        <h2>Scheduling Queue</h2>
    </div>
    <div class="col-md-5" *ngIf="userIsCarrierOrCustomer()">
        <button 
            class="btn btn-primary btn-manage-appointments" 
            (click)="redirectToManageSchedulingPage()" 
            [disabled]="!selectedEntities || selectedEntities.length <= 0">
            Manage Appointments
        </button>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <div class="office-date-filter__wrapper" *ngIf="userIsCarrierOrCustomer()">
        <mt-search-filter-select
            [items]="locationMetaItems"
            entity="Office"
            (selectionChanged)="getOrdersForCustomer(currentUser.Id, false)"
        >
        </mt-search-filter-select>
        <mt-search-filter-daterange
            [startDate]="customerPickupStartDate"
            [endDate]="customerPickupEndDate"
            [entity]="'Pickup Date'"
            (selectionChanged)="dateSelectionChanged($event)"
        ></mt-search-filter-daterange>
    </div>
    <div class="row gx-5 checkbox-filters__wrapper">
        <div *ngIf="!userIsCarrierOrCustomer()" class="checkbox-filter col-md-1 archived-checkbox__wrapper">
            <label class="checkbox-label">
                <input
                    [(ngModel)]="showArchived"
                    type="checkbox"
                    (change)="getOrders()"
                />
                Include Archived
            </label>
        </div>
        <div *ngIf="!userIsCarrierOrCustomer()" class="checkbox-filter col-md-1">
            <label class="checkbox-label">
                <input
                    [(ngModel)]="showMissingWarehouseOrders"
                    type="checkbox"
                    (change)="getOrders()"
                />
                Missing Warehouse Id
            </label>
        </div>
        <mt-search-filter-select
            *ngIf="!userIsCarrierOrCustomer()"
            [items]="customerMetaItems"
            entity="Customer"
            (selectionChanged)="getOrders()"
            class="col-md-1"
        >
        </mt-search-filter-select>
        <div *ngIf="!userIsCarrierOrCustomer()" class="last-synced-file-name col-sm-8">
            Last Synced:{{ lastSyncedFileName }} at
            {{ lastSyncedDate | date: "medium" }}
        </div>

    </div>
    <button class="btn btn-primary" (click)="redirectToCarrierRequestForm()">Request New Carrier</button>
</div>
<br>
<mt-entity-list 
    *ngIf="entityListConfig"
    [entities]="orders"
    [total]="total"
    [(itemsPerPage)]="itemsPerPage"
    [(currentPage)]="currentPage"
    (pageChanged)="getOrders()"
    (selectionChangedEvent)="selectionChanged($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (columnSelectedEvent)="columnSelected($event)"
>
</mt-entity-list>
