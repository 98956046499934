<div class="row">
    <div class="col-md-7">
        <h2>Contacts</h2>
    </div>
</div>
<div>
    <mt-dynamic-search-filters
        *ngIf="dynamicSearchFiltersConfig"
        [config]="dynamicSearchFiltersConfig"
        (searchValuesUpdated)="search($event)">
    </mt-dynamic-search-filters>
    <label for="archived">
        <input type="checkbox" id="archived" [(ngModel)]="showArchived" (change)="getContacts()"/>
        Show Archived
    </label>
</div>
<mt-entity-list
    [entities]="contacts"
    [total]="total"
    [(currentPage)]="currentPage"
    (pageChanged)="getContacts()"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (itemDeletedEvent)="onItemDeleted($event)"
    (itemSelectedEvent)="contactSelected($event)"
>
</mt-entity-list>

<div *ngIf="canAddContact" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/contacts', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
