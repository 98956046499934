/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../customers/customer.service';
import { CarrierService } from '../../carriers/services/carrier.service';
import { forkJoin } from 'rxjs';
import { IRegistrationRequest } from '../../model/interfaces/registration-request';
import { RegistrationService } from './services/registration.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from '../../common/services/common.service';
import { ICustomerForRegistration } from '../../model/interfaces/custom/customer-for-registration';
import { ICarrierForRegistration } from '../../model/interfaces/custom/carrier-for-registration';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  loading = true;
  registrationForm: FormGroup;
  selectedRadioOption: string | null = null;
  dropdownOptions: object[] = [];
  customers: ICustomerForRegistration[] = [];
  carriers: ICarrierForRegistration[] = [];
  logoFull = `${environment.imgPath}logo-full.png`;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private carrierService: CarrierService,
    private registrationService: RegistrationService,
    private notificationService: NotificationsService,
    private router: Router,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.registrationForm = this.formBuilder.group({
      firstName: ['', [Validators.required, this.commonService.firstAndLastNameValidator()]],
      lastName: ['', [Validators.required, this.commonService.firstAndLastNameValidator()]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      radio: ['', Validators.required],
      dropdownOption: ['', Validators.required],
      checkboxAll: [false],
      checkbox1: [false],
      checkbox2: [false],
      checkbox3: [false],
      checkbox4: [false]
    });

    this.registrationForm.get('checkboxAll').valueChanges.subscribe((value) => {
      if (value) {
        this.registrationForm.get('checkbox1').setValue(true);
        this.registrationForm.get('checkbox2').setValue(true);
        this.registrationForm.get('checkbox3').setValue(true);
        this.registrationForm.get('checkbox4').setValue(true);
      } else {
        this.registrationForm.get('checkbox1').setValue(false);
        this.registrationForm.get('checkbox2').setValue(false);
        this.registrationForm.get('checkbox3').setValue(false);
        this.registrationForm.get('checkbox4').setValue(false);
      }
    });

    const customers = this.customerService.getActiveCustomersForRegistration();
    const carriers = this.carrierService.getActiveCarriersForRegistration();
    forkJoin([customers, carriers]).subscribe(results => {
      this.customers = results[0];
      this.carriers = results[1];
      this.loading = false;
    });
  }

  onSubmit(): void {
    if (this.registrationForm.invalid) {
      return;
    }

    const request: IRegistrationRequest = {
      Id: 0,
      StatusId: 0,
      DateCreated: new Date(),
      FirstName: this.registrationForm.get('firstName').value,
      LastName: this.registrationForm.get('lastName').value,
      EmailAddress: this.registrationForm.get('email').value,
      PhoneNumber: this.registrationForm.get('phone').value,
      NotificationWhenDriverChecksIn: false,
      NotificationWhenDriverIsNotified: false,
      NotificationWhenDriverIsIssuedGatePass: false,
      NotificationWhenDriverIsFulfilled: false,
    }

    if (this.selectedRadioOption === 'customer') {
      request.CustomerId = this.registrationForm.get('dropdownOption').value as number;
    } else {
      request.CarrierId = this.registrationForm.get('dropdownOption').value as number;
    }

    this.registrationService.create(request).subscribe({
      error: (result: HttpErrorResponse) => {
        let errors: string[] = result?.error?.PhoneNumber;
        if (errors) {
          this.notificationService.error(`Validation failed: \r\n -- PhoneNumber: ${errors[0]}`);
        }
        errors = result?.error?.ModelState;
        if (errors) {
          this.notificationService.error(errors[0]);
        }
      },
      complete: () => {
        this.notificationService.success('Registration successful, please wait for Admin approval.');
        this.redirectToLoginPage();
      }
    })
  }

  selectAllCheckboxes(checked: boolean): void {
    this.registrationForm.get('checkbox1').setValue(checked);
    this.registrationForm.get('checkbox2').setValue(checked);
    this.registrationForm.get('checkbox3').setValue(checked);
    this.registrationForm.get('checkbox4').setValue(checked);
  }

  onRadioChange(option: string) {
    this.selectedRadioOption = option;

    if (option === 'customer') {
      this.dropdownOptions = this.customers.map(({ Id, Name }) => ({ Id, Name }));
    } else if (option === 'carrier') {
      this.dropdownOptions = this.carriers.map(({ Id, Name }) => ({ Id, Name }));
    }

    this.dropdownOptions = this.dropdownOptions.sort();
    const firstOption = this.dropdownOptions[0];
    
    if (firstOption) {
      this.registrationForm.get('dropdownOption').setValue(firstOption['Id'] as string);
    }
  }

  redirectToLoginPage(): void {
    setTimeout(() => {
      void this.router.navigate([`/login`]);
    }, 500); // wait a half sec
  }
}
