<div *ngIf="office && office.Id > 0">
    <div class="row">
        <div class="col-md-4">
            <app-office-basic-info
                [office]="office"
                [canEdit]="canEdit"
            ></app-office-basic-info
            ><br />
        </div>
        <div class="col-md-8">
            <app-office-doors
                [office]="office"
                [canEdit]="canEdit"
            ></app-office-doors
            ><br />
            <office-warehouses
                [office]="office"
                [canEdit]="canEdit"
            ></office-warehouses>
        </div>
    </div>
    <br />
    <div class="row" *ngIf="id">
        <div class="col-md-12">
            <office-pickup-override [officeId]="id"></office-pickup-override>
        </div>
    </div>
    <br />
    <div class="row" *ngIf="id">
        <div class="col-md-12">
            <office-non-twic-timing [officeId]="id"></office-non-twic-timing>
        </div>
    </div>
    <br />
    <a routerLink="/offices" class="btn btn-default">Close</a>
</div>
<div *ngIf="office && office.Id === 0">
    <div class="row">
        <div class="col-md-4">
            <app-office-basic-info [office]="office"></app-office-basic-info>
        </div>
    </div>
</div>
