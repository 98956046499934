import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IOfficeNonTwicTiming } from '../interfaces/office-non-twic-timing';
import { IOffice } from '../interfaces/office';

export interface IOfficeNonTwicTimingDynamicControlsParameters {
    formGroup?: string;
    offices?: IOffice[];
}

export class OfficeNonTwicTimingDynamicControls {

    formGroup: string;
    offices: IOffice[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private officenontwictiming?: IOfficeNonTwicTiming, additionalParameters?: IOfficeNonTwicTimingDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'OfficeNonTwicTiming';
        this.offices = additionalParameters && additionalParameters.offices || undefined;

        this.Form = {
            EndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'End Time',
                name: 'EndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.officenontwictiming && this.officenontwictiming.hasOwnProperty('EndTime') && this.officenontwictiming.EndTime !== null ? this.officenontwictiming.EndTime.toString() : '',
            }),
            FromDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'From Date',
                name: 'FromDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officenontwictiming && this.officenontwictiming.FromDate || null,
            }),
            OfficeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Office',
                name: 'OfficeId',
                options: this.offices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.officenontwictiming && this.officenontwictiming.OfficeId || null,
            }),
            StartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Start Time',
                name: 'StartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.officenontwictiming && this.officenontwictiming.hasOwnProperty('StartTime') && this.officenontwictiming.StartTime !== null ? this.officenontwictiming.StartTime.toString() : '',
            }),
            ToDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'To Date',
                name: 'ToDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officenontwictiming && this.officenontwictiming.ToDate || null,
            }),
        };

        this.View = {
            EndTime: new DynamicLabel({
			    label: 'End Time',
			    value: this.officenontwictiming && this.officenontwictiming.hasOwnProperty('EndTime') && this.officenontwictiming.EndTime !== null ? this.officenontwictiming.EndTime.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FromDate: new DynamicLabel({
			    label: 'From Date',
			    value: this.officenontwictiming && this.officenontwictiming.FromDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            OfficeId: new DynamicLabel({
			    label: 'Office',
			    value: getMetaItemValue(this.offices as unknown as IMetaItem[], this.officenontwictiming && this.officenontwictiming.hasOwnProperty('OfficeId') && this.officenontwictiming.OfficeId !== null ? this.officenontwictiming.OfficeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            StartTime: new DynamicLabel({
			    label: 'Start Time',
			    value: this.officenontwictiming && this.officenontwictiming.hasOwnProperty('StartTime') && this.officenontwictiming.StartTime !== null ? this.officenontwictiming.StartTime.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ToDate: new DynamicLabel({
			    label: 'To Date',
			    value: this.officenontwictiming && this.officenontwictiming.ToDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
        };

    }
}
