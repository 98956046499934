import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { IColumnSortedEvent, IItemDeletedEvent, IItemSelectedEvent, SortDirection} from '@mt-ng2/entity-list-module';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { forkJoin, Subscription } from 'rxjs';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { ExtraParams } from '../../common/extra-params/extra-params.library';
import { ContactEntityListConfig } from './contacts.entity-list-config';
import { IContact } from '../../model/interfaces/contact';
import { ContactService } from '../contact.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CarrierService } from '../../carriers/services/carrier.service';
import { CustomerService } from '../../customers/customer.service';
import { ICarrier } from '../../model/interfaces/carrier';
import { ICustomer } from '../../model/interfaces/customer';
import { CommonFunctions } from '../../common/services/common-functions.service';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts-list.component.html',
})
export class ContactsComponent implements OnInit {
    @ViewChild('tref', { static: true }) tref: ElementRef;
    showArchived = false;
    contacts: IContact[];
    total: number;
    currentPage = 1;
    query = '';
    entityListConfig = new ContactEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    canAddContact = false;
    regReqStatuses: MtSearchFilterItem[] = [];
    selectedStatuses: number[] = [];
    subscriptions: Subscription;
    dynamicSearchFiltersConfig: IDynamicSearchFilters;
    carriers: ICarrier[];
    customers: ICustomer[];
    selectedCarrierIds: number[] = [];
    selectedCustomerIds: number[] = [];

    constructor(
        private claimsService: ClaimsService,
        private contactsService: ContactService,
        private notificationsService: NotificationsService,
        private router: Router,
        private carrierService: CarrierService,
        private customerService: CustomerService,
    ) {}

    ngOnInit(): void {
        this.canAddContact = this.claimsService.hasClaim(ClaimTypes.Contacts, [ClaimValues.FullAccess]);
        if (!this.canAddContact) {
            this.entityListConfig.delete = null;
        }

        this.dynamicSearchFiltersConfig = this.buildSearchConfig();
        this.getContacts();
        this.initFilters();
    }

    initFilters(): void {
        forkJoin([
            this.carrierService.getActive(),
            this.customerService.getActiveCustomers(), 
        ]).subscribe(([carriers, customers]) => {
            this.carriers = carriers;
            this.customers = customers;
            this.dynamicSearchFiltersConfig = [{
                Searchbar: {
                    label: 'Search',
                    type: DynamicSearchFilterTypes.Searchbar,
                },
                Customers: {
                    label: 'Customer',
                    type: DynamicSearchFilterTypes.Select,
                    options: {
                        selectOptions: CommonFunctions.mapMtSearchFilterItems(this.customers, 'Name')
                    },
                },
                Carriers: {
                    label: 'Carrier',
                    type: DynamicSearchFilterTypes.Select,
                    options: {
                        selectOptions: CommonFunctions.mapMtSearchFilterItems(this.carriers, 'Name')
                    },
                },
            }];
        });
    }

    private buildSearchConfig(): IDynamicSearchFilters {
        const config: IDynamicSearchFilters = [
            {
                Searchbar: {
                    label: 'Search',
                    type: DynamicSearchFilterTypes.Searchbar,
                },
            },
        ];
        return config;
    }

    getContacts(): void {
        const _extraSearchParams = ExtraParams.build({
            statusIds: this.selectedStatuses,
            showArchived: this.showArchived.toString()
        });

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CarrierIds',
                valueArray: this.selectedCarrierIds,
            }),
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: this.selectedCustomerIds,
            }),
        );

        const search = this.query;

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.contactsService.get(searchparams).subscribe((answer) => {
            this.contacts = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(event: DynamicSearchValues): void {
        this.currentPage = 1;
        this.query = event.Searchbar as string;

        if (event.Carriers) {
            this.selectedCarrierIds = (event.Carriers as IMetaItem[]).map(x => x.Id);
        }

        if (event.Customers) {
            this.selectedCustomerIds = (event.Customers as IMetaItem[]).map(x => x.Id);
        }

        this.getContacts();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getContacts();
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        event.entity["Archived"] = !event.entity["Archived"];
        this.contactsService.delete(event.entity["Id"] as number).subscribe(
            () => {
                this.getContacts();
                this.notificationsService.success('Contact successfully archived!');
            },
            () => {
                this.getContacts();
                this.notificationsService.error('An error occurred while archiving the contact.');
            },
        );
    }

    contactSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/contacts', event.entity.Id]);
    }

}
