import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../order.service';
import { IOrder } from '../../../model/interfaces/order';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDatepickerOptions, InputTypes } from '@mt-ng2/dynamic-form';
import { PickupService } from '../../../model/shared-entities/pickups/pickup.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ModalService } from '@mt-ng2/modal-module';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../../users/user.service';
import { IUser } from '../../../model/interfaces/user';
import { ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { IPickup } from '../../../model/interfaces/pickup';
import { CommonFunctions } from '../../../common/services/common-functions.service';
import { CarrierService } from '../../../carriers/services/carrier.service';
import { forkJoin } from 'rxjs';
import { ICarrier } from '../../../model/interfaces/carrier';
import { DriverTimeSlot } from '../../../model/classes/driver-time-slot';
import { PhoneFormatPipe } from '../../../common/pipes/phone-format';
import { CommonService } from '../../../common/services/common.service';
import { PickupStatus } from '../../order-basic-info/pickup-status-enum';
import { IPickupParams } from '../../../model/interfaces/custom/pickup-params';

@Component({
    selector: 'app-pickup-scheduling-time-selection',
    templateUrl: './time-selection.component.html',
    styleUrls: ['./time-selection.component.css'],
})
export class PickupSchedulingTimeSelectionComponent implements OnInit {
    selectedPickup: IPickup;
    selectedOrders: IOrder[];
    drivers: IUser[];
    selectableDrivers = [];
    carriers = [];
    driverSelectionForm: FormGroup = null;
    selectedDriverTouched = false;
    selectedCarrierTouched = false;
    selectedDriver: IUser;
    selectedCarrier: ICarrier;
    selectedDateTime: string;
    selectedDateTimeCopy: string;
    selectedDriverIdCopy: number;
    pickupStatusIdCopy: number;
    availableDateTimes: DriverTimeSlot[] = [];
    startDate = new Date();
    endDate = new Date();
    today = new Date();
    phoneFormatPipe: PhoneFormatPipe;
    pickupStatus = PickupStatus;
    confirmButtonWasClicked = false;
    dateRequestedField: DynamicField;
    datepickerOptions: IDatepickerOptions = { 
        minDate: { 
            year: this.today.getFullYear(), 
            month: this.today.getMonth() + 1, 
            day: this.today.getDate() 
        } 
    };

    constructor(
        private orderService: OrderService,
        private pickupService: PickupService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private router: Router,
        private fb: FormBuilder,
        private carrierService: CarrierService,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.phoneFormatPipe = new PhoneFormatPipe();
        this.orderService.selectedPickupOrders$.subscribe((selectedOrders) => {
            if (!selectedOrders || selectedOrders.length <= 0) {
                void this.router.navigate([`/create-requests/order-selection`]);
                return;
            }
                
            this.selectedOrders = selectedOrders;
        });

        forkJoin([
            this.pickupService.getPickupDrivers(),
            this.carrierService.getActive(),
        ]).subscribe(([drivers, carriers]) => {
            this.drivers = drivers;
            this.carriers = carriers;
            this.carriers.sort((a, b) => a.Name.localeCompare(b.Name));
            this.pickupService.selectedPickup$.subscribe((selectedPickup) => {
                if (selectedPickup) {
                    this.selectedPickup = selectedPickup;
                    this.selectedCarrier = this.carriers?.find(f => f.Id === selectedPickup.CarrierId);
                    const dateTime = this.selectedPickup.DateConfirmed ?? this.selectedPickup.DateRequested;
                    this.selectedDateTime = dateTime?.toString();
                    this.selectedDateTimeCopy = this.selectedDateTime;
                    this.selectedDriverIdCopy = this.selectedPickup.DriverId;
                    this.pickupStatusIdCopy = this.selectedPickup.StatusId;
                    this.startDate = new Date(dateTime)
                    this.endDate = new Date(dateTime);
                    this.updateSelectableDrivers();
                    this.selectedDriver = this.selectableDrivers?.find(f => f.Id === selectedPickup.DriverId);
                }

                const value = this.selectedPickup?.DateConfirmed ?? this.selectedPickup?.DateRequested ?? this.today;
                this.dateRequestedField = new DynamicField({
                    formGroup: '',
                    label: '',
                    labelHtml: `<i class="fa fa-lg fa-fw fa-calendar"></i> <b>Select Date</b>:`,
                    name: 'dateRequested',
                    type: new DynamicFieldType({
                        fieldType: DynamicFieldTypes.Input,
                        inputType: InputTypes.Datepicker,
                        datepickerOptions: this.datepickerOptions
                    }),
                    validators: { required: true },
                    value: value,
                });

                this.driverSelectionForm = this.fb.group({
                    deliveryZipCode: [this.selectedPickup?.DeliveryZipCode ?? '', [Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]],
                    dateRequested: new FormControl(this.dateRequestedField.value, [Validators.required])
                });
            });
        });
    }

    onDriverSelected(event: ISelectionChangedEvent): void {
        this.selectedDriverTouched = true;
        this.selectedDriver = this.selectableDrivers.find(f => f.Id === event['selection']?.Id);
        this.getSearchTimes();
    }

    onCarrierSelected(event: ISelectionChangedEvent): void {
        this.selectedCarrierTouched = true;
        this.selectedCarrier = this.carriers.find(f => f.Id === event['selection']?.Id);
        this.getSearchTimes();
        this.selectedDriver = null;
        this.updateSelectableDrivers();
    }

    updateSelectableDrivers(): void {
        if (!this.selectedCarrier)
            return;

        this.selectableDrivers = [];
        this.drivers.forEach(driver => {
            if (driver.CarrierId !== this.selectedCarrier.Id && driver.CarrierId !== null)
                return;
            
            const driverForDisplay = { 
                Name: `${driver.FirstName} ${driver.LastName} - ${this.phoneFormatPipe.transform(driver.Phone)}`,
                Id: driver.Id,
                CarrierId: driver.CarrierId 
            };

            if (UserService.checkUserIsTwicVerified(driver))
                driverForDisplay.Name = `${driverForDisplay.Name} (TWIC)`;

            this.selectableDrivers.push(driverForDisplay);
        });
        this.selectableDrivers.sort((a, b) => a.Name.localeCompare(b.Name));
    }
    
    startDateChange(event: Date): void {
        this.startDate = event;
        this.endDate = event;
        this.getSearchTimes();
    }

    onDriverSelectionFormSubmit(): void {
        if (this.selectedPickup?.Id > 0) {
            this.selectedPickup.Orders = this.selectedOrders;
            this.selectedPickup.DriverId = this.selectedDriver?.Id;
            this.selectedPickup.DeliveryZipCode = this.driverSelectionForm.controls.deliveryZipCode.value as string;
            this.selectedPickup.CarrierId = this.selectedCarrier?.Id;

            let successMessage = 'Your pickup appointment has been updated.';
            if (this.confirmButtonWasClicked) {
                this.selectedPickup.StatusId = PickupStatus.Confirmed;
                successMessage = 'Your pickup appointment has been confirmed.';
            }

            if (this.selectedPickup.StatusId === PickupStatus.Confirmed) {
                this.selectedPickup.DateConfirmed = CommonFunctions.getUTCEquivalent(this.selectedDateTime);
            } else {
                this.selectedPickup.DateRequested = CommonFunctions.getUTCEquivalent(this.selectedDateTime);
            }

            this.pickupService.updateWithFks(this.selectedPickup).subscribe({
                next: () => {
                    this.sendPickupEmails(this.selectedPickup.Id, successMessage);
                },
                error: (errorResponse: HttpErrorResponse) => {
                    this.handleHttpErrorResponse(errorResponse);
                }
            });

            return;
        };

        const params: IPickupParams = {
            dateRequested: CommonFunctions.getUTCEquivalent(this.selectedDateTime),
            dateConfirmed: null,
            orders: this.selectedOrders,
            carrierId: this.selectedCarrier?.Id,
            driverId: this.selectedDriver?.Id,
            deliveryZipCode: this.driverSelectionForm.controls.deliveryZipCode.value as string,
            statusId: null,
        }

        let successMessage = 'Your pickup appointment has been updated.';
        if (this.confirmButtonWasClicked) {
            successMessage = 'Your pickup appointment has been confirmed.';
            params.statusId = PickupStatus.Confirmed;
            params.dateConfirmed = CommonFunctions.getUTCEquivalent(this.selectedDateTime);
        }

        this.pickupService.createScheduledPickup(params).subscribe({
            next: (pickup: IPickup) => {
                this.sendPickupEmails(pickup.Id, successMessage);
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.handleHttpErrorResponse(errorResponse);
            }
        });
    }

    onDateTimeSelected(dateTime: string) {
        this.selectedDateTime = dateTime;
    }

    pickupFormIsInvalid(): boolean {
        if (!this.selectedCarrier || !this.selectedDateTime || this.driverSelectionForm.controls.deliveryZipCode.invalid)
            return true;

        return !(this.availableDateTimes.find(time => time.TimeSlot.toString() === this.selectedDateTime));
    }

    pickupHasNotBeenAltered(): boolean {
        const zipCode = this.driverSelectionForm.controls.deliveryZipCode.value as string;
        const carrierId = this.selectedCarrier?.Id ?? null;
        const driverId = this.selectedDriver?.Id ?? null;
        const originalOrderIds = this.selectedPickup.Orders.map(o => o.Id);
        const selectedOrderIds = this.selectedOrders.map(o => o.Id);

        return (
            this.selectedPickup.DeliveryZipCode === zipCode &&
            this.selectedPickup.CarrierId === carrierId &&
            this.selectedPickup.DriverId === driverId &&
            this.selectedDateTimeCopy === this.selectedDateTime &&
            CommonFunctions.areArraysIdentical(originalOrderIds, selectedOrderIds)
        );
    }

    cancelPickup(pickup: IPickup): void {
        this.commonService.cancelPickup(pickup);
    }

    confirmPickup(): void {
        this.confirmButtonWasClicked = true;
        this.onDriverSelectionFormSubmit();
    }

    private getSearchTimes() {
        this.pickupService.getDriverSearchTimes(
            this.selectedOrders.map(o => o.Id),
            CommonFunctions.setToMidnight(this.startDate),
            CommonFunctions.setToMidnight(this.endDate),
            this.selectedOrders[0].CustomerId,
            this.selectedOrders[0].Warehouse?.OfficeId,
            this.selectedDriver?.Id ?? 0
        )
        .subscribe((availableDateTimes) => {
            this.availableDateTimes = availableDateTimes;
        });
    }

    private sendPickupEmails(pickupId: number, successMessage: string): void {
        if (this.confirmButtonWasClicked) {
            this.pickupService.sendConfirmedEmails(pickupId).subscribe({
                next: () => {
                    this.showSuccessModal(successMessage);
                },
                error: (errorResponse: HttpErrorResponse) => {
                    this.handleHttpErrorResponse(errorResponse);
                }
            });
        } else {
            this.pickupService.sendUpdatedEmails(pickupId).subscribe({
                next: () => {
                    this.showSuccessModal(successMessage);
                },
                error: (errorResponse: HttpErrorResponse) => {
                    this.handleHttpErrorResponse(errorResponse);
                }
            });
        }
    }

    private showSuccessModal(successMessage: string): void {
        this.modalService
            .showModal({
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                text: successMessage,
                title: 'Got It!',
                icon: 'success',
            })
            .subscribe(() => {
                void this.router.navigate(['create-requests/manage-pickups']);
            });
    }
    
    private handleHttpErrorResponse(errorResponse: HttpErrorResponse): void {
        if (typeof(errorResponse.error) === 'string') {
            this.notificationsService.error(errorResponse.error);
        } else {
            this.notificationsService.error(errorResponse.error['ModelState'][0] as string);
        }
        
        if (this.selectedPickup) {
            this.selectedPickup.StatusId = this.pickupStatusIdCopy;
            this.selectedPickup.DriverId = this.selectedDriverIdCopy;
        }
        
        this.confirmButtonWasClicked = false;    
    }
}
