<div class="row">
    <div class="col-md-7">
        <h2>Offices</h2>
        <mt-search-bar (searched)="search($event)"></mt-search-bar>
        <br />
    </div>
</div>

<div class="row">
    <div class="col-md-7">
        <mt-entity-list
            [entities]="offices"
            [total]="total"
            [(currentPage)]="currentPage"
            (pageChanged)="getOffices()"
            (itemSelectedEvent)="officeSelected($event)"
            (columnSortedEvent)="columnSorted($event)"
            [entityListConfig]="entityListConfig"
            (itemDeletedEvent)="onItemDeleted($event)"
        >
        </mt-entity-list>
    </div>
</div>

<div *ngIf="canAddOffice" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/offices', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
