import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { LocationService } from '../services/location.service';

@Component({
    selector: 'app-location-header',
    templateUrl: './location-header.component.html',
})
export class LocationHeaderComponent implements OnInit {
    id: number;
    header$: Observable<string>;
    readonly locationService = inject(LocationService);
    readonly route = inject(ActivatedRoute);

    constructor() {
        this.id = +(this.route.snapshot.paramMap.get('locationId') ?? 0);
    }

    ngOnInit(): void {
        this.locationService.setInitialTitle(this.id);
        this.header$ = this.locationService.title$;
    }
}
