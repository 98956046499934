import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { CarrierDynamicConfig } from '../carrier.dynamic-config';
import { CarrierService } from '../services/carrier.service';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ICarrier } from '../../model/interfaces/carrier';
import { IDynamicFormConfig, DynamicField } from '@mt-ng2/dynamic-form';
import { Router } from '@angular/router';

@Component({
    selector: 'app-carrier',
    templateUrl: './carrier.component.html',
})
export class CarrierComponent implements OnInit {
    canEdit: boolean;
    canAdd: boolean;
    isEditing: boolean;
    isHovered: boolean;
    includeArchived: boolean;
    doubleClickIsDisabled = false;
    formFactory: CarrierDynamicConfig<ICarrier>;
    carriersList: Array<ICarrier>;
    carrier: ICarrier;
    query = '';
    formObject: DynamicField[] = [];

    constructor(
        private carrierService: CarrierService, 
        private notificationsService: NotificationsService, 
        private claimsService: ClaimsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Carriers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.isEditing = false;
        this.includeArchived = false;
        this.getCarriers();
        this.setConfig();
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        if (this.includeArchived) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'IncludeArchived',
                    value: 'true',
                }),
            );
        } else {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'IncludeArchived',
                    value: 'false',
                }),
            );
        }

        return _extraSearchParams;
    }

    getCarriers(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Name',
            orderDirection: 'asc',
            query: search && search.length > 0 ? search : '',
        };

        const searchparams = new SearchParams(searchEntity);
        this.carrierService.get(searchparams).subscribe((answer) => {
            this.carriersList = answer.body;
        });
    }

    includeSelectionChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.includeArchived = event.value;
        this.getCarriers();
    }

    setConfig(): void {
        let config: IDynamicFormConfig;
        this.formFactory = new CarrierDynamicConfig<ICarrier>(this.carrier);
        if (this.carrier != null) {
            if (this.carrier.Id === 0) {
                this.isEditing = true;
                config = this.formFactory.getForCreate();
            } else {
                config = this.formFactory.getForUpdate();
            }
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        }
    }

    editCarrier(carrier: ICarrier): void {
        void this.router.navigate([`/carriers/${carrier.Id}`]);
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.carrier, form.value.Carrier as ICarrier);
            if (!this.carrier.Id || this.carrier.Id === 0) {
                this.carrierService
                    .create(this.carrier)                    .subscribe(() => {
                        this.isEditing = false;
                        this.success();
                        this.getCarriers();
                    });
            } else {
                this.carrierService
                    .update(this.carrier)                    .subscribe(() => {
                        this.isEditing = false;
                        this.success();
                        this.getCarriers();
                    });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    archiveCarrier(id: number, index: number, archive: boolean): void {
        const partialObject = { Id: id, Archived: archive };
        this.carrierService
            .updatePartial(partialObject, id)            .subscribe(() => {
                this.carriersList[index].Archived = archive;
                if (!this.includeArchived && archive) {
                    this.carriersList.splice(index, 1);
                }
                this.successArchive(archive);
            });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Carrier saved successfully.');
    }

    successArchive(archive: boolean): void {
        if (archive) {
            this.notificationsService.success('Archived item successfully.');
        } else {
            this.notificationsService.success('Unarchived item successfully.');
        }
    }

    search(query: string): void {
        this.query = query;
        this.getCarriers();
    }
}
