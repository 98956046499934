import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICarrierRequestStatus } from '../model/interfaces/carrier-request-status';


export const emptyCarrierRequestStatus: ICarrierRequestStatus = {
    Id: 0,
    Name: null,
};

@Injectable({
    providedIn: 'root',
})
export class CarrierRequestStatusService extends BaseService<ICarrierRequestStatus> {
    constructor(public http: HttpClient) {
        super('/carrier-request-statuses', http);
    }

    getEmptyCarrierRequestStatus(): ICarrierRequestStatus {
        return { ...emptyCarrierRequestStatus };
    }


}
