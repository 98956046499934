import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IEntity, ISelectionChangedEvent } from '@mt-ng2/entity-list-module';
import { OrderService } from '../../order.service';
import { IOrder } from '../../../model/interfaces/order';
import { ICarrier } from '../../../model/interfaces/carrier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CarrierService } from '../../../carriers/services/carrier.service';
import { Router } from '@angular/router';
import { IPickup } from '../../../model/interfaces/pickup';
import { PickupService } from '../../../model/shared-entities/pickups/pickup.service';

@Component({
    selector: 'app-pickup-scheduling-carrier-selection',
    templateUrl: './carrier-selection.component.html',
    styleUrls: ['./carrier-selection.component.css'],
})
export class PickupSchedulingCarrierSelection implements OnInit {
    selectedOrders: IOrder[];
    selectedEntities: IEntity[];
    carriers: ICarrier[];
    selectedCarrier: ICarrier;
    selectedCarrierTouched = false;
    carrierSelectionForm: FormGroup = null;
    selectedPickup: IPickup;

    constructor(
        private orderService: OrderService,
        private carrierService: CarrierService,
        private pickupService: PickupService,
        private fb: FormBuilder,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.orderService.selectedPickupOrders$.subscribe((selectedOrders) => {
            if (!selectedOrders || selectedOrders.length <= 0) {
                void this.router.navigate([`/create-requests/order-selection`]);
                return;
            }
            this.selectedOrders = selectedOrders;
        });

        forkJoin([this.carrierService.getActive()]).subscribe(([carriers]) => {
            this.carriers = carriers;
            this.pickupService.selectedPickup$.subscribe((selectedPickup) => {
                if (selectedPickup) {
                    this.selectedPickup = selectedPickup;
                    this.selectedCarrier = this.carriers?.find(f => f.Id === selectedPickup?.CarrierId); 
                }
                this.carrierSelectionForm = this.fb.group({
                    deliveryZipCode: [this.selectedPickup?.DeliveryZipCode ?? '', [Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]],
                });
            });
        });
    }

    onCarrierSelected(event: ISelectionChangedEvent): void {
        this.selectedCarrierTouched = true;
        this.selectedCarrier = this.carriers.find(f => f.Id === event['selection']?.Id);
    }

    onCarrierSelectionFormSubmit(): void {
        this.orderService.selectedPickupOrders$.next(this.selectedOrders);
        this.orderService.selectedPickupCarrier$.next(this.selectedCarrier);
        this.orderService.selectedPickupZip$.next(this.carrierSelectionForm.value.deliveryZipCode as string);
        void this.router.navigate(['create-requests/date-selection']);
    }
}
