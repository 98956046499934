import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { TruckerService } from './trucker.service';
import { TruckerRoutingModule } from './trucker-routing.module';
import { TruckersComponent } from './trucker-list/truckers.component';
import { TruckerHeaderComponent } from './trucker-header/trucker-header.component';
import { TruckerDetailComponent } from './trucker-detail/trucker-detail.component';
import { TruckerInfoComponent } from './trucker-info/trucker-info.component';
import { TwicImageComponent } from './twic-image/twic-image.component';
import { ArchiveTruckerListComponent } from './trucker-list/archive-trucker-list/archive-trucker-list.component';
import { TruckerBanHistoryComponent } from './trucker-ban-history/trucker-ban-history.component';

@NgModule({
    declarations: [
        TruckersComponent,
        TruckerHeaderComponent,
        TruckerDetailComponent,
        TruckerInfoComponent,
        TwicImageComponent,
        ArchiveTruckerListComponent,
        TruckerBanHistoryComponent
    ],
    imports: [SharedModule, TruckerRoutingModule],
})
export class TruckerModule {
    static forRoot(): ModuleWithProviders<TruckerModule> {
        return {
            ngModule: TruckerModule,
            providers: [TruckerService],
        };
    }
}
