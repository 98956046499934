import { Component, OnInit } from '@angular/core';
import { DeliveryService } from '../services/delivery.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CalendarOptions, DatesSetArg, EventClickArg, EventSourceInput } from '@fullcalendar/core';
import { IDelivery } from '../../model/interfaces/delivery';
import { DeliveryStatuses } from '../../model/DeliveryStatuses';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { CustomerService } from '../../customers/customer.service';
import { CarrierService } from '../../carriers/services/carrier.service';
import { WarehouseService } from '../../warehouses/services/warehouse.service';
import { forkJoin } from 'rxjs';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IWarehouse } from '../../model/interfaces/warehouse';
import { GetDeliveriesForCalendarDTO } from '../../model/interfaces/custom/get-deliveries-for-calendar-dto';
import { CommonFunctions } from '../../common/services/common-functions.service';

@Component({
    selector: 'app-delivery-calendar',
    templateUrl: './delivery-calendar.component.html',
    styleUrls: ['./delivery-calendar.component.css'],
})
export class DeliveryCalendarComponent implements OnInit {
    calendarOptions: CalendarOptions = {
        initialView: 'timeGridWeek',
        plugins: [dayGridPlugin, timeGridPlugin],
        slotMinTime: '06:00:00',
        slotMaxTime: '20:00:00',
        events: [],
        eventClick: this.handleEventClick.bind(this),
        datesSet: this.handleDatesSet.bind(this),
        height: 'auto',
        contentHeight: 'auto',
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth'
        }
    };

    modalOptions = {
        text: '',
        title: '',
        width: '70%',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Go to Deliveries Page',
    };

    statusColors = {
        [DeliveryStatuses.Requested]: '#f90',
        [DeliveryStatuses.Confirmed]: '#528be9',
        [DeliveryStatuses.ReadyForCheckIn]: '#6aa84f',
        [DeliveryStatuses.CheckedIn]: '#a51fff'
    };

    start: string;
    end: string;
    customerMetaItems: MtSearchFilterItem[] = [];
    carrierMetaItems: MtSearchFilterItem[] = [];
    deliveryEquipmentTypeMetaItems: MtSearchFilterItem[] = [];
    deliveryTypeMetaItems: MtSearchFilterItem[] = [];
    deliveryStatusMetaItems: MtSearchFilterItem[] = [];
    warehouseMetaItems: MtSearchFilterItem[] = [];
    warehouses: IWarehouse[] = [];
    deliveriesByEventId: { [key: string]: IDelivery[] } = {};
    selectedDeliveries: IDelivery[] = [];
    deliveryCalendarTableDialogApi: IModalWrapperApi;
    
    constructor(
        private deliveryService: DeliveryService,
        private customerService: CustomerService,
        private carrierService: CarrierService,
        private warehouseService: WarehouseService,
    ) { }

    ngOnInit(): void {
        forkJoin([
            this.customerService.getActiveCustomers(),
            this.carrierService.getSimpleCarriers(),
            this.deliveryService.getSimpleDeliveryEquipmentTypes(),
            this.deliveryService.getSimpleDeliveryTypes(),
            this.deliveryService.getSimpleDeliveryStatuses(),
            this.warehouseService.getUserWarehouses(),
        ]).subscribe(([
            customers,
            carriers,
            deliveryEquipmentTypes,
            deliveryTypes,
            deliveryStatuses,
            warehouses,
        ]) => {
            this.customerMetaItems = CommonFunctions.mapMtSearchFilterItems(customers);
            this.carrierMetaItems = CommonFunctions.mapMtSearchFilterItems(carriers);
            this.deliveryEquipmentTypeMetaItems = CommonFunctions.mapMtSearchFilterItems(deliveryEquipmentTypes);
            this.deliveryTypeMetaItems = CommonFunctions.mapMtSearchFilterItems(deliveryTypes);
            this.deliveryStatusMetaItems = CommonFunctions.mapMtSearchFilterItems(deliveryStatuses);
            this.warehouses = warehouses;
            const warehouseMetaItems = warehouses.map((item) => {
                return new MtSearchFilterItem({ Id: item.Id, Name: item.WarehouseId }, false);
            });
            this.warehouseMetaItems = warehouseMetaItems;
        });
    }

    handleEventClick(arg: EventClickArg): void {
        const eventId = arg.event.id;
        this.selectedDeliveries = this.deliveriesByEventId[eventId];
        this.modalOptions.text = arg.event.title;
        this.modalOptions.title = arg.event.title;
        this.deliveryCalendarTableDialogApi.show();
    }
    
    handleDatesSet(arg: DatesSetArg): void {
        this.start = arg.start.toDateString();
        this.end = arg.end.toDateString();
        this.fetchEvents();
    }

    fetchEvents(): void {
        const selectedCustomersIds: number[] = this.getSelectedFilters(this.customerMetaItems);
        const selectedCarriersIds: number[] = this.getSelectedFilters(this.carrierMetaItems);
        const selectedEquipmentTypeIds: number[] = this.getSelectedFilters(this.deliveryEquipmentTypeMetaItems);
        const selectedDeliveryTypeIds: number[] = this.getSelectedFilters(this.deliveryTypeMetaItems);
        const selectedDeliveryStatusIds: number[] = this.getSelectedFilters(this.deliveryStatusMetaItems);
        const warehouseMetaItems = this.warehouseMetaItems.filter((item) => item.Selected).map((item) => item.Item.Name);
        const warehouses = this.warehouses.filter((item) => warehouseMetaItems.includes(`${item.WarehouseId}`));
        const selectedWarehouseIds: number[] = warehouses.map((item) => item.Id);

        const dto: GetDeliveriesForCalendarDTO = {
            start: this.start,
            end: this.end,
            customerIds: selectedCustomersIds,
            carrierIds: selectedCarriersIds,
            warehouseIds: selectedWarehouseIds,
            equipmentTypeIds: selectedEquipmentTypeIds,
            deliveryTypeIds: selectedDeliveryTypeIds,
            deliveryStatusIds: selectedDeliveryStatusIds,
        }

        this.deliveryService.getDeliveriesForCalendar(dto).subscribe(deliveries => {
            const events = this.groupDeliveriesByDateAndStatus(deliveries);
            this.calendarOptions.events = events;
        });
    }

    groupDeliveriesByDateAndStatus(deliveries: IDelivery[]): EventSourceInput {
        const groupedEvents = {};
        deliveries.forEach(delivery => {
            const date = (delivery.StatusId === DeliveryStatuses.Requested) 
                ? delivery.DeliveryDate.toString()
                : delivery.ConfirmedDeliveryDate.toString();

            if (!groupedEvents[date]) {
                groupedEvents[date] = {};
            }

            const status = delivery.StatusId;
            if (!groupedEvents[date][status]) {
                groupedEvents[date][status] = [];
            }

            groupedEvents[date][status].push(delivery);
        });

        let eventId = 1;
        const events: EventSourceInput = [];
        for (const date in groupedEvents) {
            for (const status in groupedEvents[date]) {
                eventId++;
                const deliveriesInGroup = groupedEvents[date][status] as IDelivery[];
                const isAllDay = Number(status) === DeliveryStatuses.Requested;
                const title = deliveriesInGroup.length === 1 
                    ? `${deliveriesInGroup.length} Delivery` 
                    : `${deliveriesInGroup.length} Deliveries`;

                events.push({
                    id: eventId.toString(),
                    title: title,
                    start: date,
                    end: date,
                    color: this.statusColors[status],
                    allDay: isAllDay,
                });

                // Store deliveries by event ID
                this.deliveriesByEventId[eventId.toString()] = deliveriesInGroup;
            }
        }

        return events;
    }

    closeDeliveryCalendarTableDialog(): void {
        this.deliveryCalendarTableDialogApi.close();
        this.selectedDeliveries = [];
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }
}
