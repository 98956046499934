import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';

import { OfficeService } from '../services/office.service';
import { IOffice } from '../../model/interfaces/office';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { OfficesEntityListConfig } from './offices.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-offices',
    templateUrl: './offices.component.html',
})
export class OfficesComponent implements OnInit {
    offices: IOffice[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new OfficesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddOffice = false;

    constructor(
        private officeService: OfficeService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canAddOffice = this.claimsService.hasClaim(ClaimTypes.Offices, [ClaimValues.FullAccess]);
        if (!this.canAddOffice) {
            this.entityListConfig.delete = null;
        }
        this.getOffices();
    }

    getOffices(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.officeService.get(searchparams).subscribe((answer) => {
            this.offices = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getOffices();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getOffices();
    }

    officeSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/offices', event.entity.Id]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        this.officeService.deleteOffice(event.entity.Id as number).subscribe(
            () => {
                this.getOffices();
                this.notificationsService.success(' Deleted');
            },
            () => {
                this.getOffices();
                this.notificationsService.error('Delete Failed');
            },
        );
    }
}
