<div *ngIf="selectedOrders?.length > 0">
    <div class="row">
        <div class="col-md-7">
            <h2>Pickup Scheduling - Step 2 of 2</h2>
            <h4>
                Select an available date & time to pick up these orders.
            </h4>
        </div>
    </div>
    <br>
    <div class="miles-editable miles-card centered-card">
        <div *ngIf="selectedPickup?.Id > 0">
            <h3 class="pickup-number-label">Pickup Number: {{ selectedPickup?.PickupNumber}}</h3>
            <br>
        </div>
        <table class="table table-striped pickup-scheduling-table">
            <thead>
                <tr>
                    <th><b>Order Number</b></th>
                    <th><b>Office</b></th>
                    <th><b>Warehouse</b></th>
                    <th><b>Customer</b></th>
                    <th><b>Date Created</b></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of selectedOrders">
                    <td><span>{{ order.OrderNumber }}</span></td>
                    <td><span>{{ order.Warehouse?.Office?.Title }}</span></td>
                    <td><span>{{ order.Warehouse?.WarehouseId }}</span></td>
                    <td><span>{{ order.Customer?.Name }}</span></td>
                    <td><span>{{ order.DateCreated | date: 'medium' }}</span></td>
            </tbody>
        </table>
        <form [formGroup]="driverSelectionForm" (ngSubmit)="onDriverSelectionFormSubmit()" *ngIf="driverSelectionForm">
            <div class="row input-row-padding">
                <div class="input-group input-group-text">
                    <label [ngClass]="{ 'error-text': selectedCarrierTouched && !selectedCarrier}">
                        <i class="fa fa-lg fa-fw fa-truck reverse-icon"></i> Select Trucking Company:
                    </label>
                    <span class="error-text ng-star-inserted"> *</span>
                    <mt-type-ahead [ngClass]="{ 'has-error': selectedCarrierTouched && !selectedCarrier}"
                        (selectionChanged)="onCarrierSelected($event)" [selectedItem]="selectedCarrier" [items]="carriers"
                        [maxToShow]="1000">
                    </mt-type-ahead>
                    <div class="error-text" *ngIf="!selectedCarrier && selectedCarrierTouched">
                        <small>Carrier is required.</small>
                    </div>
                </div>
            </div>
            <div *ngIf="selectableDrivers.length > 0" class="row input-row-padding">
                <div class="input-group input-group-text">
                    <label>
                        <i class="fa fa-lg fa-fw fa-drivers-license reverse-icon"></i> Select Driver:
                    </label>
                    <mt-type-ahead
                        (selectionChanged)="onDriverSelected($event)" 
                        [selectedItem]="selectedDriver" 
                        [items]="selectableDrivers"
                        [maxToShow]="1000"
                        [disabled]="!selectedCarrier"
                    >
                    </mt-type-ahead>
                </div>
            </div>
            <div class="row input-row-padding">
                <div class="input-group input-group-text">
                    <label
                        [ngClass]="{ 'error-text': driverSelectionForm.controls.deliveryZipCode.invalid && driverSelectionForm.controls.deliveryZipCode.touched }">
                        <i class="fa fa-lg fa-fw fa-map-o"></i> Delivery Zip Code:
                    </label>
                    <input class="form-control" type="text" formControlName="deliveryZipCode"
                        [ngClass]="{ 'has-error': driverSelectionForm.controls.deliveryZipCode.invalid && driverSelectionForm.controls.deliveryZipCode.touched }">
                    <div class="error-text"
                        *ngIf="driverSelectionForm.controls.deliveryZipCode.invalid && driverSelectionForm.controls.deliveryZipCode.touched">
                        <small *ngIf="driverSelectionForm.controls.deliveryZipCode.errors?.pattern">Invalid Zip-code
                            format.</small>
                    </div>
                </div>
            </div>
            <div class="row input-row-padding">
                <mt-dynamic-field
                    (valueChanges)="startDateChange($event)" [field]="dateRequestedField" [form]="driverSelectionForm">
                </mt-dynamic-field>
            </div>
            <div *ngIf="availableDateTimes?.length" class="row input-row-padding">
                <div>
                    <label>
                        <i class="fa fa-lg fa-fw fa-clock-o"></i> Available Times:
                    </label>
                </div>
                <div class="datetime-buttons-grid">
                    <button *ngFor="let dateTime of availableDateTimes" 
                        type="button"  
                        class="btn btn-success datetime-button"
                        [class.active]="selectedDateTime === dateTime.TimeSlot"
                        [class.btn-btn]="selectedDateTime === dateTime.TimeSlot"
                        [class.btn-success]="selectedDateTime === dateTime.TimeSlot"
                        (click)="onDateTimeSelected(dateTime.TimeSlot)">
                        ({{dateTime.NumberOfSlots}}) {{ dateTime.TimeSlot | date:'shortTime' }}
                    </button>
                  </div>
            </div>
            <br>
            <div class="text-center">
                <button 
                    class="btn btn-success" 
                    type="button"
                    mtDisableClickDuringHttpCalls
                    (click)="confirmPickup()"
                    [disabled]="selectedPickup?.StatusId === pickupStatus.Confirmed || pickupFormIsInvalid()">
                    Confirm Pickup
                </button>
                <button 
                    *ngIf="selectedPickup"
                    class="btn btn-success" 
                    type="submit"
                    mtDisableClickDuringHttpCalls
                    [disabled]="pickupFormIsInvalid() || pickupHasNotBeenAltered()">
                    Save Pickup
                </button>
                <button 
                    *ngIf="selectedPickup" 
                    class="btn btn-danger" 
                    type="button" 
                    mtDisableClickDuringHttpCalls
                    (click)="cancelPickup(selectedPickup)">
                    Cancel Pickup
                </button>
            </div>
        </form>
    </div>
</div>
