import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { ICarrier } from '../model/interfaces/carrier';
// import
import { RegistrationRequestDynamicControlsPartial } from '../model/partials/registration-request-partial.form-controls';
import { IRegistrationRequest } from '../model/interfaces/registration-request';
import { ICustomer } from '../model/interfaces/customer';

export class RegistrationRequestDynamicConfig<T extends IRegistrationRequest> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private registrationRequest: T, private carriers: ICarrier[], private customers: ICustomer[], private configControls?: string[]) {
        super();
        const dynamicControls = new RegistrationRequestDynamicControlsPartial(this.registrationRequest, {carriers: carriers, customers: customers});
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'FirstName',
                'LastName',
                'CarrierId',
                'CustomerId',
                'EmailAddress',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
