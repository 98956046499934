<div class="row">
    <div class="col-md-7">
        <h2>{{ pageTitle() }}</h2>
        <h4>
            Select the orders you would like to schedule for pick up.
        </h4>
    </div>
</div>
<div>
    <mt-dynamic-search-filters [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search($event)"></mt-dynamic-search-filters>
    <mt-search-filter-daterange
        *ngIf="datepickerEntityName"
        [entity]="datepickerEntityName"
        [startDate]="startDate"
        [endDate]="endDate"
        (selectionChanged)="dateSelectionChanged($event)"
    ></mt-search-filter-daterange>
    <button class="btn btn-success" (click)="clear()">Clear</button>
</div>
<br>
<mt-entity-list 
    *ngIf="orders && entityListConfig"
    [entities]="orders" 
    [total]="total" 
    [(currentPage)]="currentPage" 
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getOrders()" 
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (selectionChangedEvent)="selectionChanged($event)"
    [selectedEntities]="selectedOrders"
>
</mt-entity-list>

<br><button class="btn btn-success" [disabled]="!selectedOrders || selectedOrders.length === 0" (click)="nextStep()">Next Step</button>

<mt-modal-wrapper
    [showActions]="false"
    [autoShow]="false"
    (ready)="orderTotalPalletDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }">
    
    <order-total-pallet-dialog *ngIf="selectedOrder" [order]="selectedOrder" (onClose)="closeOrderTotalPalletDialog()"></order-total-pallet-dialog>
</mt-modal-wrapper>

<mt-modal-wrapper
    [showActions]="false"
    [autoShow]="false"
    (ready)="orderTotalWeightDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }">

    <order-total-weight-dialog *ngIf="selectedOrder" [order]="selectedOrder" (onClose)="closeOrderTotalWeightDialog()"></order-total-weight-dialog>
</mt-modal-wrapper>
