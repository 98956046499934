<div class="text-center public-responsive">
    <h1>Check-In</h1>
    <img class="page-center-message" src="{{ logoImageSrc }}" />
    <p class="f-20">Please enter your phone number to get started.</p>
    <br />
    <form class="phone-form" *ngIf="formControls && phoneNumberInputForm" [formGroup]="phoneNumberInputForm">
        <div class="form-group">
            <mt-dynamic-field class="input-large" [field]="formControls.Phone" [form]="phoneNumberInputForm"></mt-dynamic-field>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <button type="button" (click)="savePhoneAndProceedAsPickup()" class="btn btn-primary">I'm Picking Up</button>
            </div>
            <div class="col-md-6 col-sm-12">
                <button type="button" (click)="savePhoneAndProceedAsDelivery()" class="btn btn-primary">Container</button>
            </div>
        </div>
    </form>
    <br />
</div>
