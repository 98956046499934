import { Component } from '@angular/core';
import {IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';
import { IOrder } from '../../model/interfaces/order';
import { Router } from '@angular/router';

@Component({
    template: `
        <ng-container>
            <button (click)="orderSelected()" class="btn btn-success btn-xs">Edit</button>
        </ng-container>
    `,
})
export class EditOrderButtonComponent implements IEntityListDynamicCellComponentGeneric<IOrder> {
    entityListComponentMembers: IEntityListComponentMembers;
    entity: IOrder;
    constructor(private router: Router ) { }

    orderSelected(): void {
        void this.router.navigate(['/orders/manage-scheduling', this.entity["CustomerId"], this.entity["OrderNumber"]]);
    }
}
