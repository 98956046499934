import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { DeliveriesEntityListConfig } from './deliveries.entity-list-config';
import { IColumnSortedEvent, SortDirection, IItemDeletedEvent, ISelectionChangedEvent } from '@mt-ng2/entity-list-module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { CustomerService } from '../../customers/customer.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { DeliveryService } from '../services/delivery.service';
import { IDelivery } from '../../model/interfaces/delivery';
import { CommonService } from '../../common/services/common.service';
import { ICountry } from '../../model/interfaces/country';
import { CarrierService } from '../../carriers/services/carrier.service';
import { OrderService } from '../../orders/order.service';
import { UserRoles } from '../../model/UserRoles';
import { forkJoin } from 'rxjs';
import { DeliveryStatuses } from '../../model/DeliveryStatuses';
import { saveAs } from 'file-saver';
import { HttpErrorResponse } from '@angular/common/http';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control';
import { WarehouseService } from '../../warehouses/services/warehouse.service';
import { IWarehouse } from '../../model/interfaces/warehouse';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { ActivatedRoute } from '@angular/router';
import { ISearchbarControlAPI } from '@mt-ng2/searchbar-control';

@Component({
    selector: 'app-deliveries',
    templateUrl: './deliveries.component.html',
})
export class DeliveriesComponent implements OnInit {
    deliveries: IDelivery[] = [];
    searchControl = new UntypedFormControl();
    customerMetaItems: MtSearchFilterItem[] = [];
    carrierMetaItems: MtSearchFilterItem[] = [];
    deliveryEquipmentTypeMetaItems: MtSearchFilterItem[] = [];
    deliveryTypeMetaItems: MtSearchFilterItem[] = [];
    deliveryStatusMetaItems: MtSearchFilterItem[] = [];
    countryMetaItems: MtSearchFilterItem[] = [];
    warehouseMetaItems: MtSearchFilterItem[] = [];
    countries: ICountry[] = [];
    warehouses: IWarehouse[] = [];
    total: number;
    currentPage = 1;
    itemsPerPage = 10;
    query = '';
    entityListConfig: DeliveriesEntityListConfig;
    order = 'DeliveryDate';
    orderDirection: string;
    canAddDelivery = false;
    authUserRole: number;
    selectedDeliveries: IDelivery[] = [];
    confirmDeliveriesDialogApi: IModalWrapperApi;
    deliveryDatePickerEntityName = 'Requested Delivery Date';
    deliveryDateStart: Date;
    deliveryDateEnd: Date;
    confirmedDatePickerEntityName = 'Confirmed Delivery Date';
    confirmedDateStart: Date;
    confirmedDateEnd: Date;
    includeArchived: boolean;
    searchbarControlAPI?: ISearchbarControlAPI;

    constructor(
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private customerService: CustomerService,
        private carrierService: CarrierService,
        private deliveryService: DeliveryService,
        private commonService: CommonService,
        private authService: AuthService,
        private orderService: OrderService,
        private warehouseService: WarehouseService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.canAddDelivery = this.claimsService.hasClaim(ClaimTypes.Deliveries, [ClaimValues.FullAccess]);
        if (!this.canAddDelivery) {
            this.entityListConfig.delete = null;
        }

        const loggedIn = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(loggedIn.AuthId).subscribe(authUserRole => {
            this.authUserRole = authUserRole;
            forkJoin([
                this.customerService.getActiveCustomers(),
                this.carrierService.getSimpleCarriers(),
                this.deliveryService.getSimpleDeliveryEquipmentTypes(),
                this.deliveryService.getSimpleDeliveryTypes(),
                this.deliveryService.getSimpleDeliveryStatuses(),
                this.commonService.getCountries(),
                this.warehouseService.getUserWarehouses(),
            ]).subscribe(([
                customers,
                carriers,
                deliveryEquipmentTypes,
                deliveryTypes,
                deliveryStatuses,
                countries,
                warehouses,
            ]) => {
                this.customerMetaItems = CommonFunctions.mapMtSearchFilterItems(customers);
                this.carrierMetaItems = CommonFunctions.mapMtSearchFilterItems(carriers);
                this.deliveryEquipmentTypeMetaItems = CommonFunctions.mapMtSearchFilterItems(deliveryEquipmentTypes);
                this.deliveryTypeMetaItems = CommonFunctions.mapMtSearchFilterItems(deliveryTypes);
                this.deliveryStatusMetaItems = CommonFunctions.mapMtSearchFilterItems(deliveryStatuses);

                this.countries = countries;
                const countryMetaItems = countries.map((item) => {
                    return new MtSearchFilterItem({ Id: 0, Name: `${item['Name']} (${item['CountryCode']})` }, false);
                });

                this.warehouses = warehouses;
                const warehouseMetaItems = warehouses.map((item) => {
                    return new MtSearchFilterItem({ Id: item.Id, Name: item.WarehouseId }, false);
                });

                this.countryMetaItems = countryMetaItems;
                this.warehouseMetaItems = warehouseMetaItems;
                this.handleQueryParams();
                this.initEntityListConfig();
                this.getDeliveries();
            });
        });
    }

    getDeliveries(): void {
        const searchParams = this.getSearchParams();
        this.deliveryService.get(searchParams).subscribe((answer) => {
            this.deliveries = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            this.syncSelectedDeliveries();
        });
    }

    private syncSelectedDeliveries(): void {
        if (!this.selectedDeliveries || this.selectedDeliveries?.length <= 0)
            return;

        this.selectedDeliveries = this.deliveries.filter((delivery) => this.selectedDeliveries.some((selectedDelivery) => selectedDelivery.Id === delivery.Id));
    }

    private initEntityListConfig(): void {
        this.entityListConfig = new DeliveriesEntityListConfig(this.authUserRole);
        this.order = 'DeliveryDate';
        this.orderDirection = 'asc';
    }

    getSearchParams(): SearchParams {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        return new SearchParams(searchEntity);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedCustomersIds: number[] = this.getSelectedFilters(this.customerMetaItems);
        const selectedCarriersIds: number[] = this.getSelectedFilters(this.carrierMetaItems);
        const selectedEquipmentTypeIds: number[] = this.getSelectedFilters(this.deliveryEquipmentTypeMetaItems);
        const selectedDeliveryTypeIds: number[] = this.getSelectedFilters(this.deliveryTypeMetaItems);
        const selectedDeliveryStatusIds: number[] = this.getSelectedFilters(this.deliveryStatusMetaItems);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: selectedCustomersIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CarrierIds',
                valueArray: selectedCarriersIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'DeliveryEquipmentTypeIds',
                valueArray: selectedEquipmentTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'DeliveryTypeIds',
                valueArray: selectedDeliveryTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'DeliveryStatusIds',
                valueArray: selectedDeliveryStatusIds,
            }),
        );

        const countryMetaItems = this.countryMetaItems.filter((item) => item.Selected).map((item) => item.Item.Name);
        const countries = this.countries.filter((item) => countryMetaItems.includes(`${item.Name} (${item.CountryCode})`));
        const selectedCountryCodes: string[] = countries.map((item) => item.CountryCode);
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CountryCodes',
                valueArray: selectedCountryCodes,
            }),
        );
        
        const warehouseMetaItems = this.warehouseMetaItems.filter((item) => item.Selected).map((item) => item.Item.Name);
        const warehouses = this.warehouses.filter((item) => warehouseMetaItems.includes(`${item.WarehouseId}`));
        const selectedWarehouses: number[] = warehouses.map((item) => item.Id);
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'WarehouseIds',
                valueArray: selectedWarehouses,
            }),
        );

        if (this.deliveryDateStart) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DeliveryDateStart',
                value: this.deliveryDateStart.toDateString(),
            }));
        }

        if (this.deliveryDateEnd) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DeliveryDateEnd',
                value: this.deliveryDateEnd.toDateString(),
            }));
        }

        if (this.confirmedDateStart) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'ConfirmedDeliveryDateStart',
                value: this.confirmedDateStart.toDateString(),
            }));
        }

        if (this.confirmedDateEnd) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'ConfirmedDeliveryDateEnd',
                value: this.confirmedDateEnd.toDateString(),
            }));
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeArchived',
                value: this.includeArchived ? 'true' : 'false',
            }),
        );

        return _extraSearchParams;
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getDeliveries();
    }

    search(query: string): void {
        this.query = query;
        this.getDeliveries();
    }

    selectionChanged(event: ISelectionChangedEvent): void {
        this.selectedDeliveries = event.selectedEntities;
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const delivery = event.entity as IDelivery;
        const isCarrierOrCustomer = this.authUserRole === UserRoles.Carrier || this.authUserRole === UserRoles.Customer;
        if (isCarrierOrCustomer && delivery.StatusId !== DeliveryStatuses.Requested) {
            this.notificationsService.error('Only requested deliveries can be deleted.');
            return;
        }

        this.deliveryService.delete(event.entity.Id as number).subscribe({
            next: () => {
                this.notificationsService.success('Delivery deleted successfully.');
                this.getDeliveries();
            },
            error: (response: HttpErrorResponse) => {
                this.commonService.handleErrorResponse(response);
            }
        });
    }

    downloadDeliveriesTemplate(): void {
        this.deliveryService.downloadDeliveriesTemplate().subscribe({
            next: (blob) => {
                saveAs(blob, 'DeliveriesTemplate.csv');
            },
            error: (response: HttpErrorResponse) => {
                this.commonService.handleErrorResponse(response);
            }
        });
    }

    onFileSelected(event: Event): void {
        const element = event.currentTarget as HTMLInputElement;
        const file = element.files[0];
        if (!file)
            return;

        this.deliveryService.uploadDeliveriesTemplate(file).subscribe({
            next: () => {
                this.notificationsService.success('Deliveries uploaded successfully.');
                this.getDeliveries();
            },
            error: (response: HttpErrorResponse) => {
                this.commonService.handleErrorResponse(response);
            }
        });
    }

    isAdminUser(): boolean {
        return this?.authUserRole === UserRoles.Admin;
    }

    isCarrierUser(): boolean {
        return this?.authUserRole === UserRoles.Carrier;
    }

    isCustomerUser(): boolean {
        return this?.authUserRole === UserRoles.Customer;
    }

    openConfirmDeliveriesDialog(): void {
        const archivedDeliverySelected = this.selectedDeliveries.some((delivery) => delivery.Archived);
        if (archivedDeliverySelected) {
            this.notificationsService.error('Archived deliveries cannot be confirmed.');
            return;
        }

        const nonRequestedDeliverySelected = this.selectedDeliveries.some((delivery) => delivery.StatusId !== DeliveryStatuses.Requested);
        if (nonRequestedDeliverySelected) {
            this.notificationsService.error('Only requested deliveries can be confirmed.');
            return;
        }

        const multiCarrierSelected = this.selectedDeliveries.some((delivery) => delivery.CarrierId !== this.selectedDeliveries[0].CarrierId);
        if (multiCarrierSelected) {
            this.notificationsService.error('Only deliveries with the same carrier can be confirmed together.');
            return;
        }

        const deliveriesWithoutCarrier = this.selectedDeliveries.some((delivery) => !delivery.CarrierId);
        if (deliveriesWithoutCarrier) {
            this.notificationsService.error('Deliveries without a carrier cannot be confirmed. Please assign a carrier to all selected deliveries.');
            return;
        }
        this.confirmDeliveriesDialogApi.show();
    }
    
    closeConfirmDeliveriesDialog(): void {
        this.confirmDeliveriesDialogApi.close();
        this.getDeliveries();
        this.selectedDeliveries = [];
    }

    deliveryDateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.deliveryDateStart = range.startDate;
        this.deliveryDateEnd = range.endDate;
        this.getDeliveries();
    }

    confirmedDateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.confirmedDateStart = range.startDate;
        this.confirmedDateEnd = range.endDate;
        this.getDeliveries();
    }

    includeSelectionChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.includeArchived = event.value;
        this.getDeliveries();
    }

    handleQueryParams(): void {
        this.route.queryParamMap.subscribe(params => {
            const deliveryNumbersParam = params.get('deliveryNumbers');
            this.searchbarControlAPI?.getSearchControl()?.setValue(deliveryNumbersParam);
            if (deliveryNumbersParam?.length > 0) {
                return;
            }

            if (this.authUserRole === UserRoles.Admin) {
                this.deliveryStatusMetaItems.find((item) => item.Item.Id === DeliveryStatuses.Requested).Selected = true;
            }
        });
    }
}
