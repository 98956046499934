
import { environment } from '../../environments/environment';
import { ILoginConfig } from '@mt-ng2/login-module';

// the api key below is for BreckDemo and should be updated
export const LoginConfigOverride: ILoginConfig = {
    googleAuthConfig: environment.googleApiKey ? { googleApiKey: environment.googleApiKey } : null,
    messageOverrides: { 
        failedPattern: 'Valid passwords must be at least 8 characters, have a capital letter and number.',
        forgotPasswordSuccess: 'Thank you. If an account exists with the email address provided, we will send an email with instructions on how to reset your password.' 
    },
    passwordPattern: '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d!$%@#£€*?&]{8,}',
    loginComponentLinks: [
        {
            linkHtml: '<a>I forgot my password</a>',
            routerLinkPath: '/forgotpassword',
        },
        {
            linkHtml: "<div class='create-account-link'>Don't have an account? <a>Create Account</a></div>",
            routerLinkPath: '/public/registration',
        }
    ]
};
