import { HttpClient, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IPickupForSearch } from '../../interfaces/custom/pickup-for-search';
import { IPickup } from '../../../model/interfaces/pickup';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { IPickupParams } from '../../../model/interfaces/custom/pickup-params';
import { SearchParams } from '@mt-ng2/common-classes';
import { IUser } from '../../../model/interfaces/user';
import { OrderLimits } from '../../classes/order-limits';
import { DriverTimeSlot } from '../../classes/driver-time-slot';

export const emptyPickup: IPickup = {
    Id: 0,
    PickupNumber: '', 
    ValidTwicCard : false, 
    DateCreated: null,
    DateRequested: null, 
    DeliveryZipCode: '',
    CreatedById: 0,
    ModifiedById: 0,
    SecurityGateFee: 0,
    StatusId: null,
    WalkIn: false,
};


@Injectable()
export class PickupService extends BaseService<IPickup> {
    constructor(public http: HttpClient) {
        super('/pickups', http);
    }

    getEmptyPickup(): IPickup {
        return { ...emptyPickup };
    }

    createScheduledPickup(params: IPickupParams): Observable<IPickup>{
        return this.http
            .put<IPickup>(`/pickups/create`, params)
            .pipe(
                catchError((err, caught) => this.handleError(err as Response, caught))
            );
    }

    search(searchparameters: SearchParams): Observable<HttpResponse<IPickupForSearch[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IPickupForSearch[]>(`/pickups/search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    cancel(pickupId: number): Observable<void> {
        return this.http.put<void>(`/pickups/${pickupId}/cancel`, {
            responseType: 'text' as 'json',
        });
    }

    confirm(pickupId: number): Observable<void> {
        return this.http.put<void>(`/pickups/${pickupId}/confirm`, {
            responseType: 'text' as 'json',
        });
    }

    isWithinAppointmentTimeframe(pickupId: number): Observable<void> {
        return this.http.put<void>(`/pickups/${pickupId}/within-appointment-timeframe`, {
            responseType: 'text' as 'json',
        });
    }

    isOutsidePrePaymentTimeframe(pickupId: number): Observable<boolean> {
        return this.http.put<boolean>(`/pickups/${pickupId}/outside-pre-payment-timeframe`, {
            responseType: 'text' as 'json',
        });
    }

    getPickupDrivers(): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/pickups/drivers`);
    }

    getDriverSearchTimes(orderIds: number[], startDate: Date, endDate: Date, customerId: number, officeId: number, driverId: number): Observable<DriverTimeSlot[]> {
        return this.http.post<DriverTimeSlot[]>(`/pickups/driver/${driverId}/searchtimes`, {
            CustomerId: customerId,
            EndDate: endDate,
            OfficeId: officeId,
            OrderIds: orderIds,
            StartDate: startDate,
        });
    }

    getOrderLimits(): Observable<OrderLimits> {
        return this.http.get<OrderLimits>(`/options/order-limits`);
    }

    updateGatePassNumber(pickup: IPickup): Observable<void> {
        return this.http.put<void>(`/pickups/${pickup.Id}/update-gate-pass-number`, pickup);
    }

    sendUpdatedEmails(pickupId: number): Observable<void> {
        return this.http.post<void>(`/pickups/${pickupId}/send-updated-emails`, null);
    }

    sendConfirmedEmails(pickupId: number): Observable<void> {
        return this.http.post<void>(`/pickups/${pickupId}/send-confirmed-emails`, null);
    }

    getRemainingBalance(pickupId: number): Observable<number> {
        return this.http.get<number>(`/pickups/${pickupId}/remaining-balance`);
    }

    captureSquareOrder(pickupId: number, squarePaymentToken: string): Observable<null> {
        return this.http.post<null>(`/pickups/${pickupId}/capture-square-order/${squarePaymentToken}`, null);
    }

    setSecurityGateFee(pickupId: number): Observable<number> {
        return this.http.put<number>(`/pickups/${pickupId}/security-gate-fee`, null);
    }

    private _selectedPickup: BehaviorSubject<IPickup> = new BehaviorSubject<IPickup>(null);
    public get selectedPickup$(): BehaviorSubject<IPickup> {
        return this._selectedPickup;
    }
}
