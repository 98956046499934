<div class="row">
    <div class="col-md-7">
        <h2>Delivery Calendar</h2>
    </div>
</div>
<div>
    <mt-search-filter-select
        [items]="deliveryStatusMetaItems"
        entity="Status"
        (selectionChanged)="fetchEvents()">
    </mt-search-filter-select>
    <mt-search-filter-select
        [items]="customerMetaItems"
        entity="Customer"
        (selectionChanged)="fetchEvents()">
    </mt-search-filter-select>
    <mt-search-filter-select
        [items]="carrierMetaItems"
        entity="Carrier"
        (selectionChanged)="fetchEvents()">
    </mt-search-filter-select>
    <mt-search-filter-select
        [items]="deliveryEquipmentTypeMetaItems"
        entity="Equipment Type"
        (selectionChanged)="fetchEvents()">
    </mt-search-filter-select>
    <mt-search-filter-select
        [items]="deliveryTypeMetaItems"
        entity="Delivery Type"
        (selectionChanged)="fetchEvents()">
    </mt-search-filter-select>
    <mt-search-filter-select
        [items]="warehouseMetaItems"
        valueIdAccessor="Warehouse"
        entity="Warehouse"
        (selectionChanged)="fetchEvents()">
    </mt-search-filter-select>
</div>
<br>
<div class="row">
    <div class="col-md-12">
        <div class="miles-card padded">
            <br>
            <full-calendar [options]="calendarOptions"></full-calendar>
        </div>
    </div>
</div>

<mt-modal-wrapper
    [showActions]="false"
    [autoShow]="false"
    (ready)="deliveryCalendarTableDialogApi = $event"
    [options]="modalOptions">

    <delivery-calendar-table-dialog 
        [deliveries]="selectedDeliveries" 
        (onClose)="closeDeliveryCalendarTableDialog()">
    </delivery-calendar-table-dialog>
</mt-modal-wrapper>