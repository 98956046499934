<div class="row">
    <div class="col-md-6">
        <div *ngIf="contact">
            <app-contact-info
            [contact]="contact"
            [canEdit]="canEdit">
            </app-contact-info>
            <br/>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <a (click)="close()" class="btn btn-default">Close</a>
    </div>
</div>
