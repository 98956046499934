<div *ngIf="trucker?.Id > 0">
    <div class="row">
        <div class="col-md-7">
            <h2>{{trucker.FirstName}} {{trucker.LastName}} Ban History</h2>
        </div>
    </div>
    <br>
    <mt-entity-list 
        *ngIf="entityListConfig"
        [entities]="truckerBanHistory"
        [total]="total"
        [(itemsPerPage)]="itemsPerPage"
        [(currentPage)]="currentPage"
        (pageChanged)="getTruckerBanHistory()"
        (columnSortedEvent)="columnSorted($event)"
        [entityListConfig]="entityListConfig">
    </mt-entity-list>
    <br>
    <a (click)="close()" class="btn btn-default">Close</a>
</div>