import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { CommonModule } from '@angular/common';
import { PickupsRoutingModule } from './pickups-routing.module';
import { PickupService } from '../model/shared-entities/pickups/pickup.service';
import { PickupsListComponent } from './pickups-list/pickups-list.component';
import { PickupPrePaymentComponent } from './pickup-pre-payment/pickup-pre-payment.component';
import { MtPayPalModule, IMtPayPalModuleConfig, MtPayPalModuleConfigToken } from '@mt-ng2/paypal';
import { environment } from '../environments/environment';

const paypalConfig: IMtPayPalModuleConfig = {
    clientId: environment.payPalClientId 
}

@NgModule({
    declarations: [PickupsListComponent, PickupPrePaymentComponent],
    imports: [SharedModule, PickupsRoutingModule, CommonModule, MtPayPalModule],
})
export class PickupModule {
    static forRoot(): ModuleWithProviders<PickupModule> {
        return {
            ngModule: PickupModule,
            providers: [PickupService, { provide: MtPayPalModuleConfigToken, useValue: paypalConfig }],
        };
    }
}
