<div class="miles-editable miles-card padded">
    <h4>Pickup Overrides</h4>
    <div *ngIf="selectedPickupOverride && canEdit">
        <mt-dynamic-form  [config]="formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)" (formCreated)="formCreated($event)">
            <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">Save</button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
        </mt-dynamic-form>
    </div>
    <div *ngIf="!selectedPickupOverride">
        <table class="table table-hover table-striped" style="color: #000">
            <thead>
                <tr class="no-pointer">
                    <th>
                        <b>Start Time</b>
                    </th>
                    <th>
                        <b>End Time</b>
                    </th>
                    <th>
                        <b>No Of Pickups Per Hour</b>
                    </th>
                    <th>
                        <b>Is Recurring</b>
                    </th>
                    <th>
                        <b>Starts On</b>
                    </th>
                    <th>
                        <b>Ends On</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Edit</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Delete</b>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let override of officePickupOverrides">
                    <td class="ellipsis">
                        <span *ngIf="!isEditMode(override)">
                            {{ override.StartTime }}
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!isEditMode(override)">
                            {{override.EndTime}}
                        </span>
                    </td>
                    <td>
                        <div *ngIf="!isEditMode(override)">
                            {{override.NoOfPickupsPerHour}}
                        </div>
                    </td>
                    <td>
                        <div *ngIf="!isEditMode(override)">
                            {{override.IsRecurring ? 'Yes' : 'No'}}
                        </div>
                    </td>
                    <td>
                        <span>
                            {{override.FromDate | date}}
                        </span>
                    </td>
                    <td>
                        <span *ngIf="override.NoEndDate">
                            No End Date
                        </span>
                        <span *ngIf="!override.NoEndDate">
                            {{override.ToDate | date}}
                        </span>
                    </td>
                    <td *ngIf="canEdit">
                        <div>
                            <i class="fa fa-2x fa-fw fa-pencil-square-o icon-main" (click)="setEdit(override)"></i>
                        </div>
                    </td>
                    <td *ngIf="canEdit">
                        <div>
                            <i class="fa fa-2x fa-fw fa-trash icon-main" (mtConfirm)="onDelete(override)"></i>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="fab-wrap" *ngIf="!selectedPickupOverride && canEdit">
            <a class="btn btn-primary btn-fab-md btn-fab-center" (click)="add()">
                <span class="fa fa-plus"></span>
            </a>
        </div>
    </div>
    <br/>
</div>