<div class="row">
    <div class="col-md-7">
        <h2>Closed Dates</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
<br>
</div>

<mt-entity-list [entities]="closedDates" [total]="total" [(currentPage)]="currentPage" [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getClosedDates()" (itemSelectedEvent)="closedDateSelected($event)" (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig">
</mt-entity-list>

<div *ngIf="canAddClosedDate" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/closeddates', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
