import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CarrierService } from '../services/carrier.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ICarrier } from '../../model/interfaces/carrier';
import { CarrierSharedEntities } from '../shared-entities/carrier.shared-entities';

@Component({
    selector: 'app-carrier-detail',
    templateUrl: './carrier-detail.component.html',
})
export class CarrierDetailComponent implements OnInit {
    carrier: ICarrier;
    canEdit: boolean;
    canAdd: boolean;
    id: number;

    contactsSharedEntityId = CarrierSharedEntities.Contacts;

    constructor(
        private carrierService: CarrierService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Carriers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('carrierId');
        // try load if id > 0
        if (this.id > 0) {
            this.getCarrierById(this.id);
        } else {
            // set carrier to emptyCarrier
            this.carrier = this.carrierService.getEmptyCarrier();
        }
    }

    getCarrierById(id: number): void {
        this.carrierService.getById(id).subscribe((carrier) => {
            if (!carrier) {
                void this.router.navigate(['carriers']);
            }
            this.carrier = carrier;
        });
    }
}
