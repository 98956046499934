import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { IUser } from '../../model/interfaces/user';
import { Router } from '@angular/router';
import { IPickup } from '../../model/interfaces/pickup';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CarrierService } from '../../carriers/services/carrier.service';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { ICarrierForRegistration } from '../../model/interfaces/custom/carrier-for-registration';

@Component({
    selector: 'app-driver-check-in-edit-pickup',
    templateUrl: './driver-check-in-edit-pickup.component.html',
    styleUrls: ['./driver-check-in-edit-pickup.component.css'],
})
export class DriverCheckInEditPickupComponent implements OnInit {
    loading = true;
    driver: IUser;
    pickup: IPickup;
    carriers: ICarrierForRegistration[];
    availableDateTimes: Date[];
    selectedDateTime: string;
    editPickupForm: FormGroup;
    
    constructor(
        private formBuilder: FormBuilder, 
        private checkInService: PublicCheckInService,
        private notificationService: NotificationsService,
        private router: Router,
        private carrierService: CarrierService,
    ) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
        });

        this.checkInService.checkedInPickup$.subscribe((pickup) => {
            this.pickup = pickup;
            this.selectedDateTime = pickup?.DateRequested?.toString();
        });

        setTimeout(() => {
            if (!this.driver || !this.pickup) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }

            this.carrierService.getActiveCarriersForRegistration().subscribe((carriers) => {
                this.carriers = carriers;
                const orders = this.pickup?.Orders;
                const officeId = orders[0].Warehouse?.OfficeId;
                this.checkInService.getDriverSearchTimes(
                    orders.map(o => o.Id),
                    CommonFunctions.setToMidnight(this.pickup.DateRequested),
                    CommonFunctions.setToMidnight(this.pickup.DateRequested),
                    officeId,
                    this.driver.Id
                )
                .subscribe((availableDateTimes) => {
                    this.availableDateTimes = availableDateTimes;
                    this.initForm();
                    this.loading = false;
                });
            });
        }, 500);
    }

    onDateTimeSelected(dateTime: string) {
        this.selectedDateTime = dateTime;
    }

    onSubmit() {
        const carrierId = this.editPickupForm.get('carrier').value as number;
        const UTCEquivalent = CommonFunctions.getUTCEquivalent(this.selectedDateTime);
        this.checkInService.setPickupDateTime(this.pickup.Id, UTCEquivalent, this.driver.Id, carrierId)
            .subscribe({
                next: (pickup) => {
                    this.notificationService.success(`Succcessfully updated Time & Date. The Holt Admin will review to confirm the appointment.`);
                    this.checkInService.checkedInPickup$.next(pickup);
                    void this.router.navigate(['public/driver-check-in-info']);
                },
            });
    }

    initForm(): void {
        const midnight = CommonFunctions.setToMidnight(this.pickup.DateRequested);
        const dateRequested = CommonFunctions.formatDateForInput(midnight);
        this.editPickupForm = this.formBuilder.group({
            carrier: [{ value: this.driver.CarrierId, disabled: true }],
            dateRequested: [{ value: dateRequested, disabled: true }]
        });
    }

    goToDriverCheckInInfoPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate(['public/driver-check-in-info']);
    }
}
