<div class="text-center public-responsive">
    <h1>Order Confirmation</h1>
    <br>
        <div class="card-container" *ngIf="allSet !== null">
            <div class="miles-card padded" *ngIf="allSet === false">
                <h2>You're picking up Order(s):</h2>
                <h2>#{{order?.OrderNumber}}</h2>
                <br>
                <div class="loader">
                    <div class="spinner"></div>
                    <img src="{{ logoImageSrc }}" class="centered-image">
                </div>
                <br><br>
                <button type="button" class="btn btn-primary" (click)="checkPickupStatus()">OK</button>
                <br>
            </div>
            <div class="miles-card padded" *ngIf="allSet === true">
                <h2>You're all set!</h2>
                <p class="f-10">It looks like you've picked up all your orders.</p>
                <p class="f-10">Ready to head out?</p>
                <br>
                <button type="button" class="btn btn-primary margin-bottom-custom" (click)="navigateToGoogleMaps()">Yes - help me find the exit</button>
                <br>
                <button type="button" class="btn btn-primary" (click)="goBack()">No - I'd like to pickup another order</button>
                <br>
            </div>
        </div>
    <app-driver-check-in-support-button [driver]="driver"></app-driver-check-in-support-button>
    <br>
</div>
