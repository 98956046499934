<h2>Carriers</h2>
<div class="row">
    <div class="col-md-8">
        <div class="miles-editable miles-card padded" *ngIf="!isEditing" [style.cursor]="canEdit ? 'pointer' : 'default'">
            <h4>
                Carrier
                <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
                    <i class="fa fa-lg fa-edit"></i>
                </span>
            </h4>
            <div class="row">
                <div class="col-md-10">
                    <mt-search-bar (searched)="search($event)"></mt-search-bar>
                </div>
                <div class="col-md-2">
                    <mt-search-filter-checkbox
                        (searchCheckboxChanged)="includeSelectionChanged($event)"
                        text="Include Archived"
                        [value]="includeArchived"
                    ></mt-search-filter-checkbox>
                </div>
            </div>
            <br />
            <div *ngIf="!carriersList || !carriersList.length">
                <i>No Carriers</i>
            </div>
            <div class="list-group">
                <div
                    *ngFor="let item of carriersList; let i = index"
                    class="list-group-item"
                    [ngStyle]="{ 'background-color': item.Archived ? '#fbd9d3' : '' }"
                    (click)="editCarrier(item)"
                >
                    {{ item.Name }}
                    <i
                        *ngIf="!item.Archived"
                        style="font-size: 23px"
                        Class="fa fa-fw fa-trash pull-right"
                        (click)="$event.stopPropagation(); archiveCarrier(item.Id, i, true)"
                    ></i>
                    <i
                        *ngIf="item.Archived"
                        style="font-size: 23px"
                        Class="fa fa-fw fa-unlock pull-right"
                        (click)="$event.stopPropagation(); archiveCarrier(item.Id, i, false)"
                    ></i>
                </div>
            </div>
            <br />
            <div class="fab-wrap">
                <button *ngIf="canAdd" class="btn btn-primary btn-fab-md btn-fab-center" type="button" [routerLink]="['/carriers', 'add']">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
    </div>
</div>
