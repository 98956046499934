import { CarrierRequestDynamicControls, ICarrierRequestDynamicControlsParameters } from '../form-controls/carrier-request.form-controls';
import { ICarrierRequest } from '../interfaces/carrier-request';
import { ICarrierRequestStatus } from '../interfaces/carrier-request-status';

export class CarrierRequestDynamicControlsPartial extends CarrierRequestDynamicControls {

    constructor(carrierrequestPartial?: ICarrierRequest, additionalParameters?: ICarrierRequestDynamicControlsParameters) {
        super(carrierrequestPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.CompanyName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ContactEmailAddress).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ContactName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ContactPhoneNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateCreated).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateModified).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ModifiedById).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.StatusId).type.fieldType = DynamicFieldTypes.Input;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.CompanyName).label = 'Some other Label';
        // (<DynamicLabel>this.View.ContactEmailAddress).label = 'Some other Label';
        // (<DynamicLabel>this.View.ContactName).label = 'Some other Label';
        // (<DynamicLabel>this.View.ContactPhoneNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateCreated).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateModified).label = 'Some other Label';
        // (<DynamicLabel>this.View.ModifiedById).label = 'Some other Label';
        // (<DynamicLabel>this.View.StatusId).label = 'Some other Label';

    }
}
