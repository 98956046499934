import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { IOrderStatus } from '../../model/interfaces/order-status';
import { ICustomer } from '../../model/interfaces/customer';
import { IOrder } from '../../model/interfaces/order';
import { CustomerService } from '../customer.service';
import { OrderDynamicConfig } from '../order.dynamic-config';
import { OrderService } from '../order.service';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { PickupStatus } from './pickup-status-enum';
import { CarrierService } from '../../carriers/services/carrier.service';
import { ICarrier } from '../../model/interfaces/carrier';

@Component({
    selector: 'app-order-basic-info',
    templateUrl: './order-basic-info.component.html',
    styles: [`
        .invalid-label {
            color: red !important
        }
    `]
})
export class OrderBasicInfoComponent implements OnInit {
    order: IOrder;
    customers: ICustomer[];
    carriers: ICarrier[];
    statuses: IOrderStatus[];
    config: IDynamicFormConfig = { formObject: [], viewOnly: [] };
    formFactory: OrderDynamicConfig<IOrder>;
    twicVerifiedLabel: string;

    constructor(
        private orderService: OrderService,
        private customerService: CustomerService,
        private carrierService: CarrierService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            const orderId = Number(params.get('id'));
            forkJoin(this.orderService.getById(orderId), this.customerService.getItems(), this.carrierService.getAll(), this.orderService.getStatuses()).subscribe((answers) => {
                const [order, customers, carriers, statuses, ] = answers;
                this.order = order;
                this.customers = customers;
                this.carriers = carriers;
                this.statuses = statuses;
                this.setConfig();
                this.twicVerifiedLabel = OrderService.GetOrderTwicVerifiedLabel(order);
            });
        });
    }

    setConfig(): void {
        const configControls = [
            'CustomerId',
            'OrderNumber',
            'WarehouseId',
            'Location',
            'PickUpDateTime',
            'ScheduleConfirmationNumber',
            'SchedulerEmail',
            'DateRequested',
            'PrevDateRequested',
            'StatusId',
            'CarrierId',
        ];
        this.formFactory = new OrderDynamicConfig<IOrder>(this.order, this.customers, this.carriers, this.statuses, configControls);
        this.config = this.formFactory.getForUpdate();
    }

    getPickupStatus(statusId: number): string {
        if (statusId > 0) {
            return `${PickupStatus[statusId]}`; 
        }
        return '';
    }
}
