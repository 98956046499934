import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    LabelPositions,
    LabelPosition,
    IDatepickerOptions,
} from '@mt-ng2/dynamic-form';
import { CustomerPickupOverrideDynamicControls, ICustomerPickupOverrideDynamicControlsParameters } from '../form-controls/customer-pickup-override.form-controls';
import { ICustomerPickupOverride } from '../interfaces/customer-pickup-override';

export class CustomerPickupOverrideControlsPartial extends CustomerPickupOverrideDynamicControls {
    constructor(customerpickupoverride?: ICustomerPickupOverride, additionalParameters?: ICustomerPickupOverrideDynamicControlsParameters) {
        super(customerpickupoverride, additionalParameters);

        (<DynamicField>this.Form.StartTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.EndTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.StartTime).type.timepickerOptions = { meridian: true };
        (<DynamicField>this.Form.EndTime).type.timepickerOptions = { meridian: true };
        
        const today = new Date();
        const datepickerOptions: IDatepickerOptions = { 
            minDate: { 
                year: today.getFullYear(), 
                month: today.getMonth() + 1, 
                day: today.getDate() 
            } 
        };

        (<DynamicField>this.Form.FromDate).type.datepickerOptions = datepickerOptions;
        (<DynamicField>this.Form.FromDate).setRequired(true);
        (<DynamicField>this.Form.FromDate).labelHtml = '<span class="dynamic-field-label"><label>Starts On</label></span>';
        (<DynamicField>this.Form.ToDate).labelHtml = '<span class="dynamic-field-label"><label>Ends On</label></span>';
        
        (<DynamicField>this.Form.IsRecurringMon).labelHtml = '<label>Every Monday</label>';
        (<DynamicField>this.Form.IsRecurringTue).labelHtml = '<label>Every Tuesday</label>';
        (<DynamicField>this.Form.IsRecurringWed).labelHtml = '<label>Every Wednesday</label>';
        (<DynamicField>this.Form.IsRecurringThu).labelHtml = '<label>Every Thursday</label>';
        (<DynamicField>this.Form.IsRecurringFri).labelHtml = '<label>Every Friday</label>';
        (<DynamicField>this.Form.IsRecurringSat).labelHtml = '<label>Every Saturday</label>';
        (<DynamicField>this.Form.IsRecurringSun).labelHtml = '<label>Every Sunday</label>';
    }
}
