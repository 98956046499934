<div class="row">
    <div class="col-md-7">
        <h2>Deliveries</h2>
    </div>
</div>
<div>
    <mt-search-bar (ready)="searchbarControlAPI = $event" (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-checkbox
        *ngIf="isAdminUser()"
        (searchCheckboxChanged)="includeSelectionChanged($event)"
        text="Include Archived"
        [value]="includeArchived">
    </mt-search-filter-checkbox>
    <mt-search-filter-daterange
        *ngIf="deliveryDatePickerEntityName"
        [entity]="deliveryDatePickerEntityName"
        [startDate]="deliveryDateStart"
        [endDate]="deliveryDateEnd"
        (selectionChanged)="deliveryDateSelectionChanged($event)"
    ></mt-search-filter-daterange>
    <mt-search-filter-daterange
        *ngIf="confirmedDatePickerEntityName && isAdminUser()"
        [entity]="confirmedDatePickerEntityName"
        [startDate]="confirmedDateStart"
        [endDate]="confirmedDateEnd"
        (selectionChanged)="confirmedDateSelectionChanged($event)"
    ></mt-search-filter-daterange>
    <mt-search-filter-select
        [items]="deliveryStatusMetaItems"
        entity="Status"
        (selectionChanged)="getDeliveries()">
    </mt-search-filter-select>
    <mt-search-filter-select
        *ngIf="isAdminUser() || isCarrierUser()"
        [items]="customerMetaItems"
        entity="Customer"
        (selectionChanged)="getDeliveries()">
    </mt-search-filter-select>
    <mt-search-filter-select
        *ngIf="isAdminUser() || isCustomerUser()"
        [items]="carrierMetaItems"
        entity="Carrier"
        (selectionChanged)="getDeliveries()">
    </mt-search-filter-select>
    <mt-search-filter-select
        [items]="deliveryEquipmentTypeMetaItems"
        entity="Equipment Type"
        (selectionChanged)="getDeliveries()">
    </mt-search-filter-select>
    <mt-search-filter-select
        [items]="deliveryTypeMetaItems"
        entity="Delivery Type"
        (selectionChanged)="getDeliveries()">
    </mt-search-filter-select>
    <mt-search-filter-select
        [items]="countryMetaItems"
        valueIdAccessor="CountryCode"
        entity="Country"
        (selectionChanged)="getDeliveries()">
    </mt-search-filter-select>
    <mt-search-filter-select
        *ngIf="isAdminUser()"
        [items]="warehouseMetaItems"
        valueIdAccessor="Warehouse"
        entity="Warehouse"
        (selectionChanged)="getDeliveries()">
    </mt-search-filter-select>
    <br>
    <button 
        class="btn btn-primary" 
        *ngIf="isAdminUser()"
        [disabled]="!selectedDeliveries || selectedDeliveries.length === 0 || includeArchived"
        (click)="openConfirmDeliveriesDialog()">
        Confirm Delivery
    </button>
    <button 
        class="btn btn-primary" 
        (click)="downloadDeliveriesTemplate()">
        Download Template
    </button>
    <button 
        class="btn btn-primary" 
        onclick="document.getElementById('upload-template-input').click();">
        Upload Template
    </button>
</div>
<br>
<mt-entity-list 
    *ngIf="entityListConfig"
    [entities]="deliveries"
    [total]="total"
    [(itemsPerPage)]="itemsPerPage"
    [(currentPage)]="currentPage"
    (pageChanged)="getDeliveries()"
    (columnSortedEvent)="columnSorted($event)"
    [selectedEntities]="selectedDeliveries"
    [entityListConfig]="entityListConfig"
    (itemDeletedEvent)="onItemDeleted($event)"
    (selectionChangedEvent)="selectionChanged($event)"
>
</mt-entity-list>

<div *ngIf="canAddDelivery" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/deliveries', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>

<input 
    id="upload-template-input" 
    type="file" 
    style="display:none;" 
    onclick="this.value = null"
    (change)="onFileSelected($event)" 
    accept=".csv">

<mt-modal-wrapper
    [showActions]="false"
    [autoShow]="false"
    (ready)="confirmDeliveriesDialogApi = $event"
    [options]="{ width: 500, showCloseButton: false }">

    <confirm-deliveries-dialog 
        [deliveries]="selectedDeliveries" 
        (onClose)="closeConfirmDeliveriesDialog()">
    </confirm-deliveries-dialog>
</mt-modal-wrapper>