import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IGuestUser } from '../interfaces/guest-user';
import { ICarrier } from '../interfaces/carrier';

export interface IGuestUserDynamicControlsParameters {
    formGroup?: string;
    carriers?: ICarrier[];
}

export class GuestUserDynamicControls {

    formGroup: string;
    carriers: ICarrier[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private guestuser?: IGuestUser, additionalParameters?: IGuestUserDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'GuestUser';
        this.carriers = additionalParameters && additionalParameters.carriers || undefined;

        this.Form = {
            CarrierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                name: 'CarrierId',
                options: this.carriers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.guestuser && this.guestuser.CarrierId || null,
            }),
            DriverLicenseNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Driver License Number',
                name: 'DriverLicenseNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.guestuser && this.guestuser.hasOwnProperty('DriverLicenseNumber') && this.guestuser.DriverLicenseNumber !== null ? this.guestuser.DriverLicenseNumber.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.guestuser && this.guestuser.hasOwnProperty('FirstName') && this.guestuser.FirstName !== null ? this.guestuser.FirstName.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.guestuser && this.guestuser.hasOwnProperty('LastName') && this.guestuser.LastName !== null ? this.guestuser.LastName.toString() : '',
            }),
            LicensePlate: new DynamicField({
                formGroup: this.formGroup,
                label: 'License Plate',
                name: 'LicensePlate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.guestuser && this.guestuser.hasOwnProperty('LicensePlate') && this.guestuser.LicensePlate !== null ? this.guestuser.LicensePlate.toString() : '',
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone',
                name: 'Phone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(10) ],
                validators: { 'required': true, 'maxlength': 10 },
                value: this.guestuser && this.guestuser.hasOwnProperty('Phone') && this.guestuser.Phone !== null ? this.guestuser.Phone.toString() : '',
            }),
            TruckingCompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trucking Company Name',
                name: 'TruckingCompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.guestuser && this.guestuser.hasOwnProperty('TruckingCompanyName') && this.guestuser.TruckingCompanyName !== null ? this.guestuser.TruckingCompanyName.toString() : '',
            }),
            UsdotNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Usdot Number',
                name: 'UsdotNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.guestuser && this.guestuser.hasOwnProperty('UsdotNumber') && this.guestuser.UsdotNumber !== null ? this.guestuser.UsdotNumber.toString() : '',
            }),
        };

        this.View = {
            CarrierId: new DynamicLabel({
			    label: 'Carrier',
			    value: getMetaItemValue(this.carriers as unknown as IMetaItem[], this.guestuser && this.guestuser.hasOwnProperty('CarrierId') && this.guestuser.CarrierId !== null ? this.guestuser.CarrierId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DriverLicenseNumber: new DynamicLabel({
			    label: 'Driver License Number',
			    value: this.guestuser && this.guestuser.hasOwnProperty('DriverLicenseNumber') && this.guestuser.DriverLicenseNumber !== null ? this.guestuser.DriverLicenseNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FirstName: new DynamicLabel({
			    label: 'First Name',
			    value: this.guestuser && this.guestuser.hasOwnProperty('FirstName') && this.guestuser.FirstName !== null ? this.guestuser.FirstName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LastName: new DynamicLabel({
			    label: 'Last Name',
			    value: this.guestuser && this.guestuser.hasOwnProperty('LastName') && this.guestuser.LastName !== null ? this.guestuser.LastName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LicensePlate: new DynamicLabel({
			    label: 'License Plate',
			    value: this.guestuser && this.guestuser.hasOwnProperty('LicensePlate') && this.guestuser.LicensePlate !== null ? this.guestuser.LicensePlate.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Phone: new DynamicLabel({
			    label: 'Phone',
			    value: this.guestuser && this.guestuser.hasOwnProperty('Phone') && this.guestuser.Phone !== null ? this.guestuser.Phone.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TruckingCompanyName: new DynamicLabel({
			    label: 'Trucking Company Name',
			    value: this.guestuser && this.guestuser.hasOwnProperty('TruckingCompanyName') && this.guestuser.TruckingCompanyName !== null ? this.guestuser.TruckingCompanyName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            UsdotNumber: new DynamicLabel({
			    label: 'Usdot Number',
			    value: this.guestuser && this.guestuser.hasOwnProperty('UsdotNumber') && this.guestuser.UsdotNumber !== null ? this.guestuser.UsdotNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
