import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IOrder } from '../../model/interfaces/order';
import { ICheckInDetail } from '../../model/interfaces/check-in-detail';
import { PickupStatus } from '../../orders/order-basic-info/pickup-status-enum';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { IPickup } from '../../model/interfaces/pickup';
import { Location } from '@angular/common';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-driver-order-confirmation',
    templateUrl: './driver-order-confirmation.component.html',
    styleUrls: ['./driver-order-confirmation.component.css'],
})
export class DriverOrderConfirmationComponent implements OnInit {
    driver: IUser;
    pickup: IPickup;
    order: IOrder;
    checkInDetails: ICheckInDetail[];
    allSet: boolean | null = null;
    logoImageSrc = '../../../../assets/logo.png';

    constructor(
        private router: Router, 
        private checkInService: PublicCheckInService, 
        private location: Location,
        private notificationService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            this.checkInService.checkedInOrder$.subscribe((order) => {
                this.order = order;
                if (!driver || !order) {
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }
                this.checkPickupStatus();
            });
        });
    }

    checkPickupStatus(): void {
        this.checkInService.getPickupById(this.order.PickUpId)
            .subscribe({
                next: (pickup) => {
                    this.pickup = pickup;

                    if (this.allSet === null) {
                        this.allSet = this.pickup.StatusId === PickupStatus.Fulfilled;
                        return;
                    }

                    this.allSet = this.pickup.StatusId === PickupStatus.Fulfilled;
                    if (!this.allSet) {
                        this.notificationService.error('This pickup has not been fulfilled yet. Please wait for the pickup to be fulfilled.');
                    }
                }
            });
    }

    navigateToGoogleMaps() {
        const url = `https://www.google.com/maps/`;
        window.open(url, '_blank');
    }

    goBack(): void {
        this.location.back();
    }
}
