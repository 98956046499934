export enum Settings {
    CompanyName = 0,
    TwicFee = 1,
    NonTwicFee = 2,
    WelcomeContent = 3,
    RegistrationNotificationEmails = 4,
    EditAppointmentTimeframe = 5,
    PalletCount = 6,
    OrderWeight = 7,
}
