import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IContact } from '../model/interfaces/contact';

export const emptyContact: IContact = {
    Archived: false,
    CarrierId: null,
    CustomerId: null,
    DateCreated: new Date(),
    Email: '',
    FirstName: '',
    GrantAccessToPortal: false,
    Id: 0,
    LastName: '',
    NotificationTypeId: 1,
    NotificationWhenDriverChecksIn: false,
    NotificationWhenDriverIsFulfilled: false,
    NotificationWhenDriverIsIssuedGatePass: false,
    NotificationWhenDriverIsNotified: false,
    PhoneNo: '',
    PrimaryContact: false,
    Title: '',
    TypeId: 1,
};

@Injectable({
    providedIn: 'root',
})
export class ContactService extends BaseService<IContact> {
    constructor(public http: HttpClient) {
        super('/contacts-dedicated', http);
    }

    getEmptyContact(): IContact {
        return { ...emptyContact };
    }
}
