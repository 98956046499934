import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { LocationService } from '../services/location.service';
import { LocationDynamicConfig } from '../location.dynamic-config';
import { ILocation } from '../../model/interfaces/location';
import { IAddress } from '../../model/interfaces/address';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-location-basic-info',
    templateUrl: './location-basic-info.component.html',
})
export class LocationBasicInfoComponent implements OnInit {
    @Input() location: ILocation;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: LocationDynamicConfig<ILocation>;
    doubleClickIsDisabled = false;

    addresses: IAddress[];

    get isNewLocation(): boolean {
        return this.location && this.location.Id ? false : true;
    }

    constructor(private locationService: LocationService, private notificationsService: NotificationsService, private router: Router) {}

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        let config: IDynamicFormConfig;
        this.formFactory = new LocationDynamicConfig<ILocation>(this.location, null);
        
        if (this.isNewLocation) {
            this.isEditing = true;
            config = this.formFactory.getForCreate();
        } else {
            config = this.formFactory.getForUpdate();
        }
        
        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) =>new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewLocation) {
            void this.router.navigate(['/locations']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            const loc = form.value.Location as ILocation
            this.formFactory.assignFormValues(this.location, loc);
            this.saveLocation();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveLocation(): void {
        if (this.isNewLocation) {
            this.locationService
                .create(this.location).subscribe((answer) => {
                    this.location.Id = answer;
                    this.success(true);
                });
        } else {
            this.locationService
                .update(this.location).subscribe(() => {
                    this.success();
                });
        }
    }

    private success(newLocationSave?: boolean): void {
        if (newLocationSave) {
            void this.router.navigate([`/locations/${this.location.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.locationService.emitChange(this.location);
        this.notificationsService.success('Location saved successfully.');
    }
}
