import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ICustomerPickupOverride } from '../interfaces/customer-pickup-override';
import { ICustomer } from '../interfaces/customer';

export interface ICustomerPickupOverrideDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
}

export class CustomerPickupOverrideDynamicControls {

    formGroup: string;
    customers?: ICustomer[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customerpickupoverride?: ICustomerPickupOverride, additionalParameters?: ICustomerPickupOverrideDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerPickupOverride';
        this.customers = additionalParameters && additionalParameters.customers || undefined;

        this.Form = {
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerpickupoverride && this.customerpickupoverride.CustomerId || null,
            }),
            EndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'End Time',
                name: 'EndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('EndTime') && this.customerpickupoverride.EndTime != null ? this.customerpickupoverride.EndTime.toString() : '',
            }),
            FromDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'From Date',
                name: 'FromDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.FromDate || null,
            }),
            IsRecurring: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring',
                name: 'IsRecurring',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurring') && this.customerpickupoverride.IsRecurring != null ? this.customerpickupoverride.IsRecurring : true,
            }),
            IsRecurringFri: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Fri',
                name: 'IsRecurringFri',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringFri') && this.customerpickupoverride.IsRecurringFri != null ? this.customerpickupoverride.IsRecurringFri : true,
            }),
            IsRecurringMon: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Mon',
                name: 'IsRecurringMon',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringMon') && this.customerpickupoverride.IsRecurringMon != null ? this.customerpickupoverride.IsRecurringMon : true,
            }),
            IsRecurringSat: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Sat',
                name: 'IsRecurringSat',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringSat') && this.customerpickupoverride.IsRecurringSat != null ? this.customerpickupoverride.IsRecurringSat : true,
            }),
            IsRecurringSun: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Sun',
                name: 'IsRecurringSun',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringSun') && this.customerpickupoverride.IsRecurringSun != null ? this.customerpickupoverride.IsRecurringSun : true,
            }),
            IsRecurringThu: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Thu',
                name: 'IsRecurringThu',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringThu') && this.customerpickupoverride.IsRecurringThu != null ? this.customerpickupoverride.IsRecurringThu : true,
            }),
            IsRecurringTue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Tue',
                name: 'IsRecurringTue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringTue') && this.customerpickupoverride.IsRecurringTue != null ? this.customerpickupoverride.IsRecurringTue : true,
            }),
            IsRecurringWed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring Wed',
                name: 'IsRecurringWed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringWed') && this.customerpickupoverride.IsRecurringWed != null ? this.customerpickupoverride.IsRecurringWed : true,
            }),
            NoEndDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'No End Date',
                name: 'NoEndDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('NoEndDate') && this.customerpickupoverride.NoEndDate != null ? this.customerpickupoverride.NoEndDate : true,
            }),
            NoOfPickupsPerHour: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Of Pickups Per Hour',
                name: 'NoOfPickupsPerHour',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.NoOfPickupsPerHour || 0,
            }),
            StartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Start Time',
                name: 'StartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('StartTime') && this.customerpickupoverride.StartTime != null ? this.customerpickupoverride.StartTime.toString() : '',
            }),
            ToDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'To Date',
                name: 'ToDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerpickupoverride && this.customerpickupoverride.ToDate || null,
            }),
        };

        this.View = {
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('CustomerId') ? this.customerpickupoverride.CustomerId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            EndTime: new DynamicLabel({
                label: 'End Time',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('EndTime') && this.customerpickupoverride.EndTime != null ? this.customerpickupoverride.EndTime.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FromDate: new DynamicLabel({
                label: 'From Date',
                value: this.customerpickupoverride && this.customerpickupoverride.FromDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            IsRecurring: new DynamicLabel({
                label: 'Is Recurring',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurring') && this.customerpickupoverride.IsRecurring != null ? this.customerpickupoverride.IsRecurring : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringFri: new DynamicLabel({
                label: 'Is Recurring Fri',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringFri') && this.customerpickupoverride.IsRecurringFri != null ? this.customerpickupoverride.IsRecurringFri : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringMon: new DynamicLabel({
                label: 'Is Recurring Mon',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringMon') && this.customerpickupoverride.IsRecurringMon != null ? this.customerpickupoverride.IsRecurringMon : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringSat: new DynamicLabel({
                label: 'Is Recurring Sat',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringSat') && this.customerpickupoverride.IsRecurringSat != null ? this.customerpickupoverride.IsRecurringSat : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringSun: new DynamicLabel({
                label: 'Is Recurring Sun',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringSun') && this.customerpickupoverride.IsRecurringSun != null ? this.customerpickupoverride.IsRecurringSun : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringThu: new DynamicLabel({
                label: 'Is Recurring Thu',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringThu') && this.customerpickupoverride.IsRecurringThu != null ? this.customerpickupoverride.IsRecurringThu : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringTue: new DynamicLabel({
                label: 'Is Recurring Tue',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringTue') && this.customerpickupoverride.IsRecurringTue != null ? this.customerpickupoverride.IsRecurringTue : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsRecurringWed: new DynamicLabel({
                label: 'Is Recurring Wed',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('IsRecurringWed') && this.customerpickupoverride.IsRecurringWed != null ? this.customerpickupoverride.IsRecurringWed : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NoEndDate: new DynamicLabel({
                label: 'No End Date',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('NoEndDate') && this.customerpickupoverride.NoEndDate != null ? this.customerpickupoverride.NoEndDate : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NoOfPickupsPerHour: new DynamicLabel({
                label: 'No Of Pickups Per Hour',
                value: this.customerpickupoverride && this.customerpickupoverride.NoOfPickupsPerHour || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            StartTime: new DynamicLabel({
                label: 'Start Time',
                value: this.customerpickupoverride && this.customerpickupoverride.hasOwnProperty('StartTime') && this.customerpickupoverride.StartTime != null ? this.customerpickupoverride.StartTime.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ToDate: new DynamicLabel({
                label: 'To Date',
                value: this.customerpickupoverride && this.customerpickupoverride.ToDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
        };

    }
}
