import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IContact } from '../../model/interfaces/contact';

export class ContactEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return `${contact.FirstName} ${contact.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'FirstName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return contact.Email;
                    },
                    name: 'Email Address',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Email',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return contact.PhoneNo;
                    },
                    name: 'Phone Number',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'PhoneNo',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return contact.NotificationType?.Name;
                    },
                    name: 'Notification Type',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'NotificationType.Name'
                    }
                }),
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return contact.Customer?.Name;
                    },
                    name: 'Customer',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Customer.Name'
                    }
                }),
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return contact.Carrier?.Name;
                    },
                    name: 'Carrier',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Carrier.Name'
                    }
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IContact) => entity.Archived ? '' : '<i class="fa fa-2x fa-fw fa-archive icon-main"></i>',
                confirm: (entity: IContact) => {
                    if (entity.Archived) {
                        return {
                            title: 'This contact has already been archived.',
                            text: '',
                            icon: 'error',
                            showConfirmButton: false,
                        }    
                    }
                    return {
                        confirmButtonText: 'Archive',
                        text: 'Are you sure you want to archive this contact?',
                        title: 'Archive Contact?',
                    }
                },
                headerText: 'Archive',
            }),
            rowClass: (entity: IContact) => entity.Archived ? 'archived' : '',
        };
        super(listConfig);
    }
}
