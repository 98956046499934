import { NgModule } from '@angular/core';

import { LocationRoutingModule } from './location-routing.module';
import { LocationAddComponent } from './location-add/location-add.component';
import { LocationBasicInfoComponent } from './location-basic-info/location-basic-info.component';
import { LocationDetailComponent } from './location-detail/location-detail.component';
import { LocationsComponent } from './location-list/locations.component';
import { LocationHeaderComponent } from './location-header/location-header.component';
import { SharedModule } from '../common/shared.module';

@NgModule({
    declarations: [LocationsComponent, LocationHeaderComponent, LocationAddComponent, LocationDetailComponent, LocationBasicInfoComponent, LocationHeaderComponent],
    imports: [SharedModule, LocationRoutingModule]
})
export class LocationModule {}
