
import { ClaimTypes } from '../model/ClaimTypes';
import { ClaimValues } from '@mt-ng2/auth-module';
import { RegistrationRequestsNavComponent } from './registration-requests-nav.component';
import { WalkInOrdersNavComponent } from './walk-in-orders-nav.component';
import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module';

// tslint:disable:object-literal-sort-keys
export const appNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    // this section is an example only, and should be configured
    new NavSidebarParentRowItem({
        // with routes from the src/app/app-nav-module.ts getMenu() function
        content: 'Administration',
        icon: 'fa fa-fw fa-university',
        claimType: ClaimTypes.AppSettings,
        settingsLink: '/settings',
        children: [
            new NavSidebarRowItem({
                content: 'Users',
                icon: 'fa fa-fw fa-user',
                link: '/users',
                claimType: ClaimTypes.Users,
                addLink: '/users/add',
            }),
            new NavSidebarRowItem({
                content: 'User Roles',
                icon: 'fa fa-fw fa-lock',
                link: '/roles',
                claimType: ClaimTypes.UserRoles,
                addLink: 'roles/add',
            }),
            new NavSidebarRowItem({
                rowComponent: RegistrationRequestsNavComponent,
                content: ''
            }),
            new NavSidebarRowItem({
                content: 'Contacts',
                icon: 'fa fa-fw fa-users',
                link: '/contacts',
                claimType: ClaimTypes.Contacts,
                addLink: 'contacts/add'
            }),
            new NavSidebarRowItem({
                content: 'Payments Transactions History',
                icon: 'fa fa-fw fa-money',
                link: '/payments-transactions-history',
                claimType: ClaimTypes.PickupPaymentDetails,
            }),
        ],
    }),
    new NavSidebarRowItem({
        content: 'Offices',
        icon: 'fa fa-fw fa-building',
        claimType: ClaimTypes.Offices,
        link: '/offices',
        addLink: '/offices/add',
        claimValues: [ClaimValues.FullAccess],
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Closed Dates',
        icon: 'fa fa-fw fa-building',
        claimType: ClaimTypes.Offices,
        link: '/closeddates',
        addLink: '/closeddates/add',
        claimValues: [ClaimValues.FullAccess],
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Gate Locations',
        icon: 'fa fa-fw fa-building',
        claimType: ClaimTypes.Locations,
        link: '/locations',
        addLink: '/locations/add',
        claimValues: [ClaimValues.FullAccess],
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Carriers',
        icon: 'fa fa-fw fa-truck',
        claimType: ClaimTypes.Carriers,
        claimValues: [ClaimValues.FullAccess],
        link: '/carriers',
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Customers',
        icon: 'fa fa-fw fa-users',
        claimType: ClaimTypes.Customers,
        link: '/customers',
        addLink: '/customers/add',
        claimValues: [ClaimValues.FullAccess],
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarParentRowItem({
        content: 'Deliveries',
        icon: 'fa fa-fw fa-plane',
        claimType: ClaimTypes.Deliveries,
        claimValues: [ClaimValues.FullAccess],
        children: [
            new NavSidebarRowItem({
                content: 'Manage Deliveries',
                icon: 'fa fa-fw fa-exchange',
                claimType: ClaimTypes.Deliveries,
                link: '/deliveries',
                addLink: '/deliveries/add',
                claimValues: [ClaimValues.FullAccess],
                addClaimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                rowComponent: WalkInOrdersNavComponent,
                content: '',
                claimType: ClaimTypes.Deliveries,
            }),
            new NavSidebarRowItem({
                content: 'Calendar View',
                icon: 'fa fa-fw fa-calendar',
                link: '/deliveries-calendar',
                claimType: ClaimTypes.AppSettings,
            }),
        ],
    }),
    new NavSidebarRowItem({
        content: 'Drivers',
        icon: 'fa fa-fw fa-truck',
        claimType: ClaimTypes.Truckers,
        link: '/truckers',
    }),
    new NavSidebarParentRowItem({
        content: 'Order Fulfillment',
        icon: 'fa fa-fw fa-feed',
        claimType: ClaimTypes.CheckIns,
        children: [
            new NavSidebarRowItem({
                content: 'Check-In Monitoring',
                icon: 'fa fa-fw fa-exchange',
                link: '/checkins',
                claimType: ClaimTypes.CheckIns,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Fulfillment Monitoring',
                icon: 'fa fa-fw fa-exchange',
                link: '/pickups',
                claimType: ClaimTypes.Orders,
            }),
            new NavSidebarRowItem({
                content: 'Fulfillment Report',
                icon: 'fa fa-fw fa-table',
                link: '/fulfillment/report',
                claimType: ClaimTypes.CheckIns,
            }),
        ],
    }),
    new NavSidebarParentRowItem({
        content: 'Orders',
        icon: 'fa fa-fw fa-feed',
        claimType: ClaimTypes.Orders,
        claimValues: [ClaimValues.FullAccess],
        children: [
            new NavSidebarRowItem({
                content: 'Scheduling Queue',
                icon: 'fa fa-fw fa-exchange',
                link: '/orders',
                claimType: ClaimTypes.Orders,
            }),
            new NavSidebarRowItem({
                content: 'Manage Orders',
                icon: 'fa fa-fw fa-exchange',
                link: '/orders/manage-orders',
                claimType: ClaimTypes.Orders,
            }),
        ],
    }),
    new NavSidebarParentRowItem({
        content: 'Scheduling ',
        icon: 'fa fa-fw fa-feed',
        claimType: ClaimTypes.Orders,
        children: [
            new NavSidebarRowItem({
                content: 'Create Requests',
                icon: 'fa fa-fw fa-exchange',
                link: '/create-requests/order-selection',
                claimType: ClaimTypes.Orders,
            }),
            new NavSidebarRowItem({
                content: 'Upcoming Pickup Schedule',
                icon: 'fa fa-fw fa-exchange',
                link: '/create-requests/manage-pickups',
                claimType: ClaimTypes.Orders,
            }),
        ],
    }),
];
