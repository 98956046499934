<div class="row">
    <div class="col-md-7">
        <h2>Users</h2>
    </div>
</div>
<div>
    <mt-dynamic-search-filters [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search($event)"></mt-dynamic-search-filters>
    <label for="archived">
        <input type="checkbox" id="archived" [(ngModel)]="showArchived" (change)="getUsers()"/>
        Show Archived
    </label>
    <br />
</div>
<mt-entity-list
    [entities]="users"
    [total]="total"
    [(currentPage)]="currentPage"
    (pageChanged)="getUsers()"
    (itemSelectedEvent)="userSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    (itemDeletedEvent)="archiveSelected($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>

<div *ngIf="canAddUser" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/users', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
