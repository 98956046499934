import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthEntityService } from '../../auth-entity.service';
import { IAuthUser } from '../../../model/interfaces/auth-user';
import { AuthUserDynamicConfig } from '../auth-user.dynamic-config';
import { AuthService } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-auth-user-password',
    templateUrl: './auth-user-password.component.html',
})
export class AuthUserPasswordComponent implements OnInit {
    @Input('AuthUser') authUser: IAuthUser;
    @Input('canEdit') canEdit: boolean;
    @Output('updateVersion') updateVersion: EventEmitter<string> = new EventEmitter<string>();
    formObject: DynamicField[] = [];
    formFactory: AuthUserDynamicConfig<IAuthUser>;
    doubleClickIsDisabled = false;
    isEditing: boolean;
    isHovered: boolean;

    configControls: string[] = ['CurrentPassword', 'Password', 'ConfirmPassword'];

    constructor(private notificationsService: NotificationsService, private authEntityService: AuthEntityService, private authService: AuthService) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new AuthUserDynamicConfig<IAuthUser>(this.authUser, null, this.configControls);
        const config = this.formFactory.getForUpdate();
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancel(): void {
        this.isEditing = false;
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (this.authService.matchPassword(form)) {
            if (form.valid) {
                    this.authEntityService
                        .savePassword(
                            this.authUser.Id,
                            form.value.AuthUser.Password as string,
                            form.value.AuthUser.CurrentPassword as string,
                            form.value.AuthUser.ConfirmPassword as string,
                        )                        .subscribe(() => {
                            this.success();
                            this.isEditing = false;
                        });
            } else {
                markAllFormFieldsAsTouched(form);
                this.error();
            }
        } else {
            this.error('Passwords do not match.');
            
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Password save failed.  Please check the form and try again.');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Password Updated Successfully');
    }
}
