import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IOrderDynamicControlsParameters } from '../model/form-controls/order.form-controls';
import { IOrder } from '../model/interfaces/order';
import { ICustomer } from '../model/interfaces/customer';
import { IOrderStatus } from '../model/interfaces/order-status';
import { ICarrier } from '../model/interfaces/carrier';
import { OrderDynamicControlsPartial } from '../model/partials/order-partial.form-controls';

export class OrderDynamicConfig<T extends IOrder> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private order: T, private customers?: ICustomer[], private carriers?: ICarrier[], private statuses?: IOrderStatus[], private configControls?: string[]) {
        super();
        const additionalParams: IOrderDynamicControlsParameters = {
            customers: this.customers,
            carriers: this.carriers,
            statuses: this.statuses,
        };
        const dynamicControls = new OrderDynamicControlsPartial(this.order, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['CustomerId', 'CarrierId', 'StatusId', 'DateCreated', 'Archived'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
