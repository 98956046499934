import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import {
    LoginOldStyleComponent,
    ForgotPasswordOldStyleComponent,
    ResetPasswordOldStyleComponent,
    AdminAccessOldStyleComponent,
} from '@mt-ng2/login-module';
import { AppSettingsComponent } from './app-settings.component';
import { ClaimTypes } from './model/ClaimTypes';
import { UserService } from './users/user.service';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { CheckInsComponent } from './check-ins/check-in-list/checkins.component';

const homeRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.CheckIns,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    isHomePage: true,
};

const userEntityConfig: IEntityRouteConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Users,
    documentsPath: 'documents',
    entityIdParam: 'userId',
    notesPath: 'notes',
    service: UserService,
};

const appRoutes: Routes = [
    { path: 'login', component: LoginOldStyleComponent },
    { path: 'forgotpassword', component: ForgotPasswordOldStyleComponent },
    { path: 'resetpassword', component: ResetPasswordOldStyleComponent },
    { path: 'adminaccess', component: AdminAccessOldStyleComponent },
    { path: 'home', component: CheckInsComponent, canActivate: [AuthGuard], data: homeRoleGuard },
    { path: 'settings', component: AppSettingsComponent, canActivate: [AuthGuard] },
    {
        canActivate: [AuthGuard],
        component: UserDetailComponent,
        data: userEntityConfig,
        path: 'my-profile',
        pathMatch: 'full',
    },
    { path: '**', component: CheckInsComponent, canActivate: [AuthGuard], data: homeRoleGuard },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
