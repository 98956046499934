import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { IDelivery } from '../../model/interfaces/delivery';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { DeliveryService } from '../services/delivery.service';
import { DeliveryDocumentsService } from '../services/delivery-documents.service';
import { OrderService } from '../../orders/order.service';
import { UserRoles } from '../../model/UserRoles';
import { DeliveryStatuses } from '../../model/DeliveryStatuses';

@Component({
    selector: 'app-delivery-detail',
    templateUrl: './delivery-detail.component.html',
})
export class DeliveryDetailComponent implements OnInit {
    delivery: IDelivery;
    authUserRole: number;
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    documentService: DeliveryDocumentsService
    allowedMimeTypes = [
        'image/png', 
        'image/jpg', 
        'image/jpeg', 
        'image/gif', 
        'text/csv', //.csv
        'text/plain', //.txt
        'application/msword', //.doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //.docx
        'application/vnd.ms-excel', //.xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //.xlsx
        'application/pdf' //.pdf
    ];

    constructor(
        private deliveryService: DeliveryService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private orderService: OrderService,
    ) { }

    ngOnInit(): void {
        this.canAdd = this.claimsService.hasClaim(ClaimTypes.Deliveries, [ClaimValues.FullAccess]);
        const loggedIn = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(loggedIn.AuthId).subscribe(authUserRole => {
            this.authUserRole = authUserRole;
            this.id = +this.route.snapshot.paramMap.get('deliveryId');
            if (this.id > 0) {
                this.getDeliveryById(this.id);
            } else {
                this.delivery = this.deliveryService.getEmptyDelivery();
            }
            this.editingComponent.next('');
        });
    }

    getDeliveryById(id: number): void {
        this.deliveryService.getById(id).subscribe((delivery) => {
            this.delivery = delivery;
            this.canEdit = this.determineCanEdit(this.authUserRole, delivery);
        });
    }

    private determineCanEdit(authUserRole: number, delivery: IDelivery): boolean {
        if (!this.canAdd)
            return false;

        if (authUserRole === UserRoles.Admin)
            return true;

        const userIsCarrierOrCustomer = authUserRole === UserRoles.Carrier || authUserRole === UserRoles.Customer;
        if (userIsCarrierOrCustomer && delivery.StatusId === DeliveryStatuses.Requested)
            return true;

        return false;
    }
}
