import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { CarrierService } from '../../carriers/services/carrier.service';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { RegistrationRequestService } from '../registration-request.service';
import { IRegistrationRequest } from '../../model/interfaces/registration-request';
import { RegistrationRequestDynamicConfig } from '../registration-request.dynamic-config';
import { CustomerService } from '../../customers/customer.service';
import { forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-registration-request-info',
  templateUrl: './registration-request-info.component.html',
})
export class RegistrationRequestInfoComponent implements OnInit {
  @Input() registrationRequest: IRegistrationRequest;
  @Input() canEdit: boolean;
  isEditing: boolean;
  isHovered: boolean;
  registrationRequestId = 0;
  checkInId = 0;
  viewOnly: DynamicLabel[] = [];
  formObject: DynamicField[] = [];
  formFactory: RegistrationRequestDynamicConfig<IRegistrationRequest>;
  doubleClickIsDisabled = false;
  errorMessage: string;

  constructor(
    private registrationRequestService: RegistrationRequestService,
    private notificationsService: NotificationsService,
    private router: Router,
    private carrierService: CarrierService,
    private customerService: CustomerService,
  ) { }

  ngOnInit(): void {
    this.isEditing = false;
    this.setConfig();
  }

  setConfig(): void {
    forkJoin([this.carrierService.getActive(), this.customerService.getActiveCustomers()]).subscribe((response) => {
      const carriers = response[0];
      const customers = response[1];

      let configControls = [] as string[];
      if (this.registrationRequest.CarrierId || !this.registrationRequest.CustomerId) {
        configControls = [
            'FirstName',
            'LastName',
            'CarrierId',
            'EmailAddress',
            'PhoneNumber'
          ];
      }
      else if (this.registrationRequest.CustomerId || !this.registrationRequest.CarrierId) {
        configControls = [
            'FirstName',
            'LastName',
            'CustomerId',
            'EmailAddress',
            'PhoneNumber'
          ];
      }
      else {
        configControls = [
            'FirstName',
            'LastName',
            'CarrierId',
            'CustomerId',
            'EmailAddress',
            'PhoneNumber'
          ];
      }


      this.formFactory = new  RegistrationRequestDynamicConfig<IRegistrationRequest>(
        this.registrationRequest,
        carriers,
        customers,
        configControls,
      );

      let config: IDynamicFormConfig;
      if (this.registrationRequest.Id === 0) {
        // new customer
        this.isEditing = true;
        config = this.formFactory.getForCreate();
      } else {
        // existing customer
        config = this.formFactory.getForUpdate();
      }

      this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
      this.formObject = config.formObject?.map((x) =>new DynamicField(x));
    });
  }

  edit(): void {
    if (this.canEdit) {
      this.isEditing = true;
    }
  }

  cancelClick(): void {
    if (this.registrationRequest.Id === 0) {
      void this.router.navigate(['/registration-requests']);
    } else {
      this.isEditing = false;
    }
  }

  formSubmitted(form: UntypedFormGroup): void {
    if (form.valid) {
      this.formFactory.assignFormValues(this.registrationRequest, form.value.RegistrationRequest as IRegistrationRequest);
      this.registrationRequest.DateModified = new Date();
      if (!this.registrationRequest.Id || this.registrationRequest.Id === 0) {
        void this.router.navigate(['/registration-requests']);
      } else {
        this.registrationRequestService.update(this.registrationRequest)
          .pipe(finalize(() => this.doubleClickIsDisabled = false))
          .subscribe(
            () => {
              this.isEditing = false;
              this.success();
              this.registrationRequestService.emitChange(this.registrationRequest);
              this.setConfig();
            },
            (errorResponse: HttpErrorResponse) => {
              this.notificationsService.error(errorResponse.error["ModelState"][0] as string);
            }
          );
      }
    } else {
      markAllFormFieldsAsTouched(form);
      this.error();
    }
  }

  error(): void {
    this.notificationsService.error('Save Failed');
  }

  success(): void {
    this.notificationsService.success('Saved Successfully');
  }
}
