import { ExtraSearchParams } from '@mt-ng2/common-classes';

/**
 * options object for ExtraParams.build method
 * @property (extraParams) a starting array of ExtraSearchParams to build on
 * @property (valuesToIgnore) an array of keyof to ignore from the values object
 */
export interface IExtraParamsBuilderOptions<T> {
    extraParams: ExtraSearchParams[];
    valuesToIgnore: (keyof T)[];
}

/**
 * Utility class with methods to build out ExtraSearchParams[] for use with BaseService.get method,
 * used mainly on search/list pages (i.e. CustomersComponent)
 */
export abstract class ExtraParams {
    /**
     * Builds ExtraSearchParams[] for use with BaseService.get method.  Iterates over the properties in the
     * values object to create the return value.  Will only add properties from values object that are truthy
     * and have not been passed in the options as a value to ignore.
     *
     * Handles properties with raw values, date values, and/or array values.
     *
     * @example
     *   const extraSearchParams = ExtraParams.build({
     *       sourceIds: SearchFilterItems.getSelectedIds(this.sources),
     *       statusIds: SearchFilterItems.getSelectedIds(this.statuses),
     *   });
     *
     * @param values object with values that will be iterated over to create the ExtraSearchParams[]
     * @param options allows passing a starting array to build on and/or a valuesToIgnore array
     * @returns ExtraSearchParams[]
     */
    static build<T>(values: T, options: Partial<IExtraParamsBuilderOptions<T>> = {}): ExtraSearchParams[] {
        // if passed an extraParams array in the options, build on that, else make new
        const params: ExtraSearchParams[] = options.extraParams ?? [];

        Object.entries(values).forEach(([key, value]) => {
            // do nothing if value is falsy or this key is in the valuesToIgnore
            if (!value || options.valuesToIgnore?.includes(<keyof T>key)) {
                return;
            }

            let newParam: ExtraSearchParams;
            if (Array.isArray(value)) {
                newParam = this._buildParamForArray(key, value );
            } else if (value instanceof Date) {
                newParam = this._buildParamForDate(key, value );
            } else {
                newParam = this._buildParamDefault(key, value);
            }

            params.push(newParam);
        });

        return params;
    }

    private static _buildParamForArray(key: string, value): ExtraSearchParams {
        return new ExtraSearchParams({
            name: key,
            valueArray: value,
        });
    }

    private static _buildParamForDate(key: string, value: Date): ExtraSearchParams {
        return new ExtraSearchParams({
            name: key,
            value: value.toISOString(),
        });
    }

    private static _buildParamDefault(key: string, value): ExtraSearchParams {
        return new ExtraSearchParams({
            name: key,
            value: value.toString?.() ?? value,
        });
    }
}
