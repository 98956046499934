import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { UserDynamicControls, IUserDynamicControlsParameters } from '../form-controls/user.form-controls';
import { IGuestUser } from '../interfaces/guest-user';
import { IUser } from '../interfaces/user';
import { NotificationTypes } from '../NotificationTypes';

export class UserDynamicControlsPartial extends UserDynamicControls {
    constructor(user?: IUser, additionalParameters?: IUserDynamicControlsParameters) {
        super(user, additionalParameters);

        (<DynamicField>this.Form.CarrierId).type.inputType =  SelectInputTypes.TypeAhead;
        (<DynamicField>this.Form.NotificationTypeId).value = user && user.NotificationTypeId ? user.NotificationTypeId : NotificationTypes.None;
        (<DynamicField>this.Form.ClaimsToHoldValidTwicCard).labelHtml = '<label>Do you have a valid TWIC card?</label>';
        (<DynamicField>this.Form.ClaimsToHoldValidTwicCard).value = false;
        (<DynamicField>this.Form.Phone).validation.push(Validators.required);
        (<DynamicField>this.Form.Phone).validators.pattern = new RegExp(/^[1]?\s?((\(\d{3}\))|\d{3})(\s|\-)?\d{3}(\-|\s)?\d{4}$/); // Pattern for phone number
        (<DynamicField>this.Form.BannedFromPickupReason).labelHtml = '<label>Ban/Unban Reason</label>';
        (<DynamicField>this.View.BannedFromPickupReason).labelHtml = '<label>Ban/Unban Reason</label>';
    }
}
