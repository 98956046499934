<div *ngIf="carrier">
    <div class="row">
        <div class="col-md-6">
            <app-carrier-basic-info [carrier]="carrier" [canEdit]="canEdit"></app-carrier-basic-info>
            <br />
            <customer-detail-shared-entities 
                [sharedEntityId]="contactsSharedEntityId" 
                [canEdit]="canEdit" 
                [canAdd]="canAdd" 
                [max]="1000"
            >
            </customer-detail-shared-entities>
        </div>
    </div>
</div>