import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { TruckerService } from './trucker.service';
import { TruckersComponent } from './trucker-list/truckers.component';
import { TruckerHeaderComponent } from './trucker-header/trucker-header.component';
import { TruckerDetailComponent } from './trucker-detail/trucker-detail.component';
import { TruckerBanHistoryComponent } from './trucker-ban-history/trucker-ban-history.component';

const truckerEntityConfig = {
    claimType: ClaimTypes.Truckers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'truckerId',
    service: TruckerService,
    title: 'Trucker Info',
};

const truckerListRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Truckers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
};

const truckerAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Truckers,
    claimValues: [ClaimValues.FullAccess],
};

const truckerRoutes: Routes = [
    { path: 'truckers', component: TruckersComponent, canActivate: [AuthGuard], data: truckerListRoleGuard },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: TruckerDetailComponent, pathMatch: 'full' }],
        component: TruckerHeaderComponent,
        data: truckerAddRoleGuard,
        path: 'truckers/add',
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: TruckerDetailComponent, pathMatch: 'full' }],
        component: TruckerHeaderComponent,
        data: truckerEntityConfig,
        path: `truckers/:${truckerEntityConfig.entityIdParam}`,
        title: `Driver`
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: TruckerDetailComponent, pathMatch: 'full' }],
        component: TruckerBanHistoryComponent,
        data: truckerEntityConfig,
        path: `truckers/:${truckerEntityConfig.entityIdParam}/ban-history`,
        title: `Driver Ban History`
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(truckerRoutes)],
})
export class TruckerRoutingModule {}
