import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { IUser } from '../../model/interfaces/user';
import { Router } from '@angular/router';
import { TwicImageTypes } from '../../model/TwicImageTypes';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../users/user.service';
import { CommonFunctions } from '../../common/services/common-functions.service';

@Component({
    selector: 'app-driver-check-in-edit-twic-card',
    templateUrl: './driver-check-in-edit-twic-card.component.html',
    styleUrls: ['./driver-check-in-edit-twic-card.component.css'],
})
export class DriverCheckInEditTwicCardComponent implements OnInit {
    editDriverForm: FormGroup;
    driver: IUser;
    imagePath: string;
    isTwicVerified: boolean;
    TwicImageTypes = TwicImageTypes;
    
    constructor(
        private checkInService: PublicCheckInService,
        private notificationService: NotificationsService,
        private router: Router,
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.imagePath = environment.imgPath;
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            if (!driver || driver === null) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }
            this.driver = driver;
            this.isTwicVerified = UserService.checkUserIsTwicVerified(driver);
            this.initForm();
        });
    }

    goToDriverEditPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate([`public/driver-check-in-edit`]);
    }

    initForm(): void {
        this.editDriverForm = this.formBuilder.group({
            twicCardNumber: [this.driver?.TwicCardNumber, [Validators.required, Validators.maxLength(50)]],
            twicExpirationDate: [{value: CommonFunctions.formatDateForInput(this.driver?.TwicExpirationDate), disabled: true}, Validators.required],
        });
    }

    onSubmit() {
        this.driver.TwicCardNumber = this.editDriverForm.get('twicCardNumber').value;
        this.checkInService
            .setDriverTwicCardNumber(this.driver.Id, this.driver.TwicCardNumber)
            .subscribe({
                next: (driver) => {
                    this.checkInService.checkedInDriver$.next(driver);
                    this.notificationService.success('TWIC card number updated.');
                },
                error: (errorResponse: HttpErrorResponse) => {
                    this.notificationService.error(errorResponse.error['ModelState'] as string);
                },
            });
    }

    onFileSelected(event: Event, twicImageType: TwicImageTypes) {
        const input = event.target as HTMLInputElement;
        if (!input.files?.length) {
            return;
        }

        const file = input.files[0];
        this.checkInService.saveTwicImage(this.driver.Id, file, twicImageType).subscribe((answer) => {
            switch (twicImageType) {
                case TwicImageTypes.Front:
                    this.driver.TwicFrontImage = answer.Image;
                    this.driver.TwicFrontImageId = answer.Image.Id;
                    this.driver.TwicExpirationDate = null;
                    break;
                case TwicImageTypes.Back:
                    this.driver.TwicBackImage = answer.Image;
                    this.driver.TwicBackImageId = answer.Image.Id;
                    this.driver.TwicExpirationDate = null;
                    break;
                default:
                    break;
            }
            this.driver.Version = answer.Version;
            this.isTwicVerified = UserService.checkUserIsTwicVerified(this.driver);
            this.initForm();
            this.notificationService.success('Twic Image Saved Successfully');
        });
    }

    isUnchanged(): boolean {
        return this.editDriverForm.get('twicCardNumber').value === this.driver?.TwicCardNumber;
    }    
}
