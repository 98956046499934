<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && user.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Office Assignment
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <li *ngFor="let office of user.Offices">
        {{ office.Title }}
    </li>
    <div *ngIf="user.Offices.length === 0">
        <h5><i>No offices assigned</i></h5>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Office Assignment</h4>
    <mt-multiselect
        [items]="officeSelectItems"
        [filterMode]="false"
        entity="Offices"
        (selectionChanged)="officeSelectionChanged($event)"
    ></mt-multiselect>
    <br /><br />
    <button
        type="submit"
        (click)="formSubmitted()"
        mtDisableClickDuringHttpCalls
        Class="btn btn-flat btn-success"
    >
        Save
    </button>
    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
</div>
