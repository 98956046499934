import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IOfficePickupOverride } from '../../model/interfaces/office-pickup-override';

export const emptyOfficePickupOverride: IOfficePickupOverride = {
    EndTime: null,
    FromDate: null,
    Id: 0,
    NoOfPickupsPerHour: 0,
    OfficeId: null,
    StartTime: null,
    ToDate: null,
    NoEndDate: null,
    IsRecurring: true,
    IsRecurringSun: false,
    IsRecurringMon: false,
    IsRecurringTue: false,
    IsRecurringWed: false,
    IsRecurringThu: false,
    IsRecurringFri: false,
    IsRecurringSat: false,
};

@Injectable()
export class OfficePickupOverrideService extends BaseService<IOfficePickupOverride> {
    constructor(public http: HttpClient) {
        super('/office-pickup-overrides', http);
    }

    getByOffice(officeId: number): Observable<IOfficePickupOverride[]> {
        return this.http.get<IOfficePickupOverride[]>(`/office-pickup-overrides/office/${officeId}`);
    }

    getEmptyOfficePickupOverride(): IOfficePickupOverride {
        return { ...emptyOfficePickupOverride };
    }
}
