import { ICheckInQueueItem } from '../interfaces/custom/check-in-queue-item';
import { CheckInDetailQueueItem } from './check-in-detail-queue-item';
import { CheckInInfo } from './check-in-info';

export class CheckInQueueItem implements ICheckInQueueItem {
    CheckInInfo: CheckInInfo;
    Id: number;
    SelectDoorsForOrder: boolean;
    Version: number[];
    WaitingTime: number;
    CheckInQueueItemDetails: CheckInDetailQueueItem[];
    ShowOrders: boolean = true;

    getName(): string {
        if (this.CheckInInfo.CheckInUserName) {
            return this.CheckInInfo.CheckInUserName;
        }
        if (this.CheckInInfo.GuestUserName) {
            return this.CheckInInfo.GuestUserName;
        }
        return '';
    }

    getPhone(): string {
        if (this.CheckInInfo.CheckInUserPhone) {
            return this.CheckInInfo.CheckInUserPhone;
        }
        if (this.CheckInInfo.GuestUserPhone) {
            return this.CheckInInfo.GuestUserPhone;
        }
        return '';
    }

    constructor(iCheckInQueueItem: ICheckInQueueItem) {
        Object.assign(this, iCheckInQueueItem);
    }
}
