import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { Subscription } from 'rxjs';
import { IOrder } from '../../model/interfaces/order';
import { WalkInOrdersStateService } from './walk-in-orders-state.service';
import { IWalkInOrderPartial } from '../../check-ins/services/check-in-detail.service';
import { HourFilterPipe } from '../../common/filters/common.hour-filter';
import { UserService } from '../../users/user.service';

export class WalkInOrdersEntityListConfig extends EntityListConfig {
    selectedCheckInDetailIds: number[];
    subscriptions: Subscription = new Subscription();

    constructor(private stateService: WalkInOrdersStateService, private hourFilter: HourFilterPipe) {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    accessorFunction: (checkInDetail: IWalkInOrderPartial) => {
                        const checkInUser = checkInDetail.CheckInUser;
                        const guestUser = checkInDetail.GuestUser;
                        return checkInUser
                            ? `${checkInUser.FirstName} ${checkInUser.LastName}`
                            : guestUser
                            ? `${guestUser.FirstName} ${guestUser.LastName}`
                            : '';
                    },
                    name: 'Driver',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (checkInDetail: IWalkInOrderPartial) => {
                        const checkInUser = checkInDetail.CheckInUser;
                        const guestUser = checkInDetail.GuestUser;
                        return checkInUser ? `${checkInUser.Phone}` : guestUser ? `${guestUser.Phone}` : '';
                    },
                    name: 'Driver Phone',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CustomerName'],
                    name: 'Customer',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CheckInDate'],
                    name: 'Check-In Date',
                    pipes: ['date:medium'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (checkInDetail: IWalkInOrderPartial) => {
                        return checkInDetail.WaitingTime ? this.hourFilter.transform(checkInDetail.WaitingTime) : '';
                    },
                    name: 'Waiting Time',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['OrderNo'],
                    name: 'Order Number',
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder) => {
                        return this.selectedCheckInDetailIds.indexOf(order.Id) !== -1
                            ? `<i class="fa fa-check-square-o" aria-hidden="true"></i>`
                            : `<i class="fa fa-square-o" aria-hidden="true"></i>`;
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Add to Bulk Assignment',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (walkInOrder: IWalkInOrderPartial): string => {
                        const isTwicVerified: boolean = this.CheckWalkInOrderForTwicCard(walkInOrder);
                        return isTwicVerified ? `TWIC` : `Non-TWIC`;
                    },
                    columnClass: (walkInOrder: IWalkInOrderPartial) => {
                        const isTwicVerified: boolean = this.CheckWalkInOrderForTwicCard(walkInOrder);
                        if (isTwicVerified === false) {
                            return 'non-twic-verified-label';
                        }
                        return '';
                    },
                    name: 'TWIC Status',
                    sort: { disableSort: true },
                }),
            ],
        });
        this.subscriptions.add(
            this.stateService.selectedCheckInDetailIds$.subscribe((value) => {
                this.selectedCheckInDetailIds = value;
            }),
        );
    }

    private CheckWalkInOrderForTwicCard(walkInOrder: IWalkInOrderPartial): boolean {
        if(walkInOrder.CheckInUser != null){
            const checkInUser = walkInOrder.CheckInUser;
            let isTwicVerified: boolean = checkInUser?.ClaimsToHoldValidTwicCard;

            if (isTwicVerified === null || isTwicVerified === undefined) {
                isTwicVerified = UserService.checkUserIsTwicVerified(checkInUser);
            }
            return isTwicVerified;
        }
       return false;
     }
}
