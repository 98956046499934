import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICheckInDetail } from '../../model/interfaces/check-in-detail';
import { IPickup } from '../../model/interfaces/pickup';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';

@Component({
    selector: 'app-driver-orders-ready',
    templateUrl: './driver-orders-ready.component.html',
    styleUrls: ['./driver-orders-ready.component.css'],
})
export class DriverOrdersReadyComponent implements OnInit {
    driver: IUser;
    pickup: IPickup;
    checkInDetails: ICheckInDetail[];
    groupedCheckIns: ICheckInDetail[][];

    constructor(private router: Router, private checkInService: PublicCheckInService) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            this.checkInService.checkedInPickup$.subscribe((pickup) => {
                this.pickup = pickup;
                if (!driver || !pickup || !pickup.CheckIns || pickup.CheckIns.length <= 0) {
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }

                const checkInId = pickup.CheckIns[0].Id;
                this.checkInService.getCheckInDetailDoors(checkInId).subscribe({
                    next: (checkInDetails) => {
                        this.checkInDetails = checkInDetails;
                        this.groupedCheckIns = this.groupCheckInsByDoor(checkInDetails);
                    }
                });
            });
        });
    }

    navigateToGoogleMaps(detail: ICheckInDetail): void {
        const url = `https://www.google.com/maps/search/?api=1&query=${detail.OfficeDoor.Latitude},${detail.OfficeDoor.Longitude}`;
        window.open(url, '_blank');
    }

    goToDriverOrdersReadyPage(detail: ICheckInDetail): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        this.checkInService.checkedInOrder$.next(detail?.Order);
        void this.router.navigate([`public/driver-order-confirmation`]);
    }

    groupCheckInsByDoor(checkIns: ICheckInDetail[]): ICheckInDetail[][] {
        const doorMap = new Map<string, ICheckInDetail[]>();
        checkIns.forEach(checkIn => {
            const groupedCheckIns = doorMap.get(checkIn.OfficeDoor?.Door);
            if (groupedCheckIns) {
                groupedCheckIns.push(checkIn);
            } else {
                doorMap.set(checkIn.OfficeDoor?.Door, [checkIn]);
            }
        });

        return Array.from(doorMap.values());
    }

    getOrderNumbers(checkIns: ICheckInDetail[]): string {
        return checkIns.map(checkIn => checkIn.Order?.OrderNumber).join(', ');
    }
}
