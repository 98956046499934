import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { IUser } from '../../model/interfaces/user';
import { ActivatedRoute, Router } from '@angular/router';
import { IPickup } from '../../model/interfaces/pickup';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { PickupStatus } from '../../orders/order-basic-info/pickup-status-enum';
import { IModalResult, ModalService } from '@mt-ng2/modal-module';
import { CheckInStatuses } from '../../model/CheckInStatuses'
import { CommonFunctions } from '../../common/services/common-functions.service';

@Component({
    selector: 'app-driver-check-in-add-pickups',
    templateUrl: './driver-check-in-add-pickups.component.html',
    styleUrls: ['./driver-check-in-add-pickups.component.css'],
})
export class DriverCheckInAddPickupsComponent implements OnInit {
    driver: IUser;
    findPickupForm: FormGroup;
    pickups: IPickup[] = [];
    pickupNumber: string;

    constructor(
        private formBuilder: FormBuilder, 
        private checkInService: PublicCheckInService,
        private notificationService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.pickupNumber = params['pickupNumber'] ?? '';
            this.checkInService.checkedInDriver$.subscribe((driver) => {
                if (!driver) {
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }

                this.driver = driver;
                this.initForm(this.pickupNumber);
            });
        });
    }

    onSubmit() {
        const pickupNumber = this.findPickupForm.get('pickupNumber').value as string;
        this.checkInService.findValidPickup(pickupNumber)
            .subscribe({
                next: (pickup) => {
                    if (!pickup) {
                        this.notificationService.error('We were not able to find a pickup scheduled for today with the provided pickup number.');
                        return;
                    }

                    if (pickup.DateConfirmed && !CommonFunctions.isToday(pickup.DateConfirmed.toString())) {
                        const dateToDisplay = new Date(pickup.DateConfirmed);
                        this.notificationService.error(`The pickup number ${pickupNumber} is scheduled for ${dateToDisplay.toDateString()} ${dateToDisplay.toLocaleTimeString()}.`);
                        return;
                    }

                    const orders = pickup.Orders;
                    if (!orders || orders.length < 0) {
                        this.processPickup(pickup);
                        return;
                    }

                    const office = orders[0].Warehouse?.Office;
                    const location = office?.Location;
                    if (!office || office.CheckInRadiusLimit <= 0 || !location) {
                        this.processPickup(pickup);
                        return;
                    }

                    this.getCoordinates()
                        .then((coords: GeolocationCoordinates) => {
                            const distance = this.getDistanceFromLatLonInMiles(coords.latitude, coords.longitude, location.EntranceLatitude, location.EntranceLongitude);
                            if (distance > office.CheckInRadiusLimit) {
                                this.modalService
                                    .showModal({
                                        title: `Check-in is not allowed`,
                                        icon: 'error',
                                        html: `<p>We were not able to locate you within ${office.CheckInRadiusLimit} mile(s) of your check-in destination. Please get closer and try again.</p>
                                               <p>If you need help, please call us: <a href="tel:8564723142">(856) 742-3142</a></p>`,
                                        confirmButtonText: 'OK',
                                    })
                                    .subscribe();
                                return;
                            }
                            this.processPickup(pickup);
                        })
                        .catch((error: GeolocationPositionError) => {
                            if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
                                this.modalService
                                    .showModal({
                                        title: error.message,
                                        icon: 'error',
                                        html: `<p>Please allow your browser to track your location.</p>`,
                                        confirmButtonText: 'OK',
                                    })
                                    .subscribe();
                                return;
                            }
                            this.notificationService.error(`Error trying to get user's coordinates.`);
                        });
                },
                error: (errorResponse: HttpErrorResponse) => {
                    this.notificationService.error(errorResponse.error['ModelState'] as string);
                },
            });
    }

    initForm(pickupNumber: string): void {
        this.findPickupForm = this.formBuilder.group({
            pickupNumber: [pickupNumber, [Validators.required]],
        });
    }

    navigateToGoogleMaps(pickup: IPickup) {
        this.checkInService.getPickupLocation(this.driver.Id, pickup.Id)
            .subscribe({
                next: (location) => {
                    if (!location?.EntranceLatitude || !location?.EntranceLongitude) {
                        this.notificationService.error("This pickup does not have entrance coordinates entered for it's office location.");
                        return;
                    }
                    const url = `https://www.google.com/maps/search/?api=1&query=${location.EntranceLatitude},${location.EntranceLongitude}`;
                    window.open(url, '_blank');
                },
                error: () => {
                    this.notificationService.error('Error trying to get pickup location.');
                },
            });
    }

    goToCreatePickupPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate([`public/driver-check-in-create-pickup`]);
    }

    private processPickup(pickup: IPickup) {
        if (pickup.CheckIns && pickup.CheckIns[0]?.StatusId === CheckInStatuses.DriverNotified || pickup.StatusId === PickupStatus.DriverNotified) {
            this.checkInService.sendDriverNotifiedSms(this.driver.Id, pickup.Id)
                .subscribe({
                    next: (pickup) => {
                        this.checkInService.checkedInDriver$.next(this.driver);
                        this.checkInService.checkedInPickup$.next(pickup);
                        void this.router.navigate([`public/driver-notified`]);
                    },
                    error: () => {
                        this.notificationService.error('Error trying to get send driver notified SMS.');
                    },
                });
            return;
        }

        if (pickup.StatusId === PickupStatus.CheckedIn) {
            this.checkInService.checkedInDriver$.next(this.driver);
            this.checkInService.checkedInPickup$.next(pickup);
            void this.router.navigate([`public/driver-checked-in`]);
            return;
        }

        if (pickup.StatusId === PickupStatus.Requested) {
            this.checkInService.checkedInDriver$.next(this.driver);
            this.checkInService.checkedInPickup$.next(pickup);
            void this.router.navigate([`public/driver-check-in-edit-pickup`]);
            return;
        }
        
        if (pickup.StatusId === PickupStatus.Fulfilled) {
            this.checkInService.checkedInDriver$.next(this.driver);
            this.checkInService.checkedInPickup$.next(pickup);
            void this.router.navigate([`public/driver-notified`]);
            return;
        }

        if (pickup.StatusId !== PickupStatus.Confirmed) {
            this.notificationService.error('This pickup has not been confirmed yet.');
            return;
        }

        if (this.pickups.some(p => p.Id === pickup.Id)) {
            this.notificationService.error('This pickup has already been entered.');
            return;
        }
        
        this.modalService
            .showModal({
                title: 'Found It!',
                html: this.determineFoundPickupDialogMessage(),
                confirmButtonText: 'OK',
                denyButtonText: 'Edit Orders',
                showDenyButton: true,
                showCancelButton: true,
                denyButtonColor: '#7066e0',
                cancelButtonColor: '#7066e0',
                cancelButtonText: 'Close',
            })
            .subscribe((result: IModalResult) => {
                this.checkInService.createCheckInForDriver(this.driver.Id, pickup.Id)
                    .subscribe((checkIn) => {
                        this.checkInService.driverCreatedCheckIn$.next(checkIn);
                        this.checkInService.checkedInDriver$.next(this.driver);
                        this.checkInService.checkedInPickup$.next(pickup);
                        
                        if (result.isConfirmed) {
                            void this.router.navigate([`public/driver-check-in-payment`]);
                            return;
                        }

                        if (result.isDenied) {
                            void this.router.navigate([`public/driver-check-in-edit-pickup-orders`]);
                            return;
                        }
                    });
            });
    }

    private determineFoundPickupDialogMessage(): string { 
        let pickupDialogMessage = `
            <p>We've located the Pickup # you have entered.</p>
            <p>Select OK to proceed with payment or Edit Orders to add or remove orders from the pickup.</p>`;

        const twicCardIsCaptured = this.driver.TwicBackImageId && this.driver.TwicFrontImageId;
        const twicCardExpired = new Date(this.driver.TwicExpirationDate) < new Date();
        if (!twicCardIsCaptured || twicCardExpired) {
            pickupDialogMessage += `<br><p><b>NOTE:</b> Your TWIC card is either missing or expired. Please contact your administrator to resolve this issue.</p>`
        }

        return pickupDialogMessage;
    }

    private getCoordinates(): Promise<GeolocationCoordinates> {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject(new Error("Geolocation is not supported by your browser."));
                return;
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve(position.coords);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    // https://stackoverflow.com/a/27943
    private getDistanceFromLatLonInMiles(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const R = 3959; // Radius of the earth in miles
        const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
        const dLon = this.deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in miles
        return d;
    }

    private deg2rad(deg: number): number {
        return deg * (Math.PI / 180);
    }
}
