<div class="row">
    <div class="col-md-7">
        <h2>Documents</h2>
    </div>
</div>
<br>
<mt-search-bar (searched)="search($event)"></mt-search-bar>
<br>
<mt-entity-list 
    *ngIf="entityListConfig"
    [entities]="documents"
    [total]="total"
    [(itemsPerPage)]="itemsPerPage"
    [(currentPage)]="currentPage"
    (pageChanged)="getDeliveryDocuments()"
    [entityListConfig]="entityListConfig"
    (columnSortedEvent)="columnSorted($event)"
    (itemSelectedEvent)="onItemSelected($event)"
    (itemDeletedEvent)="onItemDeleted($event)">
>
</mt-entity-list>
