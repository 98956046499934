import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TruckerService } from '../trucker.service';
import { IUser } from '../../model/interfaces/user';

@Component({
    selector: 'app-trucker-header',
    templateUrl: './trucker-header.component.html',
})
export class TruckerHeaderComponent implements OnInit, OnDestroy {
    trucker: IUser;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private truckerService: TruckerService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get('truckerId');
        if (id > 0) {
            this.truckerService.getById(id).subscribe((trucker) => {
                this.setHeader(trucker);
            });
        } else {
            this.header = 'Add Trucker';
            this.trucker = this.truckerService.getEmptyTrucker();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(trucker: IUser): void {
        this.trucker = trucker;
        this.header = `Driver:${trucker.FirstName + ' ' + trucker.LastName}`;
    }
}
