import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICarrierRequest } from '../interfaces/carrier-request';
import { ICarrierRequestStatus } from '../interfaces/carrier-request-status';

export interface ICarrierRequestDynamicControlsParameters {
    formGroup?: string;
    statuses?: ICarrierRequestStatus[];
}

export class CarrierRequestDynamicControls {

    formGroup: string;
    statuses: ICarrierRequestStatus[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private carrierrequest?: ICarrierRequest, additionalParameters?: ICarrierRequestDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CarrierRequest';
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;

        this.Form = {
            CompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Name',
                name: 'CompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.carrierrequest && this.carrierrequest.hasOwnProperty('CompanyName') && this.carrierrequest.CompanyName !== null ? this.carrierrequest.CompanyName.toString() : '',
            }),
            ContactEmailAddress: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contact Email Address',
                name: 'ContactEmailAddress',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.carrierrequest && this.carrierrequest.hasOwnProperty('ContactEmailAddress') && this.carrierrequest.ContactEmailAddress !== null ? this.carrierrequest.ContactEmailAddress.toString() : '',
            }),
            ContactName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contact Name',
                name: 'ContactName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.carrierrequest && this.carrierrequest.hasOwnProperty('ContactName') && this.carrierrequest.ContactName !== null ? this.carrierrequest.ContactName.toString() : '',
            }),
            ContactPhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contact Phone Number',
                name: 'ContactPhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(15) ],
                validators: { 'required': true, 'maxlength': 15 },
                value: this.carrierrequest && this.carrierrequest.hasOwnProperty('ContactPhoneNumber') && this.carrierrequest.ContactPhoneNumber !== null ? this.carrierrequest.ContactPhoneNumber.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.carrierrequest && this.carrierrequest.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.carrierrequest && this.carrierrequest.DateModified || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.carrierrequest && this.carrierrequest.ModifiedById || null,
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.carrierrequest && this.carrierrequest.StatusId || 1,
            }),
        };

        this.View = {
            CompanyName: new DynamicLabel({
			    label: 'Company Name',
			    value: this.carrierrequest && this.carrierrequest.hasOwnProperty('CompanyName') && this.carrierrequest.CompanyName !== null ? this.carrierrequest.CompanyName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ContactEmailAddress: new DynamicLabel({
			    label: 'Contact Email Address',
			    value: this.carrierrequest && this.carrierrequest.hasOwnProperty('ContactEmailAddress') && this.carrierrequest.ContactEmailAddress !== null ? this.carrierrequest.ContactEmailAddress.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ContactName: new DynamicLabel({
			    label: 'Contact Name',
			    value: this.carrierrequest && this.carrierrequest.hasOwnProperty('ContactName') && this.carrierrequest.ContactName !== null ? this.carrierrequest.ContactName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ContactPhoneNumber: new DynamicLabel({
			    label: 'Contact Phone Number',
			    value: this.carrierrequest && this.carrierrequest.hasOwnProperty('ContactPhoneNumber') && this.carrierrequest.ContactPhoneNumber !== null ? this.carrierrequest.ContactPhoneNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.carrierrequest && this.carrierrequest.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.carrierrequest && this.carrierrequest.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: this.carrierrequest && this.carrierrequest.ModifiedById || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            StatusId: new DynamicLabel({
			    label: 'Status',
			    value: getMetaItemValue(this.statuses as unknown as IMetaItem[], this.carrierrequest && this.carrierrequest.hasOwnProperty('StatusId') && this.carrierrequest.StatusId !== null ? this.carrierrequest.StatusId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
