import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { IOfficePickupOverride } from '../../model/interfaces/office-pickup-override';
import { OfficePickupOverrideService } from '../services/office-pickup-override.service';
import { OfficePickupOverrideDynamicConfig } from '../office-pickup-override.dynamic-config';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'office-pickup-override',
    styles: [
        `
            .copy-text {
                font-size: 12px;
                text-align: center;
                margin-top: 15px;
            }
        `,
    ],
    templateUrl: 'office-pickup-override.component.html',
})
export class OfficePickupOverrideComponent implements OnInit {
    @Input() officeId = 0;
    formFactory: OfficePickupOverrideDynamicConfig<IOfficePickupOverride>;
    formObject: DynamicField[] = [];
    doubleClickIsDisabled = false;
    subscription: Subscription = new Subscription();
    officePickupOverrides: IOfficePickupOverride[] = [];
    selectedPickupOverride: IOfficePickupOverride;
    canEdit = false;
    isEditing = false;
    emptyOfficePickupOverride: IOfficePickupOverride = {
        EndTime: null,
        FromDate: null,
        Id: 0,
        NoOfPickupsPerHour: 0,
        OfficeId: this.officeId,
        StartTime: null,
        ToDate: null,
        NoEndDate: null,
        IsRecurring: true,
        IsRecurringSun: false,
        IsRecurringMon: false,
        IsRecurringTue: false,
        IsRecurringWed: false,
        IsRecurringThu: false,
        IsRecurringFri: false,
        IsRecurringSat: false,
    };

    constructor(
        public officePickupOverrideService: OfficePickupOverrideService,
        private cdr: ChangeDetectorRef,
        private notificationService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Offices, [ClaimValues.FullAccess]);
        this.getOverrides();
    }

    setConfig(): void {
        let config:IDynamicFormConfig;
        this.formFactory = new OfficePickupOverrideDynamicConfig<IOfficePickupOverride>(this.selectedPickupOverride);
        if (this.selectedPickupOverride.Id === 0) {
            // new office
            config = this.formFactory.getForCreate();
        } else {
            // existing office
            config = this.formFactory.getForUpdate();
        }
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
        this.repositionNoEndDateCheckbox();
    }

    getOverrides(): void {
        this.officePickupOverrideService.getByOffice(this.officeId).subscribe((result) => {
            this.officePickupOverrides = result;
        });
    }

    formSubmitted(form: UntypedFormGroup): void {
        // Kick out if the form is not valid
        if (!form.valid) {
            markAllFormFieldsAsTouched(form);
            return;
        }

        // Kick out if no days are selected for a recurring pickup
        const override: IOfficePickupOverride = form.value.OfficePickupOverride as IOfficePickupOverride;
        if (override.IsRecurring && !this.checkForOneOrMoreRecurringDays(override)){
            this.notificationService.error('At least one day must be selected for recurring pickup');
            return;
        }

        // Kick out if a non-recurring pickup has no end date
        if (!override.IsRecurring && override.NoEndDate) {
            this.notificationService.error('Non-recurring pickups must have an end date');
            return;
        }

        // Assign the form values to the selected pickup override
        this.formFactory.assignFormValues(this.selectedPickupOverride, override);
        this.selectedPickupOverride.OfficeId = this.officeId;
        if (this.selectedPickupOverride.NoEndDate) {
            this.selectedPickupOverride.ToDate = null;
        }

        // Create a new pickup override if it doesn't have an Id
        if (!this.selectedPickupOverride.Id || this.selectedPickupOverride.Id === 0) {
            this.officePickupOverrideService
                .create(this.selectedPickupOverride).subscribe({
                    next: () => {
                        this.selectedPickupOverride = null;
                        this.getOverrides();
                    },
                    error: (response: HttpErrorResponse) => {
                        this.handleErrorResponse(response);
                    }
                });
                return;
        }

        // Update the pickup override if it has an Id
        this.officePickupOverrideService
            .update(this.selectedPickupOverride).subscribe({
                next: () => {
                    this.selectedPickupOverride = null;
                    this.getOverrides();
                },
                error: (response: HttpErrorResponse) => {
                    this.handleErrorResponse(response);
                }
            });
    }

    isEditMode(override: IOfficePickupOverride): boolean {
        return this.selectedPickupOverride && this.selectedPickupOverride.Id === override.Id;
    }

    setEdit(override: IOfficePickupOverride): void {
        this.selectedPickupOverride = { ...override };
        this.setConfig();
    }

    cancel(): void {
        this.selectedPickupOverride = null;
    }

    add(): void {
        this.selectedPickupOverride = { ...this.emptyOfficePickupOverride };
        this.setConfig();
    }

    formCreated(event: UntypedFormGroup): void {
        this.subscription.add(
            event.get('OfficePickupOverride.NoEndDate').valueChanges.subscribe((answer) => {
                if (answer) {
                    event.get('OfficePickupOverride.ToDate').disable();
                    event.get('OfficePickupOverride.ToDate').setValue(null);
                    event.get('OfficePickupOverride.ToDate').mtSetRequired(false);
                } else {
                    event.get('OfficePickupOverride.ToDate').enable();
                    event.get('OfficePickupOverride.ToDate').mtSetRequired(true);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onDelete(event: IOfficePickupOverride): void {
        this.officePickupOverrideService.delete(event.Id).subscribe(
            () => {
                this.getOverrides();
                this.notificationService.success('Deleted Successfully.');
            },
            () => {
                this.notificationService.error('Error could not delete.');
            },
        );
    }

    private checkForOneOrMoreRecurringDays(override: IOfficePickupOverride): boolean {
        return (
            override.IsRecurringSun ||
            override.IsRecurringMon ||
            override.IsRecurringTue ||
            override.IsRecurringWed ||
            override.IsRecurringThu ||
            override.IsRecurringFri ||
            override.IsRecurringSat
        );
    }

    private repositionNoEndDateCheckbox(): void {
        setTimeout(() => {
            // Get the NoEndDate checkbox
            const noEndDateCheckbox = document.getElementById('NoEndDate');

            // Reposition it next to the EndDate field
            noEndDateCheckbox.parentElement.style.position = 'absolute';
            noEndDateCheckbox.parentElement.style.top = '380px';
            noEndDateCheckbox.parentElement.style.left = '210px';
        });
    }

    private handleErrorResponse(errorResponse: HttpErrorResponse): void {
        if (typeof(errorResponse.error) === 'string') {
            this.notificationService.error(errorResponse.error);
            return;
        }
        this.notificationService.error(errorResponse.error['ModelState'][0] as string);
    }
}
