import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScriptLoaderService {
    private loadedScripts: { [url: string]: { promise: Promise<void>; loaded: boolean } } = {};

    loadScript(url: string): Promise<void> {
        if (!this.loadedScripts[url]) {
            this.loadedScripts[url] = {
                promise: this.createScriptLoadPromise(url),
                loaded: false
            };
        }

        return this.loadedScripts[url].loaded 
            ? Promise.resolve() 
            : this.loadedScripts[url].promise;
    }

    private createScriptLoadPromise(url: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;

            script.onload = () => {
                this.loadedScripts[url].loaded = true;
                resolve();
            };
            
            script.onerror = (error) => reject(error);
            document.body.appendChild(script);
        });
    }
}