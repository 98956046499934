import { Component, inject, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { NavSidebarService } from './sidebar.service';
import { NavContentDynamicRowComponent } from '@mt-ng2/nav-module';
import { Subscription } from 'rxjs';

@Component({
    styles: [`
        .registration-requests-nav-link {
            padding: 5px 5px 5px 15px;
            display: block;
            font-size: 14px;
        }

        .registration-requests-nav-link:hover {
            color: #fff;
        }

        .registration-requests-nav-menu-title {
            white-space: normal;
        }
    `],
    template: `
        <a class="nav-item registration-requests-nav-link" [routerLink]="link">
            <ng-container class="ng-star-inserted">
                <div class="menu-img fa fa-fw fa-exchange"></div>
                <span [class.active]="IsRouterLinkActive" class="menu-title registration-requests-nav-menu-title">
                    Walk-In Deliveries <span>[{{count}}]</span>
                </span>
            </ng-container>
        </a>
    `
})
export class WalkInOrdersNavComponent extends NavContentDynamicRowComponent implements OnInit {
    private router = inject(Router);
    private navSidebarService = inject(NavSidebarService);
    private subscription: Subscription;
    count: number;
    link = '/orders/walk-in-orders';
    routerLinkActiveOptions: IsActiveMatchOptions = {
        paths: 'exact',
        queryParams: 'exact',
        fragment: 'ignored',
        matrixParams: 'ignored',
    };

    get IsRouterLinkActive(): boolean {
        return this.router.isActive(this.link, this.routerLinkActiveOptions);
    }

    ngOnInit(): void {
        this.refreshCount();
        this.subscription = this.navSidebarService.message$.subscribe(() => {
            this.refreshCount();
        });
    }

    private refreshCount(): void {
        this.navSidebarService.getWalkInOrdersCount().subscribe((value) => {
            this.count = value;
        });
    }
}
