import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IUser } from '../interfaces/user';
import { IAddress } from '../interfaces/address';
import { IAuthUser } from '../interfaces/auth-user';
import { ICarrier } from '../interfaces/carrier';
import { IContact } from '../interfaces/contact';
import { INotificationType } from '../interfaces/notification-type';
import { IImage } from '../interfaces/image';

export interface IUserDynamicControlsParameters {
    formGroup?: string;
    authUsers?: IAuthUser[];
    profileImages?: IImage[];
    addresses?: IAddress[];
    twicFrontImages?: IImage[];
    twicBackImages?: IImage[];
    contacts?: IContact[];
    carriers?: ICarrier[];
    notificationTypes?: INotificationType[];
}

export class UserDynamicControls {

    formGroup: string;
    authUsers?: IAuthUser[];
    profileImages?: IImage[];
    addresses?: IAddress[];
    twicFrontImages?: IImage[];
    twicBackImages?: IImage[];
    contacts?: IContact[];
    carriers?: ICarrier[];
    notificationTypes?: INotificationType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private user?: IUser, additionalParameters?: IUserDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'User';
        this.authUsers = additionalParameters && additionalParameters.authUsers || undefined;
        this.profileImages = additionalParameters && additionalParameters.profileImages || undefined;
        this.addresses = additionalParameters && additionalParameters.addresses || undefined;
        this.twicFrontImages = additionalParameters && additionalParameters.twicFrontImages || undefined;
        this.twicBackImages = additionalParameters && additionalParameters.twicBackImages || undefined;
        this.contacts = additionalParameters && additionalParameters.contacts || undefined;
        this.carriers = additionalParameters && additionalParameters.carriers || undefined;
        this.notificationTypes = additionalParameters && additionalParameters.notificationTypes || undefined;

        this.Form = {
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.AddressId || null,
            }),
            AuthUserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auth User',
                name: 'AuthUserId',
                options: this.authUsers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.user && this.user.AuthUserId || null,
            }),
            BannedFromPickup: new DynamicField({
                formGroup: this.formGroup,
                label: 'Banned From Pickup',
                name: 'BannedFromPickup',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.hasOwnProperty('BannedFromPickup') && this.user.BannedFromPickup != null ? this.user.BannedFromPickup : false,
            }),
            BannedFromPickupReason: new DynamicField({
                formGroup: this.formGroup,
                label: 'Banned From Pickup Reason',
                name: 'BannedFromPickupReason',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5000) ],
                validators: { 'maxlength': 5000 },
                value: this.user && this.user.hasOwnProperty('BannedFromPickupReason') && this.user.BannedFromPickupReason != null ? this.user.BannedFromPickupReason.toString() : '',
            }),
            CarrierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                name: 'CarrierId',
                options: this.carriers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.CarrierId || null,
            }),
            ClaimsToHoldValidTwicCard: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claims To Hold Valid Twic Card',
                name: 'ClaimsToHoldValidTwicCard',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.hasOwnProperty('ClaimsToHoldValidTwicCard') && this.user.ClaimsToHoldValidTwicCard != null ? this.user.ClaimsToHoldValidTwicCard : true,
            }),
            ContactId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contact',
                name: 'ContactId',
                options: this.contacts,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.ContactId || null,
            }),
            DriverNotificationOptOut: new DynamicField({
                formGroup: this.formGroup,
                label: 'Driver Notification Opt Out',
                name: 'DriverNotificationOptOut',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.hasOwnProperty('DriverNotificationOptOut') && this.user.DriverNotificationOptOut != null ? this.user.DriverNotificationOptOut : true,
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50), Validators.email ],
                validators: { 'required': true, 'maxlength': 50, 'email': true },
                value: this.user && this.user.hasOwnProperty('Email') && this.user.Email != null ? this.user.Email.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('FirstName') && this.user.FirstName != null ? this.user.FirstName.toString() : '',
            }),
            IsArchived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Archived',
                name: 'IsArchived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.hasOwnProperty('IsArchived') && this.user.IsArchived != null ? this.user.IsArchived : false,
            }),
            IsMobileUser: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Mobile User',
                name: 'IsMobileUser',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.hasOwnProperty('IsMobileUser') && this.user.IsMobileUser != null ? this.user.IsMobileUser : false,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('LastName') && this.user.LastName != null ? this.user.LastName.toString() : '',
            }),
            NotificationTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification Type',
                name: 'NotificationTypeId',
                options: this.notificationTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.NotificationTypeId || null,
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone',
                name: 'Phone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.user && this.user.hasOwnProperty('Phone') && this.user.Phone != null ? this.user.Phone.toString() : '',
            }),
            ProfileImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Profile Image',
                name: 'ProfileImageId',
                options: this.profileImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.ProfileImageId || null,
            }),
            TractorLicensePlate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tractor License Plate',
                name: 'TractorLicensePlate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('TractorLicensePlate') && this.user.TractorLicensePlate != null ? this.user.TractorLicensePlate.toString() : '',
            }),
            TrailerLicensePlate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trailer License Plate',
                name: 'TrailerLicensePlate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('TrailerLicensePlate') && this.user.TrailerLicensePlate != null ? this.user.TrailerLicensePlate.toString() : '',
            }),
            TruckingCompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trucking Company Name',
                name: 'TruckingCompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.user && this.user.hasOwnProperty('TruckingCompanyName') && this.user.TruckingCompanyName != null ? this.user.TruckingCompanyName.toString() : '',
            }),
            TwicBackImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Back Image',
                name: 'TwicBackImageId',
                options: this.twicBackImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.TwicBackImageId || null,
            }),
            TwicCardNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Card Number',
                name: 'TwicCardNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.user && this.user.hasOwnProperty('TwicCardNumber') && this.user.TwicCardNumber != null ? this.user.TwicCardNumber.toString() : '',
            }),
            TwicDateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Date Modified',
                name: 'TwicDateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.TwicDateModified || null,
            }),
            TwicExpirationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Expiration Date',
                name: 'TwicExpirationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.TwicExpirationDate || null,
            }),
            TwicFrontImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Front Image',
                name: 'TwicFrontImageId',
                options: this.twicFrontImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.TwicFrontImageId || null,
            }),
            UsdotNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Usdot Number',
                name: 'UsdotNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('UsdotNumber') && this.user.UsdotNumber != null ? this.user.UsdotNumber.toString() : '',
            }),
        };

        this.View = {
            AddressId: new DynamicLabel({
                label: 'Address',
                value: getMetaItemValue(this.addresses as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('AddressId') ? this.user.AddressId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            AuthUserId: new DynamicLabel({
                label: 'Auth User',
                value: getMetaItemValue(this.authUsers as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('AuthUserId') ? this.user.AuthUserId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            BannedFromPickup: new DynamicLabel({
                label: 'Banned From Pickup',
                value: this.user && this.user.hasOwnProperty('BannedFromPickup') && this.user.BannedFromPickup != null ? this.user.BannedFromPickup : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            BannedFromPickupReason: new DynamicLabel({
                label: 'Banned From Pickup Reason',
                value: this.user && this.user.hasOwnProperty('BannedFromPickupReason') && this.user.BannedFromPickupReason != null ? this.user.BannedFromPickupReason.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CarrierId: new DynamicLabel({
                label: 'Carrier',
                value: getMetaItemValue(this.carriers as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('CarrierId') ? this.user.CarrierId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClaimsToHoldValidTwicCard: new DynamicLabel({
                label: 'Claims To Hold Valid Twic Card',
                value: this.user && this.user.hasOwnProperty('ClaimsToHoldValidTwicCard') && this.user.ClaimsToHoldValidTwicCard != null ? this.user.ClaimsToHoldValidTwicCard : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ContactId: new DynamicLabel({
                label: 'Contact',
                value: getMetaItemValue(this.contacts as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('ContactId') ? this.user.ContactId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DriverNotificationOptOut: new DynamicLabel({
                label: 'Driver Notification Opt Out',
                value: this.user && this.user.hasOwnProperty('DriverNotificationOptOut') && this.user.DriverNotificationOptOut != null ? this.user.DriverNotificationOptOut : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Email: new DynamicLabel({
                label: 'Email',
                value: this.user && this.user.hasOwnProperty('Email') && this.user.Email != null ? this.user.Email.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.user && this.user.hasOwnProperty('FirstName') && this.user.FirstName != null ? this.user.FirstName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsArchived: new DynamicLabel({
                label: 'Is Archived',
                value: this.user && this.user.hasOwnProperty('IsArchived') && this.user.IsArchived != null ? this.user.IsArchived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsMobileUser: new DynamicLabel({
                label: 'Is Mobile User',
                value: this.user && this.user.hasOwnProperty('IsMobileUser') && this.user.IsMobileUser != null ? this.user.IsMobileUser : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.user && this.user.hasOwnProperty('LastName') && this.user.LastName != null ? this.user.LastName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            NotificationTypeId: new DynamicLabel({
                label: 'Notification Type',
                value: getMetaItemValue(this.notificationTypes as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('NotificationTypeId') ? this.user.NotificationTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Phone: new DynamicLabel({
                label: 'Phone',
                value: this.user && this.user.hasOwnProperty('Phone') && this.user.Phone != null ? this.user.Phone.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ProfileImageId: new DynamicLabel({
                label: 'Profile Image',
                value: getMetaItemValue(this.profileImages as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('ProfileImageId') ? this.user.ProfileImageId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TractorLicensePlate: new DynamicLabel({
                label: 'Tractor License Plate',
                value: this.user && this.user.hasOwnProperty('TractorLicensePlate') && this.user.TractorLicensePlate != null ? this.user.TractorLicensePlate.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            TrailerLicensePlate: new DynamicLabel({
                label: 'Trailer License Plate',
                value: this.user && this.user.hasOwnProperty('TrailerLicensePlate') && this.user.TrailerLicensePlate != null ? this.user.TrailerLicensePlate.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            TruckingCompanyName: new DynamicLabel({
                label: 'Trucking Company Name',
                value: this.user && this.user.hasOwnProperty('TruckingCompanyName') && this.user.TruckingCompanyName != null ? this.user.TruckingCompanyName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            TwicBackImageId: new DynamicLabel({
                label: 'Twic Back Image',
                value: getMetaItemValue(this.twicBackImages as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('TwicBackImageId') ? this.user.TwicBackImageId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TwicCardNumber: new DynamicLabel({
                label: 'Twic Card Number',
                value: this.user && this.user.hasOwnProperty('TwicCardNumber') && this.user.TwicCardNumber != null ? this.user.TwicCardNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            TwicDateModified: new DynamicLabel({
                label: 'Twic Date Modified',
                value: this.user && this.user.TwicDateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            TwicExpirationDate: new DynamicLabel({
                label: 'Twic Expiration Date',
                value: this.user && this.user.TwicExpirationDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            TwicFrontImageId: new DynamicLabel({
                label: 'Twic Front Image',
                value: getMetaItemValue(this.twicFrontImages as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('TwicFrontImageId') ? this.user.TwicFrontImageId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            UsdotNumber: new DynamicLabel({
                label: 'Usdot Number',
                value: this.user && this.user.hasOwnProperty('UsdotNumber') && this.user.UsdotNumber != null ? this.user.UsdotNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
