import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IDeliveryDynamicControlsParameters } from '../model/form-controls/delivery.form-controls';
import { IDelivery } from '../model/interfaces/delivery';
import { DeliveryDynamicControlsPartial } from '../model/partials/delivery-partial.form-controls';
import { IDeliveryType } from '../model/interfaces/delivery-type';
import { ITrailerType } from '../model/interfaces/trailer-type';
import { ICountry } from '../model/interfaces/country';
import { ICustomer } from '../model/interfaces/customer';
import { IDeliveryEquipmentType } from '../model/interfaces/delivery-equipment-type';
import { ICarrier } from '../model/interfaces/carrier';
import { UserRoles } from '../model/UserRoles';
import { IWarehouse } from '../model/interfaces/warehouse';

export class DeliveryDynamicConfig<T extends IDelivery> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private delivery: T, 
        private deliveryTypes: IDeliveryType[], 
        private deliveryEquipmentTypes: IDeliveryEquipmentType[],
        private trailerTypes: ITrailerType[],
        private countries: ICountry[],
        private customers: ICustomer[],
        private carriers: ICarrier[],
        private warehouses: IWarehouse[],
        private authUserRole: number,
        private configControls?: string[]
    ) {
        super();
        const additionalParams: IDeliveryDynamicControlsParameters = {
            deliveryTypes: this.deliveryTypes,
            deliveryEquipmentTypes: this.deliveryEquipmentTypes,
            trailerTypes: this.trailerTypes,
            countries: this.countries,
            customers: this.customers,
            carriers: this.carriers,
            warehouses: this.warehouses,
        };

        const dynamicControls = new DeliveryDynamicControlsPartial(this.delivery, additionalParams);

        if (configControls) {
            this.setControls(this.configControls, dynamicControls);
            return;
        }

        this.configControls = ['DeliveryDate'];

        if (this.authUserRole === UserRoles.Admin) 
            this.configControls.push('ConfirmedDeliveryDate');

        this.configControls = this.configControls.concat([
            'VesselDischargeDate',
            'DeliveryEquipmentTypeId',
            'DeliveryTypeId',
            'TrailerTypeId',
            'ContainerNumber',
            'TrailerNumber',
            'CustomerId',
            'CarrierId',
        ]);

        if (this.authUserRole === UserRoles.Admin) {
            this.configControls.push('WarehouseId');
        }
        this.configControls = this.configControls.concat([
            'CountryCode',
            'Commodity',
            'SupplierName',
            'ShippingLine',
            'VesselName',
            'VesselVoyage',
            'BookingNumber',
            'ChassisNumber',
            'SealNumber',
            'Temperature',
            'Full',
            'Hazmat',
        ]);

        if (this.authUserRole === UserRoles.Admin) {
            this.configControls.push('AtTemp');
            this.configControls.push('DataFile');
            this.configControls.push('Coc');
        }

        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
