<div *ngIf="loading" class="loading-spinner">
    <div class="spinner"></div>
</div>

<div *ngIf="!loading && driver && pickup" class="text-center public-responsive">
    <h1>Select Time & Date</h1>
    <br><br>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="list-container">
                <ul>
                    <li *ngFor="let order of pickup?.Orders">
                        <i class="fa fa-lg fa-check-circle"></i> {{ order.OrderNumber }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <br><br>
    <div class="row text-center centered-row">
        <div class="col-md-6 centered-column">
            <form [formGroup]="editPickupForm" (ngSubmit)="onSubmit()">
                <div>
                    <div>
                        <label for="dateRequested">Date Requested:</label>
                        <input type="date" id="dateRequested" formControlName="dateRequested">
                    </div>
                </div>
                <br>
                <div>
                    <label for="carrier">Trucking Company:</label>
                    <select id="carrier" formControlName="carrier">
                        <option *ngFor="let carrier of carriers" [value]="carrier.Id">{{ carrier.Name }}</option>
                    </select>
                </div>
                <br>
                <div *ngIf="availableDateTimes?.length">
                    <div>
                        <label>
                            <i class="fa fa-lg fa-fw fa-clock-o"></i> Available Times:
                        </label>
                    </div>
                    <div class="datetime-buttons-grid">
                        <button *ngFor="let dateTime of availableDateTimes" 
                            type="button"  
                            class="btn btn-success datetime-button"
                            [class.active]="selectedDateTime === dateTime"
                            [class.btn-btn]="selectedDateTime === dateTime"
                            [class.btn-success]="selectedDateTime === dateTime"
                            (click)="onDateTimeSelected(dateTime)">
                            {{ dateTime | date:'shortTime' }}
                        </button>
                    </div>
                </div>
                <div *ngIf="!availableDateTimes || availableDateTimes.length <= 0">
                    <h3>There are no available times. Please reach out to the Holt Admin for help with checking in.</h3>
                </div>
            </form>
            <br>
            <button *ngIf="availableDateTimes?.length" (click)="onSubmit()" type="submit" class="btn btn-primary" [disabled]="!selectedDateTime">Save Pickup</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <span class="new-driver-link--wrapper">
                <div class="new-driver-link">Finished Editing? Please <a (click)="goToDriverCheckInInfoPage()">click here</a> to return to the check-in info page.
                </div>
            </span>
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver" [pickup]="pickup"></app-driver-check-in-support-button>
    <br />
</div>
