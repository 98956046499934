import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    IDynamicField,
} from '@mt-ng2/dynamic-form';
import { OverrideNumericFunctionsEmptyWhenZero } from '../../common/services/common-functions.service';

import { CustomerDynamicControls, ICustomerDynamicControlsParameters } from '../form-controls/customer.form-controls';
import { ICustomer } from '../interfaces/customer';
import { IOffice } from '../interfaces/office';

export class CustomerDynamicControlsPartial extends CustomerDynamicControls {
    selectedOffice: IOffice;
    constructor(customerPartial?: ICustomer, additionalParameters?: ICustomerDynamicControlsParameters) {
        super(customerPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        (<DynamicField>this.Form.AvailableForCheckIn).labelHtml =
            '<label>Available For Check-In</label>';
        (<DynamicField>this.Form.AvailableForCheckIn).label =
            'Available For Check-In';

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.Archived).label = 'Some other Label';
        (<DynamicLabel>this.View.AvailableForCheckIn).label =
            'Available For Check-In';
        (<DynamicField>this.Form.NeedsAppointmentToCheckIn).labelHtml =
            '<label>Needs Appointment To Check-In</label>';
        (<DynamicField>this.Form.NeedsAppointmentToCheckIn).label =
            'Needs Appointment To Check-In';
        (<DynamicLabel>this.View.NeedsAppointmentToCheckIn).label =
        'Needs Appointment To Check-In';
        (<DynamicField>this.Form.DispatcherEmail).labelHtml =
            '<label>Customer Dispatcher Email</label>';
        (<DynamicField>this.Form.DispatcherEmail).label =
            'Customer Dispatcher Email';
        (<DynamicLabel>this.View.DispatcherEmail).label =
            'Customer Dispatcher Email';
        (<DynamicField>this.Form.DispatcherEmail).type.inputType = InputTypes.Textbox;

        (<DynamicField>this.Form.ParsNumberRequired).label = 'PARS Number Required';
        (<DynamicLabel>this.View.ParsNumberRequired).label = 'PARS Number Required';

        (<DynamicField>this.View.SecurityFee).value = customerPartial.SecurityFee ? `$${customerPartial.SecurityFee.toFixed(2)}` : '';

        (<DynamicField>this.Form.DefaultStartTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.DefaultEndTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.DefaultStartTime).type.timepickerOptions = { meridian: true };
        (<DynamicField>this.Form.DefaultEndTime).type.timepickerOptions = { meridian: true };

        (<DynamicField>this.View.DefaultWeekdayMon).labelHtml = '<b>Every Monday</b>';
        (<DynamicField>this.View.DefaultWeekdayTue).labelHtml = '<b>Every Tuesday</b>';
        (<DynamicField>this.View.DefaultWeekdayWed).labelHtml = '<b>Every Wednesday</b>';
        (<DynamicField>this.View.DefaultWeekdayThu).labelHtml = '<b>Every Thursday</b>';
        (<DynamicField>this.View.DefaultWeekdayFri).labelHtml = '<b>Every Friday</b>';
        (<DynamicField>this.View.DefaultWeekdaySat).labelHtml = '<b>Every Saturday</b>';
        (<DynamicField>this.View.DefaultWeekdaySun).labelHtml = '<b>Every Sunday</b>';

        (<DynamicField>this.Form.DefaultWeekdayMon).labelHtml = '<label>Every Monday</label>';
        (<DynamicField>this.Form.DefaultWeekdayTue).labelHtml = '<label>Every Tuesday</label>';
        (<DynamicField>this.Form.DefaultWeekdayWed).labelHtml = '<label>Every Wednesday</label>';
        (<DynamicField>this.Form.DefaultWeekdayThu).labelHtml = '<label>Every Thursday</label>';
        (<DynamicField>this.Form.DefaultWeekdayFri).labelHtml = '<label>Every Friday</label>';
        (<DynamicField>this.Form.DefaultWeekdaySat).labelHtml = '<label>Every Saturday</label>';
        (<DynamicField>this.Form.DefaultWeekdaySun).labelHtml = '<label>Every Sunday</label>';

        (<DynamicField>this.Form.SkipPayment).labelHtml = '<label>Skip Security Gate Fee</label>';
        (<DynamicLabel>this.View.SkipPayment).label = 'Skip Security Gate Fee';

        this.Form.NonTwicFee = new DynamicField({
            formGroup: this.formGroup,
            label: 'Security Gate Fee Non TWIC (in USD)',
            name: 'NonTwicFee',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Currency,
                scale: 2,
            }),
            validation: [ this.usdMoneyValidator() ],
            validators: {  },
            value: customerPartial && customerPartial.NonTwicFee && Number(customerPartial.NonTwicFee).toFixed(2),
        } as IDynamicField);

        this.Form.TwicFee = new DynamicField({
            formGroup: this.formGroup,
            label: 'Security Gate Fee TWIC (in USD)',
            name: 'TwicFee',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Currency,
                scale: 2,
            }),
            validation: [ this.usdMoneyValidator() ],
            validators: {  },
            value: customerPartial && customerPartial.TwicFee && Number(customerPartial.TwicFee).toFixed(2),
        } as IDynamicField);

        (<DynamicField>this.View.NonTwicFee).label = 'Security Gate Fee Non TWIC (in USD)';
        (<DynamicField>this.View.TwicFee).label = 'Security Gate Fee TWIC (in USD)';

        if (customerPartial?.NonTwicFee > 0) {
            (<DynamicField>this.View.NonTwicFee).value = `$${Number(customerPartial.NonTwicFee).toFixed(2)}`;
        } else {
            (<DynamicField>this.View.NonTwicFee).value = '';
        }

        if (customerPartial?.TwicFee > 0) {
            (<DynamicField>this.View.TwicFee).value = `$${Number(customerPartial.TwicFee).toFixed(2)}`;
        } else {
            (<DynamicField>this.View.TwicFee).value = '';
        }

        this.Form.PalletCount = new DynamicField({
            formGroup: this.formGroup,
                label: 'Pallet Count Limit',
                name: 'PalletCount',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    allowNullableOption: true,
                    scale: 0,
                }),
                validation: [ ],
                validators: { },
                value: customerPartial && customerPartial.PalletCount && Number(customerPartial.PalletCount).toFixed(0),
        } as IDynamicField);

        this.Form.OrderWeight = new DynamicField({
            formGroup: this.formGroup,
                label: 'Order Weight Limit (in lbs)',
                name: 'OrderWeight',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Float,
                    allowNullableOption: true,
                    scale: 2,
                }),
                validation: [ ],
                validators: { },
                
                value: customerPartial && customerPartial.OrderWeight && Number(customerPartial.OrderWeight).toFixed(2),
        } as IDynamicField);

        (<DynamicField>this.View.PalletCount).label = 'Pallet Count Limit';
        (<DynamicField>this.View.OrderWeight).label = 'Order Weight Limit (in lbs)';

        (<DynamicField>this.View.PalletCount).value = customerPartial?.PalletCount;
        (<DynamicField>this.View.OrderWeight).value = customerPartial?.OrderWeight;

        this.Form.NoOfPickupsPerHour = new DynamicField({
            formGroup: this.formGroup,
                label: 'No Of Pickups Per Hour',
                name: 'NoOfPickupsPerHour',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    allowNullableOption: true,
                    scale: 0,
                }),
                validation: [ ],
                validators: { },
                value: customerPartial && customerPartial.NoOfPickupsPerHour && Number(customerPartial.NoOfPickupsPerHour).toFixed(0),
        } as IDynamicField);

        (<DynamicField>this.View.NoOfPickupsPerHour).value = customerPartial?.NoOfPickupsPerHour;

        // Force fields to be empty instead of showing '0' when value is null.
        OverrideNumericFunctionsEmptyWhenZero([this.Form.OrderWeight, this.Form.PalletCount, this.Form.NoOfPickupsPerHour, this.Form.TwicFee, this.Form.NonTwicFee]);
    }

    usdMoneyValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value === null || value === '') {
                return null; // Don't validate empty values to allow optional controls
            }
            const validMoneyFormat = /^\d+(\.\d{1,2})?$/;
            const isValid = validMoneyFormat.test(value);
            return isValid ? null : { invalidMoneyFormat: true };
        };
    }
}
