<form #readyForm="ngForm" (ngSubmit)="formSubmitted(readyForm)">
    <label for="gatePassNumber">Gate Pass Number</label>
    <input type="text" name="gatePassNumber" [ngModel]="pickup.GatePassNumber" class="form-control" />
    <br>
    <div class="text-center">
        <button type="submit" class="btn btn-success" [disabled]="doubleClickIsDisabled">
            Save
        </button>
        <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
    </div>
</form>
