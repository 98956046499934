<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Pickup Pre-Payment</h2>
        </div>
    </div>
    <div class="miles-editable miles-card centered-card">
        <table class="table table-striped pickup-scheduling-table">
            <thead>
                <tr>
                    <th><b>Order Number</b></th>
                    <th><b>Office</b></th>
                    <th><b>Warehouse</b></th>
                    <th><b>Customer</b></th>
                    <th><b>Date Created</b></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of pickup?.Orders">
                    <td><span>{{ order.OrderNumber }}</span></td>
                    <td><span>{{ order.Warehouse?.Office?.Title }}</span></td>
                    <td><span>{{ order.Warehouse?.WarehouseId }}</span></td>
                    <td><span>{{ order.Customer?.Name }}</span></td>
                    <td><span>{{ order.DateCreated | date: 'medium' }}</span></td>
            </tbody>
        </table>
        <br>
        <div>
            <div class="form-group">
                <p><b>Fee:</b> {{ remainingBalance | currency:'USD' }}</p>
                <p><b>Pickup Number:</b> {{ pickup?.PickupNumber }}</p>
                <p><b>Payment Method:</b></p>
                <div>
                    <label class="radio-inline">
                        <input type="radio" name="paymentMethod" value="creditCard" [(ngModel)]="selectedPaymentMethod"
                            (change)="initCreditCardPayment()" [disabled]="loading">
                        Credit Card
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="paymentMethod" value="applePay" [(ngModel)]="selectedPaymentMethod"
                            (change)="initApplePayPayment()" [disabled]="loading">
                        Apple Pay
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="paymentMethod" value="googlePay" [(ngModel)]="selectedPaymentMethod"
                            (change)="initGooglePayPayment()" [disabled]="loading">
                        Google Pay
                    </label>
                </div>
            </div>
            
            <div [ngSwitch]="selectedPaymentMethod">
                <div *ngSwitchCase="'creditCard'">
                    <div id="payment-form">
                        <div #creditCardContainer id="credit-card-container"></div>
                        <button #creditCardButton id="credit-card-button" type="button"
                            class="btn btn-raised btn-success" [disabled]="loading">
                            {{ loading ? 'Loading...' : 'Pay' }}
                        </button>
                    </div>
                </div>
                <div *ngSwitchCase="'applePay'">
                    <div id="payment-form">
                        <div #applePayContainer id="apple-pay-container"></div>
                        <button #applePayButton id="apple-pay-button" type="button" 
                            class="btn btn-raised btn-success" [disabled]="loading || applePayNotLoaded">
                            {{ determineApplePayButtonText() }}
                        </button>
                    </div>
                </div>
                <div *ngSwitchCase="'googlePay'">
                    <div id="payment-form">
                        <button type="button" class="btn btn-raised btn-success" *ngIf="loading" disabled>
                            Loading...
                        </button>
                        <div #googlePayButton id="google-pay-button">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
