import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { ICustomerPickupOverride } from '../model/interfaces/customer-pickup-override';

export const emptyCustomerPickupOverride: ICustomerPickupOverride = {
    CustomerId: null,
    EndTime: null,
    FromDate: null,
    Id: 0,
    NoOfPickupsPerHour: 0,
    StartTime: null,
    ToDate: null,
    NoEndDate: null,
    IsRecurring: true,
    IsRecurringSun: true,
    IsRecurringMon: true,
    IsRecurringTue: true,
    IsRecurringWed: true,
    IsRecurringThu: true,
    IsRecurringFri: true,
    IsRecurringSat: true,
};

@Injectable()
export class CustomerPickupOverrideService extends BaseService<ICustomerPickupOverride> {
    constructor(public http: HttpClient) {
        super('/customer-pickup-overrides', http);
    }

    getByCustomer(customerId: number): Observable<ICustomerPickupOverride[]> {
        return this.http.get<ICustomerPickupOverride[]>(`/customer-pickup-overrides/customer/${customerId}`);
    }

    getEmptyCustomerPickupOverride(): ICustomerPickupOverride {
        return { ...emptyCustomerPickupOverride };
    }
}
