import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { CommonModule } from '@angular/common';
import { ContactsRoutingModule } from './contacts-routing.module';
import { ContactService } from '../model/shared-entities/contacts/contact.service';
import { ContactsComponent } from './contacts-list/contacts-list.component';
import { ContactDetailComponent } from './contacts-details/contacts-details.component';

@NgModule({
    declarations: [ContactsComponent, ContactDetailComponent],
    imports: [SharedModule, ContactsRoutingModule, CommonModule],
})
export class ContactModule {
    static forRoot(): ModuleWithProviders<ContactModule> {
        return {
            ngModule: ContactModule,
            providers: [ContactService],
        };
    }
}
