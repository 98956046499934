import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { CarrierService } from '../../carriers/services/carrier.service';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { CustomerService } from '../../customers/customer.service';
import { forkJoin } from 'rxjs';
import { ContactService } from '../contact.service';
import { IContact } from '../../model/interfaces/contact';
import { ContactsDynamicConfig } from '../contacts.dynamic-config';
import { NotificationTypeService } from '../notification-type.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IUser } from '../../model/interfaces/user';
import { UserRoles } from '../../model/UserRoles';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { UserService } from '../../users/user.service';
import { ICarrier } from '../../model/interfaces/carrier';
import { ICustomer } from '../../model/interfaces/customer';
import { INotificationType } from '../../model/interfaces/notification-type';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contacts-info.component.html',
})
export class ContactInfoComponent implements OnInit {
  @Input() contact: IContact;
  @Input() canEdit: boolean;
  user: IUser;
  currentUser: ILoggedIn;
  isEditing: boolean;
  isHovered: boolean;
  contactId = 0;
  checkInId = 0;
  viewOnly: DynamicLabel[] = [];
  formObject: DynamicField[] = [];
  formFactory: ContactsDynamicConfig<IContact>;
  doubleClickIsDisabled = false;
  errorMessage: string;
  carriers: ICarrier[];
  customers: ICustomer[];
  notificationTypes: INotificationType[];

  constructor(
    private contactsService: ContactService,
    private notificationsService: NotificationsService,
    private router: Router,
    private carrierService: CarrierService,
    private customerService: CustomerService,
    private notificationTypeService: NotificationTypeService,
    private authService: AuthService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.isEditing = false;
    this.currentUser = this.authService.currentUser.getValue();
    forkJoin([
      this.carrierService.getActive(), 
      this.customerService.getActiveCustomers(), 
      this.notificationTypeService.getAll(),
      this.userService.getById(this.currentUser.Id ?? 0),
    ]).subscribe((response:  [ICarrier[], ICustomer[], INotificationType[], IUser]) => {
      this.carriers = response[0];
      this.customers = response[1];
      this.notificationTypes = response[2].sort((a,b) => a.Id - b.Id);
      this.user = response[3];

      // Add option to un-select contact carrier.
      const noCarrier = this.carrierService.getEmptyCarrier();
      noCarrier.Name = 'None'
      this.carriers.unshift(noCarrier);

      // Add option to un-select contact customer.
      const noCustomer = this.customerService.getEmptyCustomer();
      noCustomer.Name = 'None'
      this.customers.unshift(noCustomer);

      this.setConfig();
    });
  }

  setConfig(): void {
    const configControls: string[] = [
      'FirstName',
      'LastName',
      'Email',
      'PhoneNo',
      'NotificationTypeId',
      'CarrierId',
      'CustomerId',
    ];

    this.formFactory = new  ContactsDynamicConfig<IContact>(
      this.contact,
      this.carriers,
      this.customers,
      this.notificationTypes,
      configControls,
    );

    let config: IDynamicFormConfig;
    if (this.contact.Id === 0) {
      // new customer
      this.isEditing = true;
      config = this.formFactory.getForCreate();
    } else {
      // existing customer
      config = this.formFactory.getForUpdate();
    }

    this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
    this.formObject = config.formObject?.map((x) =>new DynamicField(x));

    // If logged-in user is not an admin, then disable carrier & customer fields
    if (this.user?.AuthUser?.RoleId !== UserRoles.Admin) { 
      this.formObject.find(o => o.name === 'CarrierId').disabled = true;
      this.formObject.find(o => o.name === 'CustomerId').disabled = true;
      return;
    }

    // If contact has a selected carrier, then disable the customer field
    if (this.contact.CarrierId > 0)
      this.formObject.find(o => o.name === 'CustomerId').disabled = true;

    // If contact has a selected customer, then disable the carrier field
    if (this.contact.CustomerId > 0)
      this.formObject.find(o => o.name === 'CarrierId').disabled = true;
  }

  edit(): void {
    if (this.canEdit) {
      this.isEditing = true;
    }
  }

  cancelClick(): void {
    if (this.contact.Id === 0) {
      void this.router.navigate(['/contacts']);
    } else {
      this.isEditing = false;
    }
  }

  formSubmitted(form: UntypedFormGroup): void {
    if (form.valid) {
      this.formFactory.assignFormValues(this.contact, form.value.Contact as IContact);
      
      if (this.contact.CarrierId === 0)
        this.contact.CarrierId = null;

      if (this.contact.CustomerId === 0)
        this.contact.CustomerId = null;

      if (!this.contact.Id || this.contact.Id === 0) {
        this.contactsService
            .create(this.contact)
            .pipe(finalize((() => (this.doubleClickIsDisabled = false))))
            .subscribe((answer) => {
                void this.router.navigate([`/contacts/${answer}`]);
                this.success();
                this.contactsService.emitChange(this.contact);
            },
            (errorResponse: HttpErrorResponse) => {
              this.notificationsService.error(errorResponse.error["ModelState"][0] as string);
            });
      } else {
        this.contactsService.update(this.contact)
          .pipe(finalize(() => this.doubleClickIsDisabled = false))
          .subscribe(
            (response: IContact) => {
              this.contact = response;
              this.isEditing = false;
              this.contactsService.emitChange(this.contact);
              this.setConfig();
              this.success();
            },
            (errorResponse: HttpErrorResponse) => {
              this.notificationsService.error(errorResponse.error["ModelState"][0] as string);
            }
          );
      }
    } else {
      markAllFormFieldsAsTouched(form);
      this.error();
    }
  }

  error(): void {
    this.notificationsService.error('Save Failed');
  }

  success(): void {
    this.notificationsService.success('Saved Successfully');
  }
}
