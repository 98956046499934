<div class="row">
    <div class="col-md-7">
        <h2>
            Check-In Monitoring
            <button *ngIf="lastCheckInId > 0" type="button" (click)="undoMarkingFulFilled()" class="btn btn-default btn-nohover btn-flat">
                <i class="fa"></i>
                <i class="fa fa-undo" aria-hidden="true"></i>
                <i class="fa"></i>
                Undo
            </button>
        </h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <br>
    <mt-search-filter-select
        *ngIf="warehouseMetaItems?.length > 0"
        [items]="warehouseMetaItems"
        entity="Warehouse"
        (selectionChanged)="getCheckInsToBeFulFilled()">
    </mt-search-filter-select>
    <mt-search-filter-select
        *ngIf="officeMetaItems?.length > 0"
        [items]="officeMetaItems"
        entity="Office"
        (selectionChanged)="getCheckInsToBeFulFilled()">
    </mt-search-filter-select>
    <mt-search-filter-select
        *ngIf="customerMetaItems?.length > 0"
        [items]="customerMetaItems"
        entity="Customer"
        (selectionChanged)="getCheckInsToBeFulFilled()">
    </mt-search-filter-select>
    <mt-search-filter-select
        *ngIf="driverMetaItems?.length > 0"
        [items]="driverMetaItems"
        entity="Driver"
        (selectionChanged)="getCheckInsToBeFulFilled()">
    </mt-search-filter-select>
    <mt-search-filter-select
        *ngIf="checkInStatusItems?.length > 0"
        [items]="checkInStatusItems"
        entity="Status"
        (selectionChanged)="getCheckInsToBeFulFilled()">
    </mt-search-filter-select>
    <button class="btn btn-default" *ngIf="showExpandAllButton()" (click)="expandAll()">
        Expand All
    </button>
    <button class="btn btn-default" *ngIf="showCollapseAllButton()" (click)="collapseAll()">
        Collapse All
    </button>
    <br><br>
</div>
<div class="table-responsive">
    <table class="table table-hover">
        <thead>
            <tr>
                <th></th>
                <th>
                    <b>Pickup Number</b>
                </th>
                <th class="cursor-pointer" (click)="sortColumnClicked(sortColumns[0])">
                    <b>Driver Name</b>
                    <sort-arrows [sortDirection]="sortColumns[0].SortDirection"></sort-arrows>
                </th>
                <th>Comment</th>
                <th class="cursor-pointer" (click)="sortColumnClicked(sortColumns[1])">
                    <b>Check-In Time</b>
                    <sort-arrows [sortDirection]="sortColumns[1].SortDirection"></sort-arrows>
                </th>
                <th class="cursor-pointer" (click)="sortColumnClicked(sortColumns[2])">
                    <b>Status</b>
                    <sort-arrows [sortDirection]="sortColumns[2].SortDirection"></sort-arrows>
                </th>
                <th>
                    <b>Gate Pass Number</b>
                </th>
                <th>
                    <b>Notify Driver</b>
                </th>
                <th *ngIf="canEditCheckIn">
                    <b>Actions</b>
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let checkinQueueItem of checkInQueueItems">
                <tr class="no-pointer pickup-row">
                    <td class="toggle-caret-wrapper" (click)="toggleOrders(checkinQueueItem)">
                        <i class="fa" [ngClass]="checkinQueueItem.ShowOrders ? 'fa-caret-down' : 'fa-caret-right'"></i>
                    </td>
                    <td>
                        {{ checkinQueueItem.CheckInInfo.Pickup?.PickupNumber ?? checkinQueueItem.CheckInInfo.Delivery?.DeliveryNumber ?? 'N/A' }}
                    </td>
                    <td>
                        <a (click)="onEdit(checkinQueueItem.CheckInInfo)">{{ checkinQueueItem.getName() }}</a>
                        <a [attr.href]="'#/truckers/' + checkinQueueItem.CheckInInfo.CheckInUserId">
                            ({{ checkinQueueItem.CheckInInfo.TwicCardVerified ? 'TWIC' : 'Non-TWIC' }})
                        </a>
                    </td>
                    <td>
                        <span *ngIf="!checkinQueueItem?.CheckInInfo?.Comment" (click)="onUpdateComment(checkinQueueItem)" class="cursor-pointer">
                            <i class="fa fa-lg fa-sticky-note"></i>
                        </span>
                        <a *ngIf="checkinQueueItem?.CheckInInfo?.Comment?.length > 0" (click)="onUpdateComment(checkinQueueItem)">
                            {{ checkinQueueItem.CheckInInfo.Comment }}
                        </a>
                    </td>
                    <td>
                        {{ checkinQueueItem.CheckInInfo.DateCreated | date: 'MM/dd/yy HH:mm' }}
                    </td>
                    <td>
                        {{ checkinQueueItem.CheckInInfo.CheckInStatusName }}
                        <span *ngIf="checkinQueueItem.CheckInInfo.DriverReportsPickup"><i>(Driver Reports Pickup)</i></span>
                    </td>
                    <td *ngIf="isAdminUser && checkinQueueItem.CheckInInfo.Pickup?.Id > 0">
                        <a (click)="updatePickupGatePassNumber(checkinQueueItem.CheckInInfo.Pickup)">
                            {{ displayGatePassNumber(checkinQueueItem.CheckInInfo.Pickup?.GatePassNumber) }}
                        </a>
                    </td>
                    <td *ngIf="isAdminUser && checkinQueueItem.CheckInInfo.Delivery?.Id > 0">
                        <a (click)="updateDeliveryGatePassNumber(checkinQueueItem.CheckInInfo.Delivery)">
                            {{ displayGatePassNumber(checkinQueueItem.CheckInInfo.Delivery?.GatePassNumber) }}
                        </a>
                    </td>
                    <td>
                        <a (click)="onNotifyDriver(checkinQueueItem)">
                            <img src="/assets/ready-for-driver.svg" height="25" />
                        </a>
                        <div *ngIf="checkinQueueItem.CheckInInfo.DriverFirstNotifiedTime">
                            <i
                                ><small>{{ getNotificationInfo(checkinQueueItem.CheckInInfo) }}</small></i
                            >
                        </div>
                    </td>
                    <td *ngIf="canEditCheckIn">
                        <a (click)="onCheckInFulFilled(checkinQueueItem.CheckInInfo)" title="Mark Fulfilled">
                            <img src="/assets/order-fulfilled.svg" height="20" />
                        </a>
                        <a (click)="onCheckInNotFulFilled(checkinQueueItem.CheckInInfo)" title="Mark Not Fulfilled" >
                            <img src="/assets/order-not-fulfilled.svg" height="40" />
                        </a>
                    </td>
                </tr>
                <ng-container *ngIf="checkinQueueItem.ShowOrders">
                    <tr class="no-pointer no-border order-row">
                        <th class="sub-item-header"
                            *ngIf="checkinQueueItem.CheckInQueueItemDetails[0] && checkinQueueItem.CheckInQueueItemDetails[0].ParsNumber" 
                        >
                            <b>PARS Number</b>
                        </th>
                        <th class="sub-item-header">
                            <b>Warehouse</b>
                        </th>
                        <th class="sub-item-header">
                            <b>PO/Order #</b>
                        </th>
                        <th class="sub-item-header">
                            <b>Office</b>
                        </th>
                        <th class="sub-item-header">
                            <b>Customer</b>
                        </th>
                        <th class="sub-item-header">
                            <b>Scheduled Date</b>
                        </th>
                        <th class="sub-item-header">
                            <b>Status</b>
                        </th>
                        <th class="sub-item-header">
                            <b>Door #</b>
                        </th>
                        <th class="sub-item-header">
                            <b>Re-notify</b>
                        </th>
                        <th *ngIf="canEditCheckIn" class="sub-item-header">
                            <b>Actions</b>
                        </th>
                    </tr>
                    <tr class="no-pointer no-border order-row" *ngFor="let checkinDetail of checkinQueueItem.CheckInQueueItemDetails">
                        <td *ngIf="checkinDetail.ParsNumber" class="ellipsis sub-item">
                            {{ checkinDetail.ParsNumber }}
                        </td>
                        <td class="ellipsis sub-item" (click)="updateWarehouse(checkinDetail)">
                            <a>{{ checkinDetail.Warehouse ? checkinDetail.Warehouse.WarehouseId : '--' }}</a>
                        </td>
                        <td class="ellipsis sub-item">
                            {{ checkinDetail.OrderNo }}
                        </td>
                        <td class="ellipsis sub-item">
                            {{ checkinDetail.Office?.Title }}
                        </td>
                        <td class="ellipsis sub-item">
                            {{ checkinDetail.CustomerName }}
                        </td>
                        <td class="ellipsis sub-item">
                            {{ checkinDetail.PickUpDateTime ? (checkinDetail.PickUpDateTime | date: 'short') : 'N/A' }}
                        </td>
                        <td *ngIf="checkinQueueItem.CheckInInfo.Pickup?.Id > 0" class="ellipsis sub-item">
                            {{ getOrderStatusName(checkinDetail.Order?.StatusId) }}
                        </td>
                        <td *ngIf="checkinQueueItem.CheckInInfo.Delivery?.Id > 0" class="ellipsis sub-item">
                            {{ getDeliveryStatusName(checkinQueueItem.CheckInInfo.Delivery?.StatusId) }}
                        </td>
                        <td class="ellipsis sub-item">
                            {{ checkinDetail.OfficeDoorName ? checkinDetail.OfficeDoorName : 'N/A' }}
                        </td>
                        <td class="ellipsis sub-item">
                            <button type="submit" class="btn btn-flat" (click)="onResendNotification(checkinDetail)">
                                <i class="fa fa-share"></i>
                            </button>
                        </td>
                        <td *ngIf="canEditCheckIn" class="ellipsis sub-item">
                            <a (click)="onOrderFulFilled(checkinQueueItem, checkinDetail)" title="Mark Fulfilled" style="margin-right: 15px;">
                                <img src="/assets/order-fulfilled.svg" height="20" />
                            </a>
                            <a (click)="onOrderNotFulFilled(checkinQueueItem, checkinDetail)" title="Mark Not Fulfilled" >
                                <img src="/assets/order-not-fulfilled.svg" height="40" />
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
    </table>
</div>

<div *ngIf="noCheckInQueueItems()">
    <h3 style="text-align: center">No data to display.</h3>
</div>

<mt-modal-wrapper
    [showActions]="false"
    [autoShow]="false"
    (ready)="pickupGatePassNumberDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }">

    <pickup-gatepass-number-dialog *ngIf="selectedPickup" [pickup]="selectedPickup" (onClose)="closePickupGatePassNumber()"></pickup-gatepass-number-dialog>
</mt-modal-wrapper>

<mt-modal-wrapper
    [showActions]="false"
    [autoShow]="false"
    (ready)="deliveryGatePassNumberDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }">

    <delivery-gatepass-number-dialog *ngIf="selectedDelivery" [delivery]="selectedDelivery" (onClose)="closeDeliveryGatePassNumber()"></delivery-gatepass-number-dialog>
</mt-modal-wrapper>

<mt-modal-wrapper
    title="Select Door"
    [showActions]="false"
    [autoShow]="false"
    (ready)="checkInDoorSelectionDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }"
>
    <check-in-door-selection-dialog
        *ngIf="selectedCheckIn"
        [checkin]="selectedCheckIn"
        (onClose)="closeOpenDoorSelectionDialog()"
    ></check-in-door-selection-dialog>
</mt-modal-wrapper>

<mt-modal-wrapper
    title="Gate Pass"
    [showActions]="false"
    [autoShow]="false"
    (ready)="gatePassDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }"
>
    <gate-pass-dialog *ngIf="selectedCheckIn" [checkin]="selectedCheckIn" (onClose)="closeGatePassDialog()"></gate-pass-dialog>
</mt-modal-wrapper>
