<div class="miles-editable miles-card padded" *ngIf="order?.Id > 0">
    <h4>Order Info</h4>
    <mt-dynamic-view [config]="config.viewOnly"></mt-dynamic-view>
    <div>
        <div class="display-padded ng-star-inserted">
            <b>Division:</b><span>{{order.Warehouse?.Division}}</span>
        </div>
        <div class="display-padded ng-star-inserted">
            <b>Location:</b><span>{{order.Warehouse?.Office?.Title}}</span>
        </div>
        <div class="display-padded ng-star-inserted">
            <b>Pickup Number:</b><span>{{order.Pickup?.PickupNumber}}</span>
        </div>
        <div class="display-padded ng-star-inserted">
            <b>TWIC Status:</b>
            <span [ngClass]="twicVerifiedLabel === 'Non-TWIC' ? 'invalid-label': ''">{{twicVerifiedLabel}}</span>
        </div>
    </div>
</div>