<mt-dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
    <!-- input element -->
    <mt-type-ahead
        [matchingStrategy]="matchingStrategy"
        [items]="items"
        [disabled]="isDisabled"
        [placeholder]="placeholder"
        (selectionChanged)="selectionChanged($event)"
        (ready)="typeAheadControlReady($event)"
        [selectedItem]="selectedItem"
        [isItemDisabled]="isItemDisabled"
        [maxToShow]="maxToShow"
    >
    </mt-type-ahead>
    <!-- maxlength elements -->
    <span
    *ngIf="config.labelLeft || hasMaxLength()"
    [style.visibility]="isFocused ? null : 'hidden'"
    class="small pull-right"
    [class.errortext]="hasError('maxlength')"
    [class.inside-box-maxlength]="config.insideBoxValidation"
    >{{ getMaxLengthText() }}</span>

    <!-- validation elements -->
    <mt-dynamic-form-validation-message
        [errorMessage]="errorMessage"
        [insideBoxValidation]="config.insideBoxValidation"
    ></mt-dynamic-form-validation-message>
</mt-dynamic-form-input-wrapper>