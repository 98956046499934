<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && trucker && trucker.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Twic Image {{ getHeader() }}
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div [ngSwitch]="twicImageType">
        <div *ngSwitchCase="TwicImageTypes.Front">
            <img *ngIf="trucker.TwicFrontImage" src="{{ imagePath }}{{ trucker.TwicFrontImage.ThumbnailPath }}" class="thumb" alt="Twic Image" />
            <img *ngIf="!trucker.TwicFrontImage" src="{{ imagePath }}NoImage.png" class="thumb" alt="Twic Image" />
        </div>
        <div *ngSwitchCase="TwicImageTypes.Back">
            <img *ngIf="trucker.TwicBackImage" src="{{ imagePath }}{{ trucker.TwicBackImage.ThumbnailPath }}" class="thumb" alt="Twic Image" />
            <img *ngIf="!trucker.TwicBackImage" src="{{ imagePath }}NoImage.png" class="thumb" alt="Twic Image" />
        </div>
        <div *ngSwitchDefault></div>
    </div>
</div>
<div *ngIf="trucker" [hidden]="!isEditing" class="miles-form padded">
    <div [ngSwitch]="twicImageType">
        <div *ngSwitchCase="TwicImageTypes.Front">
            <mt-photo [photo]="trucker.TwicFrontImage" (fileCropped)="afterFileCropped($event)" (photoDeleted)="deletePhoto()"></mt-photo>
        </div>
        <div *ngSwitchCase="TwicImageTypes.Back">
            <mt-photo [photo]="trucker.TwicBackImage" (fileCropped)="afterFileCropped($event)" (photoDeleted)="deletePhoto()"></mt-photo>
        </div>
        <div *ngSwitchDefault></div>
    </div>
    <br />
    <button class="btn btn-flat btn-primary" type="submit" (click)="savePhoto()">Save</button>
    <button class="btn btn-flat btn-default" type="button" (click)="isEditing = false">Cancel</button>
</div>
