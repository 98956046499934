<div *ngIf="driver && driver.Id > 0" class="text-center public-responsive">
    <h1>Enter Pickup #</h1>
    <br />
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <form [formGroup]="findPickupForm" (ngSubmit)="onSubmit()">
                <div class="form-contents">
                    <div>
                        <input type="text" id="pickupNumber" formControlName="pickupNumber" required>
                        <div *ngIf="findPickupForm.get('pickupNumber').invalid && findPickupForm.get('pickupNumber').touched"
                            class="error-message">
                            Pickup Number is required.
                        </div>
                    </div>
                </div>
                <br>
                <button type="submit" class="btn btn-primary" [disabled]="findPickupForm.invalid">Add this Pickup #</button>
            </form>
        </div>
    </div>
    <br />
    <div *ngIf="!pickups || pickups.length <= 0" class="row">
        <p class="f-20">Don't have a Pickup #?</p>
        <div class="col-md-12 col-sm-12">
            <button type="button" class="btn btn-primary" (click)="goToCreatePickupPage()">
                Create a Pickup
            </button>
        </div>
    </div>
    <br />
    <div *ngIf="pickups?.length > 0" class="row">
        <div class="col-md-12 col-sm-12">
            <p class="f-20">Get Directions</p>
            <ul>
                <li *ngFor="let pickup of pickups">
                    <button (click)="navigateToGoogleMaps(pickup)" class="btn btn-primary btn-get-directions">{{ pickup.PickupNumber }}</button>
                </li>
            </ul>
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver"></app-driver-check-in-support-button>
    <br />
</div>