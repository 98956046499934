import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IDriverBanHistory } from '../../model/interfaces/driver-ban-history';

export const emptyDriverBanHistory: IDriverBanHistory = {
    Action: null,
    ActionDate: null,
    Id: 0,
    UserId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class TruckerBanHistoryService extends BaseService<IDriverBanHistory> {
    constructor(public http: HttpClient) {
        super('/trucker-ban-history', http);
    }

    getEmptyDriverBanHistory(): IDriverBanHistory {
        return { ...emptyDriverBanHistory };
    }
}
