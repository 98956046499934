<div class="text-center public-responsive">
    <div>
        <img class="support-logo" src="{{ logoImageSrc }}" />
    </div>
    <span class="f-30">Holt Logistics Support Team</span>
    <p class="f-20">We are here to help! Please select the button below to get in contact with us:</p>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <button (click)="callPhoneNumber()" class="btn btn-primary">Call Us</button>
            <a id="phone-number-link" style="display: none;" href="tel:2151233456">215-123-3456</a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <span class="new-driver-link--wrapper">
                <div class="new-driver-link">Finished? Please <a (click)="goBack()">click here</a> to return to the previous page.
                </div>
            </span>
        </div>
    </div>
    <br />
</div>
