import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { IOrder } from '../../../../model/interfaces/order';
import { OrderService } from '../../../../orders/order.service';

@Component({
    selector: 'order-total-weight-dialog',   
    templateUrl: './order-total-weight-dialog.component.html',
})
export class OrderTotalWeightDialogComponent {
    customerId: number;
    doubleClickIsDisabled = false;
    totalWeight: number;

    @Input('order') order: IOrder;
    @Output('onClose') onClose: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private notificationsService: NotificationsService,
        private orderService: OrderService,
    ) { }

    formSubmitted(ngForm: NgForm): void {
        const newTotalWeight = this.roundTwoDecimalPlaces(ngForm.form.value.totalWeight as number);
        if (newTotalWeight < 0) {
            this.notificationsService.error('Please enter a valid number.');
            return;
        }
        
        this.order.TotalWeight = newTotalWeight;
        this.orderService.update(this.order)
            .pipe(finalize(() => this.doubleClickIsDisabled = false))
            .subscribe({
                next: () => {
                    this.notificationsService.success('Order Weight Updated.');
                    this.close();
                }
            });
    }

    close(): void {
        this.onClose.emit();
    }

    private roundTwoDecimalPlaces(num: number): number {
        const factor = Math.pow(10, 2);
        return Math.round(num * factor) / factor;
    }
}
