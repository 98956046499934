import { RegistrationRequestDynamicControls, IRegistrationRequestDynamicControlsParameters } from '../form-controls/registration-request.form-controls';
import { IRegistrationRequest } from '../interfaces/registration-request';
import { ICarrier } from '../interfaces/carrier';
import { ICustomer } from '../interfaces/customer';
import { IRegistrationRequestStatus } from '../interfaces/registration-request-status';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

export class RegistrationRequestDynamicControlsPartial extends RegistrationRequestDynamicControls {

    constructor(registrationRequestPartial?: IRegistrationRequest, additionalParameters?: IRegistrationRequestDynamicControlsParameters) {
        super(registrationRequestPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.CarrierId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CustomerId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateCreated).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateModified).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.EmailAddress).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.FirstName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.LastName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ModifiedBy).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotificationWhenDriverChecksIn).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotificationWhenDriverIsFulfilled).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotificationWhenDriverIsIssuedGatePass).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotificationWhenDriverIsNotified).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.PhoneNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.StatusId).type.fieldType = DynamicFieldTypes.Input;
        (<DynamicField>this.Form.CarrierId).value = registrationRequestPartial && registrationRequestPartial.CarrierId ? registrationRequestPartial.CarrierId : 0;
        (<DynamicField>this.Form.CustomerId).value = registrationRequestPartial && registrationRequestPartial.CustomerId ? registrationRequestPartial.CustomerId : 0;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.CarrierId).label = 'Some other Label';
        // (<DynamicLabel>this.View.CustomerId).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateCreated).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateModified).label = 'Some other Label';
        // (<DynamicLabel>this.View.EmailAddress).label = 'Some other Label';
        // (<DynamicLabel>this.View.FirstName).label = 'Some other Label';
        // (<DynamicLabel>this.View.LastName).label = 'Some other Label';
        // (<DynamicLabel>this.View.ModifiedBy).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotificationWhenDriverChecksIn).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotificationWhenDriverIsFulfilled).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotificationWhenDriverIsIssuedGatePass).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotificationWhenDriverIsNotified).label = 'Some other Label';
        // (<DynamicLabel>this.View.PhoneNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.StatusId).label = 'Some other Label';
        this.View.CarrierId = new DynamicLabel({
			    label: 'Carrier',
			    value: registrationRequestPartial && registrationRequestPartial.hasOwnProperty('CarrierId') && registrationRequestPartial.CarrierId !== null ? registrationRequestPartial.Carrier?.Name : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
        );

        this.View.CustomerId = new DynamicLabel({
			    label: 'Customer',
			    value: registrationRequestPartial && registrationRequestPartial.hasOwnProperty('CustomerId') && registrationRequestPartial.CustomerId !== null ? registrationRequestPartial.Customer?.Name : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
        );

    }
}
