import { formatDate } from '@angular/common';
import { EntityListConfig, SortDirection, EntityListDeleteColumn, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IDocument } from '../../model/interfaces/base';

export class DeliveryDocumentsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Name'],
                    name: 'Name',
                }),
                new EntityListColumn({
                    accessorFunction: function (document: IDocument): string {
                        return formatDate(document.DateUpload, 'medium', 'en-US');
                    },
                    name: 'Date Upload',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'DateUpload',
                    },
                }),
            ],
        };

        super(listConfig);
        this.delete = new EntityListDeleteColumn();
    }
}
