<div [ngStyle]="{ height: '500px' }">
    <br />
    <div *ngIf="customerScheduleOrderService.validOrders.length">
        <div class="row">
            <div class="text-center">
                <h4>Please select an available date and time to pick up your {{ customerScheduleOrderService.validOrders.length == 1 ? 'order' : 'orders' }} using the search below </h4>
            </div>
        </div>
        <br />
        <div class="row" [ngStyle]="{ margin: 'auto', 'max-width': '500px' }">
            <form [formGroup]="orderTimeFilterForm">
                <div class="text-center">
                    <mt-dynamic-field
                        class="text-center"
                        [field]="abstractSearchUserControls.StartDate"
                        (valueChanges)="StartDateChange($event)"
                        [form]="orderTimeFilterForm"
                    ></mt-dynamic-field>
                    <span *ngIf = "customerScheduleOrderService.fromReschedule"><i>Current Pick-up Time: {{ this.customerScheduleOrderService.prevOrderDate | date:'medium' }} </i></span>
                </div>
            </form>
        </div>
        <br />
        <div class="row">
            <div
                class="table-responsive"
                [ngStyle]="{ 'max-width': '500px', margin: 'auto', 'max-height': '250px', height: '250px', 'overflow-y': 'auto' }"
            >
                <table class="table table-hover table-striped">
                    <thead style="width: calc(100% - 1em)">
                        <tr class="no-pointer">
                            <th>
                                <b>Date</b>
                            </th>
                            <th>
                                <b>Time</b>
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let timeslot of availableDateTimes" (click)="customerScheduleOrderService.orderDate = timeslot">
                            <td [style.background-color]="timeslot === customerScheduleOrderService.orderDate ? '#7f9fc6' : 'white'">
                                {{ timeslot | date: 'M/dd/yyyy' }}
                            </td>
                            <td [style.background-color]="timeslot === customerScheduleOrderService.orderDate ? '#7f9fc6' : 'white'">
                                {{ timeslot | date: 'h:mm a' }}
                            </td>
                            <td
                                [style.background-color]="timeslot === customerScheduleOrderService.orderDate ? '#7f9fc6' : 'white'"
                                class="text-center"
                                style="vertical-align: inherit"
                            >
                                <input type="radio" [checked]="timeslot === customerScheduleOrderService.orderDate" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="!customerScheduleOrderService.validOrders.length">
        <div class="row" [ngStyle]="{ height: '405px' }">
            <div class="text-center"><h4>Please select at least one order to choose a time</h4></div>
        </div>
    </div>
    <div class="row copy-text" style="margin-top: 8px">
        <span><i>For Questions regarding your order, please contact us at 215-551-2737 or email us Customerservice@holtlogistics.com.</i></span>
    </div>
    <div class="row" style="margin: 20px; margin-top: 2px" *ngIf = "!customerScheduleOrderService.fromReschedule">
        <button class="btn btn-primary pull-right" [ngStyle]="{ 'margin-left': '40px' }" (click)="Next()">Next</button>
        <button class="btn btn-submit pull-left" (click)="Prev()">Previous</button>
    </div>
    <div class="row" style="margin: 20px; margin-top: 2px" *ngIf = "customerScheduleOrderService.fromReschedule">
        <button class="btn btn-submit pull-left" (click)="PrevFromReschedule()" [disabled]="isLoading">Previous</button>
        <button class="btn btn-primary pull-right" [ngStyle]="{ 'margin-left': '40px' }" (click)="Reschedule()" [disabled]="isLoading">Reschedule</button>
    </div>
    <ngx-loading [show]="isLoading"></ngx-loading>
</div>
