import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICarrierRequest } from '../model/interfaces/carrier-request';
import { Observable } from 'rxjs';
import { ICarrier } from '../model/interfaces/carrier';

export const emptyCarrierRequest: ICarrierRequest = {
    CompanyName: '',
    ContactEmailAddress: '',
    ContactName: '',
    ContactPhoneNumber: '',
    DateCreated: new Date(),
    Id: 0,
    StatusId: 1,
};

@Injectable({
    providedIn: 'root',
})
export class CarrierRequestService extends BaseService<ICarrierRequest> {
    constructor(public http: HttpClient) {
        super('/carrier-requests', http);
    }

    getEmptyCarrierRequest(): ICarrierRequest {
        return { ...emptyCarrierRequest };
    }

    createNewCarrier(requestId: number, carrier : ICarrier): Observable<void> {
        return this.http.post<void>(`/carrier-requests/create-new-carrier/${requestId}`, carrier);
    }
}
