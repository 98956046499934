<div *ngIf="loading" class="loading-spinner">
    <div class="spinner"></div>
</div>

<div *ngIf="!loading && driver && driver.Id > 0" class="text-center public-responsive">
    <div *ngIf="enteringOrders">
        <h1>Enter Order #</h1>
        <p class="f-20">Which orders are you picking up today?</p>
        <p class="f-20">Enter your Orders below. Once your list is complete, press 'Done'.</p>
        <br />
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <form [formGroup]="findOrderForm" (ngSubmit)="findOrderFormSubmit()">
                    <div class="form-contents">
                        <div>
                            <input type="text" id="orderNumber" formControlName="orderNumber" required>
                        </div>
                    </div>
                    <br>
                    <button type="submit" class="btn btn-primary" [disabled]="findOrderForm.invalid">Add Order to Pickup</button>
                </form>
            </div>
        </div>
        <br /><br />
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="list-container">
                    <ul>
                        <li *ngFor="let order of orders">
                            <i class="fa fa-lg fa-check-circle"></i> {{ order.OrderNumber }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <button class="btn btn-primary" [disabled]="orders.length <= 0" (click)="confirmOrders()">Done</button>
            </div>
        </div>
    </div>

    <div *ngIf="!enteringOrders">
        <h1>Select Time & Date</h1>
        <br><br>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="list-container">
                    <ul>
                        <li *ngFor="let order of orders">
                            <i class="fa fa-lg fa-check-circle"></i> {{ order.OrderNumber }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <br><br>
        <div class="row text-center centered-row">
            <div class="col-md-6 centered-column">
                <form [formGroup]="selectTimeAndDateForm" (ngSubmit)="selectTimeAndDateFormSubmit()">
                    <div>
                        <div>
                            <label for="dateRequested">Date Requested:</label>
                            <input type="date" id="dateRequested" formControlName="dateRequested">
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="carrier">Trucking Company:</label>
                        <select id="carrier" formControlName="carrier">
                            <option *ngFor="let carrier of carriers" [value]="carrier.Id">{{ carrier.Name }}</option>
                        </select>
                    </div>
                    <br>
                    <div *ngIf="availableDateTimes?.length">
                        <div>
                            <label>
                                <i class="fa fa-lg fa-fw fa-clock-o"></i> Available Times:
                            </label>
                        </div>
                        <div class="datetime-buttons-grid">
                            <button *ngFor="let dateTime of availableDateTimes" 
                                type="button"  
                                class="btn btn-success datetime-button"
                                [class.active]="selectedDateTime === dateTime"
                                [class.btn-btn]="selectedDateTime === dateTime"
                                [class.btn-success]="selectedDateTime === dateTime"
                                (click)="onDateTimeSelected(dateTime)">
                                {{ dateTime | date:'shortTime' }}
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!availableDateTimes || availableDateTimes.length <= 0">
                        <h3>There are no available times. Please reach out to the Holt Admin for help with checking in.</h3>
                    </div>
                </form>
                <br>
                <button *ngIf="availableDateTimes?.length" (click)="selectTimeAndDateFormSubmit()" type="submit" class="btn btn-primary" [disabled]="!selectedDateTime">Save Pickup</button>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <span class="new-driver-link--wrapper">
                        <div class="new-driver-link">Need to add orders? Please <a (click)="addMoreOrders()">click here</a> to return to the previous step.
                        </div>
                    </span>
                </div>
            </div>
            <br />
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver" [orders]="orders"></app-driver-check-in-support-button>
    <br />
</div>