<div class="row">
    <div class="col-md-7">
        <h2>Exception Queue</h2>
    </div>
</div>
<div>
    <mt-dynamic-search-filters [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search($event)"></mt-dynamic-search-filters>
</div>
<br>
<mt-entity-list 
    *ngIf="exceptions && entityListConfig"
    [entities]="exceptions" 
    [total]="total" 
    [(currentPage)]="currentPage" 
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="pageChanged()" 
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (columnSelectedEvent)="columnSelected($event)"
>
</mt-entity-list>
