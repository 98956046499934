import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDelivery } from '../../model/interfaces/delivery';
import { DeliveryStatuses } from '../../model/DeliveryStatuses';
import { DeliveryTypes } from '../../model/DeliveryTypes';
import { DeliveryEquipmentTypes } from '../../model/DeliveryEquipmentTypes';
import { TrailerTypes } from '../../model/TrailerTypes';

@Component({
    selector: 'delivery-calendar-table-dialog',
    templateUrl: './delivery-calendar-table-dialog.component.html',
    styleUrls: ['./delivery-calendar-table-dialog.component.css'],
})
export class DeliveryCalendarTableDialogComponent {
    @Input('deliveries') deliveries: IDelivery[];
    @Output('onClose') onClose: EventEmitter<void> = new EventEmitter<void>();
    
    DeliveryStatuses = DeliveryStatuses;
    DeliveryEquipmentTypes = DeliveryEquipmentTypes;
    DeliveryTypes = DeliveryTypes;
    TrailerTypes = TrailerTypes;

    constructor() {}

    close(): void {
        this.onClose.emit();
    }

    goToDeliveriesPage(): void {
        // Construct the URL with query parameters
        const deliveryNumbers = this.deliveries.map(delivery => delivery.DeliveryNumber).join(',');
        const url = `#/deliveries?deliveryNumbers=${encodeURIComponent(deliveryNumbers)}`;
        
        // Open the deliveries page in a new tab with the query parameters
        window.open(url, '_blank');
    }
}
