import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DeliveryService } from '../services/delivery.service';
import { IDelivery } from '../../model/interfaces/delivery';
import { DeliveryDynamicConfig } from '../delivery.dynamic-config';
import { finalize } from 'rxjs/operators';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IDeliveryType } from '../../model/interfaces/delivery-type';
import { forkJoin, Subscription } from 'rxjs';
import { ITrailerType } from '../../model/interfaces/trailer-type';
import { CommonService } from '../../common/services/common.service';
import { ICountry } from '../../model/interfaces/country';
import { CustomerService } from '../../customers/customer.service';
import { ICustomer } from '../../model/interfaces/customer';
import { IDeliveryEquipmentType } from '../../model/interfaces/delivery-equipment-type';
import { CarrierService } from '../../carriers/services/carrier.service';
import { ICarrier } from '../../model/interfaces/carrier';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { OrderService } from '../../orders/order.service';
import { DeliveryEquipmentTypes } from '../../model/DeliveryEquipmentTypes';
import { WarehouseService } from '../../warehouses/services/warehouse.service';
import { IWarehouse } from '../../model/interfaces/warehouse';
import { UserRoles } from '../../model/UserRoles';
import { UserService } from '../../users/user.service';
import { DeliveryStatuses } from '../../model/DeliveryStatuses';
import { DeliveryTypes } from '../../model/DeliveryTypes';

@Component({
    selector: 'app-delivery-basic-info',
    templateUrl: './delivery-basic-info.component.html',
})
export class DeliveryBasicInfoComponent implements OnInit {
    @Input() delivery: IDelivery;
    @Input() canEdit: boolean;
    @Input() canAdd: boolean;

    isEditing: boolean;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: DeliveryDynamicConfig<IDelivery>;
    doubleClickIsDisabled = false;
    deliveryTypes: IDeliveryType[];
    deliveryEquipmentTypes: IDeliveryEquipmentType[];
    trailerTypes: ITrailerType[];
    countries: ICountry[];
    customers: ICustomer[];
    carriers: ICarrier[];
    warehouses: IWarehouse[];
    authUserRole: number;
    subscription: Subscription = new Subscription();
    loggedIn: ILoggedIn;

    constructor(
        private deliveryService: DeliveryService,
        private notificationsService: NotificationsService,
        private router: Router,
        private commonService: CommonService,
        private customerService: CustomerService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private orderService: OrderService,
        private warehouseService: WarehouseService,
        private userService: UserService,
    ) { }

    ngOnInit(): void {
        this.isEditing = false;
        this.loggedIn = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(this.loggedIn.AuthId).subscribe(authUserRole => {
            this.authUserRole = authUserRole;
            forkJoin([
                this.deliveryService.getSimpleDeliveryTypes(),
                this.deliveryService.getSimpleDeliveryEquipmentTypes(),
                this.deliveryService.getSimpleTrailerTypes(),
                this.commonService.getCountries(),
                this.customerService.getActiveCustomers(),
                this.carrierService.getSimpleCarriers(),
                this.warehouseService.getUserWarehouses(),
            ]).subscribe(([
                deliveryTypes, 
                deliveryEquipmentTypes, 
                trailerTypes, 
                countries, 
                customers,
                carriers,
                warehouses
            ]) => {
                this.deliveryTypes = deliveryTypes;
                this.deliveryEquipmentTypes = deliveryEquipmentTypes;
                this.trailerTypes = trailerTypes;
                this.countries = countries;
                this.customers = customers;
                this.carriers = carriers;
                this.warehouses = warehouses;
                this.setConfig();
            });
        });
    }

    setConfig(): void {
        let config: IDynamicFormConfig;
        this.formFactory = new DeliveryDynamicConfig<IDelivery>(
            this.delivery, 
            this.deliveryTypes, 
            this.deliveryEquipmentTypes,
            this.trailerTypes, 
            this.countries,
            this.customers,
            this.carriers,
            this.warehouses,
            this.authUserRole
        );

        if (this.delivery.Id === 0) {
            // new delivery
            this.isEditing = true;
            config = this.formFactory.getForCreate();
        } else {
            // existing delivery
            config = this.formFactory.getForUpdate();
        }

        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.delivery.Id === 0) {
            void this.router.navigate(['/deliveries']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (!form.valid) {
            markAllFormFieldsAsTouched(form);
            this.error();
            return;
        }

        const formValues = form.value.Delivery as IDelivery;            
        this.formFactory.assignFormValues(this.delivery, formValues);

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const confirmedDeliveryDate = this.delivery.ConfirmedDeliveryDate;
        if (confirmedDeliveryDate !== null && confirmedDeliveryDate < today) {
            this.notificationsService.error('Confirmed Delivery Date cannot be set to a past date.');
            return;
        }

        // Not sure why but "assignFormValues" isn't working for these form value assignments.
        this.delivery.DeliveryEquipmentTypeId = formValues.DeliveryEquipmentTypeId;
        this.delivery.DeliveryTypeId = formValues.DeliveryTypeId;
        this.delivery.TrailerTypeId = formValues.TrailerTypeId;
        this.delivery.WarehouseId = formValues.WarehouseId;
        this.delivery.CountryCode = formValues.CountryCode;
        this.delivery.ContainerNumber = formValues.ContainerNumber;
        this.delivery.TrailerNumber = formValues.TrailerNumber;
        this.delivery.Temperature = formValues.Temperature;

        if (this.delivery.CountryCode === '')
            this.delivery.CountryCode = null;

        if (this.delivery.DeliveryTypeId <= 0)
            this.delivery.DeliveryTypeId = null;

        if (this.delivery.TrailerTypeId <= 0)
            this.delivery.TrailerTypeId = null;

        if (!this.delivery.Id || this.delivery.Id === 0) {
            // handle new delivery save
            this.deliveryService.create(this.delivery)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe((answer) => {
                    void this.router.navigate([`/deliveries/${answer}`]);
                    this.success();
                    this.deliveryService.emitChange(this.delivery);
                });
        } else {
            // handle existing delivery save
            this.changeDeliveryStatusIfNeeded();
            this.deliveryService.updateWithFks(this.delivery)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe(() => {
                    this.isEditing = false;
                    this.success();
                    this.deliveryService.emitChange(this.delivery);
                    this.setConfig();
                });
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Delivery saved successfully.');
    }

    formCreated(event: UntypedFormGroup): void {
        this.subscription.add(
            event.get('Delivery.DeliveryEquipmentTypeId').valueChanges.subscribe((answer) => {
                if (answer === DeliveryEquipmentTypes.Container) {
                    this.setContainerFields(event);
                    return;
                }

                if (answer === DeliveryEquipmentTypes.FacilitySupplies ||
                    answer === DeliveryEquipmentTypes.TrailerFlatbedOther
                ) {
                    this.setTrailerFields(event);
                    return;
                }

                this.setNonContainerNonTrailerFields(event);
            }),
        );

        this.userService.getById(this.loggedIn.Id).subscribe({
            next: (user) => {
                if (this.authUserRole === UserRoles.Carrier) {
                    event.get('Delivery.CarrierId').setValue(user.Contact?.CarrierId);
                    event.get('Delivery.CarrierId').disable();
                }

                if (this.authUserRole === UserRoles.Customer) {
                    event.get('Delivery.CustomerId').setValue(user.Contact?.CustomerId);
                    event.get('Delivery.CustomerId').disable();
                }
            }
        });        
    }

    private setContainerFields(event: UntypedFormGroup): void {
        event.get('Delivery.ContainerNumber').enable();
        event.get('Delivery.ContainerNumber').mtSetRequired(true);

        event.get('Delivery.DeliveryTypeId').enable();
        event.get('Delivery.DeliveryTypeId').mtSetRequired(true);
        
        event.get('Delivery.TrailerNumber').disable();
        event.get('Delivery.TrailerNumber').mtSetRequired(false);

        event.get('Delivery.TrailerTypeId').disable();
        event.get('Delivery.TrailerTypeId').setValue(null);
        event.get('Delivery.TrailerTypeId').mtSetRequired(false);
    }

    private setTrailerFields(event: UntypedFormGroup): void {
        event.get('Delivery.TrailerNumber').enable();
        event.get('Delivery.TrailerNumber').mtSetRequired(true);

        event.get('Delivery.DeliveryTypeId').enable();
        event.get('Delivery.DeliveryTypeId').mtSetRequired(true);

        event.get('Delivery.TrailerTypeId').enable();
        event.get('Delivery.TrailerTypeId').mtSetRequired(true);

        event.get('Delivery.ContainerNumber').disable();
        event.get('Delivery.ContainerNumber').mtSetRequired(false);
    }

    private setNonContainerNonTrailerFields(event: UntypedFormGroup): void {
        event.get('Delivery.DeliveryTypeId').disable();
        event.get('Delivery.DeliveryTypeId').setValue(null);
        event.get('Delivery.DeliveryTypeId').mtSetRequired(false);

        event.get('Delivery.TrailerTypeId').disable();
        event.get('Delivery.TrailerTypeId').setValue(null);
        event.get('Delivery.TrailerTypeId').mtSetRequired(false);

        event.get('Delivery.TrailerNumber').disable();
        event.get('Delivery.TrailerNumber').mtSetRequired(false);

        event.get('Delivery.ContainerNumber').disable();
        event.get('Delivery.ContainerNumber').mtSetRequired(false);
    }

    private changeDeliveryStatusIfNeeded(): void {
        // If the delivery is Confirmed and is cargo for warehouse and has a data file, set status to ReadyForCheckIn
        const isConfirmed = this.delivery.StatusId === DeliveryStatuses.Confirmed;
        const isCargoForWarehouse = this.delivery.DeliveryTypeId === DeliveryTypes.CargoForWarehouse;
        if (isConfirmed && isCargoForWarehouse && this.delivery.DataFile) {
            this.delivery.StatusId = DeliveryStatuses.ReadyForCheckIn;
            return;
        }

        // If the delivery is Confirmed and is cargo for fumigation and has a data file or CoC, set status to ReadyForCheckIn
        const isCargoForFumigation = this.delivery.DeliveryTypeId === DeliveryTypes.CargoForFumigation;
        if (isConfirmed && isCargoForFumigation && (this.delivery.DataFile || this.delivery.Coc)) {
            this.delivery.StatusId = DeliveryStatuses.ReadyForCheckIn;
        }

        // If the delivery is ReadyForCheckIn and is cargo for warehouse and doesn't have a data file, set status to confirmed
        const isReadyForCheckIn = this.delivery.StatusId === DeliveryStatuses.ReadyForCheckIn;
        if (isReadyForCheckIn && isCargoForWarehouse && !this.delivery.DataFile) {
            this.delivery.StatusId = DeliveryStatuses.Confirmed;
            return;
        }

        // If the delivery is ReadyForCheckIn and is cargo for fumigation and doesn't have a data file or CoC, set status to confirmed
        if (isReadyForCheckIn && isCargoForFumigation && (!this.delivery.DataFile || !this.delivery.Coc)) {
            this.delivery.StatusId = DeliveryStatuses.Confirmed;
        }
    }
}
