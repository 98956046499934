<div *ngIf="selectedOrders?.length > 0">
    <div class="row">
        <div class="col-md-7">
            <h2>Pickup Scheduling - Step 3 of 3</h2>
            <h4>
                Select an available date to pick up these orders.
            </h4>
        </div>
    </div>
    <br>
    <div class="miles-editable miles-card centered-card">
        <table class="table table-striped pickup-scheduling-table">
            <thead>
                <tr>
                    <th><b>Order Number</b></th>
                    <th><b>Office</b></th>
                    <th><b>Warehouse</b></th>
                    <th><b>Date Created</b></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of selectedOrders">
                    <td><span>{{ order.OrderNumber }}</span></td>
                    <td><span>{{ order.Warehouse?.Office?.Title }}</span></td>
                    <td><span>{{ order.Warehouse?.WarehouseId }}</span></td>
                    <td><span>{{ order.DateCreated | date: 'medium' }}</span></td>
            </tbody>
        </table>
        <br>
        <mt-dynamic-form [config]="formObject" (submitted)="formSubmitted($event)">
            <div class="text-center">
                <button 
                    type="submit" 
                    mtDisableClickDuringHttpCalls 
                    class="btn btn-success">
                    Request Pickup
                </button>
                <button 
                    *ngIf="pickup"
                    class="btn btn-danger"
                    type="button" 
                    mtDisableClickDuringHttpCalls
                    (click)="cancelPickup(pickup)">
                    Cancel Pickup
                </button>
            </div>
        </mt-dynamic-form>
    </div>
</div>
