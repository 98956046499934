<div class="remote-kiosk-box">
    <div class="remote-kiosk-box-body miles-card padded">
        <h4 class="text-center m-t-md">Please Enter Your Pin</h4>
        <div class="miles-editable">
            <form [formGroup]="truckerPinForm" (ngSubmit)="formSubmitted()">
                <mt-dynamic-field [field]="abstractTruckerUserControls.Pin" [form]="truckerPinForm"></mt-dynamic-field>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button
                            type="submit"
                            mtDisableClickDuringHttpCalls
                            Class="btn btn-flat btn-success"
                        >
                            Next
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <a (click)="forgotPin()">Forgot Pin?</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
