<form #readyForm="ngForm" (ngSubmit)="formSubmitted(readyForm)">
    <label for="palletCount">Pallet Count</label>
    <input type="number" name="palletCount" [ngModel]="order.TotalPallet" class="form-control" />
    <br>
    <div class="text-center">
        <button type="submit" class="btn btn-success" [disabled]="doubleClickIsDisabled">
            Save
        </button>
        <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
    </div>
</form>
