import { Component, OnInit, ElementRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection} from '@mt-ng2/entity-list-module';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { RegistrationRequestEntityListConfig } from './registration-request.entity-list-config';
import { IRegistrationRequest } from '../../model/interfaces/registration-request';
import { RegistrationRequestService } from '../registration-request.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { RegistrationRequestStatusService } from '../registration-request-status.service';
import { Subscription } from 'rxjs';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters } from '@mt-ng2/dynamic-search-filters';
import { IMetaItem } from '@mt-ng2/base-service';
import { ExtraParams } from '../../common/extra-params/extra-params.library';
import { RegistrationRequestStatuses } from '../../model/RegistrationRequestStatuses'

@Component({
    selector: 'app-registration-requests',
    templateUrl: './registration-request-list.component.html',
})
export class RegistrationRequestComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('tref', { static: true }) tref: ElementRef;
    searchControl = new UntypedFormControl();
    includedArchived = 0;
    registrationRequests: IRegistrationRequest[];
    total: number;
    currentPage = 1;
    query = '';
    entityListConfig = new RegistrationRequestEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    canAddRegistrationRequest = false;
    regReqStatuses: MtSearchFilterItem[] = [];
    selectedStatuses: number[] = [];
    subscriptions: Subscription;
    searchConfig: IDynamicSearchFilters;

    constructor(
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private registrationRequestService: RegistrationRequestService,
        private regReqStatusService: RegistrationRequestStatusService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.canAddRegistrationRequest = this.claimsService.hasClaim(ClaimTypes.RegistrationRequests, [ClaimValues.FullAccess]);
        if (!this.canAddRegistrationRequest) {
            this.entityListConfig.delete = null;
        }
        this.regReqStatusService.getAll().subscribe((statuses) => {
            const statusId = +this.route.snapshot.queryParamMap.get('statusId');
            this.regReqStatuses = statuses.map((status) =>
                new MtSearchFilterItem({Id: status.Id, Name: status.Name },
                (statusId === status.Id) ||
                status.Id === RegistrationRequestStatuses.Pending)
            );
            this.selectedStatuses = this.getSearchFilterItemsSelectedIds(this.regReqStatuses);

            this.searchConfig = this.buildSearchConfig();
        });
        this.getRegistrationRequests();
        this.subscriptions = new Subscription;
        this.subscriptions.add(this.registrationRequestService.registrationStatusUpdated.subscribe(()=> {
            this.getRegistrationRequests();
        }));
    }

    ngAfterViewInit(): void {
        // outputs `I am span`
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.subscriptions.unsubscribe();
    }

    private getSearchFilterItemsSelectedIds(items:MtSearchFilterItem[]): number[] {
        return items.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearchConfig(): IDynamicSearchFilters {
        const config: IDynamicSearchFilters = [
            {
                Searchbar: {
                    label: 'Search',
                    type: DynamicSearchFilterTypes.Searchbar,
                },
            },
            {
                Status: {
                    label: 'Status',
                    options: {
                        selectOptions: this.regReqStatuses,
                    },
                    type: DynamicSearchFilterTypes.Select,
                },
            },
        ];
        return config;
    }

    getRegistrationRequests(): void {
        const _extraSearchParams = ExtraParams.build({
            statusIds: this.selectedStatuses,
        });

        const search = this.query;

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.registrationRequestService.get(searchparams).subscribe((answer) => {
            this.registrationRequests = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(event: DynamicSearchValues): void {
        this.currentPage = 1;
        this.query = event.Searchbar as string;

        const selectedStatuses = event.Status as IMetaItem[];

        if (selectedStatuses) {
            this.selectedStatuses = selectedStatuses.map((status) => status.Id);
        }

        this.getRegistrationRequests();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getRegistrationRequests();
    }

    registrationRequestSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/registration-requests', event.entity.Id]);
    }

}
