import { Component, OnInit } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { OrderService } from '../../orders/order.service';
import { PickupService } from '../../model/shared-entities/pickups/pickup.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { WarehouseService } from '../../warehouses/services/warehouse.service';
import { OfficeService } from '../../offices/services/office.service';
import { CustomerService } from '../../customers/customer.service';
import { CarrierService } from '../../carriers/services/carrier.service';
import { IWarehouse } from '../../model/interfaces/warehouse';
import { IOffice } from '../../model/interfaces/office';
import { ICarrier } from '../../model/interfaces/carrier';
import { ICustomer } from '../../model/interfaces/customer';
import { IUser } from '../../model/interfaces/user';
import { HttpResponse } from '@angular/common/http';
import { IPickup } from '../../model/interfaces/pickup';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { PickupStatus } from '../../orders/order-basic-info/pickup-status-enum';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { UserRoles } from '../../model/UserRoles';
import { UserService } from '../../users/user.service';

@Component({
    selector: 'app-pickups',
    templateUrl: './pickups-list.component.html',
    styleUrls: ['./pickups-list.component.css'],
})
export class PickupsListComponent implements OnInit {
    pickups: IPickup[];
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    query = '';
    authUserRole: number;
    order = 'PickupNumber';
    orderDirection = 'asc';
    warehouses: IWarehouse[];
    offices: IOffice[];
    carriers: ICarrier[];
    customers: ICustomer[];
    drivers: IUser[];
    selectedPickup: IPickup;
    subscriptions: Subscription = new Subscription();
    pickupStatus = PickupStatus;
    pickupStatuses = [
        PickupStatus.CheckedIn,
        PickupStatus.DriverNotified,
        PickupStatus.Fulfilled
    ];
    selectedStatusIds: number[] = [...this.pickupStatuses];
    selectedWarehouseIds: number[] = [];
    selectedOfficeIds: number[] = [];
    selectedCarrierIds: number[] = [];
    selectedCustomerIds: number[] = [];
    selectedDriverIds: number[] = [];
    numOfCheckedInPickups: number;
    numOfDriverNotifiedPickups: number;
    numOfFulfilledPickups: number;
    isAdminUser = false;
    isCarrierUser = false;
    isCustomerUser = false;
    pageInitialized = false;
    CheckInTimeStartDate: Date;
    CheckInTimeEndDate: Date;
    DateRequestedStartDate: Date;
    DateRequestedEndDate: Date;
    DateFulfilledStartDate: Date;
    DateFulfilledEndDate: Date;

    constructor(
        private pickupService: PickupService,
        private warehouseService: WarehouseService,
        private officeService: OfficeService,
        private carrierService: CarrierService,
        private orderService: OrderService,
        private customerService: CustomerService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        const currentUser = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(currentUser.AuthId).subscribe(authUserRole => {
            this.authUserRole = authUserRole;
            this.isAdminUser = this.authUserRole === UserRoles.Admin;
            this.isCarrierUser = this.authUserRole === UserRoles.Carrier;
            this.isCustomerUser = this.authUserRole === UserRoles.Customer;
            if (this.isAdminUser || this.isCarrierUser) {
                this.pickupService.getPickupDrivers().subscribe(drivers => {
                    this.drivers = drivers;
                    this.getManagePickupsTableData();
                });
            } else {
                this.getManagePickupsTableData();
            }
        });
    }

    getManagePickupsTableData(): void {
        forkJoin([
            this.warehouseService.getActive(), 
            this.officeService.getActive(), 
            this.carrierService.getActive(),
            this.customerService.getActiveCustomers(), 
            this.getPickupsCall(),
        ]).subscribe(([warehouses, offices, carriers, customers, pickups]) => {
            this.warehouses = warehouses;
            this.offices = offices;
            this.carriers = carriers;
            this.customers = customers;
            this.orderService.authUserRole$.next(this.authUserRole);
            this.buildSearchBarAndFilters();
            this.pickups = pickups.body;
            this.numOfCheckedInPickups = this.pickups?.filter(p => p.StatusId === PickupStatus.CheckedIn)?.length;
            this.numOfDriverNotifiedPickups = this.pickups?.filter(p => p.StatusId === PickupStatus.DriverNotified)?.length;
            this.numOfFulfilledPickups = this.pickups?.filter(p => p.StatusId === PickupStatus.Fulfilled)?.length;
            this.selectedStatusIds = [];
            this.pageInitialized = true;
        });
    }

    search(event: DynamicSearchValues): void {
        this.query = event.Searchbar as string;
        if (event.Warehouses) {
            this.selectedWarehouseIds = (event.Warehouses as IMetaItem[]).map(x => x.Id);
        }
        if (event.Offices) {
            this.selectedOfficeIds = (event.Offices as IMetaItem[]).map(x => x.Id);
        }
        if (event.Carriers) {
            this.selectedCarrierIds = (event.Carriers as IMetaItem[]).map(x => x.Id);
        }
        if (event.Customers) {
            this.selectedCustomerIds = (event.Customers as IMetaItem[]).map(x => x.Id);
        }
        if (event.Drivers) {
            this.selectedDriverIds = (event.Drivers as IMetaItem[]).map(x => x.Id);
        }
        if (event.CheckInTime) {
            this.CheckInTimeStartDate = event.CheckInTime["startDate"] as Date;
            this.CheckInTimeEndDate = event.CheckInTime["endDate"] as Date;
        }
        if (event.ScheduledTime) {
            this.DateRequestedStartDate = event.ScheduledTime["startDate"] as Date;
            this.DateRequestedEndDate = event.ScheduledTime["endDate"] as Date;
        }
        if (event.FulfilledTime) {
            this.DateFulfilledStartDate = event.FulfilledTime["startDate"] as Date;
            this.DateFulfilledEndDate = event.FulfilledTime["endDate"] as Date;
        }
        this.getPickups();
        if (this.pageInitialized) {
            this.updateButtonColors();
        }
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        let selectedStatusIds = this.selectedStatusIds;
        if (this.selectedStatusIds.length === 0) {
            selectedStatusIds = [
                PickupStatus.CheckedIn,
                PickupStatus.DriverNotified,
                PickupStatus.Fulfilled
            ];
        }

        if (selectedStatusIds.includes(PickupStatus.Fulfilled)) { 
            selectedStatusIds = selectedStatusIds.filter(x => x !== PickupStatus.Fulfilled);
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'FulfilledToday',
                value: 'true',
            }))
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'WarehouseIds',
                valueArray: this.selectedWarehouseIds,
            }),
            new ExtraSearchParams({
                name: 'OfficeIds',
                valueArray: this.selectedOfficeIds,
            }),
            new ExtraSearchParams({
                name: 'CarrierIds',
                valueArray: this.selectedCarrierIds,
            }),
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: this.selectedCustomerIds,
            }),
            new ExtraSearchParams({
                name: 'DriverIds',
                valueArray: this.selectedDriverIds,
            }),
            new ExtraSearchParams({
                name: 'StatusIds',
                valueArray: selectedStatusIds,
            }),
        );

        if (this.CheckInTimeStartDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'CheckInTimeStartDate',
                value: this.CheckInTimeStartDate.toDateString(),
            }));
        }

        if (this.CheckInTimeEndDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'CheckInTimeEndDate',
                value: this.CheckInTimeEndDate.toDateString(),
            }));
        }

        if (this.DateRequestedStartDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateRequestedStartDate',
                value: this.DateRequestedStartDate.toDateString(),
            }));
        }
        
        if (this.DateRequestedEndDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateRequestedEndDate',
                value: this.DateRequestedEndDate.toDateString(),
            }));
        }

        if (this.DateFulfilledStartDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateFulfilledStartDate',
                value: this.DateFulfilledStartDate.toDateString(),
            }));
        }

        if (this.DateFulfilledEndDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateFulfilledEndDate',
                value: this.DateFulfilledEndDate.toDateString(),
            }));
        }

        return _extraSearchParams;
    }

    getPickupsCall(): Observable<HttpResponse<IPickup[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
        };

        const searchparams = new SearchParams(searchEntity);
        return this.pickupService.search(searchparams);
    }

    getPickups(): void {
        this.getPickupsCall().subscribe((answer) => {
            this.pickups = answer.body;
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getPickups();
    }

    buildSearchBarAndFilters(): void {    
        this.dynamicSearchFiltersConfig = [{
            Searchbar: {
                label: 'Search',
                type: DynamicSearchFilterTypes.Searchbar,
            },
            Warehouses: {
                label: 'Warehouse',
                type: DynamicSearchFilterTypes.Select,
                options: {
                    selectOptions: CommonFunctions.mapMtSearchFilterItems(this.warehouses, 'WarehouseId')
                },
            },
            Offices: {
                label: 'Office',
                type: DynamicSearchFilterTypes.Select,
                options: {
                    selectOptions: CommonFunctions.mapMtSearchFilterItems(this.offices, 'Title')
                },
            },
            ...(this.isAdminUser || this.isCarrierUser ? {
                Customers: {
                    label: 'Customer',
                    type: DynamicSearchFilterTypes.Select,
                    options: { selectOptions: CommonFunctions.mapMtSearchFilterItems(this.customers) },
                }
            } : {}),
            ...(this.isAdminUser || this.isCarrierUser ? {
                Drivers: {
                    label: 'Driver',
                    type: DynamicSearchFilterTypes.Select,
                    options: { selectOptions: CommonFunctions.mapMultiMtSearchFilterItems(this.drivers, 'FirstName', 'LastName', 'Phone') },
                }
            } : {}),
            ...(this.isCustomerUser ? {
                Carriers: {
                    label: 'Driving Company',
                    type: DynamicSearchFilterTypes.Select,
                    options: { selectOptions: CommonFunctions.mapMtSearchFilterItems(this.carriers, 'Name') },
                },
            } : {}),
            CheckInTime: {
                label: 'Check In Time',
                type: DynamicSearchFilterTypes.Daterange,
                options: {
                    startDate: this.CheckInTimeStartDate,
                    endDate: this.CheckInTimeEndDate,
                }
            },
            ScheduledTime: {
                label: 'Scheduled Time',
                type: DynamicSearchFilterTypes.Daterange,
                options: {
                    startDate: this.DateRequestedStartDate,
                    endDate: this.DateRequestedEndDate,
                }
            },
            FulfilledTime : {
                label: 'Fulfilled Time',
                type: DynamicSearchFilterTypes.Daterange,
                options: {
                    startDate: this.DateFulfilledStartDate,
                    endDate: this.DateFulfilledEndDate,
                }
            }
        }];
    }

    getStatusName(statusId: number): string {
        return Object.keys(PickupStatus).find(key => PickupStatus[key] === statusId);
    }

    orderBy(event: PointerEvent, order: string): void {
        const target = event.target as HTMLElement;
        let selectedArrows: HTMLElement[];
        
        if (target.tagName === 'I') {
            const th = target.closest('th');
            selectedArrows = Array.from(th.querySelectorAll('i'));
        } else {
            selectedArrows = Array.from(target.querySelectorAll('i'));
        }

        const thead = target.closest('thead');
        const allArrows = Array.from(thead.querySelectorAll('i'));
        const allOtherArrows: HTMLElement[] = allArrows.filter(span => !selectedArrows.includes(span));
        this.clearArrowOpacity(allOtherArrows);
        this.toggleArrowOpacity(selectedArrows);
        this.order = order;
        this.getPickups();
    }

    private toggleArrowOpacity(arrows: HTMLElement[]): void {
        const upArrow = arrows[0];
        const downArrow = arrows[1];
        const upOpacity = window.getComputedStyle(upArrow).getPropertyValue('opacity');
    
        if (upOpacity === '1') {
            this.setOpacity(upArrow, '0.4');
            this.setOpacity(downArrow, '1');
            this.orderDirection = 'asc';
        } else {
            this.setOpacity(upArrow, '1');
            this.setOpacity(downArrow, '0.4');
            this.orderDirection = 'desc';
        }
    }

    private clearArrowOpacity(arrows: HTMLElement[]): void {
        arrows.forEach(arrow => {
            this.setOpacity(arrow, '0.4');
        });
    }

    private setOpacity(element: HTMLElement, value: string): void {
        element.style.opacity = value;
    }

    checkDriverTwicStatus(driver: IUser): boolean {
        return UserService.checkUserIsTwicVerified(driver);
    }

    numOfPickupsWithStatus(status: PickupStatus): number {
        return this.pickups?.filter(p => p.StatusId === status)?.length;
    }

    filterPickupsByStatus(event: PointerEvent): void { 
        const button = event.target as HTMLElement;
        const isSelected = button.getAttribute('selected');
        if (isSelected === 'true') {
            button.setAttribute('selected', 'false');
        } else {
            button.setAttribute('selected', 'true');
        }

        const buttonIdToStatusMap = {
            'pickup-header-btn-checked-in': PickupStatus.CheckedIn,
            'pickup-header-btn-driver-notified': PickupStatus.DriverNotified,
            'pickup-header-btn-fulfilled': PickupStatus.Fulfilled,
        };
        
        const statusId = buttonIdToStatusMap[button.id] as number;
        if (statusId > 0) {
            this.setSelectedStatusIds(statusId, isSelected);
        } 

        const searchEvent: DynamicSearchValues = { 
            Searchbar: this.query, 
            Warehouses: null, 
            Offices: null, 
            Customers: null, 
            Drivers: null, 
            Carriers: null,
        };

        searchEvent.Warehouses = this.getSearchFilterItems("Warehouses")
            .filter(s => s.Selected)
            .map(filterItem => ({
                Id: filterItem.Item.Id,
                Name: filterItem.Item.Name
            }));

        searchEvent.Offices = this.getSearchFilterItems("Offices")
            .filter(s => s.Selected)
            .map(filterItem => ({
                Id: filterItem.Item.Id,
                Name: filterItem.Item.Name
            }));

        if (this.isAdminUser || this.isCarrierUser) {
            searchEvent.Customers = this.getSearchFilterItems("Customers")
                .filter(s => s.Selected)
                .map(filterItem => ({
                    Id: filterItem.Item.Id,
                    Name: filterItem.Item.Name
                }));

            searchEvent.Drivers = this.getSearchFilterItems("Drivers")
                .filter(s => s.Selected)
                .map(filterItem => ({
                    Id: filterItem.Item.Id,
                    Name: filterItem.Item.Name
                }));
        }

        if (this.isCustomerUser) {
            searchEvent.Carriers = this.getSearchFilterItems("Carriers")
                .filter(s => s.Selected)
                .map(filterItem => ({
                    Id: filterItem.Item.Id,
                    Name: filterItem.Item.Name
                }));
        }

        this.search(searchEvent);
    }

    private getSearchFilterItems(filterName: string): MtSearchFilterItem[] {
        if (!this.dynamicSearchFiltersConfig || this.dynamicSearchFiltersConfig.length <= 0)
            return [];

        return this.dynamicSearchFiltersConfig[0][filterName]["options"]["selectOptions"] as MtSearchFilterItem[];
    }

    private updateButtonColors() {
        this.setButtonColor('pickup-header-btn-checked-in', PickupStatus.CheckedIn);
        this.setButtonColor('pickup-header-btn-driver-notified', PickupStatus.DriverNotified);
        this.setButtonColor('pickup-header-btn-fulfilled', PickupStatus.Fulfilled);
    }
    
    private setButtonColor(buttonId: string, status: PickupStatus) {
        const button = document.getElementById(buttonId);
        const isSelected = this.selectedStatusIds.includes(status);
    
        if (isSelected) {
            button.classList.remove('btn-default');
            button.classList.add('btn-success');
            button.setAttribute('selected', 'true');
        } else {
            button.classList.remove('btn-success');
            button.classList.add('btn-default');
            button.setAttribute('selected', 'false');
        }
    }

    private setSelectedStatusIds(statusId: number, isSelected: string): void { 
        if (isSelected === 'true') {
            this.selectedStatusIds = this.selectedStatusIds.filter(id => id !== statusId);
            return;
        } 
        
        if (isSelected !== 'true') {
            this.selectedStatusIds.push(statusId);
        }
    }
}
