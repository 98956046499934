import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IPickup } from '../../model/interfaces/pickup';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { CheckInPayPalService } from './services/check-in-paypal.service';
import { ICheckIn } from '../../model/interfaces/check-in';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { environment } from '../../environments/environment';
import { ScriptLoaderService } from '../../common/services/script-loader-service';
import { ApplePay, Card, GooglePay, Square } from '@square/web-sdk';

// Extend the Window interface to include the Square global object
declare global {
    interface Window {
        Square?: Square;
    }
}

@Component({
    selector: 'app-driver-check-in-payment',
    templateUrl: './driver-check-in-payment.component.html',
    styleUrls: ['./driver-check-in-payment.component.css'],
})
export class DriverCheckInPaymentComponent implements OnInit, AfterViewInit {
    // Payment container elements
    @ViewChild('creditCardContainer') creditCardContainer: ElementRef<HTMLDivElement>;
    @ViewChild('applePayContainer') applePayContainer: ElementRef<HTMLDivElement>;

    // Payment button elements
    @ViewChild('creditCardButton') creditCardButton: ElementRef<HTMLButtonElement>;
    @ViewChild('applePayButton') applePayButton: ElementRef<HTMLButtonElement>;
    @ViewChild('googlePayButton') googlePayButton: ElementRef<HTMLDivElement>;

    driver: IUser;
    pickup: IPickup;
    checkIn: ICheckIn;
    remainingBalance: number;
    selectedPaymentMethod = 'creditCard';
    twicFeeLabel = 'TWIC Fee';
    googlePayButtonId = 'gpay-button-online-api-id';
    loading = true;
    applePayNotLoaded = false;
    redirectInProcess = false;
    env = environment;
    
    constructor(
        public checkInPayPalService: CheckInPayPalService,
        private router: Router,
        private checkInService: PublicCheckInService,
        private notificationsService: NotificationsService,
        private scriptLoader: ScriptLoaderService,
        private elementRef: ElementRef
    ) { }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            if (!driver) {
                this.redirectInProcess = true;
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }

            const twicCardIsCaptured = this.driver.TwicBackImageId && this.driver.TwicFrontImageId;
            const twicCardExpired = new Date(this.driver.TwicExpirationDate) < new Date();
            if (!twicCardIsCaptured || twicCardExpired) {
                this.twicFeeLabel = 'Non-TWIC Fee';
            }

            this.checkInService.driverCreatedCheckIn$.subscribe((checkIn) => {
                this.checkIn = checkIn;
                if (!checkIn) {
                    this.redirectInProcess = true;
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }

                this.checkInService.checkedInPickup$.subscribe((pickup) => {
                    this.pickup = pickup;
                    if (!pickup) {
                        this.redirectInProcess = true;
                        void this.router.navigate(['public/driver-check-in-info']);
                        return;
                    }

                    this.checkInService.getRemainingBalance(this.pickup.Id).subscribe({
                        next: (remainingBalance) => {
                            this.remainingBalance = remainingBalance;
                            if (this.remainingBalance <= 0) {
                                this.redirectInProcess = true;
                                void this.router.navigate(['public/driver-checked-in']);
                                return;
                            }
                        }
                    });
                });
            });
        });
    }

    async ngAfterViewInit() {
        // Wait for the redirect logic to complete before loading the Square script
        await new Promise(resolve => setTimeout(resolve, 500));
        if (this.redirectInProcess) {
            return;
        }

        // Load the Square payment script and initialize the credit card payment
        await this.loadSquareScript();
        await this.initCreditCardPayment();
    }

    async loadSquareScript() {
        try {
            await this.scriptLoader.loadScript(this.env.squareWebPaymentsScriptUrl);
        } catch (error) {
            this.notificationsService.error('There was an error loading the Square payment script.');
        }
    }

    async initCreditCardPayment(): Promise<void> {
        // Wait for the Square global object to be available
        this.loading = true;
        await new Promise(resolve => setTimeout(resolve, 500));

        // Initialize the Square payments object and attach the card container
        const payments = window.Square.payments(environment.squareApplicationId, environment.squareLocationId);
        
        // Attempt to attach credit card components to the DOM
        let creditCard: Card = null;
        try {
            creditCard = await payments.card();
            await creditCard.attach(this.creditCardContainer.nativeElement);
        } catch (error) {
            this.notificationsService.error('There was an error loading the credit card payment script.');
        }

        // Return if credit card did not load successfully
        this.loading = false;
        if (!creditCard) {
            return;
        }

        // Add an event listener to the card button to handle the payment
        this.creditCardButton.nativeElement.addEventListener('click', () => {
            void this.handlePayment(creditCard);
        });
    }

    async initApplePayPayment(): Promise<void> {
        // Wait for the Square global object to be available
        this.loading = true;
        await new Promise(resolve => setTimeout(resolve, 500));

        // Initialize the Square payments object and attach the card container
        const payments = window.Square.payments(environment.squareApplicationId, environment.squareLocationId);
        const paymentRequest = payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: this.remainingBalance.toString(),
                label: 'Total',
            },
        });        

        // Attempt to attach ApplePay components to the DOM
        let applePay: ApplePay = null;
        try {
            const card = await payments.card();
            await card.attach(this.applePayContainer.nativeElement);
            applePay = await payments.applePay(paymentRequest);
            this.loading = false;
        } catch (error) {
            this.notificationsService.error('There was an error loading the Apple Pay payment script.');
            this.notificationsService.error(error as string);
            this.loading = false;
        }

        // Return if Apple Pay did not load successfully
        if (!applePay) {
            this.applePayNotLoaded = true;
            return;
        }

        // Add an event listener to the card button to handle the payment
        this.applePayButton.nativeElement.addEventListener('click', () => {
            void this.handlePayment(applePay);
        });
    }

    async initGooglePayPayment(): Promise<void> {
        // Wait for the Square global object to be available
        this.loading = true;
        await new Promise(resolve => setTimeout(resolve, 500));

        // Initialize the Square payments object and attach the card container
        const payments = window.Square.payments(environment.squareApplicationId, environment.squareLocationId);
        const paymentRequest = payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: this.remainingBalance.toFixed(2),
                label: 'Total',
            },
        });

        // Attempt to attach GooglePay components to the DOM
        let googlePay: GooglePay = null;
        try {
            googlePay = await payments.googlePay(paymentRequest);
            await googlePay.attach(this.googlePayButton.nativeElement);
        } catch (error) {
            this.notificationsService.error('There was an error loading the Google Pay payment script.');
        }
        
        // Return if Google Pay did not load successfully
        this.loading = false;
        if (!googlePay) {
            return;
        }

        // Add an event listener to the card button to handle the payment
        this.googlePayButton.nativeElement.addEventListener('click', () => {
            void this.handlePayment(googlePay);
        });
    }

    async handlePayment(payment: Card | ApplePay | GooglePay): Promise<void> {
        try {
            const result = await payment.tokenize();
            const status = result.status.toString();
            if (status === 'OK') {
                this.checkInService.captureSquareOrder(this.checkIn.Id, result.token).subscribe({
                    next: () => {
                        this.orderPaid();
                    },
                    error: () => {
                        this.notificationsService.error('There was an error capturing the payment.');
                    },
                });
                return;
            } 
            
            if (status === 'Cancel') {
                this.notificationsService.info('The payment was canceled.');
                return;
            }

            let errorMessage = `Tokenization failed with status: ${result.status}`;
            if (result.errors) {
                errorMessage += ` and errors: ${JSON.stringify(result.errors)}`;
            }
            this.notificationsService.error(errorMessage);
        } catch (error) {
            this.notificationsService.error('There was an error processing the payment.');
        }
    }

    orderPaid() {
        this.checkInPayPalService
            .sendPaymentSms(this.checkIn.Id, this.remainingBalance)
            .subscribe({
                next: () => {
                    this.notificationsService.success(`${this.driver.FirstName} ${this.driver.LastName}, your payment has been successfully processed in the amount of $${this.remainingBalance.toFixed(2)} for pickup ${this.pickup.PickupNumber}.`);
                    this.checkInService.checkedInDriver$.next(this.driver);
                    this.checkInService.driverCreatedCheckIn$.next(this.checkIn);
                    this.checkInService.checkedInPickup$.next(this.pickup);
                    void this.router.navigate(['public/driver-checked-in']);
                },
                error: () => {
                    this.notificationsService.error('There was an error sending SMS of successful payment.');
                },
            });
    }

    onPaymentMethodChange(method: string) : void {
        this.selectedPaymentMethod = method;
    }

    determineApplePayButtonText(): string {
        if (this.applePayNotLoaded) {
            return 'Apple Pay is not available';
        }

        if (this.loading)
            return 'Loading...';

        return 'Pay';
    }
}
