import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IDelivery } from '../interfaces/delivery';
import { ICarrier } from '../interfaces/carrier';
import { IUser } from '../interfaces/user';
import { ICustomer } from '../interfaces/customer';
import { IDeliveryEquipmentType } from '../interfaces/delivery-equipment-type';
import { IDeliveryType } from '../interfaces/delivery-type';
import { IDeliveryStatus } from '../interfaces/delivery-status';
import { ITrailerType } from '../interfaces/trailer-type';
import { IWarehouse } from '../interfaces/warehouse';
import { ICountry } from '../interfaces/country';

export interface IDeliveryDynamicControlsParameters {
    formGroup?: string;
    statuses?: IDeliveryStatus[];
    deliveryEquipmentTypes?: IDeliveryEquipmentType[];
    deliveryTypes?: IDeliveryType[];
    trailerTypes?: ITrailerType[];
    countries?: ICountry[];
    customers?: ICustomer[];
    carriers?: ICarrier[];
    warehouses?: IWarehouse[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class DeliveryDynamicControls {

    formGroup: string;
    statuses?: IDeliveryStatus[];
    deliveryEquipmentTypes?: IDeliveryEquipmentType[];
    deliveryTypes?: IDeliveryType[];
    trailerTypes?: ITrailerType[];
    countries?: { Name: string; Id: string; }[];
    customers?: ICustomer[];
    carriers?: ICarrier[];
    warehouses?: { Name: string; Id: number; }[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private delivery?: IDelivery, additionalParameters?: IDeliveryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Delivery';
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;
        this.deliveryEquipmentTypes = additionalParameters && additionalParameters.deliveryEquipmentTypes || undefined;
        this.deliveryTypes = additionalParameters && additionalParameters.deliveryTypes || undefined;
        this.trailerTypes = additionalParameters && additionalParameters.trailerTypes || undefined;
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.carriers = additionalParameters && additionalParameters.carriers || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.countries = additionalParameters.countries?.map(v => { return { Name: v.Name, Id: v.CountryCode }; });
        this.warehouses = additionalParameters.warehouses?.map(v => { return { Name: v.WarehouseId, Id: v.Id }; });

        // Add options for selecting N/A to Carriers, Countries, and Warehouses.
        this.addOptionsForSelectingNa();

        // Add custom validator for ContainerNumber & TrailerNumber
        const onlyAlphaNumericValidator = Validators.pattern('^[a-zA-Z0-9]*$');

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.hasOwnProperty('Archived') && this.delivery.Archived != null ? this.delivery.Archived : false,
            }),
            AtTemp: new DynamicField({
                formGroup: this.formGroup,
                label: 'At Temp',
                name: 'AtTemp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.hasOwnProperty('AtTemp') && this.delivery.AtTemp != null ? this.delivery.AtTemp : false,
            }),
            BookingNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Booking Number',
                name: 'BookingNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('BookingNumber') && this.delivery.BookingNumber != null ? this.delivery.BookingNumber.toString() : '',
            }),
            CarrierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                name: 'CarrierId',
                options: this.carriers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.delivery && this.delivery.CarrierId || null,
            }),
            ChassisNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Chassis Number',
                name: 'ChassisNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('ChassisNumber') && this.delivery.ChassisNumber != null ? this.delivery.ChassisNumber.toString() : '',
            }),
            Coc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Coc',
                name: 'Coc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.hasOwnProperty('Coc') && this.delivery.Coc != null ? this.delivery.Coc : false,
            }),
            Commodity: new DynamicField({
                formGroup: this.formGroup,
                label: 'Commodity',
                name: 'Commodity',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('Commodity') && this.delivery.Commodity != null ? this.delivery.Commodity.toString() : '',
            }),
            ConfirmedDeliveryDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirmed Delivery Date',
                name: 'ConfirmedDeliveryDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.ConfirmedDeliveryDate || null,
            }),
            ContainerNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Container Number',
                name: 'ContainerNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50), onlyAlphaNumericValidator ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('ContainerNumber') && this.delivery.ContainerNumber != null ? this.delivery.ContainerNumber.toString() : '',
            }),
            CountryCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country Code',
                name: 'CountryCode',
                options: this.countries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.Dropdown,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.delivery && this.delivery.hasOwnProperty('CountryCode') && this.delivery.CountryCode != null ? this.delivery.CountryCode.toString() : '',
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.delivery && this.delivery.CreatedById || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.delivery && this.delivery.CustomerId || null,
            }),
            DataFile: new DynamicField({
                formGroup: this.formGroup,
                label: 'Data File',
                name: 'DataFile',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.hasOwnProperty('DataFile') && this.delivery.DataFile != null ? this.delivery.DataFile : false,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.DateModified || null,
            }),
            DeliveryDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Requested Delivery Date',
                name: 'DeliveryDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.delivery && this.delivery.DeliveryDate || null,
            }),
            DeliveryEquipmentTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Delivery Equipment Type',
                name: 'DeliveryEquipmentTypeId',
                options: this.deliveryEquipmentTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.delivery && this.delivery.DeliveryEquipmentTypeId || null,
            }),
            DeliveryNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Delivery Number',
                name: 'DeliveryNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('DeliveryNumber') && this.delivery.DeliveryNumber != null ? this.delivery.DeliveryNumber.toString() : '',
            }),
            DeliveryTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Delivery Type',
                name: 'DeliveryTypeId',
                options: this.deliveryTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.DeliveryTypeId || null,
            }),
            Full: new DynamicField({
                formGroup: this.formGroup,
                label: 'Full',
                name: 'Full',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.hasOwnProperty('Full') && this.delivery.Full != null ? this.delivery.Full : false,
            }),
            GatePassNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gate Pass Number',
                name: 'GatePassNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('GatePassNumber') && this.delivery.GatePassNumber != null ? this.delivery.GatePassNumber.toString() : '',
            }),
            Hazmat: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hazmat',
                name: 'Hazmat',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.hasOwnProperty('Hazmat') && this.delivery.Hazmat != null ? this.delivery.Hazmat : false,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.ModifiedById || null,
            }),
            SealNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Seal Number',
                name: 'SealNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('SealNumber') && this.delivery.SealNumber != null ? this.delivery.SealNumber.toString() : '',
            }),
            ShippingLine: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping Line',
                name: 'ShippingLine',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('ShippingLine') && this.delivery.ShippingLine != null ? this.delivery.ShippingLine.toString() : '',
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.StatusId || 1,
            }),
            SupplierName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Supplier Name',
                name: 'SupplierName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('SupplierName') && this.delivery.SupplierName != null ? this.delivery.SupplierName.toString() : '',
            }),
            Temperature: new DynamicField({
                formGroup: this.formGroup,
                label: 'Temperature',
                name: 'Temperature',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.Temperature || null,
            }),
            TrailerNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trailer Number',
                name: 'TrailerNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50), onlyAlphaNumericValidator ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('TrailerNumber') && this.delivery.TrailerNumber != null ? this.delivery.TrailerNumber.toString() : '',
            }),
            TrailerTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trailer Type',
                name: 'TrailerTypeId',
                options: this.trailerTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.TrailerTypeId || null,
            }),
            VesselDischargeDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Vessel Discharge Date',
                name: 'VesselDischargeDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.VesselDischargeDate || null,
            }),
            VesselName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Vessel Name',
                name: 'VesselName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('VesselName') && this.delivery.VesselName != null ? this.delivery.VesselName.toString() : '',
            }),
            VesselVoyage: new DynamicField({
                formGroup: this.formGroup,
                label: 'Vessel Voyage',
                name: 'VesselVoyage',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.delivery && this.delivery.hasOwnProperty('VesselVoyage') && this.delivery.VesselVoyage != null ? this.delivery.VesselVoyage.toString() : '',
            }),
            WarehouseId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Warehouse',
                name: 'WarehouseId',
                options: this.warehouses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.delivery && this.delivery.WarehouseId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.delivery && this.delivery.hasOwnProperty('Archived') && this.delivery.Archived != null ? this.delivery.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AtTemp: new DynamicLabel({
                label: 'At Temp',
                value: this.delivery && this.delivery.hasOwnProperty('AtTemp') && this.delivery.AtTemp != null ? this.delivery.AtTemp : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            BookingNumber: new DynamicLabel({
                label: 'Booking Number',
                value: this.delivery && this.delivery.hasOwnProperty('BookingNumber') && this.delivery.BookingNumber != null ? this.delivery.BookingNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CarrierId: new DynamicLabel({
                label: 'Carrier',
                value: getMetaItemValue(this.carriers as unknown as IMetaItem[], this.delivery && this.delivery.hasOwnProperty('CarrierId') ? this.delivery.CarrierId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ChassisNumber: new DynamicLabel({
                label: 'Chassis Number',
                value: this.delivery && this.delivery.hasOwnProperty('ChassisNumber') && this.delivery.ChassisNumber != null ? this.delivery.ChassisNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Coc: new DynamicLabel({
                label: 'Coc',
                value: this.delivery && this.delivery.hasOwnProperty('Coc') && this.delivery.Coc != null ? this.delivery.Coc : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Commodity: new DynamicLabel({
                label: 'Commodity',
                value: this.delivery && this.delivery.hasOwnProperty('Commodity') && this.delivery.Commodity != null ? this.delivery.Commodity.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ConfirmedDeliveryDate: new DynamicLabel({
                label: 'Confirmed Delivery Date',
                value: this.delivery && this.delivery.ConfirmedDeliveryDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            ContainerNumber: new DynamicLabel({
                label: 'Container Number',
                value: this.delivery && this.delivery.hasOwnProperty('ContainerNumber') && this.delivery.ContainerNumber != null ? this.delivery.ContainerNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CountryCode: new DynamicLabel({
                label: 'Country Code',
                value: this.delivery && this.delivery.CountryCode || 'N/A',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreatedById: new DynamicLabel({
                label: 'Created By',
                value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.delivery && this.delivery.hasOwnProperty('CreatedById') ? this.delivery.CreatedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.delivery && this.delivery.hasOwnProperty('CustomerId') ? this.delivery.CustomerId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DataFile: new DynamicLabel({
                label: 'Data File',
                value: this.delivery && this.delivery.hasOwnProperty('DataFile') && this.delivery.DataFile != null ? this.delivery.DataFile : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.delivery && this.delivery.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.delivery && this.delivery.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DeliveryDate: new DynamicLabel({
                label: 'Requested Delivery Date',
                value: this.delivery && this.delivery.DeliveryDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DeliveryEquipmentTypeId: new DynamicLabel({
                label: 'Delivery Equipment Type',
                value: getMetaItemValue(this.deliveryEquipmentTypes as unknown as IMetaItem[], this.delivery && this.delivery.hasOwnProperty('DeliveryEquipmentTypeId') ? this.delivery.DeliveryEquipmentTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DeliveryNumber: new DynamicLabel({
                label: 'Delivery Number',
                value: this.delivery && this.delivery.hasOwnProperty('DeliveryNumber') && this.delivery.DeliveryNumber != null ? this.delivery.DeliveryNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            DeliveryTypeId: new DynamicLabel({
                label: 'Delivery Type',
                value: getMetaItemValue(this.deliveryTypes as unknown as IMetaItem[], this.delivery && this.delivery.hasOwnProperty('DeliveryTypeId') ? this.delivery.DeliveryTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Full: new DynamicLabel({
                label: 'Full',
                value: this.delivery && this.delivery.hasOwnProperty('Full') && this.delivery.Full != null ? this.delivery.Full : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            GatePassNumber: new DynamicLabel({
                label: 'Gate Pass Number',
                value: this.delivery && this.delivery.hasOwnProperty('GatePassNumber') && this.delivery.GatePassNumber != null ? this.delivery.GatePassNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Hazmat: new DynamicLabel({
                label: 'Hazmat',
                value: this.delivery && this.delivery.hasOwnProperty('Hazmat') && this.delivery.Hazmat != null ? this.delivery.Hazmat : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedById: new DynamicLabel({
                label: 'Modified By',
                value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.delivery && this.delivery.hasOwnProperty('ModifiedById') ? this.delivery.ModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            SealNumber: new DynamicLabel({
                label: 'Seal Number',
                value: this.delivery && this.delivery.hasOwnProperty('SealNumber') && this.delivery.SealNumber != null ? this.delivery.SealNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ShippingLine: new DynamicLabel({
                label: 'Shipping Line',
                value: this.delivery && this.delivery.hasOwnProperty('ShippingLine') && this.delivery.ShippingLine != null ? this.delivery.ShippingLine.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            StatusId: new DynamicLabel({
                label: 'Status',
                value: getMetaItemValue(this.statuses as unknown as IMetaItem[], this.delivery && this.delivery.hasOwnProperty('StatusId') ? this.delivery.StatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            SupplierName: new DynamicLabel({
                label: 'Supplier Name',
                value: this.delivery && this.delivery.hasOwnProperty('SupplierName') && this.delivery.SupplierName != null ? this.delivery.SupplierName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Temperature: new DynamicLabel({
                label: 'Temperature',
                value: this.delivery && this.delivery.Temperature || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            TrailerNumber: new DynamicLabel({
                label: 'Trailer Number',
                value: this.delivery && this.delivery.hasOwnProperty('TrailerNumber') && this.delivery.TrailerNumber != null ? this.delivery.TrailerNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            TrailerTypeId: new DynamicLabel({
                label: 'Trailer Type',
                value: getMetaItemValue(this.trailerTypes as unknown as IMetaItem[], this.delivery && this.delivery.hasOwnProperty('TrailerTypeId') ? this.delivery.TrailerTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            VesselDischargeDate: new DynamicLabel({
                label: 'Vessel Discharge Date',
                value: this.delivery && this.delivery.VesselDischargeDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            VesselName: new DynamicLabel({
                label: 'Vessel Name',
                value: this.delivery && this.delivery.hasOwnProperty('VesselName') && this.delivery.VesselName != null ? this.delivery.VesselName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            VesselVoyage: new DynamicLabel({
                label: 'Vessel Voyage',
                value: this.delivery && this.delivery.hasOwnProperty('VesselVoyage') && this.delivery.VesselVoyage != null ? this.delivery.VesselVoyage.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            WarehouseId: new DynamicLabel({
                label: 'Warehouse',
                value: getMetaItemValue(this.warehouses as unknown as IMetaItem[], this.delivery && this.delivery.hasOwnProperty('WarehouseId') ? this.delivery.WarehouseId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }

    private addOptionsForSelectingNa(): void {
        const countriesHasNaOption = this.countries.filter((country) => country.Name === 'N/A');
        const warehousesHasNaOption = this.warehouses.filter((warehouse) => warehouse.Name === 'N/A');

        if (!countriesHasNaOption.length)
            this.countries.unshift({ Name: 'N/A', Id: '' });

        if (!warehousesHasNaOption.length)
            this.warehouses.unshift({ Name: 'N/A', Id: null });
    }
}
