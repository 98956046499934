import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { ISetting } from './model/interfaces/setting';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SettingDynamicControls } from './model/form-controls/setting.form-controls';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicField, LabelPosition, LabelPositions } from '@mt-ng2/dynamic-form';
import { Settings } from './model/Settings';
import { DBSettings } from './model/interfaces/custom/db-settings';

@Component({
    selector: 'app-settings',
    templateUrl: './app-settings.component.html',
})
export class AppSettingsComponent implements OnInit {
    settings: ISetting[] = [];
    updatedSettings: ISetting[] = [];
    form: UntypedFormGroup;
    formArray: UntypedFormArray;
    settingsDynamicForm = new SettingDynamicControls(null).Form;
    palletCount: number;
    orderWeight: number;
    registrationNotificationEmails: FormGroup;
    editAppointmentTimeframe: number;
    constructor(
        private appSettingsService: AppSettingsService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder
    ) { }

    ngOnInit(): void {
        this.setForm();
        this.appSettingsService.getAll().subscribe((answer) => {
            this.settings.push(answer[Settings.CompanyName]);
            this.setForm();
            this.palletCount = +answer[Settings.PalletCount].Value;
            this.orderWeight = +answer[Settings.OrderWeight].Value;
            this.editAppointmentTimeframe = +answer[Settings.EditAppointmentTimeframe].Value;
            this.setRegistrationNotificationEmailsForm(answer[Settings.RegistrationNotificationEmails].Value);
        });
    }

    setForm(): void {
        this.form = this.fb.group({});
        const formGroups = this.settings.map((item) => this.fb.group(item));
        this.formArray = this.fb.array(formGroups);
        this.form.addControl('Settings', this.formArray);
    }

    setRegistrationNotificationEmailsForm(value: string): void {
        const registrationNotificationEmailsControl =
            new FormControl(value, [this.emailListValidator]);
        this.registrationNotificationEmails = new FormGroup({ 'emails': registrationNotificationEmailsControl });
    }

    get currentFormArray(): UntypedFormArray {
        return this.form.get('Settings') as UntypedFormArray;
    }

    getLabel(form: UntypedFormGroup): string {
        const fieldName = 'Name';
        return form.controls[fieldName].value;
    }

    getField(form: UntypedFormGroup): DynamicField {
        const fieldName = 'Value';
        const fieldToCopy = <DynamicField>{
            ...this.settingsDynamicForm[fieldName],
        };
        const dynamicField: DynamicField = new DynamicField({
            formGroup: fieldToCopy.formGroup,
            label: fieldToCopy.label,
            name: fieldToCopy.name,
            options: fieldToCopy.options,
            placeholder: fieldToCopy.placeholder,
            type: fieldToCopy.type,
            validation: fieldToCopy.validation,
            validators: fieldToCopy.validators,
            value: form.controls[fieldName].value,
        });
        dynamicField.labelPosition = new LabelPosition({
            position: LabelPositions.Hidden,
        });
        dynamicField.insideBoxValidation = true;
        return dynamicField;
    }

    save(): void {
        this.updatedSettings = [];
        this.updatedSettings.push(this.form.value.Settings[0] as ISetting);
        this.appSettingsService.updateSettings(this.updatedSettings).subscribe(() => {
            this.notificationsService.success('Settings Saved Successfully');
        });
    }

    saveEditAppointmentTimeframe(): void {
        if (this.editAppointmentTimeframe < 0) {
            this.notificationsService.error('Please enter a number greater than or equal to zero.');
            return;
        }
        const editAppointmentTimeframe: ISetting = {
            Id: 6,
            Name: 'EditAppointmentTimeframe',
            Value: this.editAppointmentTimeframe?.toFixed(0) ?? '0',
        };
        this.appSettingsService.updateSettings([editAppointmentTimeframe]).subscribe(() => {
            this.notificationsService.success('Settings Saved Successfully');
        });
    }

    saveOrderLimits(): void {
        if (this.palletCount === null || this.palletCount < 0 || this.orderWeight === null || this.orderWeight < 0) {
            this.notificationsService.error('Order Limits must be 0 or greater.');
            return;
        }
        const palletCountSetting: ISetting = {
            Id: DBSettings.PalletCount,
            Name: DBSettings[DBSettings.PalletCount],
            Value: this.palletCount.toFixed(0),
        };
        const orderWeightSetting: ISetting = {
            Id: DBSettings.OrderWeight,
            Name: DBSettings[DBSettings.OrderWeight],
            Value: this.orderWeight.toFixed(2),
        };
        this.appSettingsService.updateSettings([palletCountSetting, orderWeightSetting]).subscribe(() => {
            this.notificationsService.success('Settings Saved Successfully');
            location.reload();
        });
    }

    emailListValidator = (control: FormControl): { [s: string]: boolean } => {
        const value = control.value;

        // If the input is empty or null, it's valid
        if (!value) {
            return null;
        }

        // Check the number of emails
        const emails = value.split(",");
        if (emails.length < 1 || emails.length > 5) {
            return { 'emails': true };
        }

        // Check if every email is valid
        for (const email of emails) {
            const emailControl = new FormControl(email.trim(), Validators.email);
            if (!emailControl.valid) {
                return { 'emails': true };
            }
        }

        // If all checks pass, return null (valid)
        return null;
    }

    saveRegistrationNotificationEmails() {
        // Check if the value is valid
        if (!this.registrationNotificationEmails.valid) {
            this.notificationsService.error('Please enter 1-5 comma-separated email addresses.');
            return;
        }

         // Remove duplicate emails and trim whitespaces
        const emails: string = this.registrationNotificationEmails.value.emails;
        const emailsArray = [...new Set(emails.split(","))].map((email: string) => email.trim());
        this.setRegistrationNotificationEmailsForm(emailsArray.toString())

        // Send the setting to the back-end for saving
        const setting: ISetting = {
            Id: 5,
            Name: 'RegistrationNotificationEmails',
            Value: this.registrationNotificationEmails.value.emails,
        };

        this.appSettingsService.updateSettings([setting]).subscribe(() => {
            this.notificationsService.success('Settings Saved Successfully');
        });
    }
}
