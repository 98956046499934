import { OrderDynamicControls, IOrderDynamicControlsParameters } from '../form-controls/order.form-controls';
import { IOrder } from '../interfaces/order';
import { DynamicLabel } from '@mt-ng2/dynamic-form';
import { OrderStatuses } from '../../model/OrderStatuses';

export class OrderDynamicControlsPartial extends OrderDynamicControls {

    constructor(orderPartial?: IOrder, additionalParameters?: IOrderDynamicControlsParameters) {
        super(orderPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.Archived).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CarrierId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CreationDate).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CustomerDockLocationId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CustomerId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CustomerPickupDate).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateCancelled).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateCreated).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateExported).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateRequested).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateScheduled).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.NotFulFilledReasonId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.OrderNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.PickUpDateTime).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.PickUpId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.PrevDateRequested).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ScheduleConfirmationNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.SchedulerAssumesTruckerTwicCard).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.SchedulerEmail).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.SchedulerFirstName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.SchedulerLastName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.SchedulerPhoneNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.StatusId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.WarehouseId).type.fieldType = DynamicFieldTypes.Input;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.Archived).label = 'Some other Label';
        // (<DynamicLabel>this.View.CarrierId).label = 'Some other Label';
        // (<DynamicLabel>this.View.CreationDate).label = 'Some other Label';
        // (<DynamicLabel>this.View.CustomerDockLocationId).label = 'Some other Label';
        // (<DynamicLabel>this.View.CustomerId).label = 'Some other Label';
        (<DynamicLabel>this.View.PickUpDateTime).label = 'Pickup Date';
        // (<DynamicLabel>this.View.DateCancelled).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateCreated).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateExported).label = 'Some other Label';
        (<DynamicLabel>this.View.DateRequested).label = 'Submitted Date';
        // (<DynamicLabel>this.View.DateScheduled).label = 'Some other Label';
        // (<DynamicLabel>this.View.NotFulFilledReasonId).label = 'Some other Label';
        // (<DynamicLabel>this.View.OrderNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.PickUpDateTime).label = 'Some other Label';
        // (<DynamicLabel>this.View.PickUpId).label = 'Some other Label';
        (<DynamicLabel>this.View.PrevDateRequested).label = 'Previous Appointment Date';
        (<DynamicLabel>this.View.ScheduleConfirmationNumber).label = 'Confirmation Number';
        // (<DynamicLabel>this.View.SchedulerAssumesTruckerTwicCard).label = 'Some other Label';
        (<DynamicLabel>this.View.SchedulerEmail).label = 'Email';
        // (<DynamicLabel>this.View.SchedulerFirstName).label = 'Some other Label';
        // (<DynamicLabel>this.View.SchedulerLastName).label = 'Some other Label';
        // (<DynamicLabel>this.View.SchedulerPhoneNumber).label = 'Some other Label';
        (<DynamicLabel>this.View.StatusId).label = 'Status';
        // (<DynamicLabel>this.View.WarehouseId).label = 'Some other Label';
        (<DynamicLabel>this.View.WarehouseId).value = `${orderPartial.Warehouse?.WarehouseId}`
        
        if (orderPartial.DateRequested) {
            const date = new Date(orderPartial.DateRequested);
            (<DynamicLabel>this.View.DateRequested).valueHtml = 
                `${date.toLocaleDateString('en-US', {month: 'short', day: 'numeric', year: 'numeric'})}, ${date.toLocaleTimeString()}`
        }

        if (orderPartial.PrevDateRequested) {
            const date = new Date(orderPartial.PrevDateRequested);
            (<DynamicLabel>this.View.PrevDateRequested).valueHtml = 
                `${date.toLocaleDateString('en-US', {month: 'short', day: 'numeric', year: 'numeric'})}, ${date.toLocaleTimeString()}`
        }

        if (orderPartial.StatusId) {
            (<DynamicLabel>this.View.StatusId).valueHtml = `${OrderStatuses[orderPartial.StatusId]}`
        }
    }
}
