import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IOrder } from '../../model/interfaces/order';
import { ActionOrderListComponent } from './action-order-list-component';
import { OrderService } from '../order.service';
import { ArchiveOrderModalComponent } from './archive-order-modal.component';

export class ScheduledOrdersEntityListConfig extends EntityListConfig {
    constructor(userIsCarrierOrCustomer: boolean) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Customer', 'Name'],
                    name: 'Customer',
                }),
                new EntityListColumn({
                    accessors: ['OrderNumber'],
                    name: 'Order Number',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order.Warehouse ? order.Warehouse.WarehouseId : '';
                    },
                    name: 'Warehouse',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order.Warehouse ? order.Warehouse.Division : '';
                    },
                    name: 'Division',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order.Warehouse?.Office?.Title ?? '';
                    },
                    name: 'Location',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['PickUpDateTime'],
                    name: 'Pickup Date',
                    pipes: ['date:medium'],
                    sort: { isDefaultForSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order.Pickup?.PickupNumber;
                    },
                    name: 'Pickup Number',
                    sort: { sortProperty: 'Pickup.PickupNumber' }
                }),
                new EntityListColumn({
                    accessors: ['PrevDateRequested'],
                    name: 'Previous Appointment Date',
                    pipes: ['date:medium'],
                    sort: { isDefaultForSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order.NotFulFilledReason ? order.OrderStatus.Name + '(' + order.NotFulFilledReason.Name + ')' : order.OrderStatus.Name;
                    },
                    name: 'Status',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return OrderService.GetOrderTwicVerifiedLabel(order);
                    },
                    columnClass: (order: IOrder) => {
                        if (OrderService.CheckOrderIsTwicVerified(order) === false) {
                            return 'non-twic-verified-label';
                        }
                        return '';
                    },
                    name: 'TWIC Status',
                    sort: { disableSort: true },
                }),
            ],
            rowClass: (entity: IOrder) => (entity.DateRequested &&
                                          entity.PrevDateRequested &&
                                          entity.StatusId === 6 &&
                                          (new Date().getTime() - new Date(entity.DateRequested).getTime()) < (24 * 60 * 60 * 1000) ? 'reschedule-requested' :
                                          entity.DateCancelled &&
                                          entity.PrevDateRequested &&
                                          entity.StatusId === 1 &&
                                          (new Date().getTime() - new Date(entity.DateCancelled).getTime()) < (24 * 60 * 60 * 1000) ? 'unscheduled' : ''),

        };

        if (!userIsCarrierOrCustomer) {
            const actionButtonsColumn = new EntityListColumn({
                component: ActionOrderListComponent,
                fireOnColumnSelected: false,
                name: '',
                sort: {
                    disableSort: true,
                },
            });
            const archivedColumn = new EntityListColumn({
                component: ArchiveOrderModalComponent,
                fireOnColumnSelected: false,
                name: 'Archived',
            });
            listConfig.columns.push(actionButtonsColumn);
            listConfig.columns.push(archivedColumn);
        }

        super(listConfig);
    }
}
