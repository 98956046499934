import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { DeliveriesComponent } from './delivery-list/deliveries.component';
import { ClaimTypes } from '../model/ClaimTypes';
import { DeliveryHeaderComponent } from './delivery-header/delivery-header.component';
import { DeliveryDetailComponent } from './delivery-detail/delivery-detail.component';
import { DeliveryDocumentsService } from './services/delivery-documents.service';
import { DeliveryDocumentsComponent } from './delivery-documents-list/delivery-documents.component';
import { DeliveryCalendarComponent } from './delivery-calendar/delivery-calendar.component';

const deliveryListRoleGuard = {
    claimType: ClaimTypes.Deliveries,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Deliveries',
};

const deliveryAddRoleGuard = {
    claimType: ClaimTypes.Deliveries,
    claimValues: [ClaimValues.FullAccess],
};

const deliveryEntityConfig = {
    claimType: ClaimTypes.Deliveries,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'deliveryId',
    service: DeliveryDocumentsService,
    title: 'Delivery Detail',
};

const deliveryRoutes: Routes = [
    { path: 'deliveries', component: DeliveriesComponent, canActivate: [AuthGuard], data: deliveryListRoleGuard },
    { path: 'deliveries-calendar', component: DeliveryCalendarComponent, canActivate: [AuthGuard], data: deliveryListRoleGuard },
    // tslint:disable-next-line:object-literal-sort-keys
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: DeliveryDetailComponent, pathMatch: 'full' }],
        component: DeliveryHeaderComponent,
        data: deliveryAddRoleGuard,
        path: 'deliveries/add',
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: DeliveryDetailComponent, pathMatch: 'full' }],
        component: DeliveryHeaderComponent,
        data: deliveryEntityConfig,
        path: `deliveries/:${deliveryEntityConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: DeliveryDocumentsComponent, pathMatch: 'full' }],
        component: DeliveryHeaderComponent,
        data: deliveryEntityConfig,
        path: `deliveries/:${deliveryEntityConfig.entityIdParam}/documents`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(deliveryRoutes)],
})
export class DeliveryRoutingModule {}
