<h2>Carrier Request Form</h2>
<div *ngIf="isEditing" class="miles-form padded">
    <mt-dynamic-form [config]="formObject" ([isEditing])="isEditing" (submitted)="formSubmitted($event)">
        <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">Submit</button>
        <!-- <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button> -->
    </mt-dynamic-form>
</div>
<div>
    <button class="btn btn-secondary" style="margin: 15px 15px 15px 0px;" (click)="backButtonPressed()">Back</button>
</div>

