import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { OrderRoutingModule } from './order-routing.module';

import { OrderService } from './order.service';
import { CustomerService } from './customer.service';
import { SchedulingQueueComponent } from './scheduling-queue/scheduling-queue.component';
import { ManageSchedulingComponent } from './manage-scheduling/manage-scheduling.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ManageOrdersComponent } from './scheduled-orders/manage-orders.component';
import { OrderBasicInfoComponent } from './order-basic-info/order-basic-info.component';
import { ActionOrderListComponent } from './scheduled-orders/action-order-list-component';
import { ArchiveOrderModalComponent } from './scheduled-orders/archive-order-modal.component';
import { EditOrderButtonComponent } from './scheduled-orders/edit-order-button.component';
import { ArchiveOrderListComponent } from './scheduling-queue/archive-order-list-component';
import { WalkInOrdersComponent } from './walk-in-orders/walk-in-orders.component';
import { CarrierRequestFormComponent } from './carrier-request/carrier-request-form.component';
import { CommonService } from '../common/services/common.service';
import { PickupSchedulingOrderSelection } from './pickup-scheduling/order-selection/order-selection.component';
import { PickupSchedulingCarrierSelection } from './pickup-scheduling/carrier-selection/carrier-selection.component';
import { PickupSchedulingDateSelection } from './pickup-scheduling/date-selection/date-selection.component';
import { PickupSchedulingManagePickupsComponent } from './pickup-scheduling/manage-pickups/manage-pickups.component';
import { PickupService } from '../model/shared-entities/pickups/pickup.service';
import { EditPickupButtonComponent } from './pickup-scheduling/manage-pickups/edit-pickup-button.component';
import { PickupSchedulingDriverSelectionComponent } from './pickup-scheduling/driver-selection/driver-selection.component';
import { PickupSchedulingTimeSelectionComponent } from './pickup-scheduling/time-selection/time-selection.component';
import { OrderTotalPalletDialogComponent } from './pickup-scheduling/manage-pickups/order-total-pallet-dialog/order-total-pallet-dialog.component';
import { OrderTotalWeightDialogComponent } from './pickup-scheduling/manage-pickups/order-total-weight-dialog/order-total-weight-dialog.component';

@NgModule({
    declarations: [
        SchedulingQueueComponent,
        ManageSchedulingComponent,
        ManageOrdersComponent,
        ActionOrderListComponent,
        ArchiveOrderModalComponent,
        EditOrderButtonComponent,
        ArchiveOrderListComponent,
        WalkInOrdersComponent,
        CarrierRequestFormComponent,
        OrderBasicInfoComponent,
        PickupSchedulingOrderSelection,
        PickupSchedulingCarrierSelection,
        PickupSchedulingDateSelection,
        PickupSchedulingManagePickupsComponent,
        OrderTotalPalletDialogComponent,
        OrderTotalWeightDialogComponent,
        EditPickupButtonComponent,
        PickupSchedulingDriverSelectionComponent,
        PickupSchedulingTimeSelectionComponent,
    ],
    imports: [
        SharedModule,
        OrderRoutingModule,
        DragDropModule,
    ]
})
export class OrderModule {
    static forRoot(): ModuleWithProviders<OrderModule> {
        return {
            ngModule: OrderModule,
            providers: [
                OrderService,
                CustomerService,
                CommonService,
                PickupService
            ],
        };
    }
}
