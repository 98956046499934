<h2>App Settings</h2>
<div *ngIf="settings.length">
    <div class="row">
        <div class="col-md-6">
            <div class="miles-card padded" [formGroup]="form">
                <div class="form-group" [formArrayName]="'Settings'">
                    <h4>Settings</h4>
                    <div *ngFor="let item of currentFormArray.controls; let i = index" [formGroupName]="i" class="input-group">
                        <span class="input-group-addon">{{ getLabel(item) }}</span>
                        <mt-dynamic-field [field]="getField(item)" [form]="item" [overrideForm]="true"></mt-dynamic-field>
                    </div>
                    <br />
                    <button type="submit" class="btn btn-success" (click)="save()">Save</button>
                </div>
            </div>
            <br>
            <div class="miles-card padded">
                <div class="form-group">
                    <h4>Edit Appointment Timeframe</h4>
                    <div>
                        <label for="twicfee">Number of Hours:</label>
                        <input type="number" [(ngModel)]="editAppointmentTimeframe" class="form-control" id="editAppointmentTimeframe" min="0" />
                    </div>
                    <br />
                    <button type="submit" class="btn btn-success" (click)="saveEditAppointmentTimeframe()">Save</button>
                </div>
            </div>
            <br>
            <div class="miles-card padded">
                <div class="form-group">
                    <h4>Edit Pickup Limits</h4>
                    <div>
                        <label for="palletCount">Maximum Number of Pallets per Pickup:</label>
                        <input type="number" [(ngModel)]="palletCount" class="form-control" id="palletCount" />
                    </div>
                    <br />
                    <div>
                        <label for="orderWeight">Maximum Weight (in lbs) per Pickup:</label>
                        <input type="number" [(ngModel)]="orderWeight" class="form-control" id="orderWeight" />
                    </div>
                    <br />
                    <button type="submit" class="btn btn-success" (click)="saveOrderLimits()">Save</button>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="miles-card padded">
                <div class="form-group" [formGroup]="registrationNotificationEmails">
                    <h4>Registration Notification Emails</h4>
                    <div>
                        <label for="emails">Emails:</label>
                        <input type="text" formControlName="emails" class="form-control" id="emails" />
                        <span class="text-danger"
                            *ngIf="!registrationNotificationEmails.get('emails').valid && registrationNotificationEmails.get('emails').touched">
                            Please enter 1-5 comma-separated email addresses.
                        </span>
                    </div>
                    <br />
                    <button type="submit" class="btn btn-success"
                        (click)="saveRegistrationNotificationEmails()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<a routerLink="./customers" class="btn btn-default btn-flat">Close</a>
