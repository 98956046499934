<div *ngIf="delivery && delivery.Id > 0">
    <div class="row">
        <div class="col-md-6">
            <app-delivery-basic-info
                [delivery]="delivery"
                [canEdit]="canEdit"
                [canAdd]="canAdd"
            ></app-delivery-basic-info
            ><br />
        </div>
        <div class="col-md-6">
            <mt-common-documents 
                [canEdit]="canAdd" 
                [canAdd]="canAdd"
                [service]="documentService"
                [allowedMimeType]="allowedMimeTypes">
            </mt-common-documents>
        </div>
    </div>
    <br />
    <a routerLink="/deliveries" class="btn btn-default">Close</a>
</div>
<div *ngIf="delivery && delivery.Id === 0">
    <div class="row">
        <div class="col-md-4">
            <app-delivery-basic-info [delivery]="delivery"></app-delivery-basic-info>
        </div>
    </div>
</div>
