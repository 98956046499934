<div class="row">
    <div class="col-md-7">
        <h2>Transaction History</h2>
    </div>
</div>
<div>
    <mt-dynamic-search-filters [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search($event)"></mt-dynamic-search-filters>
    <mt-search-filter-daterange
        *ngIf="datepickerEntityName"
        [entity]="datepickerEntityName"
        [startDate]="startDate"
        [endDate]="endDate"
        (selectionChanged)="dateSelectionChanged($event)"
    ></mt-search-filter-daterange>
</div>
<br>
<mt-entity-list 
    *ngIf="pickupPaymentDetails && entityListConfig"
    [entities]="pickupPaymentDetails" 
    [total]="total" 
    [(currentPage)]="currentPage" 
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="pageChanged()" 
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>
