import { DeliveryDynamicControls, IDeliveryDynamicControlsParameters } from '../form-controls/delivery.form-controls';
import { IDelivery } from '../interfaces/delivery';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDatepickerOptions, InputTypes } from '@mt-ng2/dynamic-form';
import { DeliveryStatuses } from '../DeliveryStatuses';

export class DeliveryDynamicControlsPartial extends DeliveryDynamicControls {

    constructor(deliveryPartial?: IDelivery, additionalParameters?: IDeliveryDynamicControlsParameters) {
        super(deliveryPartial, additionalParameters);
        
        if (!deliveryPartial.StatusId || deliveryPartial.StatusId === DeliveryStatuses.Requested)
            (<DynamicField>this.Form.ConfirmedDeliveryDate).disabled = true;

        const today = new Date();
        const datepickerOptions: IDatepickerOptions = { minDate: { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() } };
        (<DynamicField>this.Form.ConfirmedDeliveryDate).type = new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Datepicker, datepickerOptions: datepickerOptions });
        
        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.BookingNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ChassisNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Commodity).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ContainerNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CountryCode).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CreatedById).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CustomerId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateCreated).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DateModified).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DeliveryDate).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DeliveryEquipmentTypeId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DeliveryNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.DeliveryTypeId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Full).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Hazmat).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ModifiedById).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.SealNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ShippingLine).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.StatusId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.SupplierName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Temperature).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.TrailerNumber).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.TrailerTypeId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.VesselDischargeDate).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.VesselName).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.VesselVoyage).type.fieldType = DynamicFieldTypes.Input;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.BookingNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.ChassisNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.Commodity).label = 'Some other Label';
        // (<DynamicLabel>this.View.ContainerNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.CountryCode).label = 'Some other Label';
        // (<DynamicLabel>this.View.CreatedById).label = 'Some other Label';
        // (<DynamicLabel>this.View.CustomerId).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateCreated).label = 'Some other Label';
        // (<DynamicLabel>this.View.DateModified).label = 'Some other Label';
        // (<DynamicLabel>this.View.DeliveryDate).label = 'Some other Label';
        // (<DynamicLabel>this.View.DeliveryEquipmentTypeId).label = 'Some other Label';
        // (<DynamicLabel>this.View.DeliveryNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.DeliveryTypeId).label = 'Some other Label';
        // (<DynamicLabel>this.View.Full).label = 'Some other Label';
        // (<DynamicLabel>this.View.Hazmat).label = 'Some other Label';
        // (<DynamicLabel>this.View.ModifiedById).label = 'Some other Label';
        // (<DynamicLabel>this.View.SealNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.ShippingLine).label = 'Some other Label';
        // (<DynamicLabel>this.View.StatusId).label = 'Some other Label';
        // (<DynamicLabel>this.View.SupplierName).label = 'Some other Label';
        // (<DynamicLabel>this.View.Temperature).label = 'Some other Label';
        // (<DynamicLabel>this.View.TrailerNumber).label = 'Some other Label';
        // (<DynamicLabel>this.View.TrailerTypeId).label = 'Some other Label';
        // (<DynamicLabel>this.View.VesselDischargeDate).label = 'Some other Label';
        // (<DynamicLabel>this.View.VesselName).label = 'Some other Label';
        // (<DynamicLabel>this.View.VesselVoyage).label = 'Some other Label';

    }
}
