<div class="text-center public-responsive">
    <h1>Carrier Info</h1>
    <p class="f-20">What carrier are you driving for today?</p>
    <br />
    <form *ngIf="formControls && carrierInputForm" [formGroup]="carrierInputForm">
        <div class="form-group">
            <mt-dynamic-field class="input-medium" [field]="formControls.Carrier" [form]="carrierInputForm"></mt-dynamic-field>
        </div>
        <p class="f-20">
            <i
                >Please use the field to type in your carrier name, to find it. <br />
                If you cannot find your carrier, please select the "Other" carrier.</i
            >
        </p>
        <div class="row">
            <div class="col-md-6 col-sm-12"></div>
            <div class="col-md-6 col-sm-12">
                <button type="button" (click)="saveCarrierAndNavigateToOrderInfo()" class="btn btn-primary">Next Step</button>
            </div>
        </div>
    </form>
    <br />
</div>
