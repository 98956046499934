import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { catchError, Observable } from 'rxjs';
import { IExceptionQueue } from '../../model/interfaces/exception-queue';

@Injectable({
    providedIn: 'root',
})
export class ExceptionQueueService extends BaseService<IExceptionQueue> {
    constructor(public http: HttpClient) {
        super('/exception-queue', http);
    }

    search(searchparameters: SearchParams): Observable<HttpResponse<IExceptionQueue[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IExceptionQueue[]>(`/exception-queue/search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    deleteException(exceptionId: number): Observable<void> {
        return this.http.delete<void>(`/exception-queue/${exceptionId}`, {
            responseType: 'text' as 'json',
        });
    }
}
