import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { RegistrationRequestComponent } from './registration-request-list/registration-request-list.component';
import { RegistrationRequestService } from './registration-request.service';
import { RegistrationRequestDetailComponent } from './registration-request-detail/registration-request-detail.component';

const registrationRequestEntityConfig = {
    claimType: ClaimTypes.RegistrationRequests,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'registrationRequestId',
    service: RegistrationRequestService,
    title: 'Registration Request Info',
};

const registrationRequestListRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.RegistrationRequests,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
};

const registrationRequestRoutes: Routes = [
    { path: 'registration-requests', component: RegistrationRequestComponent, canActivate: [AuthGuard], data: registrationRequestListRoleGuard },
    // {
    //     canActivate: [AuthGuard],
    //     children: [{ path: '', component: RegistrationDetailComponent, pathMatch: 'full' }],
    //     component: RegistrationDetailComponent,
    //     data: truckerAddRoleGuard,
    //     path: 'truckers/add',
    // },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: RegistrationRequestDetailComponent, pathMatch: 'full' }],
        component: RegistrationRequestDetailComponent,
        data: registrationRequestEntityConfig,
        path: `registration-requests/:${registrationRequestEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(registrationRequestRoutes)],
})
export class RegistrationRequestRoutingModule {}
