import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CheckInType } from '../../model/CheckInType';
import { CheckInService } from '../../check-ins/services/check-ins.service';
import { CommonService } from '../../common/services/common.service';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDelivery } from '../../model/interfaces/delivery';

@Component({
    selector: 'app-driver-delivering',
    templateUrl: './driver-delivering.component.html',
    styleUrls: ['./driver-delivering.component.css'],
})
export class DriverDeliveringComponent implements OnInit {
    driver: IUser;
    editDriverForm: FormGroup;
    checkedIn = false;
    doubleClickIsDisabled = false;

    constructor(
        private router: Router, 
        private publicCheckInService: PublicCheckInService,
        private checkInService: CheckInService,
        private formBuilder: FormBuilder, 
        private commonService: CommonService,
        private notificationsService: NotificationsService
    ) { }

    ngOnInit(): void {
        this.publicCheckInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            if (!driver) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }

            this.editDriverForm = this.formBuilder.group({
                firstName: [this.driver.FirstName, [Validators.required, this.commonService.firstAndLastNameValidator()]],
                lastName: [this.driver.LastName, [Validators.required, this.commonService.firstAndLastNameValidator()]],
                licensePlateNumber: ['', [Validators.required, this.commonService.checkForAlphaNumericCharacters()]],
                driverLicenseNumber: [''],
                containerNumber: ['', [Validators.required, this.commonService.checkForAlphaNumericCharacters()]],
            });
        });
    }

    onSubmit() {
        const orderNumber = this.editDriverForm.get('containerNumber').value as string;
        this.publicCheckInService.getValidDeliveryByContainerNumber(orderNumber).subscribe({
            next: (delivery: IDelivery) => {
                if (!delivery) {
                    this.notificationsService.error('Invalid Container # / Trailer # number. Please try again.');
                    return;
                }

                const order = this.publicCheckInService.getEmptyOrder();
                order.OrderNumber = orderNumber;

                const publicCheckInDTO = {
                    firstName: this.editDriverForm.get('firstName').value,
                    lastName: this.editDriverForm.get('lastName').value,
                    licensePlate: this.editDriverForm.get('licensePlateNumber').value,
                    driversLicense: this.editDriverForm.get('driverLicenseNumber').value,
                    carrierId: this.driver.CarrierId,
                    phone: this.driver.Phone,
                    checkInTypeId: CheckInType.PICKUP,
                    validOrders: [order],
                    deliveryId: delivery.Id,
                    driverId: this.driver.Id,
                }

                this.publicCheckInService.submitPublicCheckIn(publicCheckInDTO).subscribe({
                    next: (checkInId: number) => {
                        this.publicCheckInService.sendCheckInNotifications(checkInId).subscribe({
                            next: (notified: boolean) => {
                                const checkInLog = {
                                    CheckInId: checkInId,
                                    DateCreatedUtc: new Date(),
                                    Id: 0,
                                    LogMessage: '[PUBLIC DELIVERY CHECK-IN] driver successfully checked in, but notifications failed to send.',
                                };

                                if (notified) {
                                    checkInLog.LogMessage = '[PUBLIC DELIVERY CHECK-IN] driver successfully checked in. Notifications sent successfully.';
                                }

                                this.checkInService.createCheckInLog(checkInLog).subscribe({
                                    next: () => {
                                        this.checkedIn = true;
                                    }
                                });
                            },
                        });
                    },
                });
            },
            error: (response: HttpErrorResponse) => {
                this.commonService.handleErrorResponse(response);
            }
        });
    }
}
