import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { INotificationType } from '../model/interfaces/notification-type';

export const emptyNotificationType: INotificationType = {
    Id: 0,
    Name: null,
};

@Injectable({
    providedIn: 'root',
})
export class NotificationTypeService extends BaseService<INotificationType> {
    constructor(public http: HttpClient) {
        super('/notification-types', http);
    }

    getEmptyNotificationType(): INotificationType {
        return { ...emptyNotificationType };
    }
}
