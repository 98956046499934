export enum DBSettings {
    CompanyName = 1,
    TwicFee = 2,
    NonTwicFee = 3,
    WelcomeContent = 4,
    RegistrationNotificationEmails = 5,
    EditAppointmentTimeframe = 6,
    PalletCount = 7,
    OrderWeight = 8,
}
