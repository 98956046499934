import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { ClosedDateDynamicConfig } from '../closed-date.dynamic-config';
import { IClosedDate } from '../../model/interfaces/closed-date';
import { ClosedDateService } from '../services/closed-date.service';
import { OfficeService } from '../../offices/services/office.service';
import { IOffice } from '../../model/interfaces/office';
import { MetaItem } from '@mt-ng2/base-service';
import { IOfficeClosedDate } from '../../model/interfaces/office-closed-date';
import { IExcludedCustomersClosedDate } from '../../model/interfaces/excluded-customers-closed-date';
import { CustomerService } from '../../customers/customer.service';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-closed-date-basic-info',
    templateUrl: './closed-date-basic-info.component.html',
})
export class ClosedDateBasicInfoComponent implements OnInit {
    @Input() closedDate: IClosedDate;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: ClosedDateDynamicConfig<IClosedDate>;
    doubleClickIsDisabled = false;
    offices: IOffice[];
    officeSelectItems: MultiselectItem[] = [];
    customerSelectItems: MultiselectItem[] = [];
    selectedOffices: MetaItem[] = [];
    selectedCustomers: MetaItem[] = [];
    selectedOfficeClosedDates: IOfficeClosedDate[] = [];
    excludedCustomerClosedDates: IExcludedCustomersClosedDate[] = [];

    get isNewClosedDate(): boolean {
        return this.closedDate && this.closedDate.Id ? false : true;
    }

    constructor(
        private closedDateService: ClosedDateService,
        private notificationsService: NotificationsService,
        private officeService: OfficeService,
        private customerService: CustomerService,
        private router: Router,
    ) {}

    ngOnInit(): void {
       this.setConfig();
       this.getOffices();
       this.getCustomers();
    }

    setConfig(): void {
        let config: IDynamicFormConfig;
        this.formFactory = new ClosedDateDynamicConfig<IClosedDate>(this.closedDate );

        if (this.isNewClosedDate) {
            this.isEditing = true;
            config = this.formFactory.getForCreate();
        } else {
            config = this.formFactory.getForUpdate();    
        }

        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) =>new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewClosedDate) {
            void this.router.navigate(['/closeddates']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.closedDate, form.value.ClosedDate as IClosedDate);
            this.closedDate.DateClosed = new Date(this.closedDate.DateClosed.setHours(0, 0, 0, 0));
            this.selectedOfficeClosedDates = this.selectedOffices.map(
                (selectedOffice) =>
                    <IOfficeClosedDate>{
                        ClosedDateId: this.closedDate.Id,
                        OfficeId: selectedOffice.Id,
                    },
            );
            this.closedDate.OfficeClosedDates = this.selectedOfficeClosedDates;
            this.excludedCustomerClosedDates = this.selectedCustomers.map(
                (selectedCustomer) =>
                    <IExcludedCustomersClosedDate>{
                        ClosedDateId: this.closedDate.Id,
                        CustomerId: selectedCustomer.Id,
                    },
            );
            this.closedDate.OfficeClosedDates = this.selectedOfficeClosedDates;
            this.closedDate.ExcludedCustomersClosedDates = this.excludedCustomerClosedDates;
            this.saveClosedDate();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveClosedDate(): void {
        if (this.isNewClosedDate) {
            this.closedDateService
                .createWithFks(this.closedDate).subscribe((answer) => {
                    this.closedDate.Id = answer;
                    this.success(true);
                });
        } else {
            this.closedDateService
                .updateWithAssociations(this.closedDate).subscribe(() => {
                    this.success();
                });
        }
    }

    private success(newClosedDateSave?: boolean): void {
        if (newClosedDateSave) {
            void this.router.navigate([`/closeddates/${this.closedDate.Id}`]);
        } else {
            this.closedDateService.getById(this.closedDate.Id).subscribe((closedDate) => {
                this.closedDate = closedDate;
                this.setConfig();
                this.getOffices();
                this.getCustomers();
                this.isEditing = false;
            });
        }
        this.closedDateService.emitChange(this.closedDate);
        this.notificationsService.success('Closed Date saved successfully.');
    }

    getOffices(): void {
       this.officeService.getActive().subscribe(
            (answer) => {
                this.offices = answer;
                this.officeSelectItems = this.offices.map(
                    (item) =>
                        new MultiselectItem(
                            new MetaItem(item.Id, item.Title),
                            this.closedDate.OfficeClosedDates?.some((o) => o.OfficeId === item.Id),
                        ),
                );
                this.selectedOffices = this.officeSelectItems.filter((cs) => cs.Selected).map((o) => o.Item);
            },
            () => {
                this.notificationsService.error('Failed to pull offices');
            },
        );
    }

    getCustomers(): void {
        this.customerService.getActiveCustomers().subscribe(
             (answer) => {
                 this.customerSelectItems = answer.map(
                     (item) =>
                         new MultiselectItem(
                             new MetaItem(item.Id, item.Name),
                             this.closedDate.ExcludedCustomersClosedDates?.some((c) => c.CustomerId === item.Id),
                         ),
                 );
                 this.selectedCustomers = this.customerSelectItems.filter((cs) => cs.Selected).map((c) => c.Item);
             },
             () => {
                 this.notificationsService.error('Failed to pull customers');
             },
         );
     }

    officeSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedOffices = event.selectedItems;
    }

    customerSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedCustomers = event.selectedItems;
    }
}
