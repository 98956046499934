<div class="miles-editable miles-card padded">
    <h4>Warehouses</h4>
    <form #warehousesForm="ngForm" (ngSubmit)="onFormSubmit(warehousesForm)">
        <table class="table table-hover table-striped" style="color: #000">
            <thead>
                <tr class="no-pointer">
                    <th>
                        <b>Warehouse Id</b>
                    </th>
                    <th>
                        <b>Division</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Edit</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Delete</b>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let warehouse of warehouses">
                    <td class="ellipsis">
                        <span *ngIf="!isEditMode(warehouse)">{{
                            warehouse.WarehouseId
                        }}</span>
                        <span *ngIf="isEditMode(warehouse)">
                            <input
                                type="text"
                                name="warehouseId"
                                [(ngModel)]="selectedWarehouse.WarehouseId"
                                class="form-control"
                                required
                                #warehouseControl="ngModel"
                            />
                            <div
                                *ngIf="
                                    warehouseControl.invalid &&
                                    warehouseControl.touched
                                "
                                class="validator-msg"
                            >
                                Warehouse Id Required!
                            </div>
                        </span>
                    </td>
                    <td class="ellipsis">
                        <span *ngIf="!isEditMode(warehouse)">{{
                            warehouse.Division
                        }}</span>
                        <span *ngIf="isEditMode(warehouse)">
                            <input
                                type="text"
                                name="division"
                                [(ngModel)]="selectedWarehouse.Division"
                                class="form-control"
                                required
                                #divisionControl="ngModel"
                            />
                            <div
                                *ngIf="
                                    divisionControl.invalid &&
                                    divisionControl.touched
                                "
                                class="validator-msg"
                            >
                                Division is Required!
                            </div>
                        </span>
                    </td>
                    <td *ngIf="canEdit">
                        <span *ngIf="!isEditMode(warehouse)">
                            <i
                                class="fa fa-2x fa-fw fa-pencil-square-o icon-main"
                                (click)="setEdit(warehouse)"
                            ></i>
                        </span>
                        <span *ngIf="isEditMode(warehouse)">
                            <button
                                class="btn btn-success"
                                type="submit"
                            >
                                Save
                            </button>
                        </span>
                    </td>
                    <td *ngIf="canEdit">
                        <span *ngIf="!isEditMode(warehouse)">
                            <i
                                class="fa fa-2x fa-fw fa-trash icon-main"
                                (mtConfirm)="onDelete(warehouse)"
                            ></i>
                        </span>
                        <span *ngIf="isEditMode(warehouse)">
                            <button
                                class="btn btn-default"
                                (click)="cancel(warehouse)"
                            >
                                Cancel
                            </button>
                        </span>
                    </td>
                </tr>
            </tbody>

            <div class="fab-wrap" *ngIf="canEdit">
                <a
                    class="btn btn-primary btn-fab-md btn-fab-center"
                    (click)="add()"
                >
                    <span class="fa fa-plus"></span>
                </a>
            </div>
        </table>
    </form>
</div>
