<div class="public-responsive">
    <h1 class="text-center">Orders Ready</h1>
    <br>
    <div class="checkInDetails-container" *ngFor="let group of groupedCheckIns">
        <div class="miles-card padded">
            <h4>LOCATION:</h4>
            <h2>{{ group[0]?.OfficeDoor?.Door ?? 'N/A' }}</h2>
            <button *ngIf="group[0]?.OfficeDoor?.Door" type="button" class="btn btn-primary margin-bottom-custom" (click)="navigateToGoogleMaps(group[0])">Take Me There!</button>
            <button *ngIf="group[0]?.OfficeDoor?.Door" type="button" class="btn btn-primary" (click)="goToDriverOrdersReadyPage(group[0])">OK - I've arrived at the dock</button>
            <br><br>
            <div><b>Order #: </b>{{ getOrderNumbers(group) }}</div>
            <div><b>Customer: </b>{{ group[0]?.Customer.Name }}</div>
        </div>
        <br>
    </div>
    <br>
    <app-driver-check-in-support-button [driver]="driver" [pickup]="pickup"></app-driver-check-in-support-button>
    <br>
</div>
