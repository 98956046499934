import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { PickupService } from '../../../model/shared-entities/pickups/pickup.service';
import { IPickup } from '../../../model/interfaces/pickup';

@Component({
    selector: 'pickup-gatepass-number-dialog',   
    templateUrl: './pickup-gatepass-number-dialog.component.html',
})
export class PickupGatePassDialogComponent {
    doubleClickIsDisabled = false;

    @Input('pickup') pickup: IPickup;
    @Output('onClose') onClose: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private notificationsService: NotificationsService,
        private pickupService: PickupService,
    ) {}

    formSubmitted(ngForm: NgForm): void {
        const gatePassNumber = ngForm.form.value.gatePassNumber;
        if (!gatePassNumber) {
            this.notificationsService.error('Please enter a valid gatepass number.');
            return;
        }
        
        this.pickup.GatePassNumber = gatePassNumber;
        this.pickupService.updateGatePassNumber(this.pickup)
            .pipe(finalize(() => this.doubleClickIsDisabled = false))
            .subscribe({
                next: () => {
                    this.notificationsService.success('Gate Pass Number Updated.');
                    this.close();
                }
            });
    }

    close(): void {
        this.onClose.emit();
    }
}
