import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { ICarrier } from '../model/interfaces/carrier';
import { IUser } from '../model/interfaces/user';
import { INotificationType } from '../model/interfaces/notification-type';
import { UserDynamicControlsPartial } from '../model/partials/user-partial.form-controls';

export class UserDynamicConfig<T extends IUser> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private user: T, private carriers: ICarrier[], private notificationTypes?: INotificationType[], private configControls?: string[]) {
        super();
        const dynamicControls = new UserDynamicControlsPartial(this.user, {carriers: carriers, notificationTypes: notificationTypes});
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'FirstName',
                'LastName',
                'Email',
                'Phone',
                'UsdotNumber',
                'DriverLicenseNumber',
                'TractorLicensePlate',
                'TrailerLicensePlate',
                'CarrierId',
                'NotificationTypeId',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
