import { IEntity } from "@mt-ng2/base-service";
import { MtSearchFilterItem } from "@mt-ng2/search-filter-select-control";

export interface ICheckInSearchParams {
    query: string;
    fromDate?: Date;
    toDate?: Date;
}

export class CheckInSearchParams implements ICheckInSearchParams {
    query: string;
    fromDate?: Date;
    toDate?: Date;
    constructor(searchParams: ICheckInSearchParams) {
        this.query = searchParams.query;
        this.fromDate = searchParams.fromDate;
        this.toDate = searchParams.toDate;
    }
}

export class CommonFunctions {
    createBlob(data: string, mimeType: string, isPlainText: boolean): Blob {
        if (!mimeType) {
            mimeType = 'application/txt';
        }

        if (isPlainText) {
            // using a blob of the byte array, force the download by creating a
            // dummy link and simulating a click on it
            return new Blob([data], { type: mimeType });
        }

        // convert the base64 string to a byte array
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const outputData = new Uint8Array(byteNumbers);
        return new Blob([outputData], { type: mimeType });
    }

    mapMtSearchFilterItems(items: IEntity[], nameKey = 'Name'): MtSearchFilterItem[] {
        return items.map((item) => {
            return new MtSearchFilterItem(
                {
                    Id: item.Id,
                    Name: item[nameKey],
                }, false,
            );
        });
    }
}
