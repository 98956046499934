import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { FindValidDriverDTO } from '../../model/interfaces/custom/find-valid-driver-dto';
import { IUser } from '../../model/interfaces/user';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../common/services/common.service';

@Component({
    selector: 'app-driver-check-in-info',
    templateUrl: './driver-check-in-info.component.html',
    styleUrls: ['./driver-check-in-info.component.css'],
})
export class DriverCheckInInfoComponent implements OnInit {
    loading = true;
    driverInfoForm: FormGroup;
    driver: IUser;
    submitButtonText = 'Continue';
    pickupNumber: string;

    constructor(
        private formBuilder: FormBuilder, 
        private checkInService: PublicCheckInService,
        private notificationService: NotificationsService,
        private route: ActivatedRoute,
        private router: Router,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.pickupNumber = params['pickupNumber'];
            this.checkInService.checkedInDriver$.subscribe((driver) => {
                this.driver = driver;
            });

            this.driverInfoForm = this.formBuilder.group({
                lastName: ['', [Validators.required]],
                phone: ['', [Validators.required, this.commonService.phoneNumberValidator()]],
            });
        });
    }

    onSubmit() {
        const dto: FindValidDriverDTO = {
            lastName: this.driverInfoForm.get('lastName').value,
            phone: this.driverInfoForm.get('phone').value,
        }
        this.checkInService.findValidDriver(dto).subscribe(driver => {
            if (driver?.Id > 0) {
                this.driver = driver;
                this.notificationService.success('Driver found.');
                if (this.pickupNumber?.length > 0) {
                    this.checkInService.checkedInDriver$.next(this.driver);
                    void this.router.navigate([`public/driver-check-in-add-pickups`], { queryParams: { pickupNumber: this.pickupNumber } });
                }
            } else {
                this.notificationService.error('Driver not found.');
                this.submitButtonText = 'Please try again';
            }
        });
    }

    goToDriverEditPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate([`public/driver-check-in-edit`]);
    }

    goToDriverCheckInPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        void this.router.navigate([`public/driver-check-in`]);
    }
}
