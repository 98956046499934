import { ModuleWithProviders, NgModule } from '@angular/core';
import { RegistrationRequestService } from './registration-request.service';
import { SharedModule } from '../common/shared.module';
import { RegistrationRequestRoutingModule } from './registration-request-routing.module';
import { RegistrationRequestComponent } from './registration-request-list/registration-request-list.component';
import { RegistrationRequestDetailComponent } from './registration-request-detail/registration-request-detail.component';
import { RegistrationRequestInfoComponent } from './registration-request-info/registration-request-info.component';
import { CommonModule } from '@angular/common';
import { RegistrationRequestActionsDynamicCellComponent } from './registration-request-list/registration-request-actions-dynamic-cell.component';

@NgModule({
    declarations: [RegistrationRequestComponent, RegistrationRequestDetailComponent, RegistrationRequestInfoComponent, RegistrationRequestActionsDynamicCellComponent],
    imports: [SharedModule, RegistrationRequestRoutingModule, CommonModule],
})
export class RegistrationRequestModule {
    static forRoot(): ModuleWithProviders<RegistrationRequestModule> {
        return {
            ngModule: RegistrationRequestModule,
            providers: [RegistrationRequestService],
        };
    }
}
