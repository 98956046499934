import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { FileUploadModule } from 'ng2-file-upload';
import { HourFilterPipe } from './filters/common.hour-filter';
import { DynamicFormModule, DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { MtNoteControlModule } from '@mt-ng2/note-control';
import { EntityComponentsNotesModule } from '@mt-ng2/entity-components-notes';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { IDocumentControlModuleConfig, MtDocumentControlModule, DocumentControlModuleConfigToken } from '@mt-ng2/document-control';
import { EntityComponentsDocumentsModule, EntityComponentsDocumentsModuleConfigToken, IEntityComponentsDocumentsModuleConfig } from '@mt-ng2/entity-components-documents';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { MtPhotoControlModule } from '@mt-ng2/photo-control';
import { EntityListModule, IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module';
import { SharedEntitiesModule } from '@mt-ng2/shared-entities-module';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { CommonService } from './services/common.service';
import { AuthEntityModule } from '../auth-entity/auth-entity.module';
import { AuthEntityService } from '../auth-entity/auth-entity.service';
import { ModalModule } from '@mt-ng2/modal-module';
import { MatchingStrategy } from './configs/type-ahead-module.config';
import { TypeAheadModule, ITypeAheadConfig, TypeAheadModuleConfigToken } from '@mt-ng2/type-ahead-control';
import { SortArrowsComponent } from './components/sort-arrows.component';
import { DynamicSearchFiltersModule } from '@mt-ng2/dynamic-search-filters';
import { CustomerDetailSharedEntitiesComponent } from '../customers/customer-detail-shared-entities/customer-detail-shared-entities.component'
import { ContactInfoComponent } from '../contacts/contacts-info/contacts-info.component';
import { ScrollableTypeAheadComponent } from './components/scrollable-type-ahead/scrollable-type-ahead.component'
import { PhoneFormatPipe } from '../common/pipes/phone-format'
import { NavModule } from '@mt-ng2/nav-module';

export const typeAheadConfig: ITypeAheadConfig = {
    matchingStrategy: MatchingStrategy.StartsWith,
};

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

const entityComponentsDocumentsModuleConfig: IEntityComponentsDocumentsModuleConfig = {
    max: 10,
}

const documentControlModuleConfig: IDocumentControlModuleConfig = {
    maxFileSize: 10 * 1024 * 1024, // 10MB
}

@NgModule({
    declarations: [
        HourFilterPipe,
        SortArrowsComponent,
        CustomerDetailSharedEntitiesComponent,
        ContactInfoComponent,
        ScrollableTypeAheadComponent,
        PhoneFormatPipe,
    ],
    exports: [
        AuthEntityModule,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        MtPhotoControlModule,
        NgxMaskModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        EntityComponentsAddressesModule,
        MtManagedListControlModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtDisableDuringHttpCallsModule,
        EntityListModule,
        ModalModule,
        MtSearchBarControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        HourFilterPipe,
        ModalModule,
        TypeAheadModule,
        SortArrowsComponent,
        DynamicSearchFiltersModule,
        CustomerDetailSharedEntitiesComponent,
        ContactInfoComponent,
        ScrollableTypeAheadComponent,
        PhoneFormatPipe,
        NavModule,
    ],
    imports: [
        AuthEntityModule,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        NgxMaskModule,
        FileUploadModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        MtManagedListControlModule,
        MtPhotoControlModule,
        EntityComponentsAddressesModule,
        ModalModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtDisableDuringHttpCallsModule,
        EntityListModule,
        MtSearchBarControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        ModalModule,
        DynamicSearchFiltersModule,
        TypeAheadModule,
        NavModule,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
                { provide: TypeAheadModuleConfigToken, useValue: typeAheadConfig },
                { provide: EntityComponentsDocumentsModuleConfigToken, useValue: entityComponentsDocumentsModuleConfig },
                { provide: DocumentControlModuleConfigToken, useValue: documentControlModuleConfig },
                AuthEntityService,
                HourFilterPipe,
            ],
        };
    }
}
