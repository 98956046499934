import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IContact } from '../interfaces/contact';
import { ICarrier } from '../interfaces/carrier';
import { ICustomer } from '../interfaces/customer';
import { IUser } from '../interfaces/user';
import { INotificationType } from '../interfaces/notification-type';
import { IContactType } from '../interfaces/contact-type';

export interface IContactDynamicControlsParameters {
    formGroup?: string;
    types?: IContactType[];
    notificationTypes?: INotificationType[];
    carriers?: ICarrier[];
    customers?: ICustomer[];
    modifiedBies?: IUser[];
    users?: IUser[];
}

export class ContactDynamicControls {

    formGroup: string;
    types: IContactType[];
    notificationTypes: INotificationType[];
    carriers: ICarrier[];
    customers: ICustomer[];
    modifiedBies: IUser[];
    users: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private contact?: IContact, additionalParameters?: IContactDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Contact';
        this.types = additionalParameters && additionalParameters.types || undefined;
        this.notificationTypes = additionalParameters && additionalParameters.notificationTypes || undefined;
        this.carriers = additionalParameters && additionalParameters.carriers || undefined;
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.users = additionalParameters && additionalParameters.users || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.hasOwnProperty('Archived') && this.contact.Archived !== null ? this.contact.Archived : false,
            }),
            CarrierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                name: 'CarrierId',
                options: this.carriers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.CarrierId || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.CustomerId || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.DateModified || null,
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50), Validators.email ],
                validators: { 'required': true, 'maxlength': 50, 'email': true },
                value: this.contact && this.contact.hasOwnProperty('Email') && this.contact.Email !== null ? this.contact.Email.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.contact && this.contact.hasOwnProperty('FirstName') && this.contact.FirstName !== null ? this.contact.FirstName.toString() : '',
            }),
            GrantAccessToPortal: new DynamicField({
                formGroup: this.formGroup,
                label: 'Grant Access To Portal',
                name: 'GrantAccessToPortal',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.hasOwnProperty('GrantAccessToPortal') && this.contact.GrantAccessToPortal !== null ? this.contact.GrantAccessToPortal : false,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.contact && this.contact.hasOwnProperty('LastName') && this.contact.LastName !== null ? this.contact.LastName.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.ModifiedById || null,
            }),
            NotificationTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification Type',
                name: 'NotificationTypeId',
                options: this.notificationTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.contact && this.contact.NotificationTypeId || null,
            }),
            NotificationWhenDriverChecksIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification When Driver Checks In',
                name: 'NotificationWhenDriverChecksIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.hasOwnProperty('NotificationWhenDriverChecksIn') && this.contact.NotificationWhenDriverChecksIn !== null ? this.contact.NotificationWhenDriverChecksIn : false,
            }),
            NotificationWhenDriverIsFulfilled: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification When Driver Is Fulfilled',
                name: 'NotificationWhenDriverIsFulfilled',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.hasOwnProperty('NotificationWhenDriverIsFulfilled') && this.contact.NotificationWhenDriverIsFulfilled !== null ? this.contact.NotificationWhenDriverIsFulfilled : false,
            }),
            NotificationWhenDriverIsIssuedGatePass: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification When Driver Is Issued Gate Pass',
                name: 'NotificationWhenDriverIsIssuedGatePass',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.hasOwnProperty('NotificationWhenDriverIsIssuedGatePass') && this.contact.NotificationWhenDriverIsIssuedGatePass !== null ? this.contact.NotificationWhenDriverIsIssuedGatePass : false,
            }),
            NotificationWhenDriverIsNotified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification When Driver Is Notified',
                name: 'NotificationWhenDriverIsNotified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.hasOwnProperty('NotificationWhenDriverIsNotified') && this.contact.NotificationWhenDriverIsNotified !== null ? this.contact.NotificationWhenDriverIsNotified : false,
            }),
            PhoneNo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone No',
                name: 'PhoneNo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.contact && this.contact.hasOwnProperty('PhoneNo') && this.contact.PhoneNo !== null ? this.contact.PhoneNo.toString() : '',
            }),
            PrimaryContact: new DynamicField({
                formGroup: this.formGroup,
                label: 'Primary Contact',
                name: 'PrimaryContact',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.hasOwnProperty('PrimaryContact') && this.contact.PrimaryContact !== null ? this.contact.PrimaryContact : false,
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.contact && this.contact.hasOwnProperty('Title') && this.contact.Title !== null ? this.contact.Title.toString() : '',
            }),
            TypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Type',
                name: 'TypeId',
                options: this.types,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.contact && this.contact.TypeId || null,
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.UserId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.contact && this.contact.hasOwnProperty('Archived') && this.contact.Archived !== null ? this.contact.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CarrierId: new DynamicLabel({
			    label: 'Carrier',
			    value: getMetaItemValue(this.carriers as unknown as IMetaItem[], this.contact && this.contact.hasOwnProperty('CarrierId') && this.contact.CarrierId !== null ? this.contact.CarrierId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CustomerId: new DynamicLabel({
			    label: 'Customer',
			    value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.contact && this.contact.hasOwnProperty('CustomerId') && this.contact.CustomerId !== null ? this.contact.CustomerId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.contact && this.contact.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.contact && this.contact.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            Email: new DynamicLabel({
			    label: 'Email',
			    value: this.contact && this.contact.hasOwnProperty('Email') && this.contact.Email !== null ? this.contact.Email.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FirstName: new DynamicLabel({
			    label: 'First Name',
			    value: this.contact && this.contact.hasOwnProperty('FirstName') && this.contact.FirstName !== null ? this.contact.FirstName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            GrantAccessToPortal: new DynamicLabel({
			    label: 'Grant Access To Portal',
			    value: this.contact && this.contact.hasOwnProperty('GrantAccessToPortal') && this.contact.GrantAccessToPortal !== null ? this.contact.GrantAccessToPortal : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LastName: new DynamicLabel({
			    label: 'Last Name',
			    value: this.contact && this.contact.hasOwnProperty('LastName') && this.contact.LastName !== null ? this.contact.LastName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.contact && this.contact.hasOwnProperty('ModifiedById') && this.contact.ModifiedById !== null ? this.contact.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NotificationTypeId: new DynamicLabel({
			    label: 'Notification Type',
			    value: getMetaItemValue(this.notificationTypes as unknown as IMetaItem[], this.contact && this.contact.hasOwnProperty('NotificationTypeId') && this.contact.NotificationTypeId !== null ? this.contact.NotificationTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NotificationWhenDriverChecksIn: new DynamicLabel({
			    label: 'Notification When Driver Checks In',
			    value: this.contact && this.contact.hasOwnProperty('NotificationWhenDriverChecksIn') && this.contact.NotificationWhenDriverChecksIn !== null ? this.contact.NotificationWhenDriverChecksIn : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NotificationWhenDriverIsFulfilled: new DynamicLabel({
			    label: 'Notification When Driver Is Fulfilled',
			    value: this.contact && this.contact.hasOwnProperty('NotificationWhenDriverIsFulfilled') && this.contact.NotificationWhenDriverIsFulfilled !== null ? this.contact.NotificationWhenDriverIsFulfilled : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NotificationWhenDriverIsIssuedGatePass: new DynamicLabel({
			    label: 'Notification When Driver Is Issued Gate Pass',
			    value: this.contact && this.contact.hasOwnProperty('NotificationWhenDriverIsIssuedGatePass') && this.contact.NotificationWhenDriverIsIssuedGatePass !== null ? this.contact.NotificationWhenDriverIsIssuedGatePass : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NotificationWhenDriverIsNotified: new DynamicLabel({
			    label: 'Notification When Driver Is Notified',
			    value: this.contact && this.contact.hasOwnProperty('NotificationWhenDriverIsNotified') && this.contact.NotificationWhenDriverIsNotified !== null ? this.contact.NotificationWhenDriverIsNotified : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhoneNo: new DynamicLabel({
			    label: 'Phone No',
			    value: this.contact && this.contact.hasOwnProperty('PhoneNo') && this.contact.PhoneNo !== null ? this.contact.PhoneNo.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PrimaryContact: new DynamicLabel({
			    label: 'Primary Contact',
			    value: this.contact && this.contact.hasOwnProperty('PrimaryContact') && this.contact.PrimaryContact !== null ? this.contact.PrimaryContact : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Title: new DynamicLabel({
			    label: 'Title',
			    value: this.contact && this.contact.hasOwnProperty('Title') && this.contact.Title !== null ? this.contact.Title.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TypeId: new DynamicLabel({
			    label: 'Type',
			    value: getMetaItemValue(this.types as unknown as IMetaItem[], this.contact && this.contact.hasOwnProperty('TypeId') && this.contact.TypeId !== null ? this.contact.TypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            UserId: new DynamicLabel({
			    label: 'User',
			    value: getMetaItemValue(this.users as unknown as IMetaItem[], this.contact && this.contact.hasOwnProperty('UserId') && this.contact.UserId !== null ? this.contact.UserId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
