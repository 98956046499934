import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { OrderService } from '../order.service';
import { AuthService, ClaimsService, ClaimValues, ILoggedIn } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { SchedulingFilterPartialDynamicControlsPartial } from '../../model/partials/scheduling-filter-partial.form-controls';
import { ITimeSlot } from '../../model/interfaces/custom/timeSlot';
import { IOrder } from '../../model/interfaces/order';
import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../../customers/customer.service';
import { ClaimTypes } from '../../model/ClaimTypes';
import { debounceTime } from 'rxjs/operators';
import { IOfficeModifiedHour } from '../../model/interfaces/office-modified-hour';
import { ISelectionChangedEvent, TypeAheadComponent } from '@mt-ng2/type-ahead-control';
import { IOffice } from '../../model/interfaces/office';
import { forkJoin, Subscription } from 'rxjs';
import { OfficeService } from '../../offices/services/office.service';
import { ICarrier } from '../../model/interfaces/carrier';
import { CarrierService } from '../../carriers/services/carrier.service';
import { OrderStatuses } from '../../model/OrderStatuses';
import { IExpandableObject } from '../../model/expandable-object';
import { IEntity } from '@mt-ng2/base-service';
import { UserRoles } from '../../model/UserRoles';
import { UserService } from '../../users/user.service';

export interface IUnscheduledOrder extends IOrder {
    MayBeChecked?: boolean,
    IsChecked?: boolean,
}

@Component({
    selector: 'app-manage-scheduling',
    styles: [
        `
            .hour-label {
                font-size: 12px;
                margin-bottom: 5px;
            }
        `,
    ],
    templateUrl: './manage-scheduling.component.html',
})
export class ManageSchedulingComponent implements OnInit {
    @ViewChild('customerTypeaheadControl') customerTypeaheadControl: TypeAheadComponent;
    @ViewChild('officeTypeaheadControl') officeTypeaheadControl: TypeAheadComponent;
    subscriptions: Subscription = new Subscription();
    selectedOrders: IEntity[];
    abstractSearchUserControls: IExpandableObject;
    schedulingFilterForm: UntypedFormGroup;
    scheduledsearchControl = new UntypedFormControl();
    unScheduledsearchControl = new UntypedFormControl();
    formCreated = false;
    customers: ICustomer[] = [];
    selectedCustomer: ICustomer;
    selectedDate: Date;
    timeSlots: ITimeSlot[] = [];
    origTimeSlots: ITimeSlot[] = [];
    unscheduledOrders: IUnscheduledOrder[];
    origunscheduledOrders: IUnscheduledOrder[];
    maxOrders = 0;
    canEdit: boolean;
    customerId: number;
    query: string;
    officeModifiedHours: IOfficeModifiedHour[];
    startingTime = '';
    endingTime = '';
    officeItems: object[] = [];
    selectedOfficeId: number;
    offices: IOffice[] = [];
    selectedOffice: IOffice;
    selectedOrder: IUnscheduledOrder;
    carriers: ICarrier[];
    selectedCarrier: ICarrier;
    maxOfficesToShow = 20;
    ordersOverridden = false;
    userIsCustomer = false;
    currentUser: ILoggedIn;

    constructor(
        private orderService: OrderService,
        private claimsService: ClaimsService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private customerService: CustomerService,
        private officeService: OfficeService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Orders, [ClaimValues.FullAccess]);
        this.customerId = +this.route.snapshot.paramMap.get('customerId');
        this.query = this.route.snapshot.paramMap.get('order');
        this.createForm();
        this.scheduledsearchControl.valueChanges.pipe(debounceTime(300)).subscribe(() => this.scheduledOrdersearch(this.scheduledsearchControl.value as string));
        this.unScheduledsearchControl.valueChanges.pipe(debounceTime(300)).subscribe(() => this.unscheduledOrdersearch(this.unScheduledsearchControl.value as string));
        this.orderService.selectedOrders$.subscribe((selectedOrders) => {
            this.selectedOrders = selectedOrders;
            if (this.selectedOrders.length > 0) {
                this.orderOverride(this.selectedOrders as IUnscheduledOrder[]);
                this.ordersOverridden = true;
            }
        });
        this.currentUser = this.authService.currentUser.getValue();
        forkJoin(
            this.officeService.getActive(),
            this.customerService.getCustomerForManageScheduling(),
            this.orderService.getByOrderNumberAndCustomerId(this.query, this.customerId),
            this.carrierService.getActive(),
            this.orderService.getAuthUserRole(this.currentUser.AuthId),
            this.userService.getPrimaryCustomer(this.currentUser.Id),
        ).subscribe((answer) => {
            this.offices = answer[0];
            this.officeItems = answer[0].map((o) => {
                return { Id: o.Id, Name: o.Title };
            });
            const authUserRole: number = answer[4];
            this.userIsCustomer = authUserRole === UserRoles.Customer;
            const primaryCustomer = answer[5];
            if (this.userIsCustomer && primaryCustomer?.Id > 0) {
                this.customerId = primaryCustomer.Id;
            }
            this.customers = answer[1];
            if (this.customerId > 0) {
                this.selectedCustomer = this.customers.find((c) => c.Id === this.customerId);
                if (this.selectedCustomer) {
                    this.customerTypeaheadControl.inputControl.setValue(this.selectedCustomer.Name);
                    this.customerTypeaheadControl.selected = this.selectedCustomer;
                }
            }
            this.selectedOrder = answer[2];
            if (!this.selectedOrder) {
                return;
            }
            if (this.selectedOrder.WarehouseId) {
                this.selectedOffice = this.offices.filter((o) => o.Warehouses.some((w) => w.Id === this.selectedOrder.WarehouseId))[0];
                this.officeTypeaheadControl.inputControl.setValue(this.selectedOffice.Title);
                this.officeTypeaheadControl.selected = { Id: this.selectedOffice.Id, Name: this.selectedOffice.Title };
            }
            if (this.selectedOrder.PickUpDateTime && this.selectedOrder?.StatusId === OrderStatuses.Scheduled) {
                this.selectedDate = new Date(this.selectedOrder.PickUpDateTime);
                const scheduleSearchControl = this.schedulingFilterForm.controls['scheduleSearch'];
                scheduleSearchControl.setValue({Date: this.selectedDate});
            }
            this.carriers = answer[3];
            this.getScheduledOrders();
            this.handleUnscheduledOrders();

        });
    }

    scheduledOrdersearch(query: string): void {
        if (query) {
            this.timeSlots = [];
            const parsedQuery = query.split(/\W/gm);
            this.timeSlots = this.origTimeSlots.filter((ts) => {
                return ts.ExistingPickups.find((p) => {
                    return p.Orders.find((o) => {
                        return parsedQuery.indexOf(o.OrderNumber) !== -1;
                    });

                });
            });
        } else {
            this.timeSlots = [...this.origTimeSlots];
        }
    }

    unscheduledOrdersearch(query: string): void {
        let parsedQuery = query.split(/\W/gm);
        if (parsedQuery[0]) {
            this.unscheduledOrders = this.origunscheduledOrders?.filter((o) => {
                return parsedQuery.indexOf(o.OrderNumber) !== -1;
            });
        }
        else {
            this.unscheduledOrders = this.origunscheduledOrders;
            parsedQuery = [];
        }
    }

    orderOverride(orders: IUnscheduledOrder[]): void {
            let allUnscheduledOrders = [];
            if(this.selectedCustomer && this.selectedOffice ) allUnscheduledOrders = this.setMayBeChecked(orders);
            else allUnscheduledOrders = this.setMayBeChecked(orders, false);
            this.unscheduledOrders = allUnscheduledOrders;
            this.origunscheduledOrders = allUnscheduledOrders;

            if (this.query !== '*' && this.query !== '') {
                if(this.selectedOrder){
                    if(this.selectedOrder?.StatusId === OrderStatuses.Scheduled) {
                        this.scheduledsearchControl.setValue(this.query);
                    }
                    else {
                        this.unScheduledsearchControl.setValue(this.query);
                    }
                }
            }
    }

    handleUnscheduledOrders(): void {
        this.orderService.selectedOrders$.subscribe((answer) =>{
            if(answer.length > 0) {
                this.orderOverride(answer as IUnscheduledOrder[]);
                this.ordersOverridden = true;
            }
            else {
                this.getUnscheduledOrders();
            }
        });
    }

    createForm(): void {
        this.getControls();
        this.schedulingFilterForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            scheduleSearch: this.fb.group({}),
        });
    }

    getControls(): void {
        this.abstractSearchUserControls = new SchedulingFilterPartialDynamicControlsPartial(null, {
            formGroup: 'scheduleSearch',
        }).Form;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        (<DynamicField>this.abstractSearchUserControls.Date).value = today;
        this.selectedDate = today;
    }

    changeDate(event: Date): void {
        this.selectedDate = event;
        this.getScheduledOrders();
    }

    clearUnScheduledSearch(): void {
        this.unScheduledsearchControl.setValue('');
        this.unscheduledOrders = this.origunscheduledOrders;
    }

    clearScheduledSearch(): void {
        this.scheduledsearchControl.setValue('');
    }

    getScheduledOrders(): void {
        if (this.selectedCustomer && this.selectedOffice && this.selectedDate) {
            this.orderService.getScheduledOrders(this.selectedCustomer.Id, this.selectedDate, this.selectedOffice.Id).subscribe((response) => {
                this.timeSlots = response;
                this.origTimeSlots = [...this.timeSlots];
            });
        }
    }

    getUnscheduledOrders(): void {
        if (this.selectedCustomer && this.selectedOffice) {
            const carrierId = this.selectedCarrier ? this.selectedCarrier.Id : 0;
            this.orderService.getCustomerUnscheduledOrders(this.selectedCustomer.Id, carrierId, this.selectedOffice.Id).subscribe((response) => {
                let allUnscheduledOrders = [];
                allUnscheduledOrders = this.setMayBeChecked(response);

                this.unscheduledOrders = allUnscheduledOrders;
                this.origunscheduledOrders = allUnscheduledOrders;

                if (this.query !== '*' && this.query !== '') {
                    if(this.selectedOrder){
                        if(this.selectedOrder?.StatusId === OrderStatuses.Scheduled) {
                            this.scheduledsearchControl.setValue(this.query);
                        }
                        else {
                            this.unScheduledsearchControl.setValue(this.query);
                        }
                    }
                }
            });
        }
    }

    private setMayBeChecked(arr: IUnscheduledOrder[], valueOverride = undefined): IUnscheduledOrder[] {
        if (valueOverride !== undefined) {
            arr.forEach((order) => {
                    order.MayBeChecked = valueOverride;
            });
        }
        else {
            arr.forEach((order) => {
                if(order.CustomerId == this.selectedCustomer.Id && order.Warehouse?.OfficeId == this.selectedOffice.Id)
                {
                    order.MayBeChecked = true;
                }
                else {
                    order.MayBeChecked = false;
                }
            });
        }
        return arr;
    }

    onCustomerSelected(event: ISelectionChangedEvent): void {
        this.selectedCustomer = event.selection;
        this.getScheduledOrders();
        this.handleUnscheduledOrders();
    }

    onOfficeSelected(event: ISelectionChangedEvent): void {
        this.selectedOffice = event.selection ? this.offices.find((o) => o.Id === event.selection.Id) : null;
        this.getScheduledOrders();
        this.handleUnscheduledOrders();
    }

    onCarrierSelected(event: ISelectionChangedEvent): void {
        this.selectedCarrier = event.selection ? this.carriers.find((o) => o.Id === event.selection.Id) : null;
        this.getScheduledOrders();
        this.handleUnscheduledOrders();
    }

    formatAMPM(date: Date): string {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? 0 + minutes : minutes;
        return `${hours}:${minutes} ${ampm}`;
    }
}
