<div *ngIf="loading" class="loading-spinner">
    <div class="spinner"></div>
</div>

<div *ngIf="!loading">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <h1 class="form-header text-center">
                Enter Pickup Details
            </h1>
            <div class="table-wrapper">
                <table class="table table-hover table-striped walk-ins-table">
                    <thead>
                        <tr class="no-pointer">
                            <th>
                                <b>Order Number</b>
                            </th>
                            <th>
                                <b>Customer</b>
                            </th>
                            <th>
                                <b>Actions</b>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pickupParam of pickupParams">
                            <td>
                                <input [disabled]="pickupParam.customerId > 0 && pickupParam.validOrderNumber"
                                    type="text" class="order-number-input" [(ngModel)]="pickupParam.orderNumber">
                            </td>
                            <td>
                                <mt-type-ahead #customerTypeaheadControl
                                    [disabled]="pickupParam.customerId > 0 && pickupParam.validOrderNumber"
                                    [items]="customers" [maxToShow]="maxToShow"
                                    (selectionChanged)="onCustomerSelected($event, pickupParam)">
                                </mt-type-ahead>
                            </td>
                            <td>
                                <button class="btn btn-default"
                                    [disabled]="pickupParam.validOrderNumber && pickupParam.customerId > 0"
                                    (click)="validateOrderNumber(pickupParam)">
                                    Save
                                </button>

                                <button class="btn btn-danger" [disabled]="pickupParams.length <= 1"
                                    (click)="removePickupParam(pickupParam)">
                                    Remove
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>
            <div class="text-center">
                <button class="btn btn-default" (click)="addPickupParam()">
                    Add Additional Order
                </button>
            </div>
            <br />
            <div class="text-center">
                <button class="btn btn-success" (click)="submitList()" [disabled]="disableSubmitButton()">
                    Create Pickup
                </button>
            </div>
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver"></app-driver-check-in-support-button>
    <br />
</div>
