import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { v4 as uuidv4 } from 'uuid';
import { IUser } from '../../model/interfaces/user';
import { CarrierService } from '../../carriers/services/carrier.service';
import { UserService } from '../../users/user.service';
import { UserRoles } from '../../model/UserRoles';
import { ICreateUserPayload } from '../../model/interfaces/custom/create-user-payload';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CommonService } from '../../common/services/common.service';
import { ICarrierForRegistration } from '../../model/interfaces/custom/carrier-for-registration';

@Component({
    selector: 'app-driver-check-in-new',
    templateUrl: './driver-check-in-new.component.html',
    styleUrls: ['./driver-check-in-new.component.css'],
})
export class DriverCheckInNewComponent implements OnInit {
    loading = true;
    newDriverForm: FormGroup;
    driver: IUser;
    carriers: ICarrierForRegistration[];
    doubleClickIsDisabled = false;

    constructor(
        private formBuilder: FormBuilder, 
        private checkInService: PublicCheckInService,
        private notificationService: NotificationsService,
        private carrierService: CarrierService,
        private userService: UserService,
        private router: Router,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.carrierService.getActiveCarriersForRegistration().subscribe((carriers) => {
            this.carriers = carriers;
            this.loading = false;
        });
        
        this.driver = this.userService.getEmptyUser();
        this.driver.IsMobileUser = false;
        this.newDriverForm = this.formBuilder.group({
            firstName: ['', [Validators.required, this.commonService.firstAndLastNameValidator()]],
            lastName: ['', [Validators.required, this.commonService.firstAndLastNameValidator()]],
            phone: ['', [Validators.required, this.commonService.phoneNumberValidator()]],
            carrier: ['', Validators.required],
            UsdotNumber: ['', [this.commonService.checkForAlphaNumericCharacters()]],
            licensePlate: ['', [Validators.required, this.commonService.checkForAlphaNumericCharacters()]],
            driverNotificationOptOut: ['', []],
        });
    }

    onSubmit() {
        this.driver.FirstName = this.newDriverForm.get('firstName').value;
        this.driver.LastName = this.newDriverForm.get('lastName').value;
        this.driver.Phone = this.newDriverForm.get('phone').value;
        this.driver.CarrierId = this.newDriverForm.get('carrier').value;
        this.driver.UsdotNumber = this.newDriverForm.get('UsdotNumber').value;
        this.driver.TrailerLicensePlate = this.newDriverForm.get('licensePlate').value;
        this.driver.DriverNotificationOptOut = this.newDriverForm.get('driverNotificationOptOut').value;
        
        // Need to temporarily set email here to get the api to accept the DTO.
        // Will change it on the back-end to the HoltTruckerEmail config setting.
        this.driver.Email = 'temp.email@example.com';

        const dto: ICreateUserPayload = {
            Password: '',
            SendEmail: false,
            User: this.driver,
            Username: uuidv4(),
            UserRoleId: UserRoles.DriverUser,
            CarrierId: 0,
            CustomerId: 0,
        };

        this.checkInService
            .createDriver(dto).subscribe({
                next: (driver) => {
                    this.notificationService.success('Driver created.');
                    this.checkInService.checkedInDriver$.next(driver);
                    void this.router.navigate(['public/driver-check-in-info']);
                },
                error: (errorResponse: HttpErrorResponse) => {
                    this.notificationService.error(errorResponse.error['ModelState'] as string);
                },
            });
    }
}
