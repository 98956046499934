import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@mt-ng2/auth-module';
import { PickupsListComponent } from './pickups-list/pickups-list.component';
import { PickupPrePaymentComponent } from './pickup-pre-payment/pickup-pre-payment.component';

const pickupsRoutes: Routes = [
    { path: 'pickups', component: PickupsListComponent, canActivate: [AuthGuard] },
    { path: `pickups/:pickupId/pre-payment`, component: PickupPrePaymentComponent, canActivate: [AuthGuard] },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(pickupsRoutes)],
})
export class PickupsRoutingModule {}
