import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IDocumentControlModuleConfig } from '@mt-ng2/document-control';
import { IHasDocuments, IDocument } from '@mt-ng2/entity-components-documents';
import { catchError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { IDelivery } from '../../model/interfaces/delivery';

@Injectable({ providedIn: 'root' })
export class DeliveryDocumentsService extends BaseService<IDelivery> implements IHasDocuments {
    documentUploadControlConfig?: IDocumentControlModuleConfig;
    
    constructor(public http: HttpClient) {
        super('/deliveries', http);
    }

    getDocument(deliveryId: number, documentId: number): Observable<Blob> {
        return this.http.get(`/deliveries/${deliveryId}/documents/${documentId}`, {
            responseType: 'blob' as const,
        });
    }

    getDocuments(deliveryId: number, searchparameters: SearchParams): Observable<HttpResponse<IDocument[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IDocument[]>(`/deliveries/${deliveryId}/documents/search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    saveDocument(deliveryId: number, file: File): Observable<IDocument> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http
            .post<IDocument>(`/deliveries/${deliveryId}/documents/save`, formData)
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    deleteDocument(deliveryId: number, documentId: number): Observable<object> {
        return this.http.delete(`/deliveries/${deliveryId}/documents/${documentId}/delete`, {
            responseType: 'text' as 'json',
        })
        .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }
}
