import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';

import { BaseService, IEntity } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IOrder } from '../model/interfaces/order';
import { IScheduleOrderParams } from '../model/interfaces/custom/schedule-order-params';
import { IScheduleSearch } from '../model/interfaces/custom/schedule-search';
import { catchError, flatMap, tap } from 'rxjs/operators';
import { IOrderStatus } from '../model/interfaces/order-status';
import { INotFulFilledReason } from '../model/interfaces/not-ful-filled-reason';
import { ITimeSlot } from '../model/interfaces/custom/timeSlot';
import { OrderStatuses } from '../model/OrderStatuses';
import { UserService } from '../users/user.service';
import { IOffice } from '../model/interfaces/office';
import { AuthService } from '@mt-ng2/auth-module';
import { IPickup } from '../model/interfaces/pickup';
import { ICarrier } from '../model/interfaces/carrier';

export const emptyOrder: IOrder = {
    Archived: null,
    CustomerId: 0,
    DateCreated: null,
    Id: 0,
    SchedulerAssumesTruckerTwicCard: false,
    StatusId: 0,
};

@Injectable()
export class OrderService extends BaseService<IOrder> {
    private orderUpdatedSubject: Subject<void>;
    orderUpdated: Observable<void>;
    private newlyScheduledOrdersSubject: Subject<IOrder[]>;
    newlyScheduledOrders: Observable<IOrder[]>;


    constructor(public http: HttpClient, private authService: AuthService) {
        super('/orders', http);

        this.orderUpdatedSubject = new Subject();
        this.orderUpdated = this.orderUpdatedSubject.asObservable();
        this.newlyScheduledOrdersSubject = new Subject<IOrder[]>();
        this.newlyScheduledOrders = this.newlyScheduledOrdersSubject.asObservable();
    }

    getEmptyOrder(): IOrder {
        return { ...emptyOrder };
    }

    getByOrderNumberAndCustomerId(orderNumber: string, customerId: number): Observable<IOrder> {
        let params = new HttpParams().set('orderNumber', orderNumber);
        params = params.append('customerId', customerId.toString());
        return this.http.get<IOrder>(`/orders/order-number`, { params: params });
    }

    getUnscheduledOrders(searchparameters: SearchParams): Observable<HttpResponse<IOrder[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IOrder[]>('/orders/scheduling-queue', {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    markOrdersAsPickedUp(orders: IOrder[]): Observable<number> {
        return this.http.put<number>(`/orders/picked-up`, orders);
    }

    scheduleOrders(orders: IOrder[], scheduleDate: Date, validTwicCard: boolean): Observable<IPickup>{
        const params: IScheduleOrderParams = {
            scheduleDate: scheduleDate,
            validTwicCard: validTwicCard,
        };
        const object = {
            Orders: orders,
            Params: params,
            UserId: this.authService.currentUser.getValue().Id,
        };
        return this.http.put<IPickup>(
            `/orders/schedule/mass`,
            object
        ).pipe(
            catchError((err, caught) => this.handleError(err as Response, caught))
        );
    }

    removeUnscheduledOrdersFromList(orders: IOrder[]) {
        this.newlyScheduledOrdersSubject.next(orders);
    }

    unscheduleOrder(orderId: number): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/unschedule`, {
            responseType: 'text' as 'json',
        });
    }

    unscheduleOrders(pickupId: number, orderIds: number[]): Observable<number> {
        return this.http.put<number>(`/orders/unschedule/mass/${pickupId}`, orderIds,{
            responseType: 'text' as 'json',
        });
    }

    confirmOrder(orderId: number): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/confirm`, {
            responseType: 'text' as 'json',
        });
    }

    uncancelOrder(orderId: number): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/uncancel`, {
            responseType: 'text' as 'json',
        });
    }

    cancelOrder(orderId: number): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/cancel`, {
            responseType: 'text' as 'json',
        });
    }

    getCustomerUnscheduledOrders(customerId: number, carrierId: number, officeId: number): Observable<IOrder[]> {
        const params = new HttpParams().set('carrierId', carrierId.toString()).set('officeId', officeId.toString());
        return this.http.get<IOrder[]>(`/orders/${customerId}/unscheduled`, { params: params });
    }

    getScheduledOrders(customerId: number, date: Date, officeId: number): Observable<ITimeSlot[]> {
        const params: IScheduleSearch = {
            CustomerId: customerId,
            Date: date,
            OfficeId: officeId,
        };
        return this.http.post<ITimeSlot[]>(`/orders/scheduled`, params);
    }

    // getPickups(): Observable<>

    deleteOrder(orderId: number): Observable<void> {
        return this.http.delete<void>(`/orders/${orderId}`, {
            responseType: 'text' as 'json',
        });
    }

    getLastSyncedDate(): Observable<Date> {
        return timer(0, 300000).pipe(
            flatMap(() => {
                // runs every 5 minutes on the page
                return this.http.get<Date>('/orders/lastsynceddate');
            }),
        );
    }

    getLastSyncedFileName(): Observable<string> {
        return timer(0, 300000).pipe(
            flatMap(() => {
                // runs every 5 minutes on the page
                return this.http.get<string>('/orders/lastsyncedfilename', { responseType: 'text' as 'json' });
            }),
        );
    }

    getStatuses(): Observable<IOrderStatus[]> {
        return this.http.get<IOrderStatus[]>('/orders/statuses');
    }

    updateSchedulerEmailForOrder(orderId: number, email: string): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/email/${email}`, { responseType: 'text' as 'json' }).pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    updateArchivedForOrder(orderId: number, archived: number): Observable<number> {
        return this.http
            .put<number>(`/orders/${orderId}/archive/${archived}`, { responseType: 'text' as 'json' })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)), tap(() => {
                this.orderUpdatedSubject.next();
            }));
    }

    sendSchedulerEmail(orderId: number, message: string): Observable<number> {
        const params = {
           Message: message,
           OrderId: orderId,
        };
        return this.http.post<number>(`/orders/sendEmail`, params);
    }

    getOrderNotFulFilledReasons(): Observable<INotFulFilledReason[]> {
        return this.http.get<INotFulFilledReason[]>('/orders/NonFulFilledReasons');
    }

    getAuthUserRole(authId: number): Observable<number> {
        return this.http.get<number>(`/options/authUser/${authId}`);
    }

    getUnscheduledOrdersForCustomer(searchparameters: SearchParams, userId: number): Observable<HttpResponse<IOrder[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IOrder[]>(`/orders/scheduling-queue-for-customer/${userId}`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    getUnscheduledOrdersForCarrier(searchparameters: SearchParams, userId: number): Observable<HttpResponse<IOrder[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IOrder[]>(`/orders/scheduling-queue-for-carrier/${userId}`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    getSimpleOffices(): Observable<IOffice[]> {
        return this.http.get<IOffice[]>(`/options/offices/simple/`);
    }

    updateCarrier(pickupId: number, carrierId: number): Observable<number> {
        return this.http.put<number>(`/orders/pickup/${pickupId}/update-carrier/${carrierId}`, {
            responseType: 'text' as 'json',
        });
    }

    updateWarehouse(orderId: number, warehouseId: number): Observable<void> {
        return this.http.put<void>(`/orders/${orderId}/update-warehouse/${warehouseId}`, {
            responseType: 'text' as 'json',
        });
    }

    pickupOrdersSearch(searchparameters: SearchParams, id: number): Observable<HttpResponse<IOrder[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IOrder[]>(`/orders/pickup/${id}/search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    private _selectedOrder: BehaviorSubject<IOrder> = new BehaviorSubject<IOrder>(null);
    public get selectedOrder$(): BehaviorSubject<IOrder> {
        return this._selectedOrder;
    }

    private _selectedOrders: BehaviorSubject<IEntity[]> = new BehaviorSubject<IEntity[]>([]);
    public get selectedOrders$(): BehaviorSubject<IEntity[]> {
        return this._selectedOrders;
    }

    private _selectedPickupOrders: BehaviorSubject<IOrder[]> = new BehaviorSubject<IOrder[]>([]);
    public get selectedPickupOrders$(): BehaviorSubject<IOrder[]> {
        return this._selectedPickupOrders;
    }

    private _selectedPickupCarrier: BehaviorSubject<ICarrier> = new BehaviorSubject<ICarrier>(null);
    public get selectedPickupCarrier$(): BehaviorSubject<ICarrier> {
        return this._selectedPickupCarrier;
    }

    private _selectedPickupZip: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public get selectedPickupZip$(): BehaviorSubject<string> {
        return this._selectedPickupZip;
    }

    private _authUserRole: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public get authUserRole$(): BehaviorSubject<number> {
        return this._authUserRole;
    }

    static GetOrderTwicVerifiedLabel(order: IOrder): string {
        const isTwicVerified: boolean = OrderService.CheckOrderIsTwicVerified(order);
        if (isTwicVerified === null) {
            return ``;
        }

        return isTwicVerified ? `TWIC` : `Non-TWIC`;
     }

    static CheckOrderIsTwicVerified(order: IOrder): boolean {
        let isTwicVerified: boolean = null;
        switch (order.StatusId) {
            case OrderStatuses.Scheduled:
            case OrderStatuses.Requested:
                isTwicVerified = order.SchedulerAssumesTruckerTwicCard;
                break;
            case OrderStatuses.CheckedIn:
                isTwicVerified = this.CheckOrderTruckerForTwicCard(order);
                break;
            default:
                break;
        }
        return isTwicVerified;
    }

    private static CheckOrderTruckerForTwicCard(order: IOrder): boolean {
        const detail = order.CheckInDetails[0];
        if (!detail) {
            return null;
        }

        const checkIn = detail.CheckIn;
        if (!checkIn) {
            return null;
        }

        const trucker = checkIn.CheckInUser;
        if (!trucker) {
            return null;
        }

        const isTwicVerified: boolean = trucker.ClaimsToHoldValidTwicCard;
        if (isTwicVerified === null || isTwicVerified === undefined) {
            return UserService.checkUserIsTwicVerified(trucker);
        }

        return isTwicVerified;
    }


}
