<div class="row">
    <div class="col-md-7">
        <h2>Manage Scheduling</h2>
    </div>
</div>
<div class="row">
    <div>
        <div class="col-md-2">
            <label for="customer">Customer</label>
            <mt-type-ahead
                #customerTypeaheadControl
                [items]="customers"
                (selectionChanged)="onCustomerSelected($event)"
                [disabled]="userIsCustomer"
            ></mt-type-ahead>
        </div>
        <div class="col-md-2">
            <label for="office">Office</label>
            <mt-type-ahead
                #officeTypeaheadControl
                [items]="officeItems"
                (selectionChanged)="onOfficeSelected($event)"
                [maxToShow]="maxOfficesToShow"
            ></mt-type-ahead>
        </div>
        <div class="col-md-2">
            <label for="office">Carrier</label>
            <mt-type-ahead
                #officeTypeaheadControl
                [items]="carriers"
                (selectionChanged)="onCarrierSelected($event)"
            ></mt-type-ahead>
        </div>
        <div class="col-md-2" *ngIf="formCreated">
            <form [formGroup]="schedulingFilterForm">
                <mt-dynamic-field
                    [field]="abstractSearchUserControls.Date"
                    (valueChanges)="changeDate($event)"
                    [form]="schedulingFilterForm"
                ></mt-dynamic-field>
            </form>
            <div
                class="hour-label"
                *ngIf="startingTime.length > 0 && endingTime.length > 0"
            >
                <span
                    ><i
                        >Todays hours are {{ startingTime }} until
                        {{ endingTime }}</i
                    ></span
                >
            </div>
        </div>
        <div class="col-md-2">
            <label for="search">UnScheduled Orders Search</label>
            <div class="input-group">
                <input
                    type="text"
                    [formControl]="unScheduledsearchControl"
                    class="form-control"
                    placeholder="Search"
                />
                <span class="input-group-btn">
                    <button
                        type="button"
                        (click)="clearUnScheduledSearch()"
                        class="btn btn-default btn-nohover btn-flat"
                    >
                        <i class="fa"></i>
                        <i class="fa fa-remove" aria-hidden="true"></i>
                        <i class="fa"></i>
                    </button>
                </span>
            </div>
            <label for="search">Scheduled Pickups Search (By Order Number)</label>
            <div class="input-group">
                <input
                    type="text"
                    [formControl]="scheduledsearchControl"
                    class="form-control"
                    placeholder="Search"
                />
                <span class="input-group-btn">
                    <button
                        type="button"
                        (click)="clearScheduledSearch()"
                        class="btn btn-default btn-nohover btn-flat"
                    >
                        <i class="fa"></i>
                        <i class="fa fa-remove" aria-hidden="true"></i>
                        <i class="fa"></i>
                    </button>
                </span>
            </div>
        </div>
        <div
            class="col-md-2"
            *ngIf="
                selectedCustomer &&
                selectedCustomer.CanSchedulePerDockLocation &&
                selectedCustomer.CustomerDockLocations
            "
        >
            <div class="panel p-m" [ngStyle]="{ padding: '7px' }">
                <b>Customer Dock Location Orders Per Hour</b>
                <div
                    *ngFor="
                        let location of selectedCustomer.CustomerDockLocations
                    "
                >
                    {{ location.DockLocation.Name }}:
                    {{ location.NoOfPickupsPerHour }}
                </div>
            </div>
        </div>
    </div>
</div>
