import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ICustomerPartial } from '../../model/partials/customer';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CustomerService } from '../customer.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { CustomerSharedEntities } from '../shared-entities/customer.shared-entities';
import { PhoneCollectionDTO } from '../../model/interfaces/custom/phone-collection.dto';

@Component({
    selector: 'app-customer-detail',
    templateUrl: './customer-detail.component.html',
})
export class CustomerDetailComponent implements OnInit {
    customer: ICustomerPartial;
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;

    contactsSharedEntityId = CustomerSharedEntities.Contacts;
    holtContactsSharedEntityId = CustomerSharedEntities.HoltContacts;

    constructor(
        private customerService: CustomerService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('customerId');
        // try load if id > 0
        if (this.id > 0) {
            this.getCustomerById(this.id);
        } else {
            // set customer to emptyCustomer
            this.customer = this.customerService.getEmptyCustomer();
        }
        this.editingComponent.next('');
    }

    getCustomerById(id: number): void {
        this.customerService.getById(id).subscribe((customer) => {
            this.customer = customer;
        });
    }

    savePhones(phoneCollection: PhoneCollectionDTO): void {
        this.customerService.saveCustomerPhones(this.customer.Id, phoneCollection).subscribe(() => {
            this.notificationsService.success('Phones Saved Successfully');
            this.customer.CustomerPhones = phoneCollection.Phones;
            this.editingComponent.next('');
        });
    }
}
