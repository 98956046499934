import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IPickup } from '../interfaces/pickup';
import { ICarrier } from '../interfaces/carrier';
import { IUser } from '../interfaces/user';
import { INotFulFilledReason } from '../interfaces/not-ful-filled-reason';
import { IPickupStatus } from '../interfaces/pickup-status';

export interface IPickupDynamicControlsParameters {
    formGroup?: string;
    carriers?: ICarrier[];
    notFulFilledReasons?: INotFulFilledReason[];
    drivers?: IUser[];
    statuses?: IPickupStatus[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class PickupDynamicControls {

    formGroup: string;
    carriers?: ICarrier[];
    notFulFilledReasons?: INotFulFilledReason[];
    drivers?: IUser[];
    statuses?: IPickupStatus[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private pickup?: IPickup, additionalParameters?: IPickupDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Pickup';
        this.carriers = additionalParameters && additionalParameters.carriers || undefined;
        this.notFulFilledReasons = additionalParameters && additionalParameters.notFulFilledReasons || undefined;
        this.drivers = additionalParameters && additionalParameters.drivers || undefined;
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            CarrierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                name: 'CarrierId',
                options: this.carriers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.CarrierId || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.pickup && this.pickup.CreatedById || null,
            }),
            DateConfirmed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Confirmed',
                name: 'DateConfirmed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.DateConfirmed || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.DateCreated || null,
            }),
            DateExported: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Exported',
                name: 'DateExported',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.DateExported || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.DateModified || null,
            }),
            DateRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Requested',
                name: 'DateRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.DateRequested || null,
            }),
            DeliveryZipCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Delivery Zip Code',
                name: 'DeliveryZipCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.pickup && this.pickup.hasOwnProperty('DeliveryZipCode') && this.pickup.DeliveryZipCode != null ? this.pickup.DeliveryZipCode.toString() : '',
            }),
            DriverId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Driver',
                name: 'DriverId',
                options: this.drivers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.DriverId || null,
            }),
            GatePassNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gate Pass Number',
                name: 'GatePassNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.pickup && this.pickup.hasOwnProperty('GatePassNumber') && this.pickup.GatePassNumber != null ? this.pickup.GatePassNumber.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.ModifiedById || null,
            }),
            NotFulFilledReasonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Not Ful Filled Reason',
                name: 'NotFulFilledReasonId',
                options: this.notFulFilledReasons,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.NotFulFilledReasonId || null,
            }),
            PickupNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pickup Number',
                name: 'PickupNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.pickup && this.pickup.hasOwnProperty('PickupNumber') && this.pickup.PickupNumber != null ? this.pickup.PickupNumber.toString() : '',
            }),
            PrevDateRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Prev Date Requested',
                name: 'PrevDateRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.PrevDateRequested || null,
            }),
            SecurityGateFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Security Gate Fee',
                name: 'SecurityGateFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.SecurityGateFee || 0,
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.pickup && this.pickup.StatusId || null,
            }),
            ValidTwicCard: new DynamicField({
                formGroup: this.formGroup,
                label: 'Valid Twic Card',
                name: 'ValidTwicCard',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.hasOwnProperty('ValidTwicCard') && this.pickup.ValidTwicCard != null ? this.pickup.ValidTwicCard : false,
            }),
            WalkIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Walk In',
                name: 'WalkIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pickup && this.pickup.hasOwnProperty('WalkIn') && this.pickup.WalkIn != null ? this.pickup.WalkIn : false,
            }),
        };

        this.View = {
            CarrierId: new DynamicLabel({
                label: 'Carrier',
                value: getMetaItemValue(this.carriers as unknown as IMetaItem[], this.pickup && this.pickup.hasOwnProperty('CarrierId') ? this.pickup.CarrierId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreatedById: new DynamicLabel({
                label: 'Created By',
                value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.pickup && this.pickup.hasOwnProperty('CreatedById') ? this.pickup.CreatedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateConfirmed: new DynamicLabel({
                label: 'Date Confirmed',
                value: this.pickup && this.pickup.DateConfirmed || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.pickup && this.pickup.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateExported: new DynamicLabel({
                label: 'Date Exported',
                value: this.pickup && this.pickup.DateExported || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.pickup && this.pickup.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateRequested: new DynamicLabel({
                label: 'Date Requested',
                value: this.pickup && this.pickup.DateRequested || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DeliveryZipCode: new DynamicLabel({
                label: 'Delivery Zip Code',
                value: this.pickup && this.pickup.hasOwnProperty('DeliveryZipCode') && this.pickup.DeliveryZipCode != null ? this.pickup.DeliveryZipCode.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            DriverId: new DynamicLabel({
                label: 'Driver',
                value: getMetaItemValue(this.drivers as unknown as IMetaItem[], this.pickup && this.pickup.hasOwnProperty('DriverId') ? this.pickup.DriverId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            GatePassNumber: new DynamicLabel({
                label: 'Gate Pass Number',
                value: this.pickup && this.pickup.hasOwnProperty('GatePassNumber') && this.pickup.GatePassNumber != null ? this.pickup.GatePassNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedById: new DynamicLabel({
                label: 'Modified By',
                value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.pickup && this.pickup.hasOwnProperty('ModifiedById') ? this.pickup.ModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            NotFulFilledReasonId: new DynamicLabel({
                label: 'Not Ful Filled Reason',
                value: getMetaItemValue(this.notFulFilledReasons as unknown as IMetaItem[], this.pickup && this.pickup.hasOwnProperty('NotFulFilledReasonId') ? this.pickup.NotFulFilledReasonId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PickupNumber: new DynamicLabel({
                label: 'Pickup Number',
                value: this.pickup && this.pickup.hasOwnProperty('PickupNumber') && this.pickup.PickupNumber != null ? this.pickup.PickupNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            PrevDateRequested: new DynamicLabel({
                label: 'Prev Date Requested',
                value: this.pickup && this.pickup.PrevDateRequested || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            SecurityGateFee: new DynamicLabel({
                label: 'Security Gate Fee',
                value: this.pickup && this.pickup.SecurityGateFee || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            StatusId: new DynamicLabel({
                label: 'Status',
                value: getMetaItemValue(this.statuses as unknown as IMetaItem[], this.pickup && this.pickup.hasOwnProperty('StatusId') ? this.pickup.StatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ValidTwicCard: new DynamicLabel({
                label: 'Valid Twic Card',
                value: this.pickup && this.pickup.hasOwnProperty('ValidTwicCard') && this.pickup.ValidTwicCard != null ? this.pickup.ValidTwicCard : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            WalkIn: new DynamicLabel({
                label: 'Walk In',
                value: this.pickup && this.pickup.hasOwnProperty('WalkIn') && this.pickup.WalkIn != null ? this.pickup.WalkIn : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
