import { IEntity } from '@mt-ng2/base-service';
import { DynamicField, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ICustomer } from '../../model/interfaces/customer';
import { IOrder } from '../../model/interfaces/order';

export const CommonFunctions = {
    /**
     * Maps a collection of items into an array of MtSearchFilterItems
     * @param items
     * @param nameKey If the provided entities don't have a Name prop by default,
     * you can supply the value you'd like to use as a key
     */
    mapMtSearchFilterItems: mapMtSearchFilterItems,
    mapMultiMtSearchFilterItems: mapMultiMtSearchFilterItems,
    formatDateForInput: formatDateForInput,
    setToMidnight: setToMidnight,
    getUTCEquivalent: getUTCEquivalent,
    OverrideNumericFunctionsEmptyWhenZero: OverrideNumericFunctionsEmptyWhenZero,
    getLargestCustomerPalletCount: getLargestCustomerPalletCount,
    getLargestCustomerOrderWeight: getLargestCustomerOrderWeight,
    getOrdersTotalPallet: getOrdersTotalPallet,
    getOrdersTotalWeight: getOrdersTotalWeight,
    isToday: isToday,
    areArraysIdentical: areArraysIdentical,
};

function mapMtSearchFilterItems(items: IEntity[], nameKey = 'Name'): MtSearchFilterItem[] {
    return items.map((item) => {
        return new MtSearchFilterItem(
            {
                Id: item.Id,
                Name: item[nameKey],
            }, false,
        );
    });
}

function mapMultiMtSearchFilterItems(items: IEntity[], keyOne: string, keyTwo: string, keyThree: string): MtSearchFilterItem[] {
    return items?.map((item) => {
        return new MtSearchFilterItem(
            {
                Id: item.Id,
                Name: `${item[keyOne] as string} ${item[keyTwo] as string} ${item[keyThree] as string}`,
            }, false,
        );
    });
}

function formatDateForInput(dateString: Date): string {
    if (!dateString)
        return '';
        
    const dateObj = new Date(dateString);
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function setToMidnight(date: Date): Date {
    const newDate = new Date(date);
    newDate.setUTCHours(0, 0, 0, 0);
    return newDate;
}

function getUTCEquivalent(localDateTimeString: string) {
    // Parse string to date.
    const localDate = new Date(localDateTimeString);

    // Get the local timezone offset in minutes.
    const timezoneOffsetMinutes = localDate.getTimezoneOffset();

    // Adjust the date by the offset to get the equivalent UTC time.
    return new Date(localDate.getTime() - timezoneOffsetMinutes * 60 * 1000);
}

// Force fields to be empty instead of showing '0' when value is null.
export function OverrideNumericFunctionsEmptyWhenZero(fields: DynamicField[]): void {
    fields.forEach((field) => {
        if (field.type.fieldType === DynamicFieldTypes.Numeric) {
            field.type.numericFunctions = {
                numberFormatDisplay: (value) => {
                    if (value === null || value === undefined) {
                        return '';
                    } else {
                        return value.toString();
                    }
                },
            };
        }
    });
}

function getLargestCustomerPalletCount(customers: ICustomer[]): number {
    return customers.reduce((max, cur) => Math.max(max, cur.PalletCount), 0);
}

function getLargestCustomerOrderWeight(customers: ICustomer[]): number {
    return customers.reduce((max, cur) => Math.max(max, cur.OrderWeight), 0);
}

function getOrdersTotalPallet(orders: IOrder[]): number {
    return orders.reduce((sum, cur) => sum + cur.TotalPallet, 0);
}

function getOrdersTotalWeight(orders: IOrder[]): number {
    return orders.reduce((sum, cur) => sum + cur.TotalWeight, 0);
}

function isToday(date: string): boolean {
    if (!date) return false;

    const today = new Date();
    const dateToCheck = new Date(date);
    return dateToCheck.getDate() === today.getDate() &&
           dateToCheck.getMonth() === today.getMonth() &&
           dateToCheck.getFullYear() === today.getFullYear();
}

function areArraysIdentical(arr1: number[], arr2: number[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const sortedArr1 = [...arr1].sort((a, b) => a - b);
    const sortedArr2 = [...arr2].sort((a, b) => a - b);

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
