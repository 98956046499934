<div
    class="miles-editable miles-card padded"
    *ngIf="false"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <mt-dynamic-view [config]="viewOnly"></mt-dynamic-view>
</div>
<div *ngIf="isConfigSet" class="miles-form padded">
    <h4 class="text-center">Driver Info</h4>
    <mt-dynamic-form [config]="formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
        <button
            type="submit"
            *ngIf="this.user.Id > 0"
            mtDisableClickDuringHttpCalls
            Class="btn btn-flat btn-success"
        >
            Check-In
        </button>
        <button
            type="submit"
            *ngIf="this.user.Id === 0"
            mtDisableClickDuringHttpCalls
            Class="btn btn-flat btn-success"
        >
            Create Account
        </button>
    </mt-dynamic-form>
</div>
<br/>
<div>
  <div style="margin:auto; width: 250px;">
      <button class="btn btn-danger" (click)="routeToKioskHome()">
          Clear Information and Start Over
      </button>
  </div>
</div>

<mt-modal-wrapper
    title="Inactivity Detected"
    [autoShow]="false"
    (ready)="inactivityDialogApi = $event"
    [options]="{ showConfirmButton: true, 
        showCancelButton: false, 
        showCloseButton: false, 
        allowOutsideClick: false, 
        confirmButtonText:'Yes, I am still checking in' }"
    (okClick)="cancelTimeoutRedirect()"
>
    <div style="margin:auto">Are you still checking in?  Confirm or your session will be cleared in 30 seconds.</div>
</mt-modal-wrapper>